import { Spinner } from "react-bootstrap";
import CompanyLogo from "../company-logo";

const FetchLoadingCompanyLogo = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <CompanyLogo width={100} height={100} />
      <div className="d-flex flex-row justify-content-between align-items-center gap-1 mt-3">
        Loading{" "}
        <Spinner
          animation="grow"
          style={{
            width: 10,
            height: 10,
          }}
          variant="success"
        />
        <Spinner
          animation="grow"
          style={{
            width: 12,
            height: 12,
          }}
          variant="success"
        />
        <Spinner
          animation="grow"
          style={{
            width: 14,
            height: 14,
          }}
          variant="success"
        />
      </div>
    </div>
  );
};

export default FetchLoadingCompanyLogo;
