import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/filter-unit-kerja";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import Select2 from "../../../hope-ui/components/select-2";

const ModalFilterUnitKerja = () => {
  const {
    showModal,
    setShowModal,
    formikFilterUnitKerja,
    submitting,
    setSubmitting,
    detailFilterUnitKerja,
    setResetForm,
    isLoadingGetDetailFilterUnitKerja,
    isLoadingPostFilterUnitKerja,
    handleGetAllDaftarUnitKerja,
    isLoadingGetAllDaftarUnitKerja,
    handleGetAllHakAkses,
    isLoadingGetAllHakAkses,
    allHakAkses,
    allDaftarUnitKerja,
  } = useQueryResponse();

  const optionDaftarUnitKerja = allDaftarUnitKerja.map((val) => {
    return {
      label: val.nama_unit_kerja,
      value: val.id,
    };
  });

  const optionHakAkses = allHakAkses.map((val) => {
    return {
      label: val.name,
      value: val.name,
    };
  });

  useEffect(() => {
    if (showModal) {
      handleGetAllDaftarUnitKerja();
      handleGetAllHakAkses();
    }
  }, [showModal]);

  useEffect(() => {
    if (detailFilterUnitKerja.id) {
      formikFilterUnitKerja?.setValues(detailFilterUnitKerja);
    }
  }, [detailFilterUnitKerja]);

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onBackdropClick={() => {
        setShowModal(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModal(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikFilterUnitKerja?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailFilterUnitKerja.id ? "Edit" : "Tambah"} Filter Unit Kerja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailFilterUnitKerja ||
            isLoadingPostFilterUnitKerja) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <Select2
                label="Hak Akses"
                placeholder="Pilih Hak Akses"
                isRequired
                onChange={(e: any) => {
                  formikFilterUnitKerja?.setFieldValue("hak_akses", e?.value);
                }}
                options={optionHakAkses}
                isLoading={isLoadingGetAllHakAkses}
                value={
                  formikFilterUnitKerja?.getFieldProps("hak_akses").value ??
                  null
                }
              />
              {submitting && formikFilterUnitKerja?.errors.hak_akses && (
                <div className="fs-6 text-danger mt-2">
                  {formikFilterUnitKerja?.errors.hak_akses}
                </div>
              )}
            </Col>

            <Col lg="6">
              <Select2
                label="Unit Kerja"
                placeholder="Pilih Unit Kerja"
                isRequired
                onChange={(e: any) => {
                  formikFilterUnitKerja?.setFieldValue(
                    "id_unit_kerja",
                    e?.value
                  );
                }}
                options={optionDaftarUnitKerja}
                isLoading={isLoadingGetAllDaftarUnitKerja}
                value={
                  formikFilterUnitKerja?.getFieldProps("id_unit_kerja").value ??
                  null
                }
              />
              {submitting && formikFilterUnitKerja?.errors.id_unit_kerja && (
                <div className="fs-6 text-danger mt-2">
                  {formikFilterUnitKerja?.errors.id_unit_kerja}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalFilterUnitKerja;
