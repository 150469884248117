import { FC } from "react";
import { Row } from "react-table";

type Props = {
  row: Row<any>;
};

export const TableRow: FC<Props> = ({ row }) => (
  <tr
    {...row.getRowProps()}
    className="fs-7 border-bottom border-gray-300 table-row"
  >
    {row.cells.map((cell) => {
      return (
        <td {...cell.getCellProps()} className="px-0 table-cell">
          <div
            style={{ fontSize: 14 }}
            className="text-center w-100 table-cell cursor-pointer"
          >
            {cell.render("Cell")}
          </div>
        </td>
      );
    })}
  </tr>
);
