import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";

export type FormKepegawaianPayload = {
  id: number | null;
  id_kepegawaian: string | null;
  nik_gtk: string | null;
  niy_nigk: string | null;
  nuptk: string | null;
  jenis_ptk: string | null;
  status_golongan_kepegawaian: string | null;
  nomor_sk_pengangkatan: string | null;
  tmt_pengangkatan: Dayjs | Date | string | null;
  masa_berlaku_sk: Dayjs | Date | string | null;
  kompetensi_khusus: string | null;
  nilai_teori: number | null;
  nilai_praktek: number | null;
  jumlah_juz: number | null;
  toefl: number | null;
  doc_kepegawaian: File[] | null;
  jenis_kepegawaian: string | null;
  //preview_doc_kepegawaian: string | null;
};

type DetailKepegawaian = {
  id: number | null;
  id_kepegawaian: string | null;
  nik_gtk: string | null;
  niy_nigk: string | null;
  nuptk: string | null;
  jenis_ptk: string | null;
  status_golongan_kepegawaian: string | null;
  nomor_sk_pengangkatan: string | null;
  tmt_pengangkatan: Date | null;
  masa_berlaku_sk: Date | null;
  kompetensi_khusus: string | null;
  nilai_teori: number | null;
  nilai_praktek: number | null;
  jumlah_juz: number | null;
  toefl: number | null;
  doc_kepegawaian: string | null;
};

export type QueryResponseContextProps<T> = {
  handlePostKepegawaian?: (data: FormData) => void;
  isLoadingPostKepegawaian?: boolean;
  handleGetDetailKepegawaian?: (id: string) => void;
  isLoadingGetDetailKepegawaian?: boolean;
  detailKepegawaian?: Response<T>;
  setDetailKepegawaian: Dispatch<SetStateAction<DetailKepegawaian>>;
  handleDeleteKepegawaian?: (id: string) => void;
  isLoadingDeleteKepegawaian?: boolean;
  handleDownloadDocKepegawaian: (url: string, fileName: string) => void;
  isLoadingDownloadDocKepegawaian: boolean;
  showModalDetailKepegawaian: boolean;
  setShowModalDetailKepegawaian: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  handleGetDetailKepegawaian: () => {},
  isLoadingGetDetailKepegawaian: false,
  setDetailKepegawaian: () => {},
  isLoadingPostKepegawaian: false,
  handleDownloadDocKepegawaian: () => {},
  isLoadingDownloadDocKepegawaian: false,
  showModalDetailKepegawaian: false,
  setShowModalDetailKepegawaian: () => {},
};
