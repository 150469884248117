import axios, { AxiosResponse } from "axios";
import { client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getHakAkses = async (query: string): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_HAK_AKSES_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailHakAkses = async (id: string): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_DETAIL_HAK_AKSES_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteHakAkses = async (id: string): Promise<any> => {
  return client_sso
    .delete(`${ApiConfig.DELETE_HAK_AKSES_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postHakAkses = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.POST_HAK_AKSES_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getHakAksesMenu = async (query: string): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_HAK_AKSES_MENU_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllAppName = async (): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_ALL_APP_NAME_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllHakAkses = async (): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_ALL_HAK_AKSES_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getUserHakAkses = async (id: string): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_USER_DETAIL_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllMenus = async (app_name: string, rolesId: number): Promise<any> => {
  return client_sso
    .get(
      `${ApiConfig.GET_ALL_MENU_URL}?app_name=${app_name}&rolesId=${rolesId}`
    )
    .then((response: AxiosResponse<any>) => response.data);
};

const postRolesMenu = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.POST_ROLES_MENU_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const postUserHakAkses = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.POST_USER_HAK_AKSES_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getHakAkses,
  postHakAkses,
  getDetailHakAkses,
  deleteHakAkses,
  getHakAksesMenu,
  getAllAppName,
  getAllHakAkses,
  getAllMenus,
  postRolesMenu,
  getUserHakAkses,
  postUserHakAkses,
};
