import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormAnggotaKelompokBPIPayload } from "../anggota-kelompok-bpi/_queryResponse";
import { FormikProps } from "formik";

export type FormAbsensiBPIPayload = {
  id: number | null;
  id_absensi_bpi: number | null;
  id_riwayat_bpi: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
};

export type FormRiwayatBPIPayload = {
  id: number | null;
  riwayat_tanggal: Date | null;
  mc: string | null;
  batas_tilawah_halaman: number | string | null;
  infaq: number | string | null;
  total_infaq: number | string | null;
  tempat: string | null;
  materi: string | null;
  nik_gtk_tutor: string | null;
  id_kelompok_bpi: number | null;
  kehadiran_bpi: Array<FormAbsensiBPIPayload>;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  handleGetAllMC?: (query: string) => void;
  isLoadingGetAllMC: boolean;
  allMC: Array<any>;
  setAllMC: Dispatch<SetStateAction<Array<any>>>;
  formikBuatAbsensi?: FormikProps<FormRiwayatBPIPayload>;
  formikBuatAbsensiValidationSchema?: any;
  currentOffset: number;
  setCurrentOffset: Dispatch<SetStateAction<number>>;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostBuatAbsensiBPI?: (payload: FormData) => void;
  isLoadingPostBuatAbsensiBPI: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetDetailAbsensiBPI: (id: string) => void;
  isLoadingGetDetailAbsensiBPI: boolean;
  detailAbsensiBPI: FormRiwayatBPIPayload;
  setDetailAbsensiBPI: Dispatch<SetStateAction<FormRiwayatBPIPayload>>;
  handleGetTotalInfaq?: (id_kelompok_bpi: string) => void;
  isLoadingGetTotalInfaq: boolean;
  totalInfaq: number;
  setTotalInfaq: Dispatch<SetStateAction<number>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingGetAllMC: false,
  allMC: [],
  setAllMC: () => {},
  currentOffset: 0,
  setCurrentOffset: () => {},
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostBuatAbsensiBPI: false,
  resetForm: false,
  setResetForm: () => {},
  handleGetDetailAbsensiBPI: () => {},
  isLoadingGetDetailAbsensiBPI: false,
  detailAbsensiBPI: {
    id: null,
    riwayat_tanggal: null,
    mc: "",
    batas_tilawah_halaman: "",
    infaq: "",
    total_infaq: 0,
    tempat: "",
    materi: "",
    nik_gtk_tutor: "",
    id_kelompok_bpi: null,
    kehadiran_bpi: [],
  },
  setDetailAbsensiBPI: () => {},
  isLoadingGetTotalInfaq: false,
  totalInfaq: 0,
  setTotalInfaq: () => {},
};
