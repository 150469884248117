import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";
import moment from "moment";

const downloadDatabaseGTK = async (url?: string): Promise<any> => {
  return client
    .get(`/backup-data/gtk`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `backup_database_GTK_${moment().format("DD MMM YYYY HH mm ss")}.sql`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const downloadDatabaseSSO = async (url?: string): Promise<any> => {
  return client_sso
    .get(`/backup-data`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `backup_database_SSO_${moment().format("DD MMM YYYY HH mm ss")}.sql`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const getHistoryBackup = async (): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_HISTORY_BACKUP}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postHistoryBackup = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.POST_HISTORY_BACKUP}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  downloadDatabaseGTK,
  downloadDatabaseSSO,
  postHistoryBackup,
  getHistoryBackup,
};
