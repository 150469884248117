import { Column } from "react-table";

import { Header } from "../../../../../../hope-ui/components/table/Header";
import NamaGTKCell from "../../../../../../hope-ui/components/table/NamaGTKCell";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-lt3q/buat-absensi";
import { AksiCell } from "./AksiCell";
import { KeteranganCell } from "./KeteranganCell";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].gtk.nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama kelompok"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "riwayat_lt3q.kelompok_lt3q.nama_kelompok_lt3q",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].riwayat_lt3q?.kelompok_lt3q
          .nama_kelompok_lt3q ??
          props.data[props.row.index].kelompok_lt3q.nama_kelompok_lt3q}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Kehadiran"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action_kehadiran",
    Cell: ({ ...props }) => (
      <AksiCell data={props.data[props.row.index]} index={props.row.index} />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Keterangan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action_keterangan",
    Cell: ({ ...props }) => (
      <KeteranganCell
        data={props.data[props.row.index]}
        index={props.row.index}
      />
    ),
  },
];
