import Swal from "sweetalert2";

const SweetAlert = async (params: {
  icon: any;
  title?: string;
  text?: string;
  isShowCancelButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  allowOutsideClick?: boolean | true;
  html?: string | HTMLElement | undefined;
  cancelButtonColor?: string;
  confirmButtonColor?: string;
}) => {
  const {
    icon,
    title,
    text,
    isShowCancelButton,
    confirmButtonText,
    cancelButtonText,
    allowOutsideClick,
    html,
    cancelButtonColor,
    confirmButtonColor,
  } = params;

  return Swal.fire({
    icon,
    title,
    text,
    html,
    heightAuto: false,
    confirmButtonText: `<span style="margin: 0 50px;">${
      confirmButtonText ?? "OK"
    }</span>`,
    cancelButtonText: isShowCancelButton
      ? `<span style="margin: 0 50px;">${cancelButtonText ?? "Cancel"}</span>`
      : undefined,
    confirmButtonColor:
      confirmButtonColor && confirmButtonColor.length > 0
        ? confirmButtonColor
        : "#009ef7",
    cancelButtonColor:
      cancelButtonColor && cancelButtonColor.length > 0
        ? cancelButtonColor
        : "#DC3545",
    showCancelButton: isShowCancelButton,
    reverseButtons: isShowCancelButton,
    allowOutsideClick,
  });
};

export { SweetAlert };
