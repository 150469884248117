import { Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/memo";
import { useQueryResponse as useQueryResponseDataGTK } from "../../../../provider/query-response/data-gtk";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";
import { useEffect, useState } from "react";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import ListMemo from "./list";
import GTKCard from "../../../../hope-ui/components/gtk-card";
import { useQueryRequest } from "../../../../provider/query-request/memo";
import { useAuth } from "../../../../provider/core/auth";
import FormEntry from "./entry";
import DetailMemo from "./detail-memo";

const ModalMemo = () => {
  const {
    isShowModalMemo,
    setShowModalMemo,
    setShowDetailMemo,
    isShowDetailMemo,
  } = useQueryResponse();

  const { nikGTK, detailGTK, handleGetDetailGTK, isLoadingGetDetailGTK } =
    useQueryResponseDetailGTK();
  const { updateState } = useQueryRequest();

  const [activeTab, setActiveTab] = useState(2);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (
      handleGetDetailGTK &&
      nikGTK &&
      isShowModalMemo &&
      currentUser &&
      currentUser.data
    ) {
      handleGetDetailGTK(nikGTK);
      const nik_pengirim = currentUser.data["username"];
      updateState({
        nik_pengirim: nik_pengirim,
        nik_tujuan: nikGTK,
        sort_by: null,
        size: 10,
        page: 1,
        col_header: `["judul_pesan", "updatedAt", "status_pesan"]`,
      });
    }

    if (isShowModalMemo) setActiveTab(2);
  }, [handleGetDetailGTK, nikGTK, isShowModalMemo, currentUser]);

  const nik_pengirim: any =
    currentUser && currentUser.data && currentUser.data["username"];
  const nik_tujuan: any = nikGTK;

  return (
    <Modal
      backdrop="static"
      show={isShowModalMemo}
      onBackdropClick={() => {
        setShowModalMemo(false);
      }}
      size="lg"
      onHide={() => setShowModalMemo(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Kirim Memo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoadingGetDetailGTK && <FetchLoadingCompanyLogo />}
        {!isLoadingGetDetailGTK && (
          <>
            <GTKCard data={detailGTK} />
            <button
              type="button"
              className={` ${
                activeTab === 1
                  ? "bg-primary text-white border border-primary"
                  : "bg-white text-dark border border-white"
              } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
              onClick={() => {
                setActiveTab(1);
                setShowDetailMemo(false);
              }}
              style={{
                display: !isShowDetailMemo ? "" : "none",
              }}
            >
              Form Kirim Memo
            </button>

            <button
              type="button"
              className={` ${
                activeTab === 2
                  ? "bg-primary text-white border border-primary"
                  : "bg-white text-dark border border-white"
              } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill `}
              onClick={() => {
                setActiveTab(2);
                setShowDetailMemo(false);
              }}
              style={{
                display: !isShowDetailMemo ? "" : "none",
              }}
            >
              Daftar Memo
            </button>
            <FormEntry
              activeTab={activeTab}
              nik_pengirim={nik_pengirim}
              nik_tujuan={nik_tujuan}
            />
            <ListMemo activeTab={activeTab} />
            <DetailMemo />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalMemo;
