import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormMapelPayload,
  initialQueryResponse,
} from "../../../models/mapel/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/mapel";
import {
  deleteMapel,
  getAllMapel,
  getDetailMapel,
  getListMapel,
  postMapel,
} from "../../../api/mapel/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormMasterMapelPayload } from "../../../models/master-data/master-mapel/_queryResponse";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama_mapel"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalMapel, setShowModalMapel] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [allMapel, setAllMapel] = useState<Array<FormMasterMapelPayload>>([]);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailMapel.nik_gtk = nik_gtk;

  const [detailMapel, setDetailMapel] = useState<FormMapelPayload>(
    initialQueryResponse.detailMapel
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikMapel.setValues(initialQueryResponse.detailMapel);
      setDetailMapel(initialQueryResponse.detailMapel);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_MAPEL}-${query}`,
    () => getListMapel(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikMapelValidationSchema = Yup.object().shape({
    nama_mapel: Yup.string().required("Nama Mata Pelajaran Tidak Boleh Kosong"),
  });

  const formikMapel = useFormik<FormMapelPayload>({
    initialValues: initialQueryResponse.detailMapel,
    enableReinitialize: false,
    validationSchema: formikMapelValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostMapel(payload);
    },
  });

  const { mutateAsync: PostMapel, isLoading: isLoadingPostMapel } = useMutation(
    {
      mutationKey: `${QUERIES.POST_MAPEL}`,
      mutationFn: ({ payload }: { payload: FormData }) => postMapel(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_MAPEL}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    }
  );

  const handlePostMapel = useCallback(
    async (payload: any) => {
      await PostMapel({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalMapel(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostMapel]
  );

  const { mutateAsync: GetDetailMapel, isLoading: isLoadingGetDetailMapel } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_MAPEL}`,
      mutationFn: ({ id }: { id: string }) => getDetailMapel(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_MAPEL}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailMapel = useCallback(
    async (id: string) => {
      await GetDetailMapel({ id })
        .then((res) => {
          setDetailMapel(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailMapel]
  );

  const { mutateAsync: GetAllMapel, isLoading: isLoadingGetAllMapel } =
    useMutation({
      mutationKey: `${QUERIES.GET_ALL_MAPEL}`,
      mutationFn: () => getAllMapel(),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_ALL_MAPEL}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetAllMapel = useCallback(async () => {
    await GetAllMapel()
      .then((res) => {
        setAllMapel(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetAllMapel]);

  const { mutateAsync: DeleteMapel, isLoading: isLoadingDeleteMapel } =
    useMutation({
      mutationKey: `${QUERIES.POST_MAPEL}`,
      mutationFn: ({ id }: { id: string }) => deleteMapel(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_MAPEL}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDeleteMapel = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteMapel({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteMapel]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalMapel,
      setShowModalMapel,
      formikMapel,
      formikMapelValidationSchema,
      submitting,
      setSubmitting,
      handlePostMapel,
      isLoadingPostMapel,
      detailMapel,
      setDetailMapel,
      handleGetDetailMapel,
      isLoadingGetDetailMapel,
      resetForm,
      setResetForm,
      handleGetAllMapel,
      isLoadingGetAllMapel,
      handleDeleteMapel,
      isLoadingDeleteMapel,
      allMapel,
      setAllMapel,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalMapel,
      setShowModalMapel,
      formikMapel,
      formikMapelValidationSchema,
      submitting,
      setSubmitting,
      handlePostMapel,
      isLoadingPostMapel,
      detailMapel,
      setDetailMapel,
      handleGetDetailMapel,
      isLoadingGetDetailMapel,
      resetForm,
      setResetForm,
      handleGetAllMapel,
      isLoadingGetAllMapel,
      handleDeleteMapel,
      isLoadingDeleteMapel,
      allMapel,
      setAllMapel,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
