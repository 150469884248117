import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC } from "react";
import { Form } from "react-bootstrap";

type Props = {
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  value?: any;
  formik?: FieldInputProps<any>;
  rows?: number;
  style?: React.CSSProperties;
  readOnly?: boolean;
};

const TextareaComponent: FC<Props> = ({
  label,
  placeholder,
  required,
  formik,
  rows = 3,
  onChange,
  value,
  style,
  readOnly,
}) => {
  return (
    <>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger"> * </span>}
        </Form.Label>
      )}
      <textarea
        placeholder={placeholder}
        onChange={onChange}
        {...formik}
        rows={rows}
        className="form-control"
        value={value}
        style={style}
        readOnly={readOnly}
      ></textarea>
    </>
  );
};

export default TextareaComponent;
