import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DataGTKListWrapper } from "../../modules/data-gtk/list";
import { useLayout } from "../../layout/core/LayoutProvider";
import { useParams } from "react-router-dom";

const DataGTK = () => {
  const { setSetting } = useLayout();
  const { status } = useParams();
  useEffect(() => {
    document.title = `Sistem Informasi Bina Ilmi | Daftar GTK ${
      status === "aktif" ? "Aktif" : "Tidak Aktif"
    } Bekerja`;
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = `Daftar GTK ${
        status === "aktif" ? "Aktif" : "Tidak Aktif"
      } Bekerja`;
      return jasper;
    });
  }, [status]);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <DataGTKListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DataGTK;
