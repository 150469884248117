import axios, { AxiosRequestConfig } from "axios";
import { SweetAlert } from "../hope-ui/components/sweet-alert";
import Cookies from "js-cookie";

export const client = axios.create({
  baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
  timeout: 60000,
  withCredentials: true,

  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
});

client.interceptors.response.use(undefined, function (err) {
  const error = err.response;

  // if error is 401
  if (
    error &&
    error.status === 401 &&
    error.config &&
    !error.config.__isRetryRequest
  ) {
    // request for a new token
    //window.location.href = "/login";
    window.location.replace(process.env.REACT_APP_BASE_URL + "login");
  } else if (error && error.status === 500) {
    SweetAlert({
      icon: "error",
      title:
        "Kami mengalami masalah internal dan tidak dapat menyelesaikan permintaan Anda.",
      text: "Mohon coba kembali sebentar lagi.",
    });
  } else if (error && error.status === 502) {
    SweetAlert({
      icon: "error",
      title:
        "Kami mengalami masalah internal dan tidak dapat menyelesaikan permintaan Anda.",
      text: "Mohon coba kembali sebentar lagi.",
    });
  }

  return Promise.reject(error);
});

// CLIEN SSO ======================================================================
export const client_sso = axios.create({
  baseURL: process.env.REACT_APP_SIMBI_SSO_URL,
  timeout: 60000,
  withCredentials: true,
  headers: {
    //Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    Authorization: `Bearer ${Cookies.get("access_token")}`,
  },
});

client_sso.interceptors.response.use(undefined, function (err) {
  const error = err.response;
  // if error is 401
  if (
    error &&
    error.status === 401 &&
    error.config &&
    !error.config.__isRetryRequest
  ) {
    // request for a new token
    //window.location.href = "/login";
    window.location.replace(process.env.REACT_APP_BASE_URL + "login");
  } else if (error && error.status === 500) {
    SweetAlert({
      icon: "error",
      title:
        "Kami mengalami masalah internal dan tidak dapat menyelesaikan permintaan Anda.",
      text: "Mohon coba kembali sebentar lagi.",
    });
  } else if (error && error.status === 502) {
    SweetAlert({
      icon: "error",
      title:
        "Kami mengalami masalah internal dan tidak dapat menyelesaikan permintaan Anda.",
      text: "Mohon coba kembali sebentar lagi.",
    });
  }

  return Promise.reject(error);
});
