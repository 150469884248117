import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const postRiwayatSertifikasi = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_RIWAYAT_SERTIFIKASI_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};
const downloadDocRiwayatSertifikasi = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
export { postRiwayatSertifikasi, downloadDocRiwayatSertifikasi };
