import { Response } from "../global";
import { Dayjs } from "dayjs";

export type FormDataSayaKepegawaianPayload = {
  id: number | null;
  id_kepegawaian: string | null;
  nik_gtk: string | null;
  niy_nigk: string | null;
  nuptk: string | null;
  jenis_ptk: string | null;
  status_golongan_kepegawaian: string | null;
  nomor_sk_pengangkatan: string | null;
  tmt_pengangkatan: Dayjs | Date | string | null;
  masa_berlaku_sk: Dayjs | Date | string | null;
  kompetensi_khusus: string | null;
  nilai_teori: number | null;
  nilai_praktek: number | null;
  jumlah_juz: number | null;
  toefl: number | null;
  doc_kepegawaian: File[] | null;
  jenis_kepegawaian: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
};
