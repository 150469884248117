import { Card } from "react-bootstrap";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import {
  useQueryResponsePagination,
  useQueryResponse,
} from "../../../../provider/query-response/absensi-bpi/rekap-absensi";
import { useQueryRequest } from "../../../../provider/query-request/absensi-bpi/rekap-absensi";
import { useState } from "react";
import { Column } from "react-table";
import KeteranganWarnaPresensiBPI from "../../../../hope-ui/components/keterangan-warna-presensi-bpi";
import TableHeader from "./list/table-header";
import TableBody from "./list/table-body";

const RekapAbsensiBPI = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  const [Columns, setColumns] = useState<Array<Column<any>>>([]);
  const Data_table = listData?.data;
  const offset = listData?.offset;
  const jumlahHari = listData?.jumlah_hari;

  return (
    <Card.Body>
      <Toolbar jumlahHari={jumlahHari ? jumlahHari + 1 : 0} />

      <DataTable
        isLoading={isLoadingFetchData}
        data={Data_table}
        header={Columns}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
        CustomHeader={<TableHeader />}
        totalHeaders={6}
        bordered={false}
        CustomBody={
          <TableBody
            rows={Data_table}
            allowed_columns={[
              "no",
              "nik_gtk",
              "gtk",
              "tutor_bpi",
              "kelompok_bpi",
              "hadir",
              "sakit",
              "izin",
              "alfa",
            ]}
            isLoading={isLoadingFetchData}
            offset={offset}
          />
        }
        totalRows={Data_table ? Data_table.length : 0}
      />

      <KeteranganWarnaPresensiBPI className="mt-3" />
    </Card.Body>
  );
};

export default RekapAbsensiBPI;
