import { FC, useEffect } from "react";
import { Accordion, Badge, Button, Modal } from "react-bootstrap";
import { useQueryResponse } from "../../provider/query-response/detail-gtk";
import FetchLoadingCompanyLogo from "../../hope-ui/components/fetch-loading-with-company-logo";
import Table18 from "../../hope-ui/components/table-18";
import { ListKepegawaianWrapper } from "./list/kepegawaian";
import GTKCard from "../../hope-ui/components/gtk-card";

type Props = {
  modalTitle?: string;
  data?: object | null;
};

const DetailGTK: FC<Props> = ({ data }) => {
  const {
    isShowModal,
    setShowModal,
    handleGetDetailGTK,
    detailGTK,
    nikGTK,
    isLoadingGetDetailGTK,
  } = useQueryResponse();

  useEffect(() => {
    if (isShowModal) {
      const nik_gtk: any = nikGTK;
      handleGetDetailGTK(nik_gtk);
    }
  }, [handleGetDetailGTK, isShowModal, nikGTK]);

  const keysToKeep = [
    "nik_gtk",
    "nama_gtk",
    "unit_kerja",
    "tinggi_badan",
    "berat_badan",
    "golongan_darah",
    "email",
    "jenis_kelamin",
    "tempat_lahir",
    "tanggal_lahir",
    "nomor_hp",
    "nama_ibu_kandung",
    "npwp",
    "nama_wajib_pajak",
    "warga_negara",
    "status_perkawinan",
    "nama_pasangan_hidup",
    "pekerjaan_pasangan_hidup",
    "jumlah_penghasilan_suami",
    "no_bpjs_kesehatan",
    "no_bpjs_ketenagakerjaan",
  ];
  let filteredObject;
  if (detailGTK) {
    filteredObject = keysToKeep.reduce((acc, key) => {
      if (key in detailGTK) {
        acc[key] = detailGTK[key];
      }
      return acc;
    }, {});

    const arrAlamat = [
      "alamat",
      "rt",
      "rw",
      "nama_dusun_kota",
      "kelurahan",
      "kecamatan",
      "kode_pos",
    ];
    arrAlamat.map((val) => {
      filteredObject[val] = detailGTK[val];
    });

    const domisili_gtk: any = detailGTK?.domisili_gtk;
    if (domisili_gtk)
      Object.keys(domisili_gtk).map((key) => {
        if (key.includes("id") === false)
          filteredObject[key] = domisili_gtk[key];
      });

    const arrBank = [
      "id_bank",
      "no_rekening_bank",
      "rekening_atas_nama",
      "no_rekening_bank",
      "id_bank_non_gaji",
      "no_rekening_bank_non_gaji",
      "rekening_atas_nama_non_gaji",
    ];
    arrBank.map((val) => {
      filteredObject[val] = detailGTK[val];
    });
  }
  let i = 1;

  return (
    <Modal
      backdrop="static"
      show={isShowModal}
      onBackdropClick={() => {
        setShowModal(false);
      }}
      size="lg"
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Detail GTK </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoadingGetDetailGTK && <FetchLoadingCompanyLogo />}
        {!detailGTK && !isLoadingGetDetailGTK && (
          <div className="d-flex flex-row justify-content-center align-items-center">
            {<label className="text-danger">Data Tidak Ditemukan</label>}
          </div>
        )}
        {detailGTK && !isLoadingGetDetailGTK && (
          <div>
            <GTKCard data={detailGTK} />

            <Accordion className="mt-4">
              <Accordion.Item eventKey="0" className="mb-2">
                <Accordion.Header>
                  {i++}. Biodata
                  {!detailGTK?.validasi_gtk.biodata.status && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  {<Table18 data={filteredObject} />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mb-2">
                <Accordion.Header>
                  {i++}. Kepegawaian{" "}
                  {!detailGTK?.validasi_gtk.kepegawaian && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  {detailGTK && (
                    <>
                      <button
                        type="button"
                        className="btn btn-info btn-sm mb-3"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}data-gtk/kepegawaian/form/${detailGTK.nik_gtk}`
                          );
                        }}
                      >
                        <i className="far fa-plus"></i> Tambah
                      </button>

                      <ListKepegawaianWrapper data={detailGTK.kepegawaian} />
                    </>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mb-2">
                <Accordion.Header>
                  {i++}. Mutasi
                  {!detailGTK?.validasi_gtk.mutasi && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  {<Table18 data={detailGTK.mutasi} />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mb-2">
                <Accordion.Header>
                  {i++}. Inpassing{" "}
                  {!detailGTK?.validasi_gtk.inpassing && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  {<Table18 data={detailGTK.inpassing} />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mb-2">
                <Accordion.Header>
                  {i++}. Anak{" "}
                  {!detailGTK?.validasi_gtk.anak && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  {<Table18 data={detailGTK.anak} />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="mb-2">
                <Accordion.Header>
                  {i++}. Buku Yang Pernah Ditulis{" "}
                  {!detailGTK?.validasi_gtk.karia_buku && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  {<Table18 data={detailGTK.karia_buku} />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="mb-2">
                <Accordion.Header>
                  {i++}. Riwayat Pekerjaan{" "}
                  {!detailGTK?.validasi_gtk.riwayat_pekerjaan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.riwayat_pekerjaan} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7" className="mb-2">
                <Accordion.Header>
                  {i++}. Pendidikan Pelatihan{" "}
                  {!detailGTK?.validasi_gtk.pendidikan_pelatihan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.pendidikan_pelatihan} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8" className="mb-2">
                <Accordion.Header>
                  {i++}. Pendidikan Terakhir{" "}
                  {!detailGTK?.validasi_gtk.riwayat_pendidikan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.riwayat_pendidikan} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9" className="mb-2">
                <Accordion.Header>
                  {i++}. Sertifikasi Pendidik{" "}
                  {!detailGTK?.validasi_gtk.riwayat_sertifikasi && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.riwayat_sertifikasi} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10" className="mb-2">
                <Accordion.Header>
                  {i++}. Karya Tulis{" "}
                  {!detailGTK?.validasi_gtk.karya_tulis && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.karya_tulis} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="11" className="mb-2">
                <Accordion.Header>
                  {i++}. Kesejahteraan{" "}
                  {!detailGTK?.validasi_gtk.kesejahteraan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.kesejahteraan} />
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="12" className="mb-2">
                <Accordion.Header>
                  {i++}. Penghargaan{" "}
                  {!detailGTK?.validasi_gtk.penghargaan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.penghargaan} />
                </Accordion.Body>
              </Accordion.Item> */}

              <Accordion.Item eventKey="13" className="mb-2">
                <Accordion.Header>
                  {i++}. Penilaian / Tes{" "}
                  {!detailGTK?.validasi_gtk.penilaian && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.penilaian} />
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="14" className="mb-2">
                <Accordion.Header>
                  {i++}. Riwayat Gaji Berkala{" "}
                  {!detailGTK?.validasi_gtk.riwayat_gaji_berkala && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.riwayat_gaji_berkala} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="15" className="mb-2">
                <Accordion.Header>
                  {i++}. Riwayat Kepangkatan / Golongan{" "}
                  {!detailGTK?.validasi_gtk.riwayat_pangkat && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.riwayat_pangkat} />
                </Accordion.Body>
              </Accordion.Item> */}

              <Accordion.Item eventKey="16" className="mb-2">
                <Accordion.Header>
                  {i++}. Tugas Tambahan{" "}
                  {!detailGTK?.validasi_gtk.tugas_tambahan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.tugas_tambahan} />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="17" className="mb-2">
                <Accordion.Header>
                  {i++}. Mata Pelajaran Yang Diampu{" "}
                  {!detailGTK?.validasi_gtk.mapel && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <Table18 data={detailGTK.mapel} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="success" onClick={() => setShowModal(false)}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetailGTK;
