import { FC, useEffect } from "react";
import { useQueryResponse } from "../../../../provider/query-response/dashboard";
import { Modal } from "react-bootstrap";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";

import { useQueryRequest } from "../../../../provider/query-request/dashboard/detail-perubahan-status-kepegawaian";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns/columns";
import { useQueryResponsePaginationDetailPerubahanStatusKepegawaian } from "../../../../provider/query-response/dashboard";
import Toolbar from "./toolbar";

type Props = {
  modalTitle?: string;
};

const Detail: FC<Props> = ({ modalTitle }) => {
  const {
    isShowDetailPerubahanStatusKepegawaian,
    setIsShowDetailPerubahanStatusKepegawaian,
    isLoadingDetailPerubahanStatusKepegawaian,
    detailPerubahanStatusKepegawaian,
  } = useQueryResponse();

  const countData = detailPerubahanStatusKepegawaian?.data
    ? detailPerubahanStatusKepegawaian?.data.length
    : 0;

  const Data_table = detailPerubahanStatusKepegawaian?.data;

  const { currentStatusGolongan } = useQueryResponse();

  return (
    <Modal
      backdrop="static"
      show={isShowDetailPerubahanStatusKepegawaian}
      onBackdropClick={() => {
        setIsShowDetailPerubahanStatusKepegawaian(false);
      }}
      size="xl"
      onHide={() => setIsShowDetailPerubahanStatusKepegawaian(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="fs-6 text-dark">
          <tr>
            <td>Unit Kerja</td>
            <td className="ps-3 pe-2"> : </td>
            <td> {currentStatusGolongan.unit_kerja} </td>
          </tr>

          <tr>
            <td>Dari</td>
            <td className="ps-3 pe-2"> : </td>
            <td> {currentStatusGolongan.status_golongan_kepegawaian} </td>
          </tr>
          {currentStatusGolongan.menuju.length > 0 && (
            <tr>
              <td>Ke</td>
              <td className="ps-3 pe-2"> : </td>
              <td> {currentStatusGolongan.menuju} </td>
            </tr>
          )}
        </table>

        <Toolbar />
        <DataTable
          isLoading={isLoadingDetailPerubahanStatusKepegawaian}
          header={Columns}
          data={Data_table}
          pagination={
            useQueryResponsePaginationDetailPerubahanStatusKepegawaian
          }
          queryRequest={useQueryRequest}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Detail;
