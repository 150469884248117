import { Fragment, useEffect } from "react";
import { ShowLimitDT } from "../../../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../../../hope-ui/components/table/SearchInput";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-bpi/kelompok-bpi/pindah-tutor";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();

  return (
    <Fragment>
      <div className="row pt-3 px-0 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Toolbar;
