import { Form, Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/hak-akses";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";
import { useEffect } from "react";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import Select2 from "../../../../hope-ui/components/select-2";
import GTKCard from "../../../../hope-ui/components/gtk-card";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const ModalHakAksesUser = () => {
  const {
    showModalHakAkses,
    setShowModalHakAkses,
    handleGetAllHakAkses,
    isLoadingGetAllHakAkses,
    allHakAkses,
    formikUserHakAkses,
    submitting,
    setSubmitting,
    handleGetUserHakAkses,
    isLoadingGetUserHakAkses,
    userHakAkses,
    isLoadingPostUserHakAkses,
  } = useQueryResponse();

  const { nikGTK, detailGTK, handleGetDetailGTK, isLoadingGetDetailGTK } =
    useQueryResponseDetailGTK();

  useEffect(() => {
    if (showModalHakAkses) {
      handleGetAllHakAkses();
      if (nikGTK) {
        handleGetDetailGTK(nikGTK);
        handleGetUserHakAkses(nikGTK);
      }
    } else setSubmitting(false);
  }, [
    nikGTK,
    showModalHakAkses,
    handleGetAllHakAkses,
    handleGetDetailGTK,
    handleGetUserHakAkses,
  ]);

  const optionHakAkses = allHakAkses.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  useEffect(() => {
    if (userHakAkses) {
      formikUserHakAkses?.setFieldValue("rolesId", userHakAkses.rolesId);
      formikUserHakAkses?.setFieldValue("username", userHakAkses.username);
    }
  }, [userHakAkses]);


  return (
    <Modal
      backdrop="static"
      show={showModalHakAkses}
      onBackdropClick={() => {
        setShowModalHakAkses(false);
      }}
      size="lg"
      onHide={() => setShowModalHakAkses(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tentukan Hak Akses</Modal.Title>
      </Modal.Header>

      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikUserHakAkses?.submitForm();
          e.preventDefault();
        }}
      >
        <Modal.Body>
          {isLoadingPostUserHakAkses && <FetchLoading />}
          {isLoadingGetDetailGTK || isLoadingGetUserHakAkses ? (
            <FetchLoadingCompanyLogo />
          ) : (
            <>
              <GTKCard
                data={detailGTK}
                //poto={detailGTK?.akun?.poto?.toString()}
              />
              <Select2
                placeholder="Pilih Hak Akses"
                label="List Hak Akses"
                isRequired
                options={optionHakAkses}
                isLoading={isLoadingGetAllHakAkses}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikUserHakAkses?.setFieldValue("rolesId", value);
                }}
                value={formikUserHakAkses?.getFieldProps("rolesId").value}
              />
              {submitting && formikUserHakAkses?.errors.rolesId && (
                <div className="text-danger mt-2">
                  {formikUserHakAkses.errors.rolesId}
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="fa fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalHakAksesUser;
