import { Modal, Table } from "react-bootstrap";
import { useQueryResponse } from "../../provider/query-response/detail-gtk";
import moment from "moment";

const RiwayatLogin = () => {
  const { detailGTK, setShowRiwayatLoginModal, showRiwayatLoginModal } =
    useQueryResponse();
  const riwayatLogin = detailGTK?.history_login;
  return (
    <Modal
      backdrop="static"
      show={showRiwayatLoginModal}
      onBackdropClick={() => {
        setShowRiwayatLoginModal(false);
      }}
      size="lg"
      onHide={() => {
        setShowRiwayatLoginModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Riwayat Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="fs-6 text-dark mb-3">
          <tr>
            <td>NIK</td>
            <td className="ps-3 pe-2"> : </td>
            <td> {detailGTK?.nik_gtk} </td>
          </tr>

          <tr>
            <td>Nama</td>
            <td className="ps-3 pe-2"> : </td>
            <td> {detailGTK?.nama_gtk} </td>
          </tr>
        </table>

        <Table className="fs-6">
          <thead>
            <tr>
              <th>No.</th>
              <th>Tanggal & Jam</th>
              <th>Aplikasi</th>
            </tr>
          </thead>
          <tbody>
            {riwayatLogin &&
              riwayatLogin.map((val, idx) => {
                return (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>
                      <div>
                        <div className="d-flex flex-row align-items-center gap-2 mb-1">
                          <i className="far fa-calendar"></i>
                          {moment(val.time_login).format("DD MMM YYYY")}
                        </div>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <i className="far fa-clock"></i>
                          {moment(val.time_login).format("H:mm")}
                        </div>
                      </div>
                    </td>
                    <td>{val.app_name}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export const RiwayatLoginWrapper = () => <RiwayatLogin />;
