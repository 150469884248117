import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/menu/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { getListMenu, postMenu } from "../../../api/menu/_request";
import { initialQueryState } from "../../../models/global";
import { useQueryRequest } from "../../query-request/menu";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "menus")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "menus"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);
  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(`${QUERIES.GET_LIST_MENU}-${query}`, () => getListMenu(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
  });

  const { mutateAsync: PostMenu, isLoading: isLoadingPostMenu } = useMutation({
    mutationKey: `${QUERIES.POST_LIST_MENU}`,
    mutationFn: ({ payload }: { payload: FormData }) => postMenu(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_LIST_MENU}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostMenu = useCallback(
    async (payload: any) => {
      await PostMenu({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success && refetchListData) {
              refetchListData();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostMenu]
  );

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      handlePostMenu,
      isLoadingPostMenu,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      handlePostMenu,
      isLoadingPostMenu,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
