import ReactLogo from "../../../assets/images/icons/404.svg";
import { Alert } from "react-bootstrap";
import LogoJundezain from "../../hope-ui/components/logo-jundezain";

const PageNotFound = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center px-3"
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <img src={ReactLogo} width={350} height={350} alt=""></img>
      <Alert key="info" variant="info" className="border-0">
        <div className="mb-2">
          <i className="fa fa-warning me-2"></i>
          Mohon Maaf, Halaman Yang Anda Akses Tidak Ditemukan
        </div>
        <div className="d-flex justify-content-start">
          <button
            type="button"
            className="btn btn-info btn-sm"
            onClick={() => {
              window.history.go(-1);
              return false;
            }}
          >
            <i className="fa fa-arrow-left me-2"></i>
            Kembali
          </button>
        </div>
      </Alert>
      <LogoJundezain />
    </div>
  );
};

export default PageNotFound;
