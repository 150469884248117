import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormMemoPayload,
  initialQueryResponse,
} from "../../../models/memo/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import {
  getListMemo,
  getDetailMemo,
  postMemo,
} from "../../../api/memo/_request";
import { initialQueryState } from "../../../models/global";
import { useQueryRequest } from "../../query-request/memo";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["judul_pesan", "updatedAt", "status_pesan"]`;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "memo")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "memo"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const [isShowModalMemo, setShowModalMemo] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [isShowDetailMemo, setShowDetailMemo] = useState<boolean>(false);

  const [detailMemo, setDetailMemo] = useState<FormMemoPayload>({
    id: null,
    id_pesan: null,
    nik_pengirim: null,
    nik_tujuan: null,
    judul_pesan: null,
    isi_pesan: null,
    status_pesan: null,
    updatedAt: null,
  });

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(`${QUERIES.GET_LIST_MEMO}-${query}`, () => getListMemo(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      console.error(error);
      SweetAlert({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: error.data ? error.data.message : error,
        allowOutsideClick: false,
      }).then((res: any) => {
        if (res.isConfirmed) {
        }
      });
    },
    enabled: isShowModalMemo,
  });

  const { mutateAsync: PostMemo, isLoading: isLoadingPostMemo } = useMutation({
    mutationKey: `${QUERIES.POST_MEMO}`,
    mutationFn: ({ payload }: { payload: FormData }) => postMemo(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_MEMO}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostMemo = useCallback(
    async (payload: any) => {
      await PostMemo({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success && refetchListData) {
              refetchListData();
              setResetForm(true);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostMemo]
  );

  const { mutateAsync: GetDetailMemo, isLoading: isLoadingGetDetailMemo } =
    useMutation({
      mutationKey: `${QUERIES.POST_MEMO}`,
      mutationFn: ({ id }: { id: number }) => getDetailMemo(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_MEMO}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailMemo = useCallback(
    async (id: number) => {
      await GetDetailMemo({ id })
        .then((res) => {
          if (res.success) {
            const data = res.data;
            console.log(data);
            setDetailMemo(data);
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailMemo]
  );

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      isShowModalMemo,
      setShowModalMemo,
      handlePostMemo,
      isLoadingPostMemo,
      resetForm,
      setResetForm,
      detailMemo,
      setDetailMemo,
      handleGetDetailMemo,
      isLoadingGetDetailMemo,
      isShowDetailMemo,
      setShowDetailMemo,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      isShowModalMemo,
      setShowModalMemo,
      handlePostMemo,
      isLoadingPostMemo,
      resetForm,
      setResetForm,
      detailMemo,
      setDetailMemo,
      handleGetDetailMemo,
      isLoadingGetDetailMemo,
      isShowDetailMemo,
      setShowDetailMemo,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
