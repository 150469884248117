import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handleExportRekapPresensiGTK: () => void;
  isLoadingExportRekapPresensiGTK: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  handleExportRekapPresensiGTK: () => {},
  isLoadingExportRekapPresensiGTK: false,
};
