import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormSertifikasiKeterampilanKhususPayload = {
  id: number | string | null;
  id_sertifikasi: string | null;
  nik_gtk: string | null;
  jenis_sertifikasi: string | null;
  nomor_sertifikasi: string | null;
  bidang_studi: string | null;
  tahun_sertifikasi: number | string | null;
  ngrg: string | null;
  nomor_peserta: string | null;
  jumlah_juz: number | string | null;
  doc_sertifikasi_keterampilan_khusus: File | string | null;
};

export type QueryResponseContextProps<T> = {
  formikSertifikasiKeterampilanKhusus?: FormikProps<FormSertifikasiKeterampilanKhususPayload>;
  formikSertifikasiKeterampilanKhususValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostSertifikasiKeterampilanKhusus: (data: FormData) => void;
  isLoadingPostSertifikasiKeterampilanKhusus: boolean;
  jenisSertifikasi: any;
  setJenisSertifikasi: Dispatch<SetStateAction<any>>;
  handleDownloadDocSertifikasiKeterampilanKhusus: (
    url: string,
    fileName: string
  ) => void;
  isLoadingDownloadDocSertifikasiKeterampilanKhusus: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostSertifikasiKeterampilanKhusus: false,
  handlePostSertifikasiKeterampilanKhusus: () => {},
  jenisSertifikasi: null,
  setJenisSertifikasi: () => {},
  handleDownloadDocSertifikasiKeterampilanKhusus: () => {},
  isLoadingDownloadDocSertifikasiKeterampilanKhusus: false,
};
