import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../layout/core/LayoutProvider";
import { AbsensiLT3QListWrapper } from "../../modules/absensi-lt3q/list";

const PresensiLT3Q = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Presensi LT3Q";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Presensi LT3Q";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <AbsensiLT3QListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PresensiLT3Q;
