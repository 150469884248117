import { 
    Button, 
    ButtonGroup, 
    Dropdown, 
    Card, 
    Col, 
    Form, 
    Modal, 
    Row
} from "react-bootstrap";
import { useEffect, useState } from "react";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import TextareaComponent from "../../../hope-ui/components/textarea-input";
import Select2 from "../../../hope-ui/components/select-2";
import { useQueryResponse } from "../../../provider/query-response/denda-persensi-gtk";
import { useQueryRequest } from "../../../provider/query-request/denda-persensi-gtk";
import { FC } from "react";


type Props = {
  id: string;
};


const AksiDandaPersensiGTK: FC<Props> = ({ id }) => {

  const { state, updateState } = useQueryRequest();
  const { ShowModalReadDendaPersensiGTK, setShowModalReadDendaPersensiGTK  } = useQueryResponse();
  const { ShowModalUpdateDendaPersensiGTK, setShowModalUpdateDendaPersensiGTK  } = useQueryResponse();
  const { ShowModalDeleteDendaPersensiGTK, setShowModalDeleteDendaPersensiGTK  } = useQueryResponse();
  
  const listParameter: Array<any> = [
    {
      label: "Menit",
      value: "Menit",
    },
    {
      label: "Jam",
      value: "Jam",
    },
    {
      label: "Hari",
      value: "Hari",
    },
  ];
  
  const [JenisDenda, setJenisDenda] = useState('');
  const [Minimal, setMinimal] = useState('');
  const [Maksimal, setMaksimal] = useState('');
  const [JumlahNominal, setJumlahNominal] = useState('');
  const [Keterangan, setKeterangan] = useState('');
  const [Parameter, setParameter] = useState(listParameter);

  return (
    <>
    <div className="px-4 py-5 ">
      <Dropdown as={ButtonGroup}>
        <Button type="button" size="sm" variant={"primary"}>
          Pilih Aksi
        </Button>
        <Dropdown.Toggle type="button" split variant={"primary"}>
          <span className="visually-hidden">Pilih Aksi</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>

        <Dropdown.Item
            href="#"
            onClick={() => {
                setShowModalReadDendaPersensiGTK(true)
            }}
        >
          View
        </Dropdown.Item>

          <Dropdown.Item
            href="#"
            onClick={() => {
                setShowModalUpdateDendaPersensiGTK(true)
            }}
          >
            Update
          </Dropdown.Item>

          <Dropdown.Item
            href="#"
            onClick={() => {
                setShowModalDeleteDendaPersensiGTK(true)
            }}
          >
            Delete
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>
    </div>
  

    <Modal
      backdrop="static"
      show={ShowModalUpdateDendaPersensiGTK}
      onBackdropClick={() => {
        setShowModalUpdateDendaPersensiGTK(false);
        //setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setShowModalUpdateDendaPersensiGTK(false);
        //setResetForm(true);
      }}
    >

      <Modal.Header closeButton>
        <Modal.Title>
          Edit Denda Presensi GTK
        </Modal.Title>        
      </Modal.Header>

      <Modal.Body>


      <Card.Body className="pt-3">
        
        <Col lg="12">
          <TextInputComponent
              label="Jenis Denda"
              isRequired
              placeholder="Jenis Denda"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setJenisDenda(value);
              }}
              
              value={JenisDenda}
          />
        </Col>

        <Col lg="12">
          <TextInputComponent
              label="Maksimal"
              isRequired
              placeholder="Maksimal"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setMaksimal(value);
              }}
              value={Maksimal}
          />
          <p>Note : 10 </p>
        </Col>

        <Col lg="12">
          <TextInputComponent
              label="Minimal"
              isRequired
              placeholder="Minimal"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setMinimal(value);
              }}
              value={Minimal}
          />
          <p>Note : 10 </p>
        </Col>



        <Col lg="12">
          <NumberInputComponent
              label="Jumlah Nominal Denda"
              required
              placeholder="Jumlah Nominal Denda"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setJumlahNominal(value);
              }}
              value={JumlahNominal}
      />
          <p>Note : 12000</p>
        </Col>

        <Col lg="12">
          <TextareaComponent
            label="Keterangan Denda"
            required
            placeholder="Keterangan Denda"
            onChange={(e) => {
              const value: any = e ? e ["value"] : null;
              setKeterangan(value);
            }}
            value={Keterangan}
          />
        </Col>

        <Col lg="12">
          <Select2
            label="Pilih Parameter"
            isClearable
            options={listParameter}
            onChange={(e) => {
              const value: any = e ? e ["value"] : null;
              setParameter(value);
            }}
            value={Parameter}
          />
        </Col>

        <Col lg="12">
        <button type="submit" 
          className="btn btn-sm btn-primary"
          style={{float:"right", marginTop:"10px"}}
          >
          <i className="fas fa-clipboard-user me-2"></i>
          Simpan
        </button>
        </Col>

    
    </Card.Body>


      </Modal.Body>
    </Modal>

    <Modal
      backdrop="static"
      show={ShowModalReadDendaPersensiGTK}
      onBackdropClick={() => {
        setShowModalReadDendaPersensiGTK(false);
        //setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setShowModalReadDendaPersensiGTK(false);
        //setResetForm(true);
      }}
    >

      <Modal.Header closeButton>
        <Modal.Title>
          Detail Denda Presensi GTK
        </Modal.Title>        
      </Modal.Header>

      <Modal.Body>


      <Card.Body className="pt-3">
        
        <Col lg="12">

          <TextInputComponent
              label="Jenis Denda"
              isRequired
              placeholder="Jenis Denda"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setJenisDenda(value);
              }}
              value={JenisDenda}
          />
        </Col>

        <Col lg="12">
          <TextInputComponent
              label="Maksimal"
              isRequired
              placeholder="Maksimal"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setMaksimal(value);
              }}
              value={Maksimal}
          />
          <p>Note : 10 </p>
        </Col>

        <Col lg="12">
          <TextInputComponent
              label="Minimal"
              isRequired
              placeholder="Minimal"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setMinimal(value);
              }}
              value={Minimal}
          />
          <p>Note : 10 </p>
        </Col>


        <Col lg="12">
          <NumberInputComponent
              label="Jumlah Nominal Denda"
              required
              placeholder="Jumlah Nominal Denda"
              onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setJumlahNominal(value);
              }}
              value={JumlahNominal}
      />
          <p>Note : 12000</p>
        </Col>

        <Col lg="12">
          <TextareaComponent
            label="Keterangan Denda"
            required
            placeholder="Keterangan Denda"
            onChange={(e) => {
              const value: any = e ? e ["value"] : null;
              setKeterangan(value);
            }}
            value={Keterangan}
          />
        </Col>

        <Col lg="12">
          <Select2
            label="Pilih Parameter"
            isClearable
            options={listParameter}
            onChange={(e) => {
              const value: any = e ? e ["value"] : null;
              setParameter(value);
            }}
            value={Parameter}
          />
        </Col>

        <Col lg="12">
        <button type="submit" 
          className="btn btn-sm btn-primary"
          style={{float:"right", marginTop:"10px"}}
          >
          <i className="fas fa-clipboard-user me-2"></i>
          Simpan
        </button>
        </Col>

    
    </Card.Body>


      </Modal.Body>
    </Modal>


    <Modal
      backdrop="static"
      show={ShowModalDeleteDendaPersensiGTK}
      onBackdropClick={() => {
        setShowModalDeleteDendaPersensiGTK(false);
        //setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setShowModalDeleteDendaPersensiGTK(false);
        //setResetForm(true);
      }}
    >

      <Modal.Header closeButton>
        <Modal.Title>
          Hapus Denda Presensi GTK
        </Modal.Title>        
      </Modal.Header>

      <Modal.Body>


      <Card.Body className="pt-3">
      <Col lg="12">
        Apakah anda yakin menhapus data ini..!!
      </Col>
      </Card.Body>


      </Modal.Body>
    </Modal>


  </>
  );
};

export default AksiDandaPersensiGTK;
