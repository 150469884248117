import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormAnakPayload = {
  id: number | string | null;
  id_anak: string | null;
  nik_gtk: string | null;
  nama_anak: string | null;
  status_anak: string | null;
  jenis_pendidikan: string | null;
  nisn: string | null;
  jenis_kelamin_anak: string | null;
  tempat_lahir: string | null;
  tanggal_lahir: Date | Dayjs | null;
  tahun_masuk: number | string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalAnak: boolean;
  setShowModalAnak: Dispatch<SetStateAction<boolean>>;
  formikAnak?: FormikProps<FormAnakPayload>;
  formikAnakValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostAnak: (data: FormData) => void;
  isLoadingPostAnak: boolean;
  handleGetDetailAnak: (id: string) => void;
  isLoadingGetDetailAnak: boolean;
  detailAnak: FormAnakPayload;
  setDetailAnak: Dispatch<SetStateAction<FormAnakPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocAnak: (url: string, fileName: string) => void;
  isLoadingDownloadDocAnak: boolean;
  handleDeleteAnak: (id: string) => void;
  isLoadingDeleteAnak: boolean;
  handleGetAllJenjangPendidikan: () => void;
  isLoadingGetAllJenjangPendidikan: boolean;
  allJenjangPendidikan: Array<any>;
  setAllJenjangPendidikan: Dispatch<SetStateAction<Array<any>>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalAnak: false,
  setShowModalAnak: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostAnak: () => {},
  isLoadingPostAnak: false,
  handleGetDetailAnak: () => {},
  isLoadingGetDetailAnak: false,
  detailAnak: {
    id: null,
    id_anak: null,
    nik_gtk: null,
    jenis_kelamin_anak: null,
    jenis_pendidikan: null,
    nama_anak: "",
    status_anak: null,
    nisn: null,
    tahun_masuk: null,
    tanggal_lahir: null,
    tempat_lahir: "",
  },
  setDetailAnak: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocAnak: () => {},
  isLoadingDownloadDocAnak: false,
  handleDeleteAnak: () => {},
  isLoadingDeleteAnak: false,
  handleGetAllJenjangPendidikan: () => {},
  isLoadingGetAllJenjangPendidikan: false,
  allJenjangPendidikan: [],
  setAllJenjangPendidikan: () => {},
};
