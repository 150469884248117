import { Column } from "react-table";
import { Header } from "../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../provider/query-request/denda-persensi-gtk";
import moment from "moment";
// import AksiCell from "./AksiCell";
import { formatRupiah } from "../../../helpers/helpers";
import AksiDandaPersensiGTK from "./aksi";


export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },
//   {
//     Header: (props) => (
//       <Header
//         tableProps={props}
//         title="Aksi"
//         className="ps-3 fs-6 text-center"
//         queryRequest={useQueryRequest}
//         isSorting={false}
//       />
//     ),
//     id: "action",
//     Cell: ({ ...props }) => <AksiCell id={props.data[props.row.index].id} />,
//   },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="JENIS DENDA"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jenis_denda",
    Cell: ({ ...props }) => (
      <label>
        {props.data[props.row.index].jenis_denda}
      </label>
    ),
  },


 
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="MAKSIMAL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "maksimal",
    Cell: ({ ...props }) => (
      <label>
        {props.data[props.row.index].maksimal} {props.data[props.row.index].parameter}
      </label>
    ),
  },


  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="MINIMAL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "minimal",
    Cell: ({ ...props }) => (
      <label>
        {props.data[props.row.index].minimal} {props.data[props.row.index].parameter}
      </label>
    ),
  },


  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="JUMLAH NOMINAL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jumlah_nominal",
    Cell: ({ ...props }) => (
      <label>
        Rp. {props.data[props.row.index].jumlah_nominal}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="KETERANGAN"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "keterangan",
    Cell: ({ ...props }) => (
      <label>
        {props.data[props.row.index].keterangan}
      </label>
    ),
  },
  
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="ACTION"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => 
    <AksiDandaPersensiGTK 
    id={props.data[props.row.index].id}
    />,
  },
























  
];
