import {
    FC,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from "react";
  
  import { initialQueryResponse } from "../../../models/denda-persensi-gtk/_queryResponse";
  import { WithChildren } from "../../../helpers/react18MigrationHelpers";
  import { Query, useMutation, useQuery, useQueryClient } from "react-query";
  import { QUERIES } from "../../../helpers/crud-helpers/const";
  import { 
            postSaveDendaPersensiGTK,
            getListDendaPersensiGTK
         } from "../../../api/denda-persensi-gtk/_request";
  import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
  import { useLocation } from "react-router-dom";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/denda-persensi-gtk";
import { initialQueryState } from "../../../models/global";
  

  const QueryResponseContext = createContext(initialQueryResponse);
  const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state, updateState } = useQueryRequest();


    const [ShowModalDendaPersensiGTK, setShowModalDendaPersensiGTK] = 
    useState<boolean>(false);

    const [ShowModalUpdateDendaPersensiGTK, setShowModalUpdateDendaPersensiGTK] =
    useState<boolean>(false);

    const [ShowModalReadDendaPersensiGTK, setShowModalReadDendaPersensiGTK] =
    useState<boolean>(false);

    const [ShowModalDeleteDendaPersensiGTK, setShowModalDeleteDendaPersensiGTK] =
    useState<boolean>(false);


    state.col_header = `["jenis_denda", "maksimal", "minimal", "jumlah_nominal", "keterangan", "parameter"]`;
    
    const [query, setQuery] = useState<string>(
        stringifyRequestQuery(state, "master-denda-presensi-gtk")
      );
      const updatedQuery = useMemo(
        () => stringifyRequestQuery(state, "master-denda-presensi-gtk"),
        [state]
      );

      const {
        data: listData,
        isFetching: isLoadingFetchData,
        refetch: refetchListData,
      } = useQuery(`${QUERIES. GET_LIST_DENDA_ABSENSI_GTK}-${query}`, () => getListDendaPersensiGTK(query), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (err) => {
          console.error(err);
        },
        enabled: query !== "?undefined",
      });

      useEffect(() => {
        if (query !== updatedQuery) {
          setQuery(updatedQuery);
        }
      }, [query, updatedQuery]);

    /*
    const [getDetailDendaPersensiGTK, setgetDetailDendaPersensiGTK] = useState<any>();
    const { pathname } = useLocation();
    const path = pathname.split("/")[1];
  
    const { mutateAsync: saveDendaPersensiGTK, isLoading: isLoadingFetchData } =
      useMutation({
        mutationKey: `${QUERIES.POST_SAVE_DENDA_ABSENSI_GTK}`,
        mutationFn: ({ payload }: { payload: FormData }) => postSaveDendaPersensiGTK(payload),
        onSuccess: async (res: any, variables) => {
          const response = res;
          try {
            await queryClient.invalidateQueries([`${QUERIES.POST_SAVE_DENDA_ABSENSI_GTK}`]);
            return response;
          } catch (err) {
            console.log(err);
          }
        },
      });
  
    const handlePostDendaPersensiGTK = useCallback(
      async (payload: any) => {
        await saveDendaPersensiGTK({ payload })
          .then((res) => {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                const id = res.data.id;
                if (id) {
                  if (path === "denda-persensi-gtk")
                    window.location.href = `${process.env.REACT_APP_BASE_URL}denda-persensi-gtk/form/${id}`;
                  else handlePostDendaPersensiGTK(id);
                }
              }
            });
          })
          .catch((error) => {
            SweetAlert({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: error.data ? error.data.message : error,
              allowOutsideClick: false,
            }).then((res: any) => {
              if (res.isConfirmed) {
              }
            });
          });
      },
      [saveDendaPersensiGTK]
    );
  
    const { mutateAsync: GetDetailDendaPersensiGTK, isLoading: isLoadingDetailPersensiGTK} =
      useMutation({
        mutationKey: `${QUERIES.GET_DETAIL_DENDA_ABSENSI_GTK}`,
        mutationFn: ({ id }: { id: string }) => getDetailDendaPersensiGTK(id),
        onSuccess: async (res: any, variables) => {
          const response = res;
          try {
            await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_DENDA_ABSENSI_GTK}`]);
            GetDetailDendaPersensiGTK(response);
            return response;
          } catch (err) {
            console.log(err);
          }
        },
      });
  
    const handleGetDetailDendaPersensiGTK = useCallback(
      async (id: string) => {
        await getDetailDendaPersensiGTK({ id }).catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
      },
      [getListDendaPersensiGTK]
    );
    */
    const value = useMemo(
      () => ({
        ShowModalDendaPersensiGTK,
        setShowModalDendaPersensiGTK,
        ShowModalReadDendaPersensiGTK,
        setShowModalReadDendaPersensiGTK,
        ShowModalUpdateDendaPersensiGTK,
        setShowModalUpdateDendaPersensiGTK,
        ShowModalDeleteDendaPersensiGTK,
        setShowModalDeleteDendaPersensiGTK,
        query,
        isLoadingFetchData,
        listData, 
        refetchListData,
        //GetDetailDendaPersensiGTK,
        //saveDendaPersensiGTK,
        //isLoadingFetchData,
        //isLoadingDetailPersensiGTK,
        //handleGetDetailDendaPersensiGTK,
        //handlePostDendaPersensiGTK,

      }),
      [
        ShowModalDendaPersensiGTK,
        setShowModalDendaPersensiGTK,
        ShowModalReadDendaPersensiGTK,
        setShowModalReadDendaPersensiGTK,
        ShowModalUpdateDendaPersensiGTK,
        setShowModalUpdateDendaPersensiGTK,
        ShowModalDeleteDendaPersensiGTK,
        setShowModalDeleteDendaPersensiGTK,
        query,
        isLoadingFetchData,
        listData, 
        refetchListData,
        //GetDetailDendaPersensiGTK,
        //saveDendaPersensiGTK,
        //isLoadingFetchData,
        //isLoadingDetailPersensiGTK,
        //handleGetDetailDendaPersensiGTK,
        //handlePostDendaPersensiGTK

      ]
    );
  
    return (
      <QueryResponseContext.Provider value={value}>
        {children}
      </QueryResponseContext.Provider>
    );
  };
  
  const useQueryResponse = () => useContext(QueryResponseContext);
  const useQueryResponsePagination = () => {
    const defaultPaginationState = {
      ...initialQueryState,
    };
  
    const { listData } = useQueryResponse();
  
    return listData ?? defaultPaginationState;
  };
  export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
  