import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/prihal-alasan";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import InputRadio from "../../../hope-ui/components/input-radio";

const ModalTambahPrihalAlasan = () => {


  const listAlasan: Array<any>= [
    {
      value: "Sakit",
      label: "Sakit",
    },
    {
      value: "Izin",
      label: "Izin",
    },
    {
      value: "Alfa",
      label: "Alfa",
    },
  ];

  const {
    showModalPrihalAlasan,
    setShowModalPrihalAlasan,
    formikPrihalAlasan,
    submitting,
    setSubmitting,
    detailPrihalAlasan,
    setResetForm,
    isLoadingGetDetailPrihalAlasan,
    isLoadingPostPrihalAlasan,
  } = useQueryResponse();

  useEffect(() => {
    if (detailPrihalAlasan.id) {
      formikPrihalAlasan?.setValues(detailPrihalAlasan);
    }
  }, [detailPrihalAlasan]);

  return (
    <Modal
      backdrop="static"
      show={showModalPrihalAlasan}
      onBackdropClick={() => {
        setShowModalPrihalAlasan(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalPrihalAlasan(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikPrihalAlasan?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailPrihalAlasan.id ? "Edit" : "Tambah"} Prihal Alasan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailPrihalAlasan ||
            isLoadingPostPrihalAlasan) && <FetchLoading />}

          <Col lg="12">
            <Form.Group>
              <InputRadio
                label="Prihal"
                data={listAlasan}
                required
                value={formikPrihalAlasan?.getFieldProps("prihal").value}
                onChange={(e) => {
                  const value = e.target.value;
                  formikPrihalAlasan?.setFieldValue("prihal", value);
                }}
              />
              {submitting && formikPrihalAlasan?.errors.prihal && (
                <div className="fs-6 text-danger mt-2">
                  {formikPrihalAlasan?.errors.prihal}
                </div>
              )}
            </Form.Group>
            </Col>

          <Row className="gy-4">
            <Col lg="12">
              <TextInputComponent
                label="Alasan"
                placeholder="Masukkan Alasan"
                isRequired
                formik={formikPrihalAlasan?.getFieldProps("alasan")}
                value={
                  formikPrihalAlasan?.getFieldProps("alasan").value ?? ""
                }
              />
              {submitting && formikPrihalAlasan?.errors.alasan && (
                <div className="fs-6 text-danger mt-2">
                  {formikPrihalAlasan?.errors.alasan}
                </div>
              )}
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalTambahPrihalAlasan;
