import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormPelajaranDiampuPayload,
  initialQueryResponse,
} from "../../../models/pelajaran-yang-diampu/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/pelajaran-yang-diampu";
import {
  deletePelajaranDiampu,
  getDetailPelajaranDiampu,
  getListPelajaranDiampu,
  postPelajaranDiampu,
} from "../../../api/pelajaran-yang-diampu/_request";
import { initialQueryState } from "../../../models/global";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama_mapel"]`;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query"),
    [state]
  );

  const [detailPelajaranDiampu, setDetailPelajaranDiampu] =
    useState<FormPelajaranDiampuPayload>(
      initialQueryResponse.detailPelajaranDiampu
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      setResetForm(false);
      setDetailPelajaranDiampu(initialQueryResponse.detailPelajaranDiampu);
      formikPelajaranDiampu.setValues(
        initialQueryResponse.detailPelajaranDiampu
      );
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_MASTER_MAPEL}-${query}`,
    () => getListPelajaranDiampu(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikPelajaranDiampuValidationSchema = Yup.object().shape({
    nama_mapel: Yup.string().required("Nama Mata Pelajaran Tidak Boleh Kosong"),
  });

  const formikPelajaranDiampu = useFormik<FormPelajaranDiampuPayload>({
    initialValues: {
      id: null,
      nama_mapel: null,
    },
    enableReinitialize: false,
    validationSchema: formikPelajaranDiampuValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostPelajaranDiampu(payload);
    },
  });

  const {
    mutateAsync: PostPelajaranDiampu,
    isLoading: isLoadingPostPelajaranDiampu,
  } = useMutation({
    mutationKey: `${QUERIES.POST_MASTER_MAPEL}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postPelajaranDiampu(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_MASTER_MAPEL}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostPelajaranDiampu = useCallback(
    async (payload: any) => {
      await PostPelajaranDiampu({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                refetchListData();
                setShowModal(false);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPelajaranDiampu]
  );

  const {
    mutateAsync: GetDetailPelajaranDiampu,
    isLoading: isLoadingGetDetailPelajaranDiampu,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_MASTER_MAPEL}`,
    mutationFn: ({ id }: { id: string }) => getDetailPelajaranDiampu(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_MASTER_MAPEL}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailPelajaranDiampu = useCallback(
    async (id: string) => {
      await GetDetailPelajaranDiampu({ id })
        .then((res) => {
          setDetailPelajaranDiampu(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailPelajaranDiampu]
  );

  const {
    mutateAsync: DeletePelajaranDiampu,
    isLoading: isLoadingDeletePelajaranDiampu,
  } = useMutation({
    mutationKey: `${QUERIES.DELETE_MASTER_MAPEL}`,
    mutationFn: ({ id }: { id: string }) => deletePelajaranDiampu(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.DELETE_MASTER_MAPEL}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeletePelajaranDiampu = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeletePelajaranDiampu({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeletePelajaranDiampu]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPelajaranDiampu,
      formikPelajaranDiampuValidationSchema,
      submitting,
      setSubmitting,
      handlePostPelajaranDiampu,
      isLoadingPostPelajaranDiampu,
      detailPelajaranDiampu,
      setDetailPelajaranDiampu,
      handleGetDetailPelajaranDiampu,
      isLoadingGetDetailPelajaranDiampu,
      handleDeletePelajaranDiampu,
      isLoadingDeletePelajaranDiampu,
      isShowModal,
      setShowModal,
      resetForm,
      setResetForm,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPelajaranDiampu,
      formikPelajaranDiampuValidationSchema,
      submitting,
      setSubmitting,
      handlePostPelajaranDiampu,
      isLoadingPostPelajaranDiampu,
      detailPelajaranDiampu,
      setDetailPelajaranDiampu,
      handleGetDetailPelajaranDiampu,
      isLoadingGetDetailPelajaranDiampu,
      handleDeletePelajaranDiampu,
      isLoadingDeletePelajaranDiampu,
      isShowModal,
      setShowModal,
      resetForm,
      setResetForm,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
