import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getAllDataGTK = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_DATA_GTK_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDataGTK = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DATA_GTK_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDataGTKChecklistFilter = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DATA_GTK_CHECKLIST_FILTER_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllTableFilterGTK = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_TABLE_FILTER_GTK_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postDataGTK = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_DATA_GTK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const putDataGTK = async (data: FormData, id: string): Promise<any> => {
  return client
    .put(`${ApiConfig.PUT_DATA_GTK_URL}/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const postImportGTK = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_IMPORT_DATA_GTK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadTemplateImportGTK = async (): Promise<any> => {
  return axios({
    url: `${ApiConfig.DOWNLOAD_TEMPLATE_IMPORT_DATA_GTK_URL}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    //console.log(response.data);
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "SIMBI Template Import GTK.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const exportExcelDataGTK = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_EXCEL_DATA_GTK_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "SIMBI Data GTK.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const exportPDFDataGTK = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_PDF_DATA_GTK_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "SIMBI Data GTK.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const getAllSebabNonAktif = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_SEBAB_NON_AKTIF_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postSebabNonAktif = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_SEBAB_NON_AKTIF_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const putResetPasswordGTK = async (id: string): Promise<any> => {
  return client_sso
    .put(`${ApiConfig.PUT_RESET_PASSWORD_GTK_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getAllDataGTK,
  getDataGTK,
  putDataGTK,
  postDataGTK,
  postImportGTK,
  downloadTemplateImportGTK,
  getAllTableFilterGTK,
  getDataGTKChecklistFilter,
  exportExcelDataGTK,
  exportPDFDataGTK,
  getAllSebabNonAktif,
  postSebabNonAktif,
  putResetPasswordGTK,
};
