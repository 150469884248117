import { Fragment, useEffect } from "react";
import { useLayout } from "../../layout/core/LayoutProvider";
import { Card, Col, Row } from "react-bootstrap";
import { DataSayaListWrapper } from "../../modules/data-saya";

const DataSaya = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Dashboard";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Data Saya";
      return jasper;
    });
  }, []);
  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <DataSayaListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DataSaya;
