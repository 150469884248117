import { useEffect } from "react";
import GTKCard from "../../hope-ui/components/gtk-card";
import { useAuth } from "../../provider/core/auth";
import {
  QueryResponseProvider as QueryResponseProviderDetailGTK,
  useQueryResponse as useQueryResponseDetailGTK,
} from "../../provider/query-response/detail-gtk";
import { Accordion, Alert, Badge, Card } from "react-bootstrap";
import FormEntryGTK from "../../pages/data-gtk/entry/form";
import FetchLoadingCompanyLogo from "../../hope-ui/components/fetch-loading-with-company-logo";
import {
  QueryRequestProvider as QueryRequestProviderMutasi,
  useQueryRequest as useQueryRequestMutasi,
} from "../../provider/query-request/mutasi";

import {
  QueryResponseProvider as QueryResponseProviderMutasi,
  useQueryResponse as useQueryResponseMutasi,
} from "../../provider/query-response/mutasi";

import {
  QueryRequestProvider as QueryRequestProviderAnak,
  useQueryRequest as useQueryRequestAnak,
} from "../../provider/query-request/anak";

import {
  QueryResponseProvider as QueryResponseProviderAnak,
  useQueryResponse as useQueryResponseAnak,
} from "../../provider/query-response/anak";

import {
  QueryRequestProvider as QueryRequestProviderKaryaBuku,
  useQueryRequest as useQueryRequestKaryaBuku,
} from "../../provider/query-request/karya-buku";

import {
  QueryResponseProvider as QueryResponseProviderKaryaBuku,
  useQueryResponse as useQueryResponseKaryaBuku,
} from "../../provider/query-response/karya-buku";

import {
  QueryRequestProvider as QueryRequestProviderRiwayatPekerjaan,
  useQueryRequest as useQueryRequestRiwayatPekerjaan,
} from "../../provider/query-request/riwayat-pekerjaan";

import {
  QueryResponseProvider as QueryResponseProviderRiwayatPekerjaan,
  useQueryResponse as useQueryResponseRiwayatPekerjaan,
} from "../../provider/query-response/riwayat-pekerjaan";

import {
  QueryRequestProvider as QueryRequestProviderPendidikanPelatihan,
  useQueryRequest as useQueryRequestPendidikanPelatihan,
} from "../../provider/query-request/pendidikan-pelatihan";

import {
  QueryResponseProvider as QueryResponseProviderPendidikanPelatihan,
  useQueryResponse as useQueryResponsePendidikanPelatihan,
} from "../../provider/query-response/pendidikan-pelatihan";

import {
  QueryRequestProvider as QueryRequestProviderRiwayatPendidikan,
  useQueryRequest as useQueryRequestRiwayatPendidikan,
} from "../../provider/query-request/riwayat-pendidikan";

import {
  QueryResponseProvider as QueryResponseProviderRiwayatPendidikan,
  useQueryResponse as useQueryResponseRiwayatPendidikan,
} from "../../provider/query-response/riwayat-pendidikan";

import {
  QueryRequestProvider as QueryRequestProviderKaryaTulis,
  useQueryRequest as useQueryRequestKaryaTulis,
} from "../../provider/query-request/karya-tulis";

import {
  QueryResponseProvider as QueryResponseProviderKaryaTulis,
  useQueryResponse as useQueryResponseKaryaTulis,
} from "../../provider/query-response/karya-tulis";

import {
  QueryRequestProvider as QueryRequestProviderKesejahteraan,
  useQueryRequest as useQueryRequestKesejahteraan,
} from "../../provider/query-request/kesejahteraan";

import {
  QueryResponseProvider as QueryResponseProviderKesejahteraan,
  useQueryResponse as useQueryResponseKesejahteraan,
} from "../../provider/query-response/kesejahteraan";

import {
  QueryRequestProvider as QueryRequestProviderPenghargaan,
  useQueryRequest as useQueryRequestPenghargaan,
} from "../../provider/query-request/penghargaan";

import {
  QueryResponseProvider as QueryResponseProviderPenghargaan,
  useQueryResponse as useQueryResponsePenghargaan,
} from "../../provider/query-response/penghargaan";

import {
  QueryRequestProvider as QueryRequestProviderPenilaian,
  useQueryRequest as useQueryRequestPenilaian,
} from "../../provider/query-request/penilaian";

import {
  QueryResponseProvider as QueryResponseProviderPenilaian,
  useQueryResponse as useQueryResponsePenilaian,
} from "../../provider/query-response/penilaian";

import {
  QueryRequestProvider as QueryRequestProviderTugasTambahan,
  useQueryRequest as useQueryRequestTugasTambahan,
} from "../../provider/query-request/tugas-tambahan";

import {
  QueryResponseProvider as QueryResponseProviderTugasTambahan,
  useQueryResponse as useQueryResponseTugasTambahan,
} from "../../provider/query-response/tugas-tambahan";

import {
  QueryRequestProvider as QueryRequestProviderMapel,
  useQueryRequest as useQueryRequestMapel,
} from "../../provider/query-request/mapel";

import {
  QueryResponseProvider as QueryResponseProviderMapel,
  useQueryResponse as useQueryResponseMapel,
} from "../../provider/query-response/mapel";

import {
  QueryResponseProvider as QueryResponseProviderInpassing,
  useQueryResponse as useQueryResponseInpassing,
} from "../../provider/query-response/inpassing";

import {
  QueryResponseProvider as QueryResponseProviderRiwayatSertifikasi,
  useQueryResponse as useQueryResponseRiwayatSertifikasi,
} from "../../provider/query-response/riwayat-sertifikasi";

import {
  QueryResponseProvider as QueryResponseProviderRiwayatGajiBerkala,
  useQueryResponse as useQueryResponseRiwayatGajiBerkala,
} from "../../provider/query-response/riwayat-gaji-berkala";

import {
  QueryResponseProvider as QueryResponseProviderRiwayatPangkat,
  useQueryResponse as useQueryResponseRiwayatPangkat,
} from "../../provider/query-response/riwayat-pangkat";

import {
  QueryRequestProvider as QueryRequestProviderDataSayaKepegawaian,
  useQueryRequest as useQueryRequestDataSayaKepegawaian,
} from "../../provider/query-request/data-saya-kepegawaian";

import {
  QueryResponseProvider as QueryResponseProviderDataSayaKepegawaian,
  useQueryResponse as useQueryResponseDataSayaKepegawaian,
} from "../../provider/query-response/data-saya-kepegawaian";

import {
  QueryResponseProvider as QueryResponseProviderSertifikasiKeterampilanKhusus,
  useQueryResponse as useQueryResponseSertifikasiKeterampilanKhusus,
} from "../../provider/query-response/sertifikasi-keterampilan-khusus";

import Mutasi from "./mutasi";
import Anak from "./anak";
import KaryaBuku from "./karya-buku";
import RiwayatPekerjaan from "./riwayat-pekerjaan";
import PendidikanPelatihan from "./pendidikan-pelatihan";
import RiwayatPendidikan from "./riwayat-pendidikan";
import KaryaTulis from "./karya-tulis";
import Kesejahteraan from "./kesejahteraan";
import Penghargaan from "./penghargaan";
import Penilaian from "./penilaian";
import TugasTambahan from "./tugas-tambahan";
import Mapel from "./mapel";
import Inpassing from "./inpassing";
import RiwayatSertifikasi from "./riwayat-sertifikasi";
import RiwayatGajiBerkala from "./riwayat-gaji-berkala";
import RiwayatPangkat from "./riwayat-pangkat";
import FetchLoading from "../../hope-ui/components/fetch-loading";
import DataSayaKepegawaian from "./kepegawaian";
import InputRadio from "../../hope-ui/components/input-radio";
import SertifikasiKeterampilanKhusus from "./sertifikasi-keterampilan-khusus";

const DataSayaList = () => {
  const {
    handleGetDetailGTK,
    isLoadingGetDetailGTK,
    detailGTK,
    setNIKGTK,
    handlePostDetailGTK,
    isLoadingPostDetailGTK,
  } = useQueryResponseDetailGTK();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.data) {
      const nik_gtk: any = currentUser.data.username;
      handleGetDetailGTK(nik_gtk);
      setNIKGTK(nik_gtk);
    }
  }, [currentUser, handleGetDetailGTK]);

  let i = 1;

  return (
    <>
      {(isLoadingGetDetailGTK || isLoadingPostDetailGTK) && (
        <div className="p-4">
          <FetchLoading />
        </div>
      )}
      <Card.Header>
        <GTKCard data={detailGTK}></GTKCard>
      </Card.Header>
      {!detailGTK?.status_aktivasi ? (
        <div className="px-2">
          <Alert key={"danger"} variant="danger" className="fs-6">
            <i className="fa fa-warning me-2"></i>STATUS AKUN ANDA SAAT INI
            TIDAK AKTIF, SILAHKAN HUBUNGI ATASAN ANDA
          </Alert>
        </div>
      ) : (
        <>
          <Card.Body className="pt-1 px-2">
            <Accordion>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Biodata{" "}
                      {!detailGTK?.validasi_gtk.biodata.status && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data diri dengan lengkap dan benar
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white p-0">
                  <FormEntryGTK useTab={false} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Kepegawaian
                      {!detailGTK?.validasi_gtk.kepegawaian && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Hubungi HCSA untuk memasukkan data kepegawaian
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <DataSayaKepegawaian />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Mutasi
                      {!detailGTK?.validasi_gtk.mutasi && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data mutasi dan siapkan kelengkapan datanya
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "mutasi_true",
                        label: "Pernah Mutasi",
                        value: true,
                      },
                      {
                        id: "mutasi_false",
                        label: "Belum Pernah Mutasi",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_mutasi}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_mutasi: any = !detailGTK.is_mutasi;
                      payload.append("id", id);
                      payload.append("is_mutasi", is_mutasi);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_mutasi && <Mutasi />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Inpassing{" "}
                      {!detailGTK?.validasi_gtk.inpassing && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data inpassing jika ada
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "inpassing_true",
                        label: "Sudah Inpassing",
                        value: true,
                      },
                      {
                        id: "inpassing_false",
                        label: "Belum Inpassing",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_inpassing}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_inpassing: any = !detailGTK.is_inpassing;
                      payload.append("id", id);
                      payload.append("is_inpassing", is_inpassing);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_inpassing && <Inpassing />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Anak
                      {!detailGTK?.validasi_gtk.anak && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data anak jika ada
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "anak_true",
                        label: "Memiliki Anak",
                        value: true,
                      },
                      {
                        id: "anak_false",
                        label: "Belum Memiliki Anak",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_punya_anak}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_punya_anak: any = !detailGTK.is_punya_anak;
                      payload.append("id", id);
                      payload.append("is_punya_anak", is_punya_anak);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_punya_anak && <Anak />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Buku Yang Pernah Ditulis
                      {!detailGTK?.validasi_gtk.karia_buku && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data buku yang pernah ditulis jika ada
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "penulis_buku_true",
                        label: "Pernah Menulis Buku",
                        value: true,
                      },
                      {
                        id: "penulis_buku_false",
                        label: "Belum Pernah Menulis Buku",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_penulis_buku}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_penulis_buku: any = !detailGTK.is_penulis_buku;
                      payload.append("id", id);
                      payload.append("is_penulis_buku", is_penulis_buku);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_penulis_buku && <KaryaBuku />}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Riwayat Pekerjaan{" "}
                      {!detailGTK?.validasi_gtk.riwayat_pekerjaan && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data riwayat pekerjaan jika memiliki
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "riwayat_pekerjaan_true",
                        label: "Memiliki Riwayat Pekerjaan",
                        value: true,
                      },
                      {
                        id: "riwayat_pekerjaan_false",
                        label: "Belum Memiliki Riwayat Pekerjaan",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_riwayat_pekerjaan}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_riwayat_pekerjaan: any =
                        !detailGTK.is_riwayat_pekerjaan;
                      payload.append("id", id);
                      payload.append(
                        "is_riwayat_pekerjaan",
                        is_riwayat_pekerjaan
                      );
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_riwayat_pekerjaan && <RiwayatPekerjaan />}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Pendidikan Pelatihan
                      {!detailGTK?.validasi_gtk.pendidikan_pelatihan && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data pendidikan dan pelatihan jika ada
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "pendidikan_pelatihan_true",
                        label: "Memiliki Pendidikan Pelatihan",
                        value: true,
                      },
                      {
                        id: "pendidikan_pelatihan_false",
                        label: "Belum Memiliki Pendidikan Pelatihan",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_pendidikan_pelatihan}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_pendidikan_pelatihan: any =
                        !detailGTK.is_pendidikan_pelatihan;
                      payload.append("id", id);
                      payload.append(
                        "is_pendidikan_pelatihan",
                        is_pendidikan_pelatihan
                      );
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_pendidikan_pelatihan && <PendidikanPelatihan />}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Pendidikan Terakhir{" "}
                      {!detailGTK?.validasi_gtk.riwayat_pendidikan && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data pendidikan terakhir Anda
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <RiwayatPendidikan />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Sertifikasi Keterampilan Khusus{" "}
                      {!detailGTK?.validasi_gtk
                        .sertifikasi_keterampilan_khusus && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data sertifikasi keterampilan khusus
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white ">
                  <InputRadio
                    data={[
                      {
                        id: "sertifikasi_keterampilan_khusus_true",
                        label: "Memiliki Sertifikasi",
                        value: true,
                      },
                      {
                        id: "sertifikasi_keterampilan_khusus_false",
                        label: "Belum Memiliki Sertifikasi",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_sertifikasi_keterampilan_khusus}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_sertifikasi_keterampilan_khusus: any =
                        !detailGTK.is_sertifikasi_keterampilan_khusus;
                      payload.append("id", id);
                      payload.append(
                        "is_sertifikasi_keterampilan_khusus",
                        is_sertifikasi_keterampilan_khusus
                      );
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_sertifikasi_keterampilan_khusus && (
                    <SertifikasiKeterampilanKhusus />
                  )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Sertifikasi Pendidik{" "}
                      {!detailGTK?.validasi_gtk.riwayat_sertifikasi && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data sertifikasi pendidik
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white ">
                  <InputRadio
                    data={[
                      {
                        id: "riwayat_sertifikasi_true",
                        label: "Memiliki Sertifikasi",
                        value: true,
                      },
                      {
                        id: "riwayat_sertifikasi_false",
                        label: "Belum Memiliki Sertifikasi",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_riwayat_sertifikasi}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_riwayat_sertifikasi: any =
                        !detailGTK.is_riwayat_sertifikasi;
                      payload.append("id", id);
                      payload.append(
                        "is_riwayat_sertifikasi",
                        is_riwayat_sertifikasi
                      );
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_riwayat_sertifikasi && <RiwayatSertifikasi />}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Karya Tulis
                      {!detailGTK?.validasi_gtk.karya_tulis && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan karya tulis jika memiliki
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "karya_tulis_true",
                        label: "Memiliki Karya Tulis",
                        value: true,
                      },
                      {
                        id: "karya_tulis_false",
                        label: "Belum Memiliki Karya Tulis",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_karya_tulis}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_karya_tulis: any = !detailGTK.is_karya_tulis;
                      payload.append("id", id);
                      payload.append("is_karya_tulis", is_karya_tulis);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_karya_tulis && <KaryaTulis />}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Kesejahteraan{" "}
                      {!detailGTK?.validasi_gtk.kesejahteraan && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data kesejahteraan yang didapat dari pemerintah
                      (PKH, KIS, atau lainnya)
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "kesejahteraan_true",
                        label: "Memiliki Kesejahteraan",
                        value: true,
                      },
                      {
                        id: "kesejahteraan_false",
                        label: "Belum Memiliki Kesejahteraan",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_kesejahteraan}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_kesejahteraan: any = !detailGTK.is_kesejahteraan;
                      payload.append("id", id);
                      payload.append("is_kesejahteraan", is_kesejahteraan);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_kesejahteraan && <Kesejahteraan />}
                </Accordion.Body>
              </Accordion.Item>
              {/*
              <Accordion.Item eventKey={(i).toString()} className="mb-2">
                <Accordion.Header>
                  13. Penghargaan{" "}
                  {!detailGTK?.validasi_gtk.penghargaan && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "penghargaan_true",
                        label: "Memiliki Penghargaan",
                        value: true,
                      },
                      {
                        id: "penghargaan_false",
                        label: "Belum Memiliki Penghargaan",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_penghargaan}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_penghargaan: any = !detailGTK.is_penghargaan;
                      payload.append("id", id);
                      payload.append("is_penghargaan", is_penghargaan);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_penghargaan && <Penghargaan />}
                </Accordion.Body>
              </Accordion.Item>
              */}

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Penilaian / Tes{" "}
                      {!detailGTK?.validasi_gtk.penilaian && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data penilaian jika ada
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "penilaian_true",
                        label: "Memiliki Penilaian / Tes",
                        value: true,
                      },
                      {
                        id: "penilaian_false",
                        label: "Belum Memiliki Penilaian / Tes",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_penilaian}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_penilaian: any = !detailGTK.is_penilaian;
                      payload.append("id", id);
                      payload.append("is_penilaian", is_penilaian);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_penilaian && <Penilaian />}
                </Accordion.Body>
              </Accordion.Item>
              {/*
              <Accordion.Item eventKey={(i).toString()} className="mb-2">
                <Accordion.Header>
                  15. Riwayat Gaji Berkala
                  {!detailGTK?.validasi_gtk.riwayat_gaji_berkala && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white p-0">
                  <RiwayatGajiBerkala />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={(i).toString()} className="mb-2">
                <Accordion.Header>
                  16. Riwayat Kepangkatan / Golongan
                  {!detailGTK?.validasi_gtk.riwayat_pangkat && (
                    <Badge className="ms-2" bg="danger">
                      !
                    </Badge>
                  )}
                </Accordion.Header>
                <Accordion.Body className="bg-white p-0">
                  <RiwayatPangkat />
                </Accordion.Body>
              </Accordion.Item>*/}

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Tugas Tambahan{" "}
                      {!detailGTK?.validasi_gtk.tugas_tambahan && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data tugas tambahan yang diemban
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "tugas_tambahan_true",
                        label: "Memiliki Tugas Tambahan",
                        value: true,
                      },
                      {
                        id: "tugas_tambahan_false",
                        label: "Belum Memiliki Tugas Tambahan",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_tugas_tambahan}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_tugas_tambahan: any =
                        !detailGTK.is_tugas_tambahan;
                      payload.append("id", id);
                      payload.append("is_tugas_tambahan", is_tugas_tambahan);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_tugas_tambahan && <TugasTambahan />}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={i.toString()} className="mb-2">
                <Accordion.Header>
                  <div className="d-flex flex-column gap-2 flex-wrap">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      {i++}. Mata Pelajaran Yang Diampu{" "}
                      {!detailGTK?.validasi_gtk.mapel && (
                        <Badge className="ms-2" bg="danger">
                          !
                        </Badge>
                      )}
                    </div>
                    <div className="fs-12 text-info fw-normal ">
                      Masukkan data mata pelajaran yang diampu jika ada
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="bg-white">
                  <InputRadio
                    data={[
                      {
                        id: "mapel_true",
                        label: "Memiliki Mata Pelajaran Yang Diampu",
                        value: true,
                      },
                      {
                        id: "mapel_false",
                        label: "Belum Memiliki Mata Pelajaran Yang Diampu",
                        value: false,
                      },
                    ]}
                    required
                    value={detailGTK.is_mapel}
                    onChange={(e) => {
                      const payload = new FormData();
                      const id: any = detailGTK.id;
                      const is_mapel: any = !detailGTK.is_mapel;
                      payload.append("id", id);
                      payload.append("is_mapel", is_mapel);
                      handlePostDetailGTK(payload);
                    }}
                  />
                  {detailGTK.is_mapel && <Mapel />}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Body>
        </>
      )}
    </>
  );
};

export const DataSayaListWrapper = () => (
  <QueryResponseProviderDetailGTK>
    <QueryRequestProviderMutasi>
      <QueryResponseProviderMutasi>
        <QueryRequestProviderAnak>
          <QueryResponseProviderAnak>
            <QueryRequestProviderKaryaBuku>
              <QueryResponseProviderKaryaBuku>
                <QueryRequestProviderRiwayatPekerjaan>
                  <QueryResponseProviderRiwayatPekerjaan>
                    <QueryRequestProviderPendidikanPelatihan>
                      <QueryResponseProviderPendidikanPelatihan>
                        <QueryRequestProviderPendidikanPelatihan>
                          <QueryResponseProviderPendidikanPelatihan>
                            <QueryRequestProviderRiwayatPendidikan>
                              <QueryResponseProviderRiwayatPendidikan>
                                <QueryRequestProviderKaryaTulis>
                                  <QueryResponseProviderKaryaTulis>
                                    <QueryRequestProviderKesejahteraan>
                                      <QueryResponseProviderKesejahteraan>
                                        <QueryRequestProviderPenghargaan>
                                          <QueryResponseProviderPenghargaan>
                                            <QueryRequestProviderPenilaian>
                                              <QueryResponseProviderPenilaian>
                                                <QueryRequestProviderTugasTambahan>
                                                  <QueryResponseProviderTugasTambahan>
                                                    <QueryRequestProviderMapel>
                                                      <QueryResponseProviderMapel>
                                                        <QueryResponseProviderInpassing>
                                                          <QueryResponseProviderRiwayatSertifikasi>
                                                            <QueryResponseProviderRiwayatGajiBerkala>
                                                              <QueryResponseProviderRiwayatPangkat>
                                                                <QueryRequestProviderDataSayaKepegawaian>
                                                                  <QueryResponseProviderDataSayaKepegawaian>
                                                                    <QueryResponseProviderSertifikasiKeterampilanKhusus>
                                                                      <DataSayaList />
                                                                    </QueryResponseProviderSertifikasiKeterampilanKhusus>
                                                                  </QueryResponseProviderDataSayaKepegawaian>
                                                                </QueryRequestProviderDataSayaKepegawaian>
                                                              </QueryResponseProviderRiwayatPangkat>
                                                            </QueryResponseProviderRiwayatGajiBerkala>
                                                          </QueryResponseProviderRiwayatSertifikasi>
                                                        </QueryResponseProviderInpassing>
                                                      </QueryResponseProviderMapel>
                                                    </QueryRequestProviderMapel>
                                                  </QueryResponseProviderTugasTambahan>
                                                </QueryRequestProviderTugasTambahan>
                                              </QueryResponseProviderPenilaian>
                                            </QueryRequestProviderPenilaian>
                                          </QueryResponseProviderPenghargaan>
                                        </QueryRequestProviderPenghargaan>
                                      </QueryResponseProviderKesejahteraan>
                                    </QueryRequestProviderKesejahteraan>
                                  </QueryResponseProviderKaryaTulis>
                                </QueryRequestProviderKaryaTulis>
                              </QueryResponseProviderRiwayatPendidikan>
                            </QueryRequestProviderRiwayatPendidikan>
                          </QueryResponseProviderPendidikanPelatihan>
                        </QueryRequestProviderPendidikanPelatihan>
                      </QueryResponseProviderPendidikanPelatihan>
                    </QueryRequestProviderPendidikanPelatihan>
                  </QueryResponseProviderRiwayatPekerjaan>
                </QueryRequestProviderRiwayatPekerjaan>
              </QueryResponseProviderKaryaBuku>
            </QueryRequestProviderKaryaBuku>
          </QueryResponseProviderAnak>
        </QueryRequestProviderAnak>
      </QueryResponseProviderMutasi>
    </QueryRequestProviderMutasi>
  </QueryResponseProviderDetailGTK>
);
