import { Spinner } from "react-bootstrap";

const FetchLoading = () => {
  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center"
      style={{
        width: "100%",
        height: "100vh",
        zIndex: 1000,
        backgroundColor: "black",
        opacity: 0.6,
        position: "fixed",
        left: 0,
        top: 0,
      }}
    >
      <Spinner
        animation="border"
        variant="success"
        style={{
          width: 100,
          height: 100,
        }}
      />
    </div>
  );
};

export default FetchLoading;
