import { Modal } from "react-bootstrap";
import {
  useQueryResponse,
  useQueryResponsePaginationDetailPresensiGTK,
} from "../../../../provider/query-response/dashboard";
import { useQueryRequest } from "../../../../provider/query-request/dashboard/detail-presensi-gtk";
import Toolbar from "./toolbar";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns/columns";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const Detail = () => {
  const {
    isShowDetailPresensiGTK,
    setIsShowDetailPresensiGTK,
    isLoadingGetDetailPresensiGTK,
    detailPresensiGTK,
    handleExportPresensiGTK,
    isLoadingExportPresensiGTK,
  } = useQueryResponse();
  return (
    <Modal
      backdrop="static"
      show={isShowDetailPresensiGTK}
      onBackdropClick={() => {
        setIsShowDetailPresensiGTK(false);
      }}
      size="xl"
      onHide={() => setIsShowDetailPresensiGTK(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Detail Presensi GTK</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingExportPresensiGTK && <FetchLoading />}
        <div className="d-flex flex-row justify-content-end mb-3">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              handleExportPresensiGTK();
            }}
          >
            Export Excel <i className="fa fa-download ms-2"></i>
          </button>
        </div>
        <Toolbar />
        <DataTable
          isLoading={isLoadingGetDetailPresensiGTK}
          header={Columns}
          data={detailPresensiGTK?.data ?? []}
          pagination={useQueryResponsePaginationDetailPresensiGTK}
          queryRequest={useQueryRequest}
        />
      </Modal.Body>
    </Modal>
  );
};

export default Detail;
