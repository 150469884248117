import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";
import { DetailAnggotaKelompokBPI } from "../anggota-kelompok-bpi/_queryResponse";
import { FormKelompokBPIPayload } from "../kelompok-bpi/_queryResponse";

export type FormPindahTutorBPIPayload = {
  id: number | string | null;
  id_kelompok_bpi: number | string | null;
  id_anggota_kelompok_bpi: number | string | null;
  nik_tutor_bpi_lama: string | null;
  nik_tutor_bpi_tujuan: string | null;
  id_kelompok_bpi_tujuan: number | string | null;
  keterangan: string | null;
};

export type TutorBPI = {
  nik_gtk: string | null;
  nama_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  formikPindahTutorBPI?: FormikProps<FormPindahTutorBPIPayload>;
  formikPindahTutorBPIValidationSchema?: any;
  handlePostPindahTutorBPI?: (payload: FormData) => void;
  isLoadingPostPindahTutorBPI: boolean;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handleGetTutorBPI?: () => void;
  isLoadingGetTutorBPI: boolean;
  allTutorBPI: Array<TutorBPI>;
  setAllTutorBPI: Dispatch<SetStateAction<Array<TutorBPI>>>;
  allKelompokTutorBPITujuan: Array<FormKelompokBPIPayload>;
  setAllKelompokTutorBPITujuan: Dispatch<
    SetStateAction<Array<FormKelompokBPIPayload>>
  >;
  handleGetAllKelompokBPITujuan?: (nik_gtk: string, type?: string) => void;
  isLoadingGetAllKelompokBPITujuan: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostPindahTutorBPI: false,
  submitting: false,
  setSubmitting: () => {},
  handleGetTutorBPI: () => {},
  isLoadingGetTutorBPI: false,
  allTutorBPI: [],
  setAllTutorBPI: () => {},
  allKelompokTutorBPITujuan: [],
  setAllKelompokTutorBPITujuan: () => {},
  isLoadingGetAllKelompokBPITujuan: false,
  resetForm: false,
  setResetForm: () => {},
};
