import { FC } from "react";
import { Accordion, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import Table18 from "../../../../hope-ui/components/table-18";
import moment from "moment";
import {
  QueryResponseProvider as QueryResponseProviderKepegawaian,
  useQueryResponse as useQueryResponseKepegawaian,
} from "../../../../provider/query-response/kepegawaian";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

type Props = {
  data?: any;
};

const ListKepegawaian: FC<Props> = ({ data }) => {
  const {
    handleDeleteKepegawaian,
    isLoadingDeleteKepegawaian,
    handleDownloadDocKepegawaian,
    isLoadingDownloadDocKepegawaian,
  } = useQueryResponseKepegawaian();
  return (
    <>
      {(isLoadingDeleteKepegawaian || isLoadingDownloadDocKepegawaian) && (
        <FetchLoading />
      )}
      <Accordion>
        {data.map((val, idx) => {
          const nik_gtk = val.nik_gtk;
          delete val.nik_gtk;
          delete val.is_current;
          //delete val.doc_kepegawaian;
          val.tmt_pengangkatan = moment(val.tmt_pengangkatan).format(
            "DD MMM YYYY"
          );
          val.masa_berlaku_sk = moment(val.masa_berlaku_sk).format(
            "DD MMM YYYY"
          );
          return (
            <Accordion.Item eventKey={idx} className="mb-2">
              <Accordion.Header>
                {idx + 1}. No. SK :{" "}
                <b className="ms-2">{val.nomor_sk_pengangkatan}</b>
              </Accordion.Header>
              <Accordion.Body className="bg-white">
                <div className="d-flex flex-row justify-content-end align-items-center gap-2 mb-3">
                  <Dropdown as={ButtonGroup}>
                    <Button type="button" size="sm" variant={"success"}>
                      Aksi
                    </Button>
                    <Dropdown.Toggle type="button" split variant={"success"}>
                      <span className="visually-hidden">Aksi</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}data-gtk/kepegawaian/form/${nik_gtk}/${val.id}`
                          );
                        }}
                        style={{
                          zIndex: 500,
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          if (handleDeleteKepegawaian)
                            handleDeleteKepegawaian(val.id);
                        }}
                      >
                        Hapus
                      </Dropdown.Item>

                      <Dropdown.Item
                        href="#"
                        onClick={(e) => {
                          console.log(val);
                          const pecah = val.doc_kepegawaian.split("/");
                          const name = pecah[pecah.length - 1];
                          handleDownloadDocKepegawaian(
                            val.doc_kepegawaian,
                            name
                          );
                        }}
                      >
                        Unduh Dokumen SK
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <Table18 data={val} />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};

export const ListKepegawaianWrapper: FC<Props> = ({ data }) => (
  <QueryResponseProviderKepegawaian>
    <ListKepegawaian data={data} />
  </QueryResponseProviderKepegawaian>
);
