import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/detail-gtk/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { getDetailGTK, postDetailGTK } from "../../../api/detail-gtk/_request";
import { initialQueryState } from "../../../models/global";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { FormDataGTKPayload } from "../../../models/data-gtk/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const [nikGTK, setNIKGTK] = useState<string>("");
  const [isShowModal, setShowModal] = useState<boolean>(
    initialQueryResponse.isShowModal
  );
  const [showRiwayatLoginModal, setShowRiwayatLoginModal] =
    useState<boolean>(false);
  const [detailGTK, setDetailGTK] = useState<FormDataGTKPayload | undefined>();
  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_DETAIL_GTK}-${nikGTK}`,
    () => getDetailGTK(nikGTK),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: false,
    }
  );

  const { mutateAsync: GetDetailGTK, isLoading: isLoadingGetDetailGTK } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_GTK}`,
      mutationFn: ({ id }: { id: string }) => getDetailGTK(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_GTK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailGTK = useCallback(
    async (id: string) => {
      await GetDetailGTK({ id })
        .then((res) => {
          if (!res.success) {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            });
            return;
          }
          setDetailGTK(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailGTK]
  );

  const { mutateAsync: PostDetailGTK, isLoading: isLoadingPostDetailGTK } =
    useMutation({
      mutationKey: `${QUERIES.GET_USER_HAK_AKSES}`,
      mutationFn: ({ data }: { data: FormData }) => postDetailGTK(data),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.GET_USER_HAK_AKSES}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostDetailGTK = useCallback(
    async (data: FormData) => {
      await PostDetailGTK({ data })
        .then((res) => {
          if (!res.success) {
            return SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            });
          }

          handleGetDetailGTK(res.data.nik_gtk);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostDetailGTK]
  );

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      nikGTK,
      setNIKGTK,
      isShowModal,
      setShowModal,
      handleGetDetailGTK,
      isLoadingGetDetailGTK,
      detailGTK,
      setDetailGTK,
      showRiwayatLoginModal,
      setShowRiwayatLoginModal,
      handlePostDetailGTK,
      isLoadingPostDetailGTK,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      nikGTK,
      setNIKGTK,
      isShowModal,
      setShowModal,
      handleGetDetailGTK,
      isLoadingGetDetailGTK,
      detailGTK,
      setDetailGTK,
      showRiwayatLoginModal,
      setShowRiwayatLoginModal,
      handlePostDetailGTK,
      isLoadingPostDetailGTK,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
