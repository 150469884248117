import { Card, Col, Row } from "react-bootstrap";
import Datepicker from "../../../../hope-ui/components/datepicker";
import { useQueryRequest } from "../../../../provider/query-request/presensi-saya/presensi-gtk";
import { FC, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
type Props = {
  jumlahHari?: number;
};

const Toolbar: FC<Props> = ({ jumlahHari }) => {
  const currentDate = new Date();
  const [tanggalAwal, setTanggalAwal] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  const [tanggalAkhir, setTanggalAkhir] = useState<string>();
  const { updateState } = useQueryRequest();

  return (
    <Card.Header>
      <Row>
        <Col lg={4}>
          <Datepicker
            label="Dari Tanggal"
            onChange={(e) => {
              const value = dayjs(e).format("YYYY-MM-DD");
              setTanggalAwal(value);
            }}
            value={dayjs(tanggalAwal).format("YYYY-MM-DD")}
            required
          />
        </Col>

        <Col lg={4}>
          <Datepicker
            label="Hingga Tanggal"
            onChange={(e) => {
              const value = dayjs(e).format("YYYY-MM-DD");
              setTanggalAkhir(value);
            }}
            value={dayjs(tanggalAkhir).format("YYYY-MM-DD")}
            minDate={dayjs(tanggalAwal)}
            required
          />
        </Col>

        <Col lg={3}>
          <div className="h-100 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-sm btn-dark"
              onClick={() => {
                updateState({
                  tanggal_awal: tanggalAwal,
                  tanggal_akhir: tanggalAkhir,
                });
              }}
            >
              Terapkan
              <i className="fa fa-sliders ms-2"></i>
            </button>
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-row justify-content-between align-items-end">
        <h6 className="pt-4">
          Berikut Hasil Dari Presensi{" "}
          <span className="fw-bold text-danger">{jumlahHari}</span> Hari
        </h6>
      </div>
      <hr className="bg-light mb-0" />
    </Card.Header>
  );
};

export default Toolbar;
