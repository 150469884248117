import { Outlet } from "react-router";
import { AsideDefault } from "./components/partials/aside/AsideDefault";
import { Content } from "./components/partials/content/Content";
import { Header } from "./components/partials/header/Header";
import { PageDataProvider } from "./core";
import SubHeader from "./components/partials/sub-header";
import { useAuth } from "../provider/core/auth";
import { Fragment } from "react";
import Loader from "./components/partials/loader";
import { useLocation, useNavigate } from "react-router-dom";
import ForbiddenPage from "../pages/403-forbidden";
import Footer from "./components/partials/footer";
import OffCanvas from "./components/partials/off-canvas";

const MasterLayout = () => {
  const { currentUser, isLoadingFetchCurrentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation().pathname.split("/")[1];
  const menus = currentUser?.data?.menus;
  let find = menus?.find((element) => element.route_name === `/${location}`);
  if (location.length === 0 && menus && menus?.length > 0) {
    const route: any = menus[0].route_name;
    navigate(route);
  }

  return (
    <PageDataProvider>
      {isLoadingFetchCurrentUser && <Loader />}
      {currentUser && !isLoadingFetchCurrentUser && find && (
        <Fragment>
          <AsideDefault />
          <main className="main-content mb-4">
            <div className="position-relative">
              <Header />
              <SubHeader />
            </div>

            <Content>
              <Outlet />
            </Content>
            <Footer />
            <OffCanvas />
          </main>
        </Fragment>
      )}

      {!find && !isLoadingFetchCurrentUser && <ForbiddenPage />}
    </PageDataProvider>
  );
};

export { MasterLayout };
