import { FC, useEffect } from "react";
import { Button, ButtonGroup, Dropdown, Nav, Tab } from "react-bootstrap";
import { useQueryResponse } from "../../../../../provider/query-response/status-aktivasi";

type Props = {
  id: string;
  data: any;
};

export const AksiCell: FC<Props> = ({ id, data }) => {
  const { handlePostAktifkanGTK, handlePostNonAktifkanGTK } =
    useQueryResponse();
  const handleClick = (status: boolean) => {
    const payload = new FormData();
    payload.append("nik_gtk", id);
    payload.append("status", status ? "true" : "false");
    if (handlePostAktifkanGTK && status) handlePostAktifkanGTK(payload);
    else if (handlePostNonAktifkanGTK && !status)
      handlePostNonAktifkanGTK(payload);
  };
  return (
    <Dropdown as={ButtonGroup}>
      <Button type="button" size="sm" variant="success">
        Aktif
      </Button>
      <Dropdown.Toggle type="button" split variant="success">
        <span className="visually-hidden">Aktif</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {/* <Dropdown.Item
          href="#"
          onClick={(e) => {
            handleClick(true);
          }}
        >
          Aktifkan
        </Dropdown.Item> */}
        <Dropdown.Item
          href="#"
          onClick={(e) => {
            handleClick(false);
          }}
        >
          Non Aktifkan
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
