import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/riwayat-pekerjaan";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/riwayat-pekerjaan";
import ModalRiwayatPekerjaan from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";

const RiwayatPekerjaan = () => {
  const {
    isLoadingFetchData,
    listData,
    isLoadingDownloadDocRiwayatPekerjaan,
    isLoadingDeleteRiwayatPekerjaan,
  } = useQueryResponse();
  return (
    <>
      {(isLoadingDownloadDocRiwayatPekerjaan ||
        isLoadingDeleteRiwayatPekerjaan) && <FetchLoading />}
      <Toolbar />
      <ModalRiwayatPekerjaan />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default RiwayatPekerjaan;
