import { FC, useEffect, useState } from "react";
type Props = {
  keyword?: string | number;
  state?: (keyword: string) => any;
  value?: string;
};
const SearchInputDT: FC<Props> = ({ keyword = "", state, value = "" }) => {
  const [searchTerm, setSearchTerm] = useState<string>(value);

  useEffect(() => {
    if (state !== undefined && searchTerm !== undefined) {
      const timeout = setTimeout(() => {
        state(searchTerm);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [searchTerm]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className="input-group ">
      <input
        type="text"
        className="form-control"
        placeholder="Search..."
        onChange={handleInputChange}
        //value={value}
      />
      <span className="input-group-text" id="">
        <svg
          width="18"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.7669"
            cy="11.7666"
            r="8.98856"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></circle>
          <path
            d="M18.0186 18.4851L21.5426 22"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </span>
    </div>
  );
};

export { SearchInputDT };
