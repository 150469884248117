import { Form } from "react-bootstrap";
import { ChangeEventHandler, FC, FocusEventHandler, useState } from "react";
import { FieldInputProps } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

type Props = {
  id?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  formik?: FieldInputProps<any>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  ref?: any;
  style?: React.CSSProperties;
};
const TextInputComponent: FC<Props> = ({
  id = "",
  label = null,
  type = "text",
  placeholder = "",
  className = "",
  disabled = false,
  onChange,
  formik,
  isRequired = false,
  value = "",
  onBlur,
  ref,
  style,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      {label && (
        <Form.Label className="">
          {label} {isRequired && <span className="text-danger"> *</span>}
        </Form.Label>
      )}
      <div className={`d-flex ${className}`}>
        <Form.Control
          ref={ref}
          type={type === "password" && !showPassword ? "password" : "text"}
          className={className}
          id={id}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          onChange={onChange}
          onBlur={onBlur}
          {...formik}
          value={formik ? formik.value : value}
          style={style}
        ></Form.Control>
        {type === "password" && !showPassword && (
          <div
            className="input-group-text cursor-pointer"
            onClick={() => setShowPassword(true)}
          >
            <FontAwesomeIcon icon={faEye} color="gray" />
          </div>
        )}

        {type === "password" && showPassword && (
          <div
            className="input-group-text cursor-pointer"
            onClick={() => setShowPassword(false)}
          >
            <FontAwesomeIcon icon={faEyeSlash} color="gray" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextInputComponent;
