import { FC, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import TextareaComponent from "../../../../../hope-ui/components/textarea-input";
import { useQueryResponse as useQueryResponseKelompokBPI } from "../../../../../provider/query-response/absensi-bpi/kelompok-bpi/kelola-kelompok";
import { useQueryResponse as useQueryResponseAnggotaKelompokBPI } from "../../../../../provider/query-response/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";
import { useAuth } from "../../../../../provider/core/auth";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-bpi/kelompok-bpi/pindah-tutor";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-bpi/kelompok-bpi/pindah-tutor";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
};

const PindahTutor: FC<Props> = ({ activeTab }) => {
  const { currentUser } = useAuth();
  const {
    isLoadingPostPindahTutorBPI,
    formikPindahTutorBPI,
    setSubmitting,
    submitting,
    handleGetTutorBPI,
    isLoadingGetTutorBPI,
    allTutorBPI,
    allKelompokTutorBPITujuan,
    setAllKelompokTutorBPITujuan,
    handleGetAllKelompokBPITujuan,
    isLoadingGetAllKelompokBPITujuan,
    isLoadingFetchData,
    listData,
  } = useQueryResponse();

  const {
    allKelompokBPI,
    handleGetAllKelompokBPI,
    isLoadingGetAllKelompokBPI,
    handleGetAnggotaKelompokBPI,
    isLoadingGetAnggotaKelompokBPI,
    anggotaKelompokBPI,
  } = useQueryResponseAnggotaKelompokBPI();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      activeTab === 3 &&
      handleGetAllKelompokBPI &&
      handleGetTutorBPI
    ) {
      const nik: any = currentUser.data.username;
      handleGetAllKelompokBPI(nik);
      handleGetTutorBPI();
    }
  }, [activeTab, handleGetAllKelompokBPI, handleGetTutorBPI, currentUser]);

  let optionsKelompokBPI: any = allKelompokBPI.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_bpi,
    };
  });

  let optionsAnggotaKelompokBPI: any = anggotaKelompokBPI?.map((val) => {
    return {
      value: val.id,
      label: `${val.nik_gtk} - ${val.gtk.nama_gtk}`,
    };
  });

  let optionsTutorBPI: any = allTutorBPI.map((val) => {
    return {
      value: val.nik_gtk,
      label: `${val.nik_gtk} - ${val.nama_gtk}`,
    };
  });

  let optionsKelompokTutorBPITujuan: any = allKelompokTutorBPITujuan.map(
    (val) => {
      return {
        value: val.id,
        label: `${val.nama_kelompok_bpi}`,
      };
    }
  );

  return (
    <Col
      style={{
        display: activeTab === 3 ? "block" : "none",
      }}
      className="p-4"
    >
      {isLoadingPostPindahTutorBPI && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikPindahTutorBPI?.submitForm();
        }}
      >
        <Row className="gy-4 mb-4">
          <h6>Pilih Anggota Kelompok</h6>
          <hr className="bg-light mb-0" />
          <Col lg="6">
            <Form.Group>
              <Select2
                isRequired
                label="Kelompok BPI"
                placeholder="Pilih Kelompok BPI"
                options={optionsKelompokBPI}
                isLoading={isLoadingGetAllKelompokBPI}
                onChange={(e: any) => {
                  const id_kelompok_bpi = e?.value;
                  if (handleGetAnggotaKelompokBPI) {
                    handleGetAnggotaKelompokBPI(id_kelompok_bpi);
                    formikPindahTutorBPI?.setFieldValue(
                      "id_anggota_kelompok_bpi",
                      null
                    );
                  }
                  formikPindahTutorBPI?.setFieldValue(
                    "id_kelompok_bpi",
                    id_kelompok_bpi
                  );
                }}
                value={
                  formikPindahTutorBPI?.getFieldProps("id_kelompok_bpi")
                    .value ?? null
                }
                isClearable
              />
              {submitting && formikPindahTutorBPI?.errors.id_kelompok_bpi && (
                <div className="text-danger mt-2">
                  {formikPindahTutorBPI?.errors.id_kelompok_bpi}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Anggota Kelompok BPI"
                placeholder="Pilih Anggota Kelompok BPI"
                isRequired
                isLoading={isLoadingGetAnggotaKelompokBPI}
                options={optionsAnggotaKelompokBPI}
                onChange={(e: any) => {
                  const id_anggota_kelompok_bpi: any = e?.value;
                  formikPindahTutorBPI?.setFieldValue(
                    "id_anggota_kelompok_bpi",
                    id_anggota_kelompok_bpi
                  );
                }}
                value={
                  formikPindahTutorBPI?.getFieldProps("id_anggota_kelompok_bpi")
                    .value ?? null
                }
              />
              {submitting &&
                formikPindahTutorBPI?.errors.id_anggota_kelompok_bpi && (
                  <div className="text-danger mt-2">
                    {formikPindahTutorBPI?.errors.id_anggota_kelompok_bpi}
                  </div>
                )}
            </Form.Group>
          </Col>

          <h6 className="mt-5">Tutor & Kelompok BPI Tujuan</h6>
          <hr className="bg-light mb-0" />

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Tutor BPI Tujuan"
                placeholder="Pilih Tutor BPI Tujuan"
                isRequired
                isLoading={isLoadingGetTutorBPI}
                onChange={(e: any) => {
                  const nik_gtk = e?.value;
                  if (handleGetAllKelompokBPITujuan) {
                    handleGetAllKelompokBPITujuan(nik_gtk, "tujuan");
                  }
                  formikPindahTutorBPI?.setFieldValue(
                    "nik_tutor_bpi_tujuan",
                    nik_gtk
                  );
                }}
                value={
                  formikPindahTutorBPI?.getFieldProps("nik_tutor_bpi_tujuan")
                    .value ?? null
                }
                options={optionsTutorBPI}
              />
            </Form.Group>
            {submitting &&
              formikPindahTutorBPI?.errors.nik_tutor_bpi_tujuan && (
                <div className="text-danger mt-2">
                  {formikPindahTutorBPI?.errors.nik_tutor_bpi_tujuan}
                </div>
              )}
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Kelompok BPI Tujuan"
                placeholder="Pilih Kelompok BPI Tujuan"
                isRequired
                options={optionsKelompokTutorBPITujuan}
                isLoading={isLoadingGetAllKelompokBPITujuan}
                onChange={(e: any) => {
                  const id_kelompok_bpi_tujuan: any = e?.value;
                  formikPindahTutorBPI?.setFieldValue(
                    "id_kelompok_bpi_tujuan",
                    id_kelompok_bpi_tujuan
                  );
                }}
                value={
                  formikPindahTutorBPI?.getFieldProps("id_kelompok_bpi_tujuan")
                    .value ?? null
                }
              />
            </Form.Group>
            {submitting &&
              formikPindahTutorBPI?.errors.id_kelompok_bpi_tujuan && (
                <div className="text-danger mt-2">
                  {formikPindahTutorBPI?.errors.id_kelompok_bpi_tujuan}
                </div>
              )}
          </Col>

          <Col lg="12">
            <Form.Group>
              <TextareaComponent
                label="Keterangan"
                placeholder="Masukkan Keterangan"
                rows={3}
                value={
                  formikPindahTutorBPI?.getFieldProps(`keterangan`).value ?? ""
                }
                formik={formikPindahTutorBPI?.getFieldProps(`keterangan`)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Card.Footer className={`p-3 d-flex flex-row justify-content-end`}>
          <button type="submit" className={`btn btn-primary btn-sm `}>
            <i className={`far fa-floppy-disk me-2`}></i>
            Simpan
          </button>
        </Card.Footer>

        <Col className="mt-4">
          <h6>History Pindah Tutor BPI</h6>
          <hr className="bg-light" />
          <Toolbar />
          <DataTable
            isLoading={isLoadingFetchData}
            data={listData?.data}
            header={Columns}
            pagination={useQueryResponsePagination}
            queryRequest={useQueryRequest}
          />
        </Col>
      </Form>
    </Col>
  );
};

export default PindahTutor;
