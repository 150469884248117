import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";
export type FormProfilPayload = {
  username: string | null;
  poto: File | string | null;
};

export type QueryResponseContextProps<T> = {
  showModalProfil: boolean;
  setShowModalProfil: Dispatch<SetStateAction<boolean>>;
  formikProfil?: FormikProps<FormProfilPayload>;
  formikProfilValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostProfil: (data: FormData) => void;
  isLoadingPostProfil: boolean;
  detailProfil: FormProfilPayload;
  setDetailProfil: Dispatch<SetStateAction<FormProfilPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  showModalProfil: false,
  setShowModalProfil: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostProfil: () => {},
  isLoadingPostProfil: false,
  detailProfil: {
    username: null,
    poto: null,
  },
  setDetailProfil: () => {},
  resetForm: false,
  setResetForm: () => {},
};
