import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/riwayat-pendidikan";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/riwayat-pendidikan";
import ModalRiwayatPendidikan from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";

const RiwayatPendidikan = () => {
  const {
    isLoadingFetchData,
    listData,
    isLoadingDownloadDocRiwayatPendidikan,
    isLoadingDeleteRiwayatPendidikan,
  } = useQueryResponse();
  return (
    <>
      {(isLoadingDownloadDocRiwayatPendidikan ||
        isLoadingDeleteRiwayatPendidikan) && <FetchLoading />}
      <Toolbar />
      <ModalRiwayatPendidikan />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default RiwayatPendidikan;
