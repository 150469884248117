import { Dispatch, SetStateAction } from "react";
import { FormMemoPayload } from "../memo/_queryResponse";

export type QueryResponseContextProps<T> = {
  isShowNotifikasi: boolean;
  setShowNotifikasi: Dispatch<SetStateAction<boolean>>;
  totalPesan: number;
  setTotalPesan: Dispatch<SetStateAction<number>>;
  listPesan: Array<FormMemoPayload>;
  setListPesan: Dispatch<SetStateAction<Array<FormMemoPayload>>>;
  handleGetDetailMemo: (id: number) => void;
  isLoadingGetDetailMemo: boolean;
  detailMemo?: FormMemoPayload;
  setDetailMemo: Dispatch<SetStateAction<FormMemoPayload>>;
  isShowDetailMemo?: boolean;
  setShowDetailMemo: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isShowNotifikasi: false,
  setShowNotifikasi: () => {},
  totalPesan: 0,
  setTotalPesan: () => {},
  listPesan: [],
  setListPesan: () => {},
  handleGetDetailMemo: () => {},
  isLoadingGetDetailMemo: false,
  setDetailMemo: () => {},
  isShowDetailMemo: false,
  setShowDetailMemo: () => {},
};
