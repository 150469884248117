import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListPenilaian = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_PENILAIAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postPenilaian = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_PENILAIAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailPenilaian = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_PENILAIAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocPenilaian = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deletePenilaian = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_PENILAIAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListPenilaian,
  postPenilaian,
  getDetailPenilaian,
  downloadDocPenilaian,
  deletePenilaian,
};
