import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormKelompokLT3QPayload } from "../kelompok-lt3q/_queryResponse";

export type FormAnggotaKelompokLT3QPayload = {
  id: number | null;
  id_kelompok_lt3q: string | null;
  nik_gtk: string | null;
  nik_tutor_lt3q: string | null;
};

export type DetailAnggotaKelompokLT3Q = {
  id: number | null;
  id_kelompok_lt3q: string | null;
  nik_gtk: string | null;
  nik_tutor_lt3q: string | null;
  gtk: any;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handlePostAnggotaKelompokLT3Q?: (data: FormData) => void;
  isLoadingPostAnggotaKelompokLT3Q: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteAnggotaKelompokLT3Q?: (id: string) => void;
  isLoadingDeleteAnggotaKelompokLT3Q?: boolean;
  handleGetDetailAnggotaKelompokLT3Q?: (id: string) => void;
  isLoadingGetDetailAnggotaKelompokLT3Q: boolean;
  detailAnggotaKelompokLT3Q?: FormAnggotaKelompokLT3QPayload;
  setDetailAnggotaKelompokLT3Q: Dispatch<
    SetStateAction<FormAnggotaKelompokLT3QPayload>
  >;
  resetDetailAnggotaKelompokLT3Q: () => void;
  handleGetAllKelompokLT3Q?: (nik_gtk: string, type?: string) => void;
  isLoadingGetAllKelompokLT3Q: boolean;
  allKelompokLT3Q: Array<FormKelompokLT3QPayload>;
  setAllKelompokLT3Q: Dispatch<SetStateAction<Array<FormKelompokLT3QPayload>>>;
  anggotaKelompokLT3Q?: Array<DetailAnggotaKelompokLT3Q>;
  setAnggotaKelompokLT3Q: Dispatch<
    SetStateAction<Array<DetailAnggotaKelompokLT3Q>>
  >;
  handleGetAnggotaKelompokLT3Q: (id: string) => void;
  isLoadingGetAnggotaKelompokLT3Q: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostAnggotaKelompokLT3Q: false,
  resetForm: false,
  setResetForm: () => {},
  isLoadingGetDetailAnggotaKelompokLT3Q: false,
  isLoadingDeleteAnggotaKelompokLT3Q: false,
  setDetailAnggotaKelompokLT3Q: () => {},
  resetDetailAnggotaKelompokLT3Q: () => {},
  isLoadingGetAllKelompokLT3Q: false,
  setAllKelompokLT3Q: () => {},
  allKelompokLT3Q: [],
  setAnggotaKelompokLT3Q: () => {},
  handleGetAnggotaKelompokLT3Q: () => {},
  isLoadingGetAnggotaKelompokLT3Q: false,
};
