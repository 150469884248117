import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";

export type FormPelajaranDiampuPayload = {
  id: number | string | null;
  nama_mapel: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  formikPelajaranDiampu?: FormikProps<FormPelajaranDiampuPayload>;
  formikPelajaranDiampuValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostPelajaranDiampu: (data: FormData) => void;
  isLoadingPostPelajaranDiampu: boolean;
  handleGetDetailPelajaranDiampu: (id: string) => void;
  isLoadingGetDetailPelajaranDiampu: boolean;
  detailPelajaranDiampu: FormPelajaranDiampuPayload;
  setDetailPelajaranDiampu: Dispatch<
    SetStateAction<FormPelajaranDiampuPayload>
  >;
  handleDeletePelajaranDiampu: (id: string) => void;
  isLoadingDeletePelajaranDiampu: boolean;
  isShowModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isShowModal: false,
  setShowModal: () => {},
  resetForm: false,
  setResetForm: () => {},
  isLoadingFetchData: false,
  submitting: false,
  setSubmitting: () => {},
  handlePostPelajaranDiampu: () => {},
  isLoadingPostPelajaranDiampu: false,
  handleGetDetailPelajaranDiampu: () => {},
  isLoadingGetDetailPelajaranDiampu: false,
  detailPelajaranDiampu: {
    id: null,
    nama_mapel: "",
  },
  setDetailPelajaranDiampu: () => {},
  handleDeletePelajaranDiampu: () => {},
  isLoadingDeletePelajaranDiampu: false,
};
