import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormTugasTambahanPayload = {
  id: number | string | null;
  id_tugas_tambahan: string | null;
  nik_gtk: string | null;
  tugas_tambahan: string | null;
  nomor_surat: string | null;
  tanggal_mulai: Date | Dayjs | null;
  tanggal_selesai: Date | Dayjs | null;
  doc_tugas_tambahan: File | string | null;
};
export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalTugasTambahan: boolean;
  setShowModalTugasTambahan: Dispatch<SetStateAction<boolean>>;
  formikTugasTambahan?: FormikProps<FormTugasTambahanPayload>;
  formikTugasTambahanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostTugasTambahan: (data: FormData) => void;
  isLoadingPostTugasTambahan: boolean;
  handleGetDetailTugasTambahan: (id: string) => void;
  isLoadingGetDetailTugasTambahan: boolean;
  detailTugasTambahan: FormTugasTambahanPayload;
  setDetailTugasTambahan: Dispatch<SetStateAction<FormTugasTambahanPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocTugasTambahan: (url: string, fileName: string) => void;
  isLoadingDownloadDocTugasTambahan: boolean;
  handleDeleteTugasTambahan: (id: string) => void;
  isLoadingDeleteTugasTambahan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalTugasTambahan: false,
  setShowModalTugasTambahan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostTugasTambahan: () => {},
  isLoadingPostTugasTambahan: false,
  handleGetDetailTugasTambahan: () => {},
  isLoadingGetDetailTugasTambahan: false,
  detailTugasTambahan: {
    id: null,
    id_tugas_tambahan: null,
    nik_gtk: null,
    nomor_surat: "",
    tanggal_mulai: null,
    tanggal_selesai: null,
    tugas_tambahan: "",
    doc_tugas_tambahan: null,
  },
  setDetailTugasTambahan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocTugasTambahan: () => {},
  isLoadingDownloadDocTugasTambahan: false,
  handleDeleteTugasTambahan: () => {},
  isLoadingDeleteTugasTambahan: false,
};
