import { FC } from "react";
import React, { useEffect, memo, Fragment } from "react";
import { useLayout } from "../../../core/LayoutProvider";
import { Link } from "react-router-dom";
import Scrollbar from "smooth-scrollbar";
import VerticalNav from "../sidebar/vertical-nav";
import CompanyLogo from "../../../../hope-ui/components/company-logo";

const AsideDefault: FC = () => {
  const { setting, setSetting } = useLayout();
  const sidebarAppName = setting.app_name.value;
  const sidebarColor = setting.sidebar_color.value;
  const sidebarType = setting.sidebar_type.value;
  const sidebarMenuStyle = setting.sidebar_menu_style.value;
  const sidebarHide = setting.sidebar_show.value;

  useEffect(() => {
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.app_name.value = "SIMBI";
      return jasper;
    });

    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.app_long_name = "Sistem Informasi dan Manajemen Bina Ilmi (SIMBI)";
      return jasper;
    });
  }, []);

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    const myScrollBar = document.querySelector("#my-scrollbar") as HTMLElement;
    if (myScrollBar) Scrollbar.init(myScrollBar);

    window.addEventListener("resize", () => {
      const tabs = document.querySelectorAll(".nav");
      const sidebarResponsive = document.querySelector(
        '[data-sidebar="responsive"]'
      );
      if (window.innerWidth < 1025) {
        Array.from(tabs, (elem) => {
          if (
            !elem.classList.contains("flex-column") &&
            elem.classList.contains("nav-tabs") &&
            elem.classList.contains("nav-pills")
          ) {
            elem.classList.add("flex-column", "on-resize");
          }
          return elem.classList.add("flex-column", "on-resize");
        });
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains("sidebar-mini")) {
            sidebarResponsive.classList.add("sidebar-mini", "on-resize");
          }
        }
      } else {
        Array.from(tabs, (elem) => {
          if (elem.classList.contains("on-resize")) {
            elem.classList.remove("flex-column", "on-resize");
          }
          return elem.classList.remove("flex-column", "on-resize");
        });
        if (sidebarResponsive !== null) {
          if (
            sidebarResponsive.classList.contains("sidebar-mini") &&
            sidebarResponsive.classList.contains("on-resize")
          ) {
            sidebarResponsive.classList.remove("sidebar-mini", "on-resize");
          }
        }
      }
    });
  });

  return (
    <Fragment>
      <aside
        className={` ${sidebarColor} ${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} ${
          sidebarHide.join(" ") ? "sidebar-none" : "sidebar"
        }   sidebar-base  `}
        data-sidebar="responsive"
      >
        <div className="sidebar-header d-flex align-items-center justify-content-start">
          <Link to="/dashboard" className="navbar-brand">
            <CompanyLogo />
            <h4 className="logo-title">{sidebarAppName}</h4>
          </Link>

          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={minisidebar}
          >
            <i className="icon">
              <svg
                width="20"
                className="icon-20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.25 12.2744L19.25 12.2744"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M10.2998 18.2988L4.2498 12.2748L10.2998 6.24976"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </i>
          </div>
        </div>

        <div
          className="pt-0"
          data-scroll="1"
          style={{
            overflow: "hidden",
          }}
        >
          {/* sidebar-list class to be added after replace css */}
          <div
            className="sidebar-list navbar-collapse pb-3"
            id="sidebar"
            style={{
              overflow: "hidden",
            }}
          >
            <VerticalNav />
          </div>
        </div>
      </aside>
    </Fragment>
  );
};
export { AsideDefault };
