import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormPenilaianPayload = {
  id: number | string | null;
  id_penilaian: string | null;
  nik_gtk: string | null;
  jenis_tes: string | null;
  penyelenggara: string | null;
  tahun: number | string | null;
  skor: number | string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalPenilaian: boolean;
  setShowModalPenilaian: Dispatch<SetStateAction<boolean>>;
  formikPenilaian?: FormikProps<FormPenilaianPayload>;
  formikPenilaianValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostPenilaian: (data: FormData) => void;
  isLoadingPostPenilaian: boolean;
  handleGetDetailPenilaian: (id: string) => void;
  isLoadingGetDetailPenilaian: boolean;
  detailPenilaian: FormPenilaianPayload;
  setDetailPenilaian: Dispatch<SetStateAction<FormPenilaianPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocPenilaian: (url: string, fileName: string) => void;
  isLoadingDownloadDocPenilaian: boolean;
  handleDeletePenilaian: (id: string) => void;
  isLoadingDeletePenilaian: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalPenilaian: false,
  setShowModalPenilaian: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostPenilaian: () => {},
  isLoadingPostPenilaian: false,
  handleGetDetailPenilaian: () => {},
  isLoadingGetDetailPenilaian: false,
  detailPenilaian: {
    id: null,
    id_penilaian: null,
    nik_gtk: null,
    jenis_tes: "",
    penyelenggara: "",
    tahun: "",
    skor: "",
  },
  setDetailPenilaian: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocPenilaian: () => {},
  isLoadingDownloadDocPenilaian: false,
  handleDeletePenilaian: () => {},
  isLoadingDeletePenilaian: false,
};
