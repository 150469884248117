import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../../provider/query-response/presensi-saya/presensi-gtk";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../../provider/query-request/presensi-saya/presensi-gtk";
import { Card } from "react-bootstrap";
import Toolbar from "./toolbar";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useEffect, useState } from "react";
import { Column } from "react-table";
import { Header } from "../../../hope-ui/components/table/Header";
import NamaGTKCell from "../../../hope-ui/components/table/NamaGTKCell";
import moment from "moment";
import KeteranganWarnaPresensiGTK from "../../../hope-ui/components/keterangan-warna-presensi-gtk";

const PresensiSayaGTK = () => {
  const [Columns, setColumns] = useState<Array<Column<any>>>([]);
  const [arrTanggal, setArrTanggal] = useState<Array<any>>([]);
  const { isLoadingFetchData, listData } = useQueryResponse();
  useEffect(() => {
    const column = [
      {
        Header: (props) => (
          <Header
            tableProps={props}
            title="NIK"
            className="ps-3 fs-6 text-center"
            queryRequest={useQueryRequest}
          />
        ),
        id: "nik_gtk",
        Cell: ({ ...props }) => (
          <label className="fw-normal text-dark pe-3 text-center w-100">
            {" "}
            {props.data[props.row.index].nik_gtk}
          </label>
        ),
      },
      {
        Header: (props) => (
          <Header
            tableProps={props}
            title="Nama Lengkap"
            className="ps-3 fs-6 text-center"
            queryRequest={useQueryRequest}
          />
        ),
        id: "nama_gtk",
        Cell: ({ ...props }) => (
          <NamaGTKCell
            id={props.data[props.row.index].nik_gtk}
            label={props.data[props.row.index].nama_gtk}
          />
        ),
      },
    ];
    if (listData && listData.data) {
      const array = listData?.data[0]["absensi"];
      array && setArrTanggal(array);
      array &&
        array.sort((a, b) => {
          return a.tanggal_absen > b.tanggal_absen ? 1 : -1;
        });
      array &&
        array.map((val, idx) => {
          const tanggal_absen = val.tanggal_absen;
          column.push({
            Header: (props) => (
              <Header
                tableProps={props}
                title={`${moment(tanggal_absen).format("DD MMM YYYY")}`}
                className="ps-3 fs-6 text-center"
                queryRequest={useQueryRequest}
              />
            ),
            id: `tanggal_absen${idx}`,
            Cell: ({ ...props }) =>
              props.data &&
              props.data[props.row.index] &&
              props.data[props.row.index].absensi &&
              props.data[props.row.index].absensi[idx] &&
              props.data[props.row.index].absensi[idx].scan_masuk ? (
                <label className="fw-normal text-dark pe-3 text-center w-100">
                  {props.data[props.row.index].absensi[idx].scan_masuk}
                </label>
              ) : (
                <>-</>
              ),
          });
        });
    }
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="N"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "normal",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].N > 0 && "text-white bg-success"
          }`}
        >
          {props.data[props.row.index].N}
        </label>
      ),
    });
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="TTKD"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "TTKD",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].TTKD > 0 && "text-white bg-yellow"
          }`}
        >
          {props.data[props.row.index].TTKD}
        </label>
      ),
    });
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="TKD"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "TKD",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].TKD > 0 && "text-white bg-yellow"
          }`}
        >
          {props.data[props.row.index].TKD}
        </label>
      ),
    });
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="TH"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "TH",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].TH > 0 && "text-white bg-gray"
          }`}
        >
          {props.data[props.row.index].TH}
        </label>
      ),
    });
    setColumns(column);
  }, [listData]);
  return (
    <div>
      <Toolbar jumlahHari={arrTanggal.length} />
      <Card.Body>
        <KeteranganWarnaPresensiGTK className="mt-0 pt-0 mb-2" />
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={listData?.data ?? []}
          queryRequest={useQueryRequest}
          isPagination={false}
        />
      </Card.Body>
    </div>
  );
};

export const PresensiSayaGTKWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <PresensiSayaGTK />
    </QueryResponseProvider>
  </QueryRequestProvider>
);
