import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-bpi/buat-absensi";
import {
  FormRiwayatBPIPayload,
  initialQueryResponse,
} from "../../../../models/absensi-bpi/buat-absensi/_queryResponse";
import {
  deleteAbsensiBPI,
  getAllMC,
  getDetailAbsensiBPI,
  getKehadiranBPI,
  getTotalInfaq,
  postBuatAbsensiBPI,
} from "../../../../api/absensi-bpi/_request";
import { FormAnggotaKelompokBPIPayload } from "../../../../models/absensi-bpi/anggota-kelompok-bpi/_queryResponse";
import { useQueryResponse as useQueryResponseAbsesnsiBPI } from "../../absensi-bpi";
import { useQueryResponse as useQueryResponseDaftarAbsensi } from "../../absensi-bpi/daftar-absensi";
import { useQueryResponse as useQueryResponseLaporanBulanan } from "../../absensi-bpi/laporan-bulanan";
import { useAuth } from "../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["riwayat_bina_pribadi_islami.kelompok_bpi.nama_kelompok_bpi", "nik_gtk", "gtk.nama_gtk", "anggota_kelompok_bpi.nik_tutor_bpi", "anggota_kelompok_bpi.level_bpi"]`;
  const { currentUser } = useAuth();
  state.nik_tutor_bpi = currentUser?.data?.username;

  const { isShowModalBuatAbsensi } = useQueryResponseAbsesnsiBPI();
  const refetchListDataAbsensi =
    useQueryResponseDaftarAbsensi().refetchListData;

  const refetchListDataLaporanBulanan =
    useQueryResponseLaporanBulanan().refetchListData;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kehadiran-bpi")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kehadiran-bpi"),
    [state]
  );

  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [detailAbsensiBPI, setDetailAbsensiBPI] =
    useState<FormRiwayatBPIPayload>(initialQueryResponse.detailAbsensiBPI);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const [allMC, setAllMC] = useState<Array<any>>([]);

  const [resetForm, setResetForm] = useState<boolean>(false);

  const [totalInfaq, setTotalInfaq] = useState<number>(
    initialQueryResponse.totalInfaq
  );

  const { mutateAsync: GetAllMC, isLoading: isLoadingGetAllMC } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
    mutationFn: ({ query }: { query: string }) => getAllMC(query),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllMC = useCallback(
    async (query: string) => {
      await GetAllMC({ query })
        .then((res) => {
          setAllMC(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllMC]
  );

  const {
    mutateAsync: GetDetailAbsensiBPI,
    isLoading: isLoadingGetDetailAbsensiBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_ABSENSI_BPI}`,
    mutationFn: ({ id }: { id: string }) => getDetailAbsensiBPI(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_ABSENSI_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailAbsensiBPI = useCallback(
    async (id: string) => {
      await GetDetailAbsensiBPI({ id })
        .then((res) => {
          if (res.success) {
            setDetailAbsensiBPI(res.data);
          } else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              html: res.message,
              allowOutsideClick: false,
            });
            return;
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAbsensiBPI]
  );

  const { mutateAsync: GetTotalInfaq, isLoading: isLoadingGetTotalInfaq } =
    useMutation({
      mutationKey: `${QUERIES.GET_TOTAL_INFAQ}`,
      mutationFn: ({ id }: { id: string }) => getTotalInfaq(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_TOTAL_INFAQ}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetTotalInfaq = useCallback(
    async (id: string) => {
      await GetTotalInfaq({ id })
        .then((res) => {
          const total_infaq = res.data;
          setTotalInfaq(total_infaq);
          formikBuatAbsensi?.setFieldValue("total_infaq", total_infaq);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetTotalInfaq]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_KEHADIRAN_BPI}-${query}`,
    () => getKehadiranBPI(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalBuatAbsensi,
    }
  );
  useEffect(() => {
    if (listData) {
      setCurrentOffset(listData.offset);
    }
  }, [listData]);

  const formikBuatAbsensiValidationSchema = Yup.object().shape({
    riwayat_tanggal: Yup.string().required("Tanggal Tidak Boleh Kosong"),
    id_kelompok_bpi: Yup.string().required("Kelompok BPI Tidak Boleh Kosong"),
    mc: Yup.string().required("MC Tidak Boleh Kosong"),
    batas_tilawah_halaman: Yup.string().required(
      "Batas Tilawah Halaman Tidak Boleh Kosong"
    ),
    nik_gtk_tutor: Yup.string().required("NIK GTK Tutor Tidak Boleh Kosong"),
    tempat: Yup.string().required("Tempat Tidak Boleh Kosong"),
    infaq: Yup.string().required("Infaq Tidak Boleh Kosong"),
    total_infaq: Yup.string().required("Total Infaq Tidak Boleh Kosong"),
    materi: Yup.string().required("Materi Tidak Boleh Kosong"),
    kehadiran_bpi: Yup.array().of(
      Yup.object().shape({
        nik_gtk: Yup.string().required("GTK Tidak Boleh Kosong"),
        status_absen: Yup.string().required("Kehadiran BPI Tidak Boleh Kosong"),
      })
    ),
  });

  const formikBuatAbsensi = useFormik<FormRiwayatBPIPayload>({
    initialValues: {
      id: null,
      id_kelompok_bpi: null,
      mc: null,
      infaq: null,
      materi: null,
      riwayat_tanggal: null,
      batas_tilawah_halaman: null,
      nik_gtk_tutor: null,
      tempat: null,
      total_infaq: 0,
      kehadiran_bpi: [],
    },
    enableReinitialize: false,
    validationSchema: formikBuatAbsensiValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        if (key === "kehadiran_bpi") {
          console.log(values[key]);
          payload.append(key, JSON.stringify(values[key]));
        } else payload.append(key, values[key]);
      });
      if (handlePostBuatAbsensiBPI) handlePostBuatAbsensiBPI(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikBuatAbsensi.setValues(initialQueryResponse.detailAbsensiBPI);
      setDetailAbsensiBPI(initialQueryResponse.detailAbsensiBPI);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    mutateAsync: PostBuatAbsensiBPI,
    isLoading: isLoadingPostBuatAbsensiBPI,
  } = useMutation({
    mutationKey: `${QUERIES.POST_BUAT_ABSENSI_BPI}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postBuatAbsensiBPI(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_BUAT_ABSENSI_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostBuatAbsensiBPI = useCallback(
    async (payload: FormData) => {
      await PostBuatAbsensiBPI({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            html: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              setResetForm(true);
              if (refetchListDataAbsensi) refetchListDataAbsensi();
              if (refetchListDataLaporanBulanan)
                refetchListDataLaporanBulanan();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostBuatAbsensiBPI]
  );
  const value = useMemo(
    () => ({
      handleGetAllMC,
      isLoadingGetAllMC,
      allMC,
      setAllMC,
      listData,
      isLoadingFetchData,
      formikBuatAbsensi,
      formikBuatAbsensiValidationSchema,
      currentOffset,
      setCurrentOffset,
      submitting,
      setSubmitting,
      handlePostBuatAbsensiBPI,
      isLoadingPostBuatAbsensiBPI,
      resetForm,
      setResetForm,
      handleGetDetailAbsensiBPI,
      isLoadingGetDetailAbsensiBPI,
      detailAbsensiBPI,
      setDetailAbsensiBPI,
      handleGetTotalInfaq,
      isLoadingGetTotalInfaq,
      totalInfaq,
      setTotalInfaq,
    }),
    [
      handleGetAllMC,
      isLoadingGetAllMC,
      allMC,
      setAllMC,
      listData,
      isLoadingFetchData,
      formikBuatAbsensi,
      formikBuatAbsensiValidationSchema,
      currentOffset,
      setCurrentOffset,
      submitting,
      setSubmitting,
      handlePostBuatAbsensiBPI,
      isLoadingPostBuatAbsensiBPI,
      resetForm,
      setResetForm,
      handleGetDetailAbsensiBPI,
      isLoadingGetDetailAbsensiBPI,
      detailAbsensiBPI,
      setDetailAbsensiBPI,
      handleGetTotalInfaq,
      isLoadingGetTotalInfaq,
      totalInfaq,
      setTotalInfaq,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
