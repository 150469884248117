import { Fragment, useEffect } from "react";
import { useLayout } from "../../layout/core/LayoutProvider";
import { TotalWrapper } from "../../modules/dashboard/total";
import { ChartKehadiranGTKWrapper } from "../../modules/dashboard/chart-kehadiran-gtk";
import { ChartJenjangBPIWrapper } from "../../modules/dashboard/chart-jenjang-bpi";
import { Col, Row } from "react-bootstrap";
import { ChartKehadiranTTQWrapper } from "../../modules/dashboard/chart-kehadiran-ttq";
import { QueryRequestProvider } from "../../provider/query-request/dashboard";
import { QueryRequestProvider as QueryRequestProviderDetailPerubahanStatusKepegawaian } from "../../provider/query-request/dashboard/detail-perubahan-status-kepegawaian";
import { QueryRequestProvider as QueryRequestProviderDetailPresensiGTK } from "../../provider/query-request/dashboard/detail-presensi-gtk";
import { QueryResponseProvider } from "../../provider/query-response/dashboard";
import { PerubahanStatusKepegawaianWrapper } from "../../modules/dashboard/perubahan-status-kepegawaian";
import { PresensiGTKWrapper } from "../../modules/dashboard/presensi-gtk";

const Dashboard = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Dashboard";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Selamat Datang";
      return jasper;
    });
  }, []);

  return (
    <QueryRequestProviderDetailPerubahanStatusKepegawaian>
      <QueryRequestProviderDetailPresensiGTK>
        <QueryRequestProvider>
          <QueryResponseProvider>
            <Fragment>
              <TotalWrapper />
              <Col md="12" lg="12">
                <Row className="flex-wrap">
                  <PerubahanStatusKepegawaianWrapper />
                  {/*<ChartKehadiranGTKWrapper />*/}
                  <PresensiGTKWrapper />
                  {/* <ChartJenjangBPIWrapper /> */}
                  {/* <ChartKehadiranTTQWrapper /> */}
                </Row>
              </Col>
            </Fragment>
          </QueryResponseProvider>
        </QueryRequestProvider>
      </QueryRequestProviderDetailPresensiGTK>
    </QueryRequestProviderDetailPerubahanStatusKepegawaian>
  );
};

export default Dashboard;
