import { ChangeEventHandler, FC } from "react";

type Props = {
  limit?: number;
  range?: Array<number>;
  onChange: ChangeEventHandler<HTMLSelectElement>;
};
const ShowLimitDT: FC<Props> = ({
  limit = 10,
  range = [10, 25, 50, 75, 100],
  onChange,
}) => {
  return (
    <div
      className="mb-3 d-flex flex-row gap-2 justify-content-between align-items-center"
      style={{ width: "fit-content" }}
    >
      <div style={{ fontSize: 14 }}>Show</div>
      <select
        className="form-select"
        style={{
          maxWidth: 100,
          height: 35,
          paddingTop: 5,
        }}
        onChange={onChange}
        value={limit}
      >
        {range.map((item, idx) => {
          return (
            <option value={item} key={idx}>
              {item}
            </option>
          );
        })}
      </select>
      <div style={{ fontSize: 14 }}>entries</div>
    </div>
  );
};

export { ShowLimitDT };
