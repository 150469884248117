import { Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/notifikasi";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import moment from "moment";

const ModalMemoNotif = () => {
  const {
    isShowDetailMemo,
    setShowDetailMemo,
    isLoadingGetDetailMemo,
    detailMemo,
  } = useQueryResponse();
  return (
    <Modal
      backdrop="static"
      show={isShowDetailMemo}
      onBackdropClick={() => {
        setShowDetailMemo(false);
      }}
      size="lg"
      onHide={() => setShowDetailMemo(false)}
      style={{
        zIndex: 5000,
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Detail Pesan Memo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div
          style={{
            display: isShowDetailMemo ? "block" : "none",
          }}
          className="px-2 py-0 "
        >
          {isLoadingGetDetailMemo && <FetchLoadingCompanyLogo />}
          {!isLoadingGetDetailMemo && detailMemo && (
            <div className="">
              <div className="d-flex flex-row justify-content-end align-items-center gap-2 mb-3">
                <i className="far fa-calendar"></i>
                <div>{moment(detailMemo.createdAt).format("DD MMM YYYY")}</div>

                <i className="far fa-clock"></i>
                <div>{moment(detailMemo.createdAt).format("HH:mm")}</div>
              </div>

              <div className="d-flex flex-row justify-content-start align-items-start mb-2">
                <label
                  className="text-dark"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    minWidth: "100px",
                  }}
                >
                  Subject
                </label>
                <div
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {detailMemo.judul_pesan}
                </div>
              </div>

              <div className="d-flex flex-row justify-content-start align-items-start">
                <label
                  className="text-dark"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    minWidth: "100px",
                  }}
                >
                  Isi Pesan
                </label>
                {/* <div
              dangerouslySetInnerHTML={{
                __html: isi_pesan.replace(/(?:\\[rn])+/g, "<br/>"),
              }}
            ></div> */}
                <label style={{ whiteSpace: "pre-line" }}>
                  {detailMemo.isi_pesan}
                </label>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMemoNotif;
