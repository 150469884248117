import { Fragment } from "react";
import {
  useQueryResponse,
  QueryResponseProvider,
} from "../../../provider/query-response/absensi-lt3q";

import {
  useQueryRequest,
  QueryRequestProvider,
} from "../../../provider/query-request/absensi-lt3q";

import {
  useQueryResponse as useQueryResponseDaftarAbsensi,
  QueryResponseProvider as QueryResponseProviderDaftarAbsensi,
} from "../../../provider/query-response/absensi-lt3q/daftar-absensi";

import {
  useQueryRequest as useQueryRequestDaftarAbsensi,
  QueryRequestProvider as QueryRequestProviderDaftarAbsensi,
} from "../../../provider/query-request/absensi-lt3q/daftar-absensi";

import Toolbar from "./toolbar";
import DaftarAbsensi from "./daftar-absensi";
import LaporanBulanan from "./laporan-bulanan";
import { Card } from "react-bootstrap";
import ModalKelompokLT3Q from "../modal/kelompok-lt3q";

import { QueryResponseProvider as QueryResponseProviderDataGTK } from "../../../provider/query-response/data-gtk";
import { QueryRequestProvider as QueryRequestProviderDataGTK } from "../../../provider/query-request/data-gtk";

import { QueryResponseProvider as QueryResponseProviderKelolaKelompok } from "../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { QueryRequestProvider as QueryRequestProviderKelolaKelompok } from "../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-kelompok";

import { QueryResponseProvider as QueryResponseProviderKelolaAnggotaKelompok } from "../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { QueryRequestProvider as QueryRequestProviderKelolaAnggotaKelompok } from "../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";

import { QueryResponseProvider as QueryResponseProviderBuatAbsensi } from "../../../provider/query-response/absensi-lt3q/buat-absensi";
import { QueryRequestProvider as QueryRequestProviderBuatAbsensi } from "../../../provider/query-request/absensi-lt3q/buat-absensi";
import ModalBuatAbsensi from "../modal/buat-absensi";

import { QueryResponseProvider as QueryResponseProviderLaporanBulanan } from "../../../provider/query-response/absensi-lt3q/laporan-bulanan";
import { QueryRequestProvider as QueryRequestProviderLaporanBulanan } from "../../../provider/query-request/absensi-lt3q/laporan-bulanan";

import { QueryResponseProvider as QueryResponseProviderPindahTutor } from "../../../provider/query-response/absensi-lt3q/kelompok-lt3q/pindah-tutor";
import { QueryRequestProvider as QueryRequestProviderPindahTutor } from "../../../provider/query-request/absensi-lt3q/kelompok-lt3q/pindah-tutor";

import NilaiTahsin from "./nilai-tahsin";

import { QueryResponseProvider as QueryResponseProviderNilaiTahsin } from "../../../provider/query-response/absensi-lt3q/nilai-tahsin";
import { QueryRequestProvider as QueryRequestProviderNilaiTahsin } from "../../../provider/query-request/absensi-lt3q/nilai-tahsin";

const AbsensiLT3QList = () => {
  const { activeTab, setActiveTab } = useQueryResponse();
  return (
    <Fragment>
      <ModalKelompokLT3Q />
      <ModalBuatAbsensi />
      <Toolbar />
      <Card.Body className="pt-3">
        <button
          type="button"
          className={` ${
            activeTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Daftar Presensi LT3Q
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 2
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Laporan Presensi LT3Q Per Bulan
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 3
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(3);
          }}
        >
          Nilai Tahsin LT3Q
        </button>

        {activeTab === 1 && <DaftarAbsensi />}
        {activeTab === 2 && <LaporanBulanan />}
        {activeTab === 3 && <NilaiTahsin />}
      </Card.Body>
    </Fragment>
  );
};

export const AbsensiLT3QListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryRequestProviderDaftarAbsensi>
        <QueryResponseProviderDaftarAbsensi>
          <QueryRequestProviderLaporanBulanan>
            <QueryResponseProviderLaporanBulanan>
              <QueryRequestProviderDataGTK>
                <QueryResponseProviderDataGTK>
                  <QueryRequestProviderKelolaKelompok>
                    <QueryResponseProviderKelolaKelompok>
                      <QueryRequestProviderKelolaAnggotaKelompok>
                        <QueryResponseProviderKelolaAnggotaKelompok>
                          <QueryRequestProviderPindahTutor>
                            <QueryResponseProviderPindahTutor>
                              <QueryRequestProviderBuatAbsensi>
                                <QueryResponseProviderBuatAbsensi>
                                  <QueryRequestProviderNilaiTahsin>
                                    <QueryResponseProviderNilaiTahsin>
                                      <AbsensiLT3QList />
                                    </QueryResponseProviderNilaiTahsin>
                                  </QueryRequestProviderNilaiTahsin>
                                </QueryResponseProviderBuatAbsensi>
                              </QueryRequestProviderBuatAbsensi>
                            </QueryResponseProviderPindahTutor>
                          </QueryRequestProviderPindahTutor>
                        </QueryResponseProviderKelolaAnggotaKelompok>
                      </QueryRequestProviderKelolaAnggotaKelompok>
                    </QueryResponseProviderKelolaKelompok>
                  </QueryRequestProviderKelolaKelompok>
                </QueryResponseProviderDataGTK>
              </QueryRequestProviderDataGTK>
            </QueryResponseProviderLaporanBulanan>
          </QueryRequestProviderLaporanBulanan>
        </QueryResponseProviderDaftarAbsensi>
      </QueryRequestProviderDaftarAbsensi>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
