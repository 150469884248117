import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListDataSayaKepegawaian = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_KEPEGAWAIAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getListDataSayaKepegawaian };
