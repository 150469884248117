import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/denda-persensi-gtk";
import { useEffect, useState } from "react";
import { useAuth } from "../../../provider/core/auth";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { useQueryRequest } from "../../../provider/query-request/denda-persensi-gtk";
import dayjs from "dayjs";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import TextareaComponent from "../../../hope-ui/components/textarea-input";
import Select2 from "../../../hope-ui/components/select-2";


const ModalDaftarDenda = () => {
  const { state, updateState } = useQueryRequest();
  const { ShowModalDendaPersensiGTK, setShowModalDendaPersensiGTK  } = useQueryResponse();




  const listParameter: Array<any> = [
    {
      label: "Menit",
      value: "Menit",
    },
    {
      label: "Jam",
      value: "Jam",
    },
    {
      label: "Hari",
      value: "Hari",
    },
  ];
  
  const [JenisDenda, setJenisDenda] = useState('');
  const [Minimal, setMinimal] = useState('');
  const [Maksimal, setMaksimal] = useState('');
  const [JumlahNominal, setJumlahNominal] = useState('');
  const [Keterangan, setKeterangan] = useState('');
  const [Parameter, setParameter] = useState(listParameter);


  return (
  <>


    <button
        type="button"
        className="btn btn-sm btn-info float-start"
        onClick={() => {
        setShowModalDendaPersensiGTK(true)
        }}>
        <i className="fa fa-users me-2"></i> Tambah Denda
    </button>


    <Modal
      backdrop="static"
      show={ShowModalDendaPersensiGTK}
      onBackdropClick={() => {
        setShowModalDendaPersensiGTK(false);
        //setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setShowModalDendaPersensiGTK(false);
        //setResetForm(true);
      }}
    >

      <Modal.Header closeButton>
        <Modal.Title>
          Tambah Daftar Denda
        </Modal.Title>        
      </Modal.Header>

      <Modal.Body>


      <Card.Body className="pt-3">
        
        <Col lg="12">
          <TextInputComponent
              label="Jenis Denda"
              isRequired
              placeholder="Jenis Denda"
              /*onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setJenisDenda(value);
              }}
              value={JenisDenda}*/
          />
        </Col>

        <Col lg="12">
          <TextInputComponent
              label="Maksimal"
              isRequired
              placeholder="Maksimal"
              /*onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setMaksimal(value);
              }}
              value={Maksimal}*/
          />
          <p>Note : 10 </p>
        </Col>

        <Col lg="12">
          <TextInputComponent
              label="Minimal"
              isRequired
              placeholder="Minimal"
              /*onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setMinimal(value);
              }}
              value={Minimal}*/
          />
          <p>Note : 10 </p>
        </Col>


        <Col lg="12">
          <NumberInputComponent
              label="Jumlah Nominal Denda"
              required
              placeholder="Jumlah Nominal Denda"
              /*onChange={(e) => {
                const value: any = e ? e ["value"] : null;
                setJumlahNominal(value);
              }}
              value={JumlahNominal}*/
      />
          <p>Note : 12000</p>
        </Col>

        <Col lg="12">
          <TextareaComponent
            label="Keterangan Denda"
            required
            placeholder="Keterangan Denda"
            /*onChange={(e) => {
              const value: any = e ? e ["value"] : null;
              setKeterangan(value);
            }}
            value={Keterangan}*/
          />
        </Col>

        <Col lg="12">
          <Select2
            label="Pilih Parameter"
            isClearable
            options={listParameter}
            /*onChange={(e) => {
              const value: any = e ? e ["value"] : null;
              setParameter(value);
            }}
            value={Parameter}*/
          />
        </Col>

        <Col lg="12">
        <button type="submit" 
          className="btn btn-sm btn-primary"
          style={{float:"right", marginTop:"10px"}}
          >
          <i className="fas fa-clipboard-user me-2"></i>
          Simpan
        </button>
        </Col>

    
    </Card.Body>


      </Modal.Body>
    </Modal>





  </>

  );
};

export default ModalDaftarDenda;
