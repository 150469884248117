import { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getFilterData = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_FILTER_DATA_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getFilterData };
