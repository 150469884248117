import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../provider/query-request/hak-akses/kelola-role-menu";
import AksiCell from "./AksiCell";
import { useQueryResponsePagination } from "../../../../../provider/query-response/hak-akses/kelola-role-menu";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aplikasi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "app_name",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].app_name}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Menu"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "label",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].label}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Route"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "route_name",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].route_name}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Hak Akses"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => <AksiCell data={props.data[props.row.index]} />,
  },
];
