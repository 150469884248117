import { Card } from "react-bootstrap";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../../provider/query-request/pelajaran-yang-diampu";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/pelajaran-yang-diampu";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns";
import Toolbar from "./toolbar";
import ModalPelajaranDiampu from "../entry";
import FetchLoading from "../../../hope-ui/components/fetch-loading";

const ListPelajaranYangDiampu = () => {
  const { isLoadingFetchData, listData, isLoadingDeletePelajaranDiampu } =
    useQueryResponse();
  return (
    <>
      {isLoadingDeletePelajaranDiampu && <FetchLoading />}
      <Toolbar />
      <ModalPelajaranDiampu />
      <Card.Body className="pt-0">
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={listData?.data}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </>
  );
};

export const ListPelajaranYangDiampuWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListPelajaranYangDiampu />
    </QueryResponseProvider>
  </QueryRequestProvider>
);
