import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  FormKelompokLT3QPayload,
  initialQueryResponse,
} from "../../../../../models/absensi-lt3q/kelompok-lt3q/_queryResponse";
import {
  getKelompokLT3Q,
  postKelompokLT3Q,
  getDetailKelompokLT3Q,
  deleteKelompokLT3Q,
} from "../../../../../api/absensi-lt3q/_request";

import { useQueryResponse as useQueryResponseAbsensiLT3Q } from "../../../absensi-lt3q";
import { useAuth } from "../../../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiLT3Q();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["nama_kelompok_lt3q"]`;
  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailKelompokLT3Q, setDetailKelompokLT3Q] = useState<any>();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kelompok-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kelompok-lt3q"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    mutateAsync: PostKelompokLT3Q,
    isLoading: isLoadingPostKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KELOMPOK_LT3Q}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postKelompokLT3Q(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_KELOMPOK_LT3Q}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostKelompokLT3Q = useCallback(
    async (payload: any) => {
      await PostKelompokLT3Q({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData) {
                refetchListData();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKelompokLT3Q]
  );

  const {
    mutateAsync: GetDetailKelompokLT3Q,
    isLoading: isLoadingGetDetailKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
    mutationFn: ({ id }: { id: string }) => getDetailKelompokLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKelompokLT3Q = useCallback(
    async (id: string) => {
      await GetDetailKelompokLT3Q({ id })
        .then((res) => {
          if (res.success) setDetailKelompokLT3Q(res.data);
          else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                //setDetailKelompokLT3Q(res.data);
              }
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailKelompokLT3Q]
  );

  const {
    mutateAsync: DeleteKelompokLT3Q,
    isLoading: isLoadingDeleteKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
    mutationFn: ({ id }: { id: string }) => deleteKelompokLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteKelompokLT3Q = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          await DeleteKelompokLT3Q({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              console.log(error);
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKelompokLT3Q]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_KELOMPOK_LT3Q}-${query}`,
    () => getKelompokLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalTentukanKelompok,
    }
  );

  const resetDetailKelompokLT3Q = () => {
    setDetailKelompokLT3Q({
      id: null,
      nama_kelompok_lt3q: null,
      nik_gtk: null,
    });
  };
  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostKelompokLT3Q,
      isLoadingPostKelompokLT3Q,
      resetForm,
      setResetForm,
      handleGetDetailKelompokLT3Q,
      isLoadingGetDetailKelompokLT3Q,
      detailKelompokLT3Q,
      setDetailKelompokLT3Q,
      resetDetailKelompokLT3Q,
      handleDeleteKelompokLT3Q,
      isLoadingDeleteKelompokLT3Q,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostKelompokLT3Q,
      isLoadingPostKelompokLT3Q,
      resetForm,
      setResetForm,
      handleGetDetailKelompokLT3Q,
      isLoadingGetDetailKelompokLT3Q,
      detailKelompokLT3Q,
      setDetailKelompokLT3Q,
      resetDetailKelompokLT3Q,
      handleDeleteKelompokLT3Q,
      isLoadingDeleteKelompokLT3Q,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
