import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";

export type QueryResponseContextProps<T> = {
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handleDownloadDatabaseGTK: () => void;
  isLoadingDownloadDatabaseGTK: boolean;
  handleDownloadDatabaseSSO?: () => void;
  isLoadingDownloadDatabaseSSO?: boolean;
  handlePostHistoryBackup?: (data: FormData) => void;
  isLoadingPostHistoryBackup?: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  handleDownloadDatabaseGTK: () => {},
  isLoadingDownloadDatabaseGTK: false,
  handleDownloadDatabaseSSO: () => {},
  isLoadingDownloadDatabaseSSO: false,
};
