import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  refetchListData?: () => void;
  isLoadingFetchData: boolean;
  bulan?: number | string | null;
  setBulan: Dispatch<SetStateAction<number | string | null>>;
  tahun?: number | null;
  setTahun: Dispatch<SetStateAction<number | null>>;
  idKelompokLT3Q?: Array<any> | null;
  setIdKelompokLT3Q: Dispatch<SetStateAction<Array<any>>>;
  handleDeleteAbsensiLT3Q?: (id: string) => void;
  isLoadingDeleteAbsensiLT3Q: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  setBulan: () => {},
  setTahun: () => {},
  setIdKelompokLT3Q: () => {},
  isLoadingDeleteAbsensiLT3Q: false,
};
