import LogoJundezainRow from "../../../../hope-ui/components/logo-jundezain-row";

const Footer = () => {
  return (
    <footer className="footer custom-footer">
      <div className="footer-body d-flex justify-content-between">
        <ul className="left-panel list-inline mb-0 p-0">
          <li className="list-inline-item">
            Version {process.env.REACT_APP_VERSION}
          </li>
          <li className="list-inline-item"></li>
        </ul>
        <div className="right-panel">
          <LogoJundezainRow />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
