import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormKaryaTulisPayload,
  initialQueryResponse,
} from "../../../models/karya-tulis/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/karya-tulis";
import {
  deleteKaryaTulis,
  downloadDocKaryaTulis,
  getDetailKaryaTulis,
  getListKaryaTulis,
  postKaryaTulis,
} from "../../../api/karya-tulis/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["judul", "tahun_pembuatan", "publikasi", "keterangan", "url"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalKaryaTulis, setShowModalKaryaTulis] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailKaryaTulis.nik_gtk = nik_gtk;

  const [detailKaryaTulis, setDetailKaryaTulis] =
    useState<FormKaryaTulisPayload>(initialQueryResponse.detailKaryaTulis);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikKaryaTulis.setValues(initialQueryResponse.detailKaryaTulis);
      setDetailKaryaTulis(initialQueryResponse.detailKaryaTulis);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_ANAK}-${query}`,
    () => getListKaryaTulis(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikKaryaTulisValidationSchema = Yup.object().shape({
    judul: Yup.string().required("Judul Tidak Boleh Kosong"),
    tahun_pembuatan: Yup.string().required(
      "Tahun Pembuatan Tidak Boleh Kosong"
    ),
    publikasi: Yup.string().required("Publikasi Tidak Boleh Kosong"),
    //tahun: Yup.string().required("Keterangan Tidak Boleh Kosong"),
    url: Yup.string().required("URL Tidak Boleh Kosong"),
  });

  const formikKaryaTulis = useFormik<FormKaryaTulisPayload>({
    initialValues: initialQueryResponse.detailKaryaTulis,
    enableReinitialize: false,
    validationSchema: formikKaryaTulisValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostKaryaTulis(payload);
    },
  });

  const { mutateAsync: PostKaryaTulis, isLoading: isLoadingPostKaryaTulis } =
    useMutation({
      mutationKey: `${QUERIES.POST_ANAK}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postKaryaTulis(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_ANAK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostKaryaTulis = useCallback(
    async (payload: any) => {
      await PostKaryaTulis({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalKaryaTulis(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKaryaTulis]
  );

  const {
    mutateAsync: GetDetailKaryaTulis,
    isLoading: isLoadingGetDetailKaryaTulis,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_ANAK}`,
    mutationFn: ({ id }: { id: string }) => getDetailKaryaTulis(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_ANAK}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKaryaTulis = useCallback(
    async (id: string) => {
      await GetDetailKaryaTulis({ id })
        .then((res) => {
          setDetailKaryaTulis(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailKaryaTulis]
  );

  const {
    mutateAsync: DownloadDocKaryaTulis,
    isLoading: isLoadingDownloadDocKaryaTulis,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_ANAK}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocKaryaTulis(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.DOWNLOAD_DOC_ANAK}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocKaryaTulis = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocKaryaTulis({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocKaryaTulis]
  );

  const {
    mutateAsync: DeleteKaryaTulis,
    isLoading: isLoadingDeleteKaryaTulis,
  } = useMutation({
    mutationKey: `${QUERIES.POST_ANAK}`,
    mutationFn: ({ id }: { id: string }) => deleteKaryaTulis(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_ANAK}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteKaryaTulis = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteKaryaTulis({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKaryaTulis]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalKaryaTulis,
      setShowModalKaryaTulis,
      formikKaryaTulis,
      formikKaryaTulisValidationSchema,
      submitting,
      setSubmitting,
      handlePostKaryaTulis,
      isLoadingPostKaryaTulis,
      detailKaryaTulis,
      setDetailKaryaTulis,
      handleGetDetailKaryaTulis,
      isLoadingGetDetailKaryaTulis,
      resetForm,
      setResetForm,
      handleDownloadDocKaryaTulis,
      isLoadingDownloadDocKaryaTulis,
      handleDeleteKaryaTulis,
      isLoadingDeleteKaryaTulis,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalKaryaTulis,
      setShowModalKaryaTulis,
      formikKaryaTulis,
      formikKaryaTulisValidationSchema,
      submitting,
      setSubmitting,
      handlePostKaryaTulis,
      isLoadingPostKaryaTulis,
      detailKaryaTulis,
      setDetailKaryaTulis,
      handleGetDetailKaryaTulis,
      isLoadingGetDetailKaryaTulis,
      resetForm,
      setResetForm,
      handleDownloadDocKaryaTulis,
      isLoadingDownloadDocKaryaTulis,
      handleDeleteKaryaTulis,
      isLoadingDeleteKaryaTulis,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
