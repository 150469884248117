import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useQueryResponse as useQueryResponseBuatAbsensi } from "../../../../../../provider/query-response/absensi-bpi/buat-absensi";
import { useQueryResponse as useQueryResponseDaftarAbsensi } from "../../../../../../provider/query-response/absensi-bpi/daftar-absensi";
import { FC } from "react";

type Props = {
  id: string;
};

const AksiCell: FC<Props> = ({ id }) => {
  const { handleGetDetailAbsensiBPI } = useQueryResponseBuatAbsensi();
  const { handleDeleteAbsensiBPI } = useQueryResponseDaftarAbsensi();
  return (
    <div className="px-4 py-5 ">
      <Dropdown as={ButtonGroup}>
        <Button type="button" size="sm" variant={"primary"}>
          Pilih Aksi
        </Button>
        <Dropdown.Toggle type="button" split variant={"primary"}>
          <span className="visually-hidden">Pilih Aksi</span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={() => {
              if (handleGetDetailAbsensiBPI) {
                handleGetDetailAbsensiBPI(id);
              }
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={() => {
              if (handleDeleteAbsensiBPI) handleDeleteAbsensiBPI(id);
            }}
          >
            Hapus
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AksiCell;
