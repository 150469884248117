import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/karya-buku";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";

const ModalKaryaBuku = () => {
  const {
    showModalKaryaBuku,
    setShowModalKaryaBuku,
    formikKaryaBuku,
    submitting,
    setSubmitting,
    detailKaryaBuku,
    setResetForm,
    isLoadingGetDetailKaryaBuku,
    isLoadingPostKaryaBuku,
  } = useQueryResponse();

  useEffect(() => {
    if (detailKaryaBuku.id) {
      formikKaryaBuku?.setValues(detailKaryaBuku);
    }
  }, [detailKaryaBuku]);

  return (
    <Modal
      backdrop="static"
      show={showModalKaryaBuku}
      onBackdropClick={() => {
        setShowModalKaryaBuku(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalKaryaBuku(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikKaryaBuku?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailKaryaBuku.id ? "Edit" : "Tambah"} Karya Buku
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailKaryaBuku || isLoadingPostKaryaBuku) && (
            <FetchLoading />
          )}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="ISBN"
                placeholder="Masukkan ISBN"
                isRequired
                formik={formikKaryaBuku?.getFieldProps("isbn")}
                value={formikKaryaBuku?.getFieldProps("isbn").value ?? ""}
              />
              {submitting && formikKaryaBuku?.errors.isbn && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaBuku?.errors.isbn}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextareaComponent
                label="Judul Buku"
                placeholder="Masukkan Judul Buku"
                required
                formik={formikKaryaBuku?.getFieldProps("judul_buku")}
                value={formikKaryaBuku?.getFieldProps("judul_buku").value ?? ""}
                rows={3}
              />
              {submitting && formikKaryaBuku?.errors.judul_buku && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaBuku?.errors.judul_buku}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="Penerbit"
                placeholder="Masukkan Penerbit"
                isRequired
                formik={formikKaryaBuku?.getFieldProps("penerbit")}
                value={formikKaryaBuku?.getFieldProps("penerbit").value ?? ""}
              />
              {submitting && formikKaryaBuku?.errors.penerbit && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaBuku?.errors.penerbit}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="Tahun Terbit"
                placeholder="Masukkan Tahun Terbit"
                isRequired
                formik={formikKaryaBuku?.getFieldProps("tahun")}
                value={formikKaryaBuku?.getFieldProps("tahun").value ?? ""}
              />
              {submitting && formikKaryaBuku?.errors.tahun && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaBuku?.errors.tahun}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalKaryaBuku;
