import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormAbsensiGtkPayload } from "../absensi-gtk/daftar-absensi-gtk/_queryResponse";

type currentStatusGolongan = {
  unit_kerja: string;
  status_golongan_kepegawaian: string;
  menuju: string;
};

export type QueryResponseContextProps<T> = {
  totalSemuaGTK: number;
  setTotalSemuaGTK: Dispatch<SetStateAction<number>>;
  setTotalGTKLaki: Dispatch<SetStateAction<number>>;
  setTotalGTKPerempuan: Dispatch<SetStateAction<number>>;
  totalGTKLaki: number;
  totalGTKPerempuan: number;
  handleGetTotalGTK: (query: string, type: string) => void;
  isLoadingTotalGTK: boolean;
  totalJenjangBPI?: Response<Array<T>>;
  setTotalJenjangBPI: Dispatch<SetStateAction<Response<Array<T>>>>;
  handleGetTotalJenjangBPI: () => void;
  isLoadingTotalJenjangBPI: boolean;
  perubahanStatusKepegawaian?: Response<Array<T>>;
  setPerubahanStatusKepegawaian: Dispatch<SetStateAction<Response<Array<T>>>>;
  handleGetPerubahanStatusKepegawaian: () => void;
  isLoadingPerubahanStatusKepegawaian: boolean;
  isShowDetailPerubahanStatusKepegawaian: boolean;
  setIsShowDetailPerubahanStatusKepegawaian: Dispatch<SetStateAction<boolean>>;
  handleGetDetailPerubahanStatusKepegawaian: (
    unit_kerja: string,
    status_golongan_kepegawaian: string
  ) => void;
  isLoadingDetailPerubahanStatusKepegawaian: boolean;
  detailPerubahanStatusKepegawaian?: Response<Array<T>>;
  setDetailPerubahanStatusKepegawaian: Dispatch<
    SetStateAction<Response<Array<T>>>
  >;
  queryDetailPerubahanStatusKepegawaian?: string;
  currentStatusGolongan: currentStatusGolongan;
  setCurrentStatusGolongan: Dispatch<SetStateAction<currentStatusGolongan>>;
  totalGTKAkunAktif: number;
  setTotalGTKAkunAktif: Dispatch<SetStateAction<number>>;
  totalGTKAkunTidakAktif: number;
  setTotalGTKAkunTidakAktif: Dispatch<SetStateAction<number>>;
  totalGTKBekerja: number;
  setTotalGTKBekerja: Dispatch<SetStateAction<number>>;
  totalGTKTidakBekerja: number;
  setTotalGTKTidakBekerja: Dispatch<SetStateAction<number>>;
  handleGetPresensiGTK: (tanggal: Date | Dayjs | string) => void;
  isLoadingGetPresensiGTK: boolean;
  presensiGTK: any;
  setPresensiGTK: Dispatch<SetStateAction<any>>;
  isShowDetailPresensiGTK: boolean;
  setIsShowDetailPresensiGTK: Dispatch<SetStateAction<boolean>>;
  handleGetDetailPresensiGTK: (
    unit_kerja: string,
    status_absen: string
  ) => void;
  setDetailPresensiGTK: Dispatch<SetStateAction<Response<Array<T>>>>;
  detailPresensiGTK?: Response<Array<T>>;
  isLoadingGetDetailPresensiGTK: boolean;
  handleExportPresensiGTK: () => void;
  isLoadingExportPresensiGTK: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isShowDetailPresensiGTK: false,
  setIsShowDetailPresensiGTK: () => {},
  handleGetDetailPresensiGTK: () => {},
  isLoadingGetDetailPresensiGTK: false,
  handleGetPresensiGTK: () => {},
  setDetailPresensiGTK: () => {},
  isLoadingGetPresensiGTK: false,
  handleExportPresensiGTK: () => {},
  isLoadingExportPresensiGTK: false,
  presensiGTK: [],
  setPresensiGTK: () => {},
  totalGTKAkunAktif: 0,
  setTotalGTKAkunAktif: () => {},
  totalGTKAkunTidakAktif: 0,
  setTotalGTKAkunTidakAktif: () => {},
  totalGTKBekerja: 0,
  setTotalGTKBekerja: () => {},
  totalGTKTidakBekerja: 0,
  setTotalGTKTidakBekerja: () => {},
  totalSemuaGTK: 0,
  totalGTKLaki: 0,
  totalGTKPerempuan: 0,
  handleGetTotalGTK: () => {},
  setTotalSemuaGTK: () => {},
  setTotalGTKLaki: () => {},
  setTotalGTKPerempuan: () => {},
  isLoadingTotalGTK: false,
  handleGetTotalJenjangBPI: () => {},
  isLoadingTotalJenjangBPI: false,
  setTotalJenjangBPI: () => {},
  setPerubahanStatusKepegawaian: () => {},
  handleGetPerubahanStatusKepegawaian: () => {},
  isLoadingPerubahanStatusKepegawaian: false,
  isShowDetailPerubahanStatusKepegawaian: false,
  setIsShowDetailPerubahanStatusKepegawaian: () => {},
  handleGetDetailPerubahanStatusKepegawaian: () => {},
  isLoadingDetailPerubahanStatusKepegawaian: false,
  setDetailPerubahanStatusKepegawaian: () => {},
  currentStatusGolongan: {
    unit_kerja: "",
    status_golongan_kepegawaian: "",
    menuju: "",
  },
  setCurrentStatusGolongan: () => {},
};
