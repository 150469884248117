import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { useEffect, useState } from "react";
import { useAuth } from "../../../provider/core/auth";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { useQueryRequest } from "../../../provider/query-request/absensi-bpi";
import dayjs from "dayjs";
import ModalImportAbsensi from "./btn-import-persensi";

const ModalImportPresensi = () => {
  const { state, updateState } = useQueryRequest();
  const { isShowModalBuatAbsensiGTK, setShowModalImportAbsensiGTK } = useQueryResponse();

  return (
  <>
  <ModalImportAbsensi/>
  <button
    type="button"
    className="btn btn-sm btn-info float-start"
    onClick={() => {
      setShowModalImportAbsensiGTK(true)
      
    }}
    >
      <i className="fa fa-users me-2"></i> Upload Presensi
  </button>

  </>
  );
};

export default ModalImportPresensi;
