import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";
import moment from "moment";

const getAbsensiBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ABSENSI_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getLaporanAbsensiBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LAPORAN_ABSENSI_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllKelompokBPI = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_KELOMPOK_BPI_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKelompokBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KELOMPOK_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailKelompokBPI = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_KELOMPOK_BPI_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteKelompokBPI = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_KELOMPOK_BPI_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postKelompokBPI = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_KELOMPOK_BPI_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAnggotaKelompokBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ANGGOTA_KELOMPOK_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAnggotaKelompokBPI = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ANGGOTA_KELOMPOK_BPI_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteAnggotaKelompokBPI = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ANGGOTA_KELOMPOK_BPI_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postAnggotaKelompokBPI = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_ANGGOTA_KELOMPOK_BPI_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllMC = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_MC_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKehadiranBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KEHADIRAN_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postBuatAbsensiBPI = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_BUAT_ABSENSI_BPI_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAbsensiBPI = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ABSENSI_BPI_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getTotalInfaq = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TOTAL_INFAQ_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteAbsensiBPI = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ABSENSI_BPI_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getRekapAbsensiBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_REKAP_ABSENSI_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const exportRekapPresensiBPI = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_REKAP_ABSENSI_BPI_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `Rekap Presensi BPI ${moment().format("DD MM YYYY")}.xlsx`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const getPindahTutorBPI = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_PINDAH_TUTOR_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postPindahTutorBPI = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_PINDAH_TUTOR_BPI_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getTutorBPI = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TUTOR_BPI_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getListAnggotaKelompokBPI = async (
  id_kelompok_bpi: string
): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ANGGOTA_KELOMPOK_BPI_URL}/${id_kelompok_bpi}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getRekapAbsensiBPI,
  exportRekapPresensiBPI,
  getAbsensiBPI,
  getLaporanAbsensiBPI,
  getKelompokBPI,
  postKelompokBPI,
  getDetailKelompokBPI,
  getAnggotaKelompokBPI,
  postAnggotaKelompokBPI,
  getDetailAnggotaKelompokBPI,
  getAllKelompokBPI,
  deleteKelompokBPI,
  deleteAnggotaKelompokBPI,
  getAllMC,
  getKehadiranBPI,
  postBuatAbsensiBPI,
  getDetailAbsensiBPI,
  getTotalInfaq,
  deleteAbsensiBPI,
  getPindahTutorBPI,
  postPindahTutorBPI,
  getTutorBPI,
  getListAnggotaKelompokBPI,
};
