import { Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/absensi-lt3q";
import { useQueryResponse as useQueryResponseKelolaKelompokLT3Q } from "../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { useQueryResponse as useQueryResponseKelolaAnggotaKelompokLT3Q } from "../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { useEffect, useState } from "react";
import KelolaKelompokLT3Q from "./kelola-kelompok";
import { useQueryRequest } from "../../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { useQueryRequest as useQueryRequestAnggota } from "../../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import KelolaAnggotaKelompokLT3Q from "./kelola-anggota-kelompok";
import PindahTutor from "./pindah-tutor";

const ModalKelompokLT3Q = () => {
  const { isShowModalTentukanKelompok, setIsShowModalTentukanKelompok } =
    useQueryResponse();
  const { setResetForm } = useQueryResponseKelolaKelompokLT3Q();
  const setResetFormAnggota =
    useQueryResponseKelolaAnggotaKelompokLT3Q().setResetForm;
  const [activeTab, setActiveTab] = useState(1);
  const { updateState } = useQueryRequest();
  const updateStateAnggota = useQueryRequestAnggota().updateState;

  return (
    <Modal
      backdrop="static"
      show={isShowModalTentukanKelompok}
      onBackdropClick={() => {
        setIsShowModalTentukanKelompok(false);
      }}
      size="xl"
      onHide={() => {
        setIsShowModalTentukanKelompok(false);
        updateState({
          size: 10,
          search: "",
          page: 1,
          sort_by: null,
          sort_type: "asc",
        });

        updateStateAnggota({
          size: 10,
          search: "",
          page: 1,
          sort_by: null,
          sort_type: "asc",
        });
        setResetForm(true);
        setResetFormAnggota(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Tentukan Kelompok LT3Q </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <button
          type="button"
          className={` ${
            activeTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Kelola Kelompok
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 2
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Kelola Anggota Kelompok
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 3
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(3);
          }}
        >
          Pindah Tutor
        </button>

        <KelolaKelompokLT3Q activeTab={activeTab} />
        <KelolaAnggotaKelompokLT3Q activeTab={activeTab} />
        <PindahTutor activeTab={activeTab} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalKelompokLT3Q;
