import { Column } from "react-table";
import { Header } from "../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../provider/query-request/data-gtk";
import NamaGTKCell from "../../../../hope-ui/components/table/NamaGTKCell";
import AksiCell from "./AksiCell";
import { useQueryResponsePagination } from "../../../../provider/query-response/data-gtk";
import { useParams } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Unit Kerja"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].unit_kerja}
      </label>
    ),
  },
  // {
  //   Header: (props) => {
  //     const { status } = useParams();
  //     return (
  //       <>
  //         {status === "aktif" && (
  //           <Header
  //             tableProps={props}
  //             title="Status Sertifikasi B. Inggris"
  //             className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
  //           />
  //         )}
  //       </>
  //     );
  //   },
  //   id: "action_status_sertifikasi_bahasa_inggris",
  //   Cell: ({ ...props }) => {
  //     const { status } = useParams();
  //     return (
  //       <>
  //         {status === "aktif" && (
  //           <StatusSertifikasiBahasaInggrisCell
  //             id={props.data[props.row.index].nik_gtk}
  //             data={props.data}
  //             status={
  //               props.data[props.row.index].status_sertifikasi_bahasa_inggris
  //             }
  //           />
  //         )}
  //       </>

  //     );
  //   },
  // },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Progres Pengisian"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "progress_bar",
    Cell: ({ ...props }) => (
      <ProgressBar
        variant="success"
        now={props.data[props.row.index].progress_bar}
        style={{
          width: "100%",
        }}
        label={`${props.data[props.row.index].progress_bar}%`}
      ></ProgressBar>
    ),
  },

  {
    Header: (props) => {
      return (
        <Header
          tableProps={props}
          title="Aksi"
          className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
        />
      );
    },
    id: "action_kirim_memo",
    Cell: ({ ...props }) => {
      return (
        <>
          <AksiCell data={props.data[props.row.index]} />
        </>
      );
    },
  },
];
