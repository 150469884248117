import { FC, useEffect } from "react";
import { Button, ButtonGroup, Dropdown, Nav, Tab } from "react-bootstrap";
import { useQueryResponse } from "../../../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { SweetAlert } from "../../../../../../../hope-ui/components/sweet-alert";

type Props = {
  id: string;
  data?: any;
};

export const AksiCell: FC<Props> = ({ id, data }) => {
  const { handleGetDetailKelompokLT3Q, handleDeleteKelompokLT3Q } =
    useQueryResponse();
  return (
    <Dropdown as={ButtonGroup}>
      <Button type="button" size="sm" variant="info">
        Pilih Aksi
      </Button>
      <Dropdown.Toggle type="button" split variant="info">
        <span className="visually-hidden">Edit</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href="#"
          onClick={(e) => {
            if (handleGetDetailKelompokLT3Q) handleGetDetailKelompokLT3Q(id);
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          onClick={(e) => {
            if (handleDeleteKelompokLT3Q) handleDeleteKelompokLT3Q(id);
          }}
        >
          Hapus
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
