import { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getDetailGTK = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_GTK_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postDetailGTK = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_DETAIL_GTK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getDetailGTK, postDetailGTK };
