import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormUserHakAksesPayload,
  initialQueryResponse,
} from "../../../models/hak-akses/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import {
  getAllHakAkses,
  getUserHakAkses,
  postUserHakAkses,
} from "../../../api/hak-akses/_request";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { FormRolesPayload } from "../../../models/hak-akses/kelola-role/_queryResponse";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UserDetail } from "../../../models/users/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [showModalHakAkses, setShowModalHakAkses] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [allHakAkses, setAllHakAkses] = useState<Array<FormRolesPayload>>([]);
  const [userHakAkses, setUserHakAkses] = useState<UserDetail>({
    id: null,
    name: null,
    username: null,
    no_telepon: null,
    email: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    rolesId: null,
  });
  const queryClient = useQueryClient();

  const { mutateAsync: GetAllHakAkses, isLoading: isLoadingGetAllHakAkses } =
    useMutation({
      mutationKey: `${QUERIES.GET_ALL_HAK_AKSES}`,
      mutationFn: () => getAllHakAkses(),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_ALL_HAK_AKSES}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetAllHakAkses = useCallback(async () => {
    await GetAllHakAkses()
      .then((res) => {
        if (!res.success) {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          });

          return;
        }
        setAllHakAkses(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetAllHakAkses]);

  const { mutateAsync: GetUserHakAkses, isLoading: isLoadingGetUserHakAkses } =
    useMutation({
      mutationKey: `${QUERIES.GET_USER_HAK_AKSES}`,
      mutationFn: ({ id }: { id: string }) => getUserHakAkses(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.GET_USER_HAK_AKSES}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetUserHakAkses = useCallback(
    async (id: string) => {
      await GetUserHakAkses({ id })
        .then((res) => {
          if (!res.success) {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            });
            return;
          }
          setUserHakAkses({
            id: res.data.id,
            name: res.data.name,
            username: res.data.username,
            no_telepon: res.data.no_telepon,
            email: res.data.email,
            createdAt: res.data.createdAt,
            updatedAt: res.data.updatedAt,
            deletedAt: res.data.deletedAt,
            rolesId: res.data.rolesId,
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetUserHakAkses]
  );

  const formikUserHakAksesValidationSchema = Yup.object().shape({
    rolesId: Yup.string().required("Hak Akses Tidak Boleh Kosong"),
  });

  const formikUserHakAkses = useFormik<FormUserHakAksesPayload>({
    initialValues: {
      rolesId: null,
      username: null,
    },
    enableReinitialize: false,
    validationSchema: formikUserHakAksesValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostUserHakAkses(payload);
    },
  });

  const {
    mutateAsync: PostUserHakAkses,
    isLoading: isLoadingPostUserHakAkses,
  } = useMutation({
    mutationKey: `${QUERIES.POST_USER_HAK_AKSES}`,
    mutationFn: ({ data }: { data: FormData }) => postUserHakAkses(data),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_USER_HAK_AKSES}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostUserHakAkses = useCallback(
    async (data: FormData) => {
      await PostUserHakAkses({ data })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal) => {
            if (res.success && swal.isConfirmed) {
              handleGetUserHakAkses(res.data.username);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostUserHakAkses]
  );

  const value = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      showModalHakAkses,
      setShowModalHakAkses,
      allHakAkses,
      setAllHakAkses,
      handleGetAllHakAkses,
      isLoadingGetAllHakAkses,
      handleGetUserHakAkses,
      isLoadingGetUserHakAkses,
      formikUserHakAkses,
      formikUserHakAksesValidationSchema,
      submitting,
      setSubmitting,
      userHakAkses,
      setUserHakAkses,
      handlePostUserHakAkses,
      isLoadingPostUserHakAkses,
    }),
    [
      activeTab,
      setActiveTab,
      showModalHakAkses,
      setShowModalHakAkses,
      allHakAkses,
      setAllHakAkses,
      handleGetAllHakAkses,
      isLoadingGetAllHakAkses,
      handleGetUserHakAkses,
      isLoadingGetUserHakAkses,
      formikUserHakAkses,
      formikUserHakAksesValidationSchema,
      submitting,
      setSubmitting,
      userHakAkses,
      setUserHakAkses,
      handlePostUserHakAkses,
      isLoadingPostUserHakAkses,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
