import TextField from "@mui/material/TextField";
import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC } from "react";
import { Form } from "react-bootstrap";
import { styled } from "@mui/material/styles";

type Props = {
  id?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  formik?: FieldInputProps<any>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  value?: string;
  ref?: any;
  style?: React.CSSProperties;
  params: any;
};

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      display: "block",
      width: "100%",
      padding: "0px",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#8A92A6",
      height: "100%",
      backgroundClip: "padding-box",
      border: "1px solid #eee",
      appearance: "none",
      borderRadius: "0.25rem",
      boxShadow: "0 0 0 0",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
  },
}));

const AutoCompleteInputComponent: FC<Props> = ({
  params,
  id = "",
  label = null,
  type = "text",
  placeholder = "",
  className = "",
  disabled = false,
  onChange,
  formik,
  isRequired = false,
  value = "",
  ref,
  style,
}) => {
  return (
    <div>
      {label && (
        <Form.Label className="">
          {label} {isRequired && <span className="text-danger"> *</span>}
        </Form.Label>
      )}
      <CustomTextField
        ref={ref}
        type={"text"}
        className={"form-control"}
        id={id}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="off"
        onChange={onChange}
        {...formik}
        value={formik ? formik.value : value}
        style={style}
        {...params}
      ></CustomTextField>
    </div>
  );
};

export default AutoCompleteInputComponent;
