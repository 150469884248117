import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormRiwayatGajiBerkalaPayload = {
  id: number | string | null;
  id_pangkat: string | null;
  nik_gtk: string | null;
  jenjang: string | null;
  nomor_sk: string | null;
  masa_kerja: number | string | null;
  gaji_pokok: string | null;
  tmt_sk: Date | Dayjs | null;
  tanggal_sk: Date | Dayjs | null;
};

export type QueryResponseContextProps<T> = {
  formikRiwayatGajiBerkala?: FormikProps<FormRiwayatGajiBerkalaPayload>;
  formikRiwayatGajiBerkalaValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostRiwayatGajiBerkala: (data: FormData) => void;
  isLoadingPostRiwayatGajiBerkala: boolean;
  jenisSertifikasi: any;
  setJenisSertifikasi: Dispatch<SetStateAction<any>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostRiwayatGajiBerkala: false,
  handlePostRiwayatGajiBerkala: () => {},
  jenisSertifikasi: null,
  setJenisSertifikasi: () => {},
};
