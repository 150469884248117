const QUERIES = {
  GET_CURRENT_USER: "get-current-user",
  POST_AUTHENTICATE: "post-authenticate",
  LOGOUT: "logout",

  //GTK
  GET_UNIT_KERJA: "get-gtk-unit-kerja",
  GET_KEPEGAWAIAN: "get-gtk-kepegawaian",
  //GTK

  //STATUS AKTIVASI
  //AKTIF
  GET_STATUS_AKTIVASI_AKTIF: "get-status-aktivasi-aktif",
  POST_AKTIFKAN_GTK: "post-aktifkan-gtk",
  //AKTIF

  //TIDAK AKTIF
  GET_STATUS_AKTIVASI_TIDAK_AKTIF: "get-status-aktivasi-tidak-aktif",
  POST_NON_AKTIFKAN_GTK: "post-non-aktifkan-gtk",
  //TIDAK AKTIF
  //STATUS AKTIVASI

  //DATA GTK
  GET_DATA_GTK: "get-data-gtk",
  GET_DATA_GTK_CHECKLIST_FILTER: "get-data-gtk-checklist-filter",
  POST_DATA_GTK: "post-data-gtk",
  PUT_DATA_GTK: "put-data-gtk",
  DOWNLOAD_DATA_GTK: "download-data-gtk",
  GET_ALL_TABLE_FILTER_GTK: "get-all-table-filter-gtk",
  EXPORT_EXCEL_DATA_GTK: "get-export-excel-gtk",
  EXPORT_PDF_DATA_GTK: "get-export-pdf-gtk",
  GET_ALL_SEBAB_NON_AKTIF: "get-all-sebab-non-aktif",
  POST_SEBAB_NON_AKTIF: "post-sebab-non-aktif",
  PUT_RESET_PASSWORD_GTK: "put-reset-password-gtk",
  //DATA GTK

  //DETAIL GTK
  GET_DETAIL_GTK: "get-detail-gtk",
  //DETAIL GTK

  //FILTER DATA
  GET_FILTER_DATA: "get-filter-data",
  //FILTER DATA

  //TOTAL JENJANG BPI
  GET_TOTAL_JENJANG_BPI: "get-total-jenjang-bpi",
  //TOTAL JENJANG BPI

  GET_DASHBOARD_PRESENSI_GTK: "get-dashboard-presensi-gtk",

  //KEPEGAWAIAN
  GET_PERUBAHAN_STATUS_KEPEGAWAIAN: "get-perubahan-status-kepegawaian",
  GET_DETAIL_PERUBAHAN_STATUS_KEPEGAWAIAN: "get-detail-status-kepegawaian",
  GET_LIST_KEPEGAWAIAN: "get-list-kepegawaian",
  DOWNLOAD_DOC_KEPEGAWAIAN: "download-doc-kepegawaian",
  //KEPEGAWAIAN

  //MEMO
  GET_LIST_MEMO: "get-list-memo",
  POST_MEMO: "post-memo",
  GET_DETAIL_MEMO_NOTIF: "detail-memo-notif",
  //MEMO

  //ABSENSI GTK
  GET_LIST_ABSENSI_GTK: "get-list-absensi-gtk",
  GET_DETAIL_ABSENSI_GTK: "get-detail-absensi-gtk",
  GET_CATATAN_ABSENSI_GTK: "get-catatan-absensi-gtk",
  GET_DETAIL_CATATAN_ABSENSI_GTK: "get-detail-catatan-presensi-gtk",
  PUT_CATATAN_ABSENSI_GTK: "put-catatan-absensi-gtk",
  POST_CATATAN_ABSENSI_GTK: "post-catatan-absensi-gtk",
  GET_LAPORAN_HARIAN_ABSENSI_GTK: "get-harian-absensi-gtk",
  GET_GTK_TIDAK_HADIR: "gtk-tidak-hadir",
  GET_LAPORAN_BULANAN_ABSENSI_GTK: "get-bulanan-absensi-gtk",
  POST_IMPORT_PRESENSI_GTK: "post-import-presensi-gtk",
  GET_REKAP_PRESENSI_GTK: "get-rekap-presensi-gtk",
  //ABSENSI GTK

  //ABSENSI GTK
  GET_LIST_DATA_PRIHAL_ALASAN: "get-list-data-prihal-alasan",
  GET_READ_PRIHAL_ALASAN: "get-read-prihal-alasan",
  GET_DETAIL_PRIHAL_ALASAN: "get-detail-prihal-alasan",
  POST_DETELE_PRIHAL_ALASAN: "post-detail-prihal-alasan",
  POST_STORE_PRIHAL_ALASAN: "post-store-prihal-alasan",
  //ABSENSI GTK

  //DENDA ABSENSI GTK
  POST_SAVE_DENDA_ABSENSI_GTK: "post-save-denda-absensi-gtk",
  GET_LIST_DENDA_ABSENSI_GTK: "get-list-denda-absensi-gtk",
  GET_DETAIL_DENDA_ABSENSI_GTK: "get-detail-denda-absensi-gtk",
  PUT_UPDATE_ABSENSI_GTK: "put-update-absensi-gtk",
  DELETE_ABSENSI_GTK: "delete-absensi-gtk",
  //DENDA ABSENSI GTK

  //ABSENSI BPI
  GET_ABSENSI_BPI: "get-absensi-bpi",
  GET_LAPORAN_ABSENSI_BPI: "get-laporan-absensi-bpi",
  GET_KELOMPOK_BPI: "get-kelompok-bpi",
  GET_ANGGOTA_KELOMPOK_BPI: "get-anggota-kelompok-bpi",
  GET_ALL_KELOMPOK_BPI: "get-all-kelompok-bpi",
  POST_KELOMPOK_BPI: "post-kelompok-bpi",
  GET_DETAIL_KELOMPOK_BPI: "get-detail-kelompok-bpi",
  DELETE_KELOMPOK_BPI: "delete-kelompok-bpi",
  DELETE_ANGGOTA_KELOMPOK_BPI: "delete-anggota-kelompok-bpi",
  GET_ALL_MC: "get-all-mc",
  GET_KEHADIRAN_BPI: "get-kehadiran-bpi",
  POST_BUAT_ABSENSI_BPI: "post-buat-absensi-bpi",
  GET_DETAIL_ABSENSI_BPI: "get-detail-absensi-bpi",
  GET_TOTAL_INFAQ: "get-total-infaq",
  GET_REKAP_PRESENSI_BPI: "get-rekap-presensi-bpi",
  EXPORT_REKAP_PRESENSI_BPI: "export-rekap-presensi-bpi",
  GET_PINDAH_TUTOR_BPI: "get-pindah-tutor-bpi",
  POST_PINDAH_TUTOR_BPI: "post-pindah-tutor-bpi",
  GET_TUTOR_BPI: "get-tutor-bpi",
  //ABSENSI BPI

  //ABSENSI LT3Q
  GET_ABSENSI_LT3Q: "get-absensi-lt3q",
  GET_LAPORAN_ABSENSI_LT3Q: "get-laporan-absensi-lt3q",
  GET_KELOMPOK_LT3Q: "get-kelompok-lt3q",
  GET_ANGGOTA_KELOMPOK_LT3Q: "get-anggota-kelompok-lt3q",
  GET_ALL_KELOMPOK_LT3Q: "get-all-kelompok-lt3q",
  POST_KELOMPOK_LT3Q: "post-kelompok-lt3q",
  GET_DETAIL_KELOMPOK_LT3Q: "get-detail-kelompok-lt3q",
  DELETE_KELOMPOK_LT3Q: "delete-kelompok-lt3q",
  DELETE_ANGGOTA_KELOMPOK_LT3Q: "delete-anggota-kelompok-lt3q",
  GET_KEHADIRAN_LT3Q: "get-kehadiran-lt3q",
  POST_BUAT_ABSENSI_LT3Q: "post-buat-absensi-lt3q",
  GET_DETAIL_ABSENSI_LT3Q: "get-detail-absensi-lt3q",
  GET_PINDAH_TUTOR_LT3Q: "get-pindah-tutor-lt3q",
  POST_PINDAH_TUTOR_LT3Q: "post-pindah-tutor-lt3q",
  GET_TUTOR_LT3Q: "get-tutor-lt3q",
  GET_ALL_NILAI_TAHSIN: "get-all-nilai-tahsin",
  POST_NILAI_TAHSIN: "post-nilai-tahsin",
  //ABSENSI LT3Q

  //MASTER DATA
  GET_JENIS_PTK: "get-jenis-ptk",
  GET_STATUS_GOLONGAN_KEPEGAWAIAN: "get-status-golongan-kepegawaian",
  GET_KOMPETENSI_KHUSUS: "get-kompetensi-khusus",
  GET_JENIS_KEPEGAWAIAN: "get-jenis-kepegawaian",
  GET_MASTER_UNIT_KERJA: "get-unit-kerja",
  GET_STATUS_PERKAWINAN: "get-status-perkawinan",
  GET_LEVEL_BPI: "get-level-bpi",
  //MASTER DATA

  //PENGATURAN
  GET_LIST_MENU: "get-list-menu",
  POST_LIST_MENU: "post-list-menu",
  GET_HAK_AKSES: "get-hak-akses",
  POST_HAK_AKSES: "post-hak-akses",
  GET_DETAIL_HAK_AKSES: "get-detail-hak-akses",
  DELETE_HAK_AKSES: "delete-hak-akses",
  GET_HAK_AKSES_MENU: "get-hak-akses-menu",
  POST_HAK_AKSES_MENU: "post-hak-akses-menu",
  GET_DETAIL_HAK_AKSES_MENU: "get-detail-hak-akses-menu",
  DELETE_HAK_AKSES_MENU: "delete-hak-akses-menu",
  GET_ALL_APP: "get-all-app",
  GET_ALL_HAK_AKSES: "get-all-hak-akses",
  GET_USER_HAK_AKSES: "get-user-hak-akses",
  POST_ROLES_MENU: "post-roles-menu",
  POST_USER_HAK_AKSES: "post-user-hak-akses",
  GET_FILTER_UNIT_KERJA: "get-filter-unit-kerja",
  POST_FILTER_UNIT_KERJA: "post-filter-unit-kerja",
  GET_DETAIL_FILTER_UNIT_KERJA: "get-detail-filter-unit-kerja",
  //PENGATURAN

  //INPASSING
  POST_INPASSING: "post-inpassing",

  //RIWAYAT SERTIFIKASI
  POST_RIWAYAT_SERTIFIKASI: "post-riwayat-sertifikasi",
  DOWNLOAD_DOC_RIWAYAT_SERTIFIKASI: "download-doc-riwayat-sertifikasi",

  //RIWAYAT GAJI BERKALA
  POST_RIWAYAT_GAJI_BERKALA: "post-riwayat-gaji-berkala",

  //RIWAYAT PANGKAT
  POST_RIWAYAT_PANGKAT: "post-riwayat-pangkat",

  //MUTASI
  GET_LIST_MUTASI: "get-list-mutasi",
  POST_MUTASI: "post-mutasi",
  DELETE_MUTASI: "delete-mutasi",
  GET_DETAIL_MUTASI: "get-detail-mutasi",
  DOWNLOAD_DOC_MUTASI: "download-doc-mutasi",

  //ANAK
  GET_LIST_ANAK: "get-list-anak",
  GET_ALL_JENJANG_PENDIDIKAN: "get-all-jenjang-pendidikan",
  POST_ANAK: "post-anak",
  DELETE_ANAK: "delete-anak",
  GET_DETAIL_ANAK: "get-detail-anak",
  DOWNLOAD_DOC_ANAK: "download-doc-anak",

  //KARYA BUKU
  GET_LIST_KARYA_BUKU: "get-list-karya-buku",
  POST_KARYA_BUKU: "post-karya-buku",
  DELETE_KARYA_BUKU: "delete-karya-buku",
  GET_DETAIL_KARYA_BUKU: "get-detail-karya-buku",
  DOWNLOAD_DOC_KARYA_BUKU: "download-doc-karya-buku",

  //RIWAYAT PEKERJAAN
  GET_LIST_RIWAYAT_PEKERJAAN: "get-list-riwayat-pekerjaan",
  POST_RIWAYAT_PEKERJAAN: "post-riwayat-pekerjaan",
  DELETE_RIWAYAT_PEKERJAAN: "delete-riwayat-pekerjaan",
  GET_DETAIL_RIWAYAT_PEKERJAAN: "get-detail-riwayat-pekerjaan",
  DOWNLOAD_DOC_RIWAYAT_PEKERJAAN: "download-doc-riwayat-pekerjaan",

  //PENDIDIKAN PELATIHAN
  GET_LIST_PENDIDIKAN_PELATIHAN: "get-list-pendidikan-pelatihan",
  POST_PENDIDIKAN_PELATIHAN: "post-pendidikan-pelatihan",
  DELETE_PENDIDIKAN_PELATIHAN: "delete-pendidikan-pelatihan",
  GET_DETAIL_PENDIDIKAN_PELATIHAN: "get-detail-pendidikan-pelatihan",
  DOWNLOAD_DOC_PENDIDIKAN_PELATIHAN: "download-doc-pendidikan-pelatihan",

  //RIWAYAT PENDIDIKAN
  GET_LIST_RIWAYAT_PENDIDIKAN: "get-list-riwayat-pendidikan",
  POST_RIWAYAT_PENDIDIKAN: "post-riwayat-pendidikan",
  DELETE_RIWAYAT_PENDIDIKAN: "delete-riwayat-pendidikan",
  GET_DETAIL_RIWAYAT_PENDIDIKAN: "get-detail-riwayat-pendidikan",
  DOWNLOAD_DOC_RIWAYAT_PENDIDIKAN: "download-doc-riwayat-pendidikan",

  //KARYA TULIS
  GET_LIST_KARYA_TULIS: "get-list-karya-tulis",
  POST_KARYA_TULIS: "post-karya-tulis",
  DELETE_KARYA_TULIS: "delete-karya-tulis",
  GET_DETAIL_KARYA_TULIS: "get-detail-karya-tulis",
  DOWNLOAD_DOC_KARYA_TULIS: "download-doc-karya-tulis",

  //KESEJAHTERAAN
  GET_LIST_KESEJAHTERAAN: "get-list-kesejahteraan",
  POST_KESEJAHTERAAN: "post-kesejahteraan",
  DELETE_KESEJAHTERAAN: "delete-kesejahteraan",
  GET_DETAIL_KESEJAHTERAAN: "get-detail-kesejahteraan",
  DOWNLOAD_DOC_KESEJAHTERAAN: "download-doc-kesejahteraan",

  //PENGHARGAAN
  GET_LIST_PENGHARGAAN: "get-list-penghargaan",
  POST_PENGHARGAAN: "post-penghargaan",
  DELETE_PENGHARGAAN: "delete-penghargaan",
  GET_DETAIL_PENGHARGAAN: "get-detail-penghargaan",
  DOWNLOAD_DOC_PENGHARGAAN: "download-doc-penghargaan",

  //PENILAIAN
  GET_LIST_PENILAIAN: "get-list-penilaian",
  POST_PENILAIAN: "post-penilaian",
  DELETE_PENILAIAN: "delete-penilaian",
  GET_DETAIL_PENILAIAN: "get-detail-penilaian",
  DOWNLOAD_DOC_PENILAIAN: "download-doc-penilaian",

  //TUGAS TAMBAHAN
  GET_LIST_TUGAS_TAMBAHAN: "get-list-tugas-tambahan",
  POST_TUGAS_TAMBAHAN: "post-tugas-tambahan",
  DELETE_TUGAS_TAMBAHAN: "delete-tugas-tambahan",
  GET_DETAIL_TUGAS_TAMBAHAN: "get-detail-tugas-tambahan",
  DOWNLOAD_DOC_TUGAS_TAMBAHAN: "download-doc-tugas-tambahan",

  //MAPEL
  GET_LIST_MAPEL: "get-list-mapel",
  POST_MAPEL: "post-mapel",
  DELETE_MAPEL: "delete-mapel",
  GET_DETAIL_MAPEL: "get-detail-mapel",
  GET_ALL_MAPEL: "get-all-mapel",
  DOWNLOAD_DOC_MAPEL: "download-doc-mapel",

  //MASTER MAPEL
  GET_LIST_MASTER_MAPEL: "get-list-master-mapel",
  POST_MASTER_MAPEL: "post-master-mapel",
  DELETE_MASTER_MAPEL: "delete-master-mapel",
  GET_DETAIL_MASTER_MAPEL: "get-detail-master-mapel",
  GET_ALL_MASTER_MAPEL: "get-all-master-mapel",
  DOWNLOAD_DOC_MASTER_MAPEL: "download-doc-master-mapel",

  //DAFTAR UNIT KERJA
  GET_LIST_DAFTAR_UNIT_KERJA: "get-list-daftar-unit-kerja",
  POST_DAFTAR_UNIT_KERJA: "post-daftar-unit-kerja",
  DELETE_DAFTAR_UNIT_KERJA: "delete-daftar-unit-kerja",
  GET_DETAIL_DAFTAR_UNIT_KERJA: "get-detail-daftar-unit-kerja",
  GET_ALL_DAFTAR_UNIT_KERJA: "get-all-daftar-unit-kerja",

  //FILTER UNIT KERJA
  GET_LIST_FILTER_UNIT_KERJA: "get-list-filter-unit-kerja",
  DELETE_FILTER_UNIT_KERJA: "delete-filter-unit-kerja",

  //PROFIL
  POST_PROFIL: "post-profil",

  //BACKUP DATA
  DOWNLOAD_DB_GTK: "download-db-gtk",
  DOWNLOAD_DB_SSO: "download-db-sso",
  GET_HISTORY_BACKUP: "get-history-backup",
  POST_HISTORY_BACKUP: "post-history-backup",

  //PRESENSI SAYA
  GET_PRESENSI_BPI_SAYA: "get-presensi-bpi-saya",
  GET_PRESENSI_LT3Q_SAYA: "get-presensi-lt3q-saya",
  GET_PRESENSI_GTK_SAYA: "get-presensi-gtk-saya",

  GET_DETAIL_PRESENSI_GTK_DASHBOARD: "get-detail-presensi-gtk-dashboard",
  EXPORT_PRESENSI_GTK_DASHBOARD: "export-presensi-gtk-dashboard",
  EXPORT_REKAP_PRESENSI_GTK: "export-rekap-presensi-gtk",

  //SERTIFIKASI KETERAMPILAN KHUSUS
  POST_SERTIFIKASI_KETERAMPILAN_KHUSUS: "post-sertifikasi-keterampilan-khusus",
  DOWNLOAD_DOC_SERTIFIKASI_KETERAMPILAN_KHUSUS:
    "download-doc-sertifikasi-keterampilan-khusus",
};

export { QUERIES };
