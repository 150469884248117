import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./layout/core/LayoutProvider";
import { AuthProvider } from "./provider/core/auth";
import { QueryResponseProvider as QueryResponseProviderDetailGTK } from "./provider/query-response/detail-gtk";
import { QueryResponseProvider as QueryResponseProviderProfil } from "./provider/query-response/profil";
import { QueryResponseProvider as QueryResponseProviderNotifikasi } from "./provider/query-response/notifikasi";
import DetailGTK from "./modules/detail-gtk";
import ModalMemoNotif from "./modules/notifikasi/modal/memo";

const App = () => {
  // useEffect(() => {
  //   document.title = "Sistem Informasi Bina Ilmi";
  // }, []);
  return (
    <Suspense fallback={<>Is loading</>}>
      <AuthProvider>
        <LayoutProvider>
          <div className="App">
            <QueryResponseProviderNotifikasi>
              <QueryResponseProviderDetailGTK>
                <QueryResponseProviderProfil>
                  <ModalMemoNotif />
                  <DetailGTK />
                  <Outlet />
                </QueryResponseProviderProfil>
              </QueryResponseProviderDetailGTK>
            </QueryResponseProviderNotifikasi>
          </div>
        </LayoutProvider>
      </AuthProvider>
    </Suspense>
  );
};
export { App };
