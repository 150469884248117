import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/anak";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/anak";
import ModalAnak from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";
const Anak = () => {
  const { isLoadingFetchData, listData, isLoadingDeleteAnak } =
    useQueryResponse();
  return (
    <>
      {isLoadingDeleteAnak && <FetchLoading />}
      <Toolbar />
      <ModalAnak />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default Anak;
