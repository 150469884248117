import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-bpi/laporan-bulanan";
import { initialQueryResponse } from "../../../../models/absensi-bpi/laporan-bulanan/_queryResponse";
import {
  getAbsensiBPI,
  getLaporanAbsensiBPI,
} from "../../../../api/absensi-bpi/_request";
import { useQueryResponse as useQueryResponseAbsesnsiBPI } from "../../absensi-bpi";
import { useAuth } from "../../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["gtk.nik_gtk", "gtk.nama_gtk", "kelompok_bpi.nama_kelompok_bpi"]`;
  const { currentUser } = useAuth();
  state.nik_gtk_tutor = currentUser?.data?.username;

  const { activeTab } = useQueryResponseAbsesnsiBPI();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "absensi-bpi")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "absensi-bpi"),
    [state]
  );

  const [bulan, setBulan] = useState<number | string | null>(null);
  const [tahun, setTahun] = useState<number | null>(null);
  const [idKelompokBPI, setIdKelompokBPI] = useState<Array<any>>([]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LAPORAN_ABSENSI_BPI}-${query}`,
    () => getLaporanAbsensiBPI(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: activeTab === 2,
    }
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      bulan,
      setBulan,
      tahun,
      setTahun,
      idKelompokBPI,
      setIdKelompokBPI,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      bulan,
      setBulan,
      tahun,
      setTahun,
      idKelompokBPI,
      setIdKelompokBPI,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
