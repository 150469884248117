import { FC, createContext, useContext, useState, useMemo } from "react";
import { initialState, defaultState } from "./LayoutSetup";
import { WithChildren } from "../../helpers/react18MigrationHelpers";

const LayoutContext = createContext(defaultState);
const LayoutProvider: FC<WithChildren> = ({ children }) => {
  const [setting, setSetting] = useState<any>(defaultState.setting);
  const [saveLocal, setSaveLocal] = useState<String>(defaultState.saveLocal);
  const [storeKey, setStoreKey] = useState<String>(defaultState.storeKey);

  const value = useMemo(
    () => ({
      saveLocal,
      setSaveLocal,
      setting,
      setSetting,
      storeKey,
      setStoreKey,
    }),
    [setting, setSetting, saveLocal, setSaveLocal, storeKey, setStoreKey]
  );

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
  return useContext(LayoutContext);
}
