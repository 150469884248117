import { FC, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import { useFormik } from "formik";
import { FormAnggotaKelompokBPIPayload } from "../../../../../models/absensi-bpi/anggota-kelompok-bpi/_queryResponse";
import * as Yup from "yup";
import { useAuth } from "../../../../../provider/core/auth";
import { useQueryResponse as useQueryResponseAbsensiBPI } from "../../../../../provider/query-response/absensi-bpi";
import { useQueryResponse as useQueryResponseDataGTK } from "../../../../../provider/query-response/data-gtk";
import { useQueryResponse as useQueryResponseLevelBPI } from "../../../../../provider/query-response/master-data/level-bpi";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
};

const KelolaAnggotaKelompokBPI: FC<Props> = ({ activeTab }) => {
  const listDataGTK = useQueryResponseDataGTK().listData;
  let optionsDataGTK: any = listDataGTK?.data?.map((val) => {
    return {
      value: val.nik_gtk,
      label: `${val.nik_gtk} - ${val.nama_gtk}`,
    };
  });
  const isLoadingFetchDataDataGTK =
    useQueryResponseDataGTK().isLoadingFetchData;

  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiBPI();
  const {
    isLoadingFetchData,
    listData,
    allKelompokBPI,
    handleGetAllKelompokBPI,
    isLoadingGetAllKelompokBPI,
    handlePostAnggotaKelompokBPI,
    isLoadingPostAnggotaKelompokBPI,
    resetForm,
    setResetForm,
    resetDetailAnggotaKelompokBPI,
    detailAnggotaKelompokBPI,
    isLoadingGetDetailAnggotaKelompokBPI,
  } = useQueryResponse();

  let optionsKelompokBPI: any = allKelompokBPI.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_bpi,
    };
  });

  const listDataLevelBPI = useQueryResponseLevelBPI().listData;
  const isLoadingFetchDataLevelBPI =
    useQueryResponseLevelBPI().isLoadingFetchData;

  let optionsLevelBPI: any = listDataLevelBPI?.data?.map((val) => {
    return {
      value: val.name,
      label: val.name,
    };
  });

  const Data_table = listData?.data;
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      isShowModalTentukanKelompok &&
      activeTab === 2
    ) {
      const nik_gtk: any = currentUser.data.username;
      formikAnggotaKelompokBPI.setFieldValue("nik_tutor_bpi", nik_gtk);
      if (handleGetAllKelompokBPI) handleGetAllKelompokBPI(nik_gtk);
    }
  }, [
    currentUser,
    isShowModalTentukanKelompok,
    handleGetAllKelompokBPI,
    activeTab,
  ]);

  const formikAnggotaKelompokBPIValidationSchema = Yup.object().shape({
    id_kelompok_bpi: Yup.string().required("Kelompok BPI Tidak Boleh Kosong"),
    nik_gtk: Yup.string().required("GTK Tidak Boleh Kosong"),
    level_bpi: Yup.string().required("Level BPI Tidak Boleh Kosong"),
  });
  const formikAnggotaKelompokBPI = useFormik<FormAnggotaKelompokBPIPayload>({
    initialValues: {
      id: null,
      id_kelompok_bpi: null,
      nik_gtk: null,
      nik_tutor_bpi: null,
      level_bpi: null,
    },
    enableReinitialize: false,
    validationSchema: formikAnggotaKelompokBPIValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostAnggotaKelompokBPI) handlePostAnggotaKelompokBPI(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikAnggotaKelompokBPI.setFieldValue("id", null);
      formikAnggotaKelompokBPI.setFieldValue("id_kelompok_bpi", null);
      formikAnggotaKelompokBPI.setFieldValue("nik_gtk", null);
      formikAnggotaKelompokBPI.setFieldValue("level_bpi", null);
      setSubmitting(false);
      setResetForm(false);
      resetDetailAnggotaKelompokBPI();
    }
  }, [resetForm]);

  useEffect(() => {
    if (detailAnggotaKelompokBPI && detailAnggotaKelompokBPI.id) {
      formikAnggotaKelompokBPI.setValues(detailAnggotaKelompokBPI);
    }
  }, [detailAnggotaKelompokBPI]);

  return (
    <Col
      style={{
        display: activeTab === 2 ? "block" : "none",
      }}
      className="p-4"
    >
      {(isLoadingPostAnggotaKelompokBPI ||
        isLoadingGetDetailAnggotaKelompokBPI) && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikAnggotaKelompokBPI.submitForm();
          e.preventDefault();
        }}
      >
        <h6>Tambah Data Anggota Kelompok BPI</h6>
        <hr className="bg-light" />
        <Row className="gy-3 mb-3">
          <Col lg="6">
            <Form.Group>
              <Select2
                label="Kelompok BPI"
                placeholder="Pilih Kelompok BPI"
                isRequired
                isLoading={isLoadingGetAllKelompokBPI}
                options={optionsKelompokBPI}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokBPI.setFieldValue(
                    "id_kelompok_bpi",
                    value
                  );
                }}
                value={
                  formikAnggotaKelompokBPI.getFieldProps("id_kelompok_bpi")
                    .value
                }
              />
              {submitting &&
                formikAnggotaKelompokBPI.errors.id_kelompok_bpi && (
                  <div className="text-danger mt-2">
                    {formikAnggotaKelompokBPI.errors.id_kelompok_bpi}
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="GTK"
                placeholder="Pilih GTK"
                isRequired
                options={optionsDataGTK}
                isLoading={isLoadingFetchDataDataGTK}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokBPI.setFieldValue("nik_gtk", value);
                }}
                disabled={
                  detailAnggotaKelompokBPI && detailAnggotaKelompokBPI.id
                    ? true
                    : false
                }
                value={formikAnggotaKelompokBPI.getFieldProps("nik_gtk").value}
              />
              {submitting && formikAnggotaKelompokBPI.errors.nik_gtk && (
                <div className="text-danger mt-2">
                  {formikAnggotaKelompokBPI.errors.nik_gtk}
                </div>
              )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="Level BPI"
                placeholder="Pilih Level BPI"
                isRequired
                options={optionsLevelBPI}
                isLoading={isLoadingFetchDataLevelBPI}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokBPI.setFieldValue("level_bpi", value);
                }}
                value={
                  formikAnggotaKelompokBPI.getFieldProps("level_bpi").value
                }
              />
              {submitting && formikAnggotaKelompokBPI.errors.level_bpi && (
                <div className="text-danger mt-2">
                  {formikAnggotaKelompokBPI.errors.level_bpi}
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Card.Footer
          className={`p-3 d-flex flex-row justify-content-${
            detailAnggotaKelompokBPI?.id ? "between" : "end"
          }`}
        >
          {detailAnggotaKelompokBPI?.id && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => setResetForm(true)}
            >
              <i className="fas fa-undo me-2"></i>
              Reset Form
            </button>
          )}

          <button
            type="submit"
            className={`btn btn-${
              detailAnggotaKelompokBPI?.id ? "dark" : "primary"
            } btn-sm `}
          >
            <i
              className={`far fa-${
                detailAnggotaKelompokBPI?.id ? "edit" : "floppy-disk"
              } me-2`}
            ></i>
            {detailAnggotaKelompokBPI?.id ? "Edit" : "Simpan"}
          </button>
        </Card.Footer>
      </Form>

      <Col className="mt-4">
        <h6>Daftar Anggota Kelompok BPI</h6>
        <hr className="bg-light" />
        <Toolbar />
        <DataTable
          isLoading={isLoadingFetchData}
          data={Data_table}
          header={Columns}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Col>
    </Col>
  );
};

export default KelolaAnggotaKelompokBPI;
