import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/riwayat-pekerjaan";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import moment from "moment";
import dayjs from "dayjs";
import FileInputComponent from "../../../../hope-ui/components/file-input";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import NumberInputComponent from "../../../../hope-ui/components/number-input";

const ModalRiwayatPekerjaan = () => {
  const {
    showModalRiwayatPekerjaan,
    setShowModalRiwayatPekerjaan,
    formikRiwayatPekerjaan,
    submitting,
    setSubmitting,
    detailRiwayatPekerjaan,
    setResetForm,
    handleDownloadDocRiwayatPekerjaan,
    isLoadingGetDetailRiwayatPekerjaan,
    isLoadingPostRiwayatPekerjaan,
    isLoadingDownloadDocRiwayatPekerjaan,
  } = useQueryResponse();

  useEffect(() => {
    if (detailRiwayatPekerjaan.id) {
      formikRiwayatPekerjaan?.setValues(detailRiwayatPekerjaan);
    }
  }, [detailRiwayatPekerjaan]);

  let doc_riwayat_pekerjaan_name;
  if (
    typeof formikRiwayatPekerjaan?.getFieldProps("doc_riwayat_pekerjaan")
      .value === "string"
  ) {
    const pecah = formikRiwayatPekerjaan
      ?.getFieldProps("doc_riwayat_pekerjaan")
      .value.split("/");
    doc_riwayat_pekerjaan_name = pecah[pecah.length - 1];
  }

  return (
    <Modal
      backdrop="static"
      show={showModalRiwayatPekerjaan}
      onBackdropClick={() => {
        setShowModalRiwayatPekerjaan(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalRiwayatPekerjaan(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikRiwayatPekerjaan?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailRiwayatPekerjaan.id ? "Edit" : "Tambah"} Riwayat Pekerjaan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailRiwayatPekerjaan ||
            isLoadingPostRiwayatPekerjaan ||
            isLoadingDownloadDocRiwayatPekerjaan) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Jenis Lembaga"
                placeholder="Masukkan Jenis Lembaga"
                isRequired
                formik={formikRiwayatPekerjaan?.getFieldProps("jenis_lembaga")}
                value={
                  formikRiwayatPekerjaan?.getFieldProps("jenis_lembaga")
                    .value ?? ""
                }
              />
              {submitting && formikRiwayatPekerjaan?.errors.jenis_lembaga && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPekerjaan?.errors.jenis_lembaga}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Posisi Pekerjaan"
                placeholder="Masukkan Posisi Pekerjaan"
                isRequired
                formik={formikRiwayatPekerjaan?.getFieldProps(
                  "posisi_pekerjaan"
                )}
                value={
                  formikRiwayatPekerjaan?.getFieldProps("posisi_pekerjaan")
                    .value ?? ""
                }
              />
              {submitting &&
                formikRiwayatPekerjaan?.errors.posisi_pekerjaan && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRiwayatPekerjaan?.errors.posisi_pekerjaan}
                  </div>
                )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Jenjang Kepegawaian"
                placeholder="Masukkan Jenjang Kepegawaian"
                isRequired
                formik={formikRiwayatPekerjaan?.getFieldProps(
                  "jenjang_kepegawaian"
                )}
                value={
                  formikRiwayatPekerjaan?.getFieldProps("jenjang_kepegawaian")
                    .value ?? ""
                }
              />
              {submitting &&
                formikRiwayatPekerjaan?.errors.jenjang_kepegawaian && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRiwayatPekerjaan?.errors.jenjang_kepegawaian}
                  </div>
                )}
            </Col>
            <Col lg="6">
              <NumberInputComponent
                label="Tahun Masuk"
                placeholder="Masukkan Tahun Masuk"
                required
                formik={formikRiwayatPekerjaan?.getFieldProps("tahun_masuk")}
                value={
                  formikRiwayatPekerjaan?.getFieldProps("tahun_masuk").value ??
                  ""
                }
              />
              {submitting && formikRiwayatPekerjaan?.errors.tahun_masuk && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPekerjaan?.errors.tahun_masuk}
                </div>
              )}
            </Col>
            <Col lg="6">
              <NumberInputComponent
                label="Tahun Keluar"
                placeholder="Masukkan Tahun Keluar"
                required
                formik={formikRiwayatPekerjaan?.getFieldProps("tahun_keluar")}
                value={
                  formikRiwayatPekerjaan?.getFieldProps("tahun_keluar").value ??
                  ""
                }
              />
              {submitting && formikRiwayatPekerjaan?.errors.tahun_keluar && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPekerjaan?.errors.tahun_keluar}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextareaComponent
                label="Alasan Resign"
                placeholder="Masukkan Alasan Resign"
                required
                formik={formikRiwayatPekerjaan?.getFieldProps("alasan_resign")}
                value={
                  formikRiwayatPekerjaan?.getFieldProps("alasan_resign")
                    .value ?? ""
                }
              />
              {submitting && formikRiwayatPekerjaan?.errors.alasan_resign && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPekerjaan?.errors.alasan_resign}
                </div>
              )}
            </Col>
            {/* <Col lg="6">
              <FileInputComponent
                acceptLabel="Format File PDF"
                label="Upload Dokumen"
                acceptedType={[".pdf"]}
                onChange={(e) => {
                  if (e.target.files) {
                    formikRiwayatPekerjaan?.setFieldValue(
                      "doc_riwayat_pekerjaan",
                      e.target.files[0]
                    );
                  } else
                    formikRiwayatPekerjaan?.setFieldValue(
                      "doc_riwayat_pekerjaan",
                      null
                    );
                }}
                fileNameLabel={
                  formikRiwayatPekerjaan?.getFieldProps("doc_riwayat_pekerjaan")
                    .value?.name ?? doc_riwayat_pekerjaan_name
                }
                resetField={
                  formikRiwayatPekerjaan?.getFieldProps("doc_riwayat_pekerjaan")
                    .value
                    ? false
                    : true
                }
                maxFileSize={25}
                linkPreview={
                  typeof formikRiwayatPekerjaan?.getFieldProps(
                    "doc_riwayat_pekerjaan"
                  ).value === "string"
                    ? formikRiwayatPekerjaan?.getFieldProps(
                        "doc_riwayat_pekerjaan"
                      ).value
                    : null
                }
                downloadPreview={() =>
                  handleDownloadDocRiwayatPekerjaan(
                    formikRiwayatPekerjaan?.getFieldProps(
                      "doc_riwayat_pekerjaan"
                    ).value,
                    doc_riwayat_pekerjaan_name
                  )
                }
              />
              {submitting &&
                formikRiwayatPekerjaan?.errors.doc_riwayat_pekerjaan && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRiwayatPekerjaan?.errors.doc_riwayat_pekerjaan}
                  </div>
                )}
            </Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalRiwayatPekerjaan;
