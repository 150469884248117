import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { initialQueryState } from "../../../../models/global";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { useQueryRequest } from "../../../query-request/absensi-gtk/rekap-absensi";
import { initialQueryResponse } from "../../../../models/absensi-gtk/rekap-absensi/_queryResponse";
import {
  exportRekapPresensiGTK,
  getRekapAbsensiGTK,
} from "../../../../api/absensi-gtk/_request";
import dayjs from "dayjs";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state, updateState } = useQueryRequest();

  state.col_header = `["nik_gtk", "nama_gtk", "unit_kerja"]`;
  //   state.tanggal_awal = dayjs().format("YYYY-MM-DD");
  //   state.tanggal_akhir = dayjs().format("YYYY-MM-DD");

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "rekap-absensi-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "rekap-absensi-gtk"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);
  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_REKAP_PRESENSI_GTK}-${query}`,
    () => getRekapAbsensiGTK(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query && query !== "?undefined" ? true : false,
    }
  );

  const {
    mutateAsync: ExportRekapPresensiGTK,
    isLoading: isLoadingExportRekapPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.EXPORT_REKAP_PRESENSI_GTK}-${query}`,
    mutationFn: () => exportRekapPresensiGTK(query),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.EXPORT_REKAP_PRESENSI_GTK}-${query}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleExportRekapPresensiGTK = useCallback(async () => {
    await ExportRekapPresensiGTK()
      .then((res) => {})
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [ExportRekapPresensiGTK]);

  const value = useMemo(
    () => ({
      query,
      isLoadingFetchData,
      listData,
      refetchListData,
      handleExportRekapPresensiGTK,
      isLoadingExportRekapPresensiGTK,
    }),
    [
      query,
      isLoadingFetchData,
      listData,
      refetchListData,
      handleExportRekapPresensiGTK,
      isLoadingExportRekapPresensiGTK,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
