import { FC } from "react";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import TableHeader from "./table-header";
import Toolbar from "./toolbar";
import { useQueryRequest } from "../../../../provider/query-request/absensi-lt3q/laporan-bulanan";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../provider/query-response/absensi-lt3q/laporan-bulanan";
import { Column } from "react-table";
import TableBody from "./table-body";

type Props = {};

const LaporanBulanan: FC<Props> = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  const Data_table = listData?.data;
  const Columns: ReadonlyArray<Column<any>> = [];
  return (
    <div className="pt-3">
      <Toolbar />
      <DataTable
        isLoading={isLoadingFetchData}
        data={Data_table}
        header={Columns}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
        CustomHeader={<TableHeader />}
        totalHeaders={6}
        bordered={false}
        CustomBody={
          <TableBody
            rows={Data_table}
            allowed_columns={[
              "nik_gtk",
              "gtk",
              "kelompok_lt3q",
              "hadir",
              "sakit",
              "izin",
              "alfa",
            ]}
            isLoading={isLoadingFetchData}
          />
        }
        totalRows={Data_table ? Data_table.length : 0}
      />
    </div>
  );
};

export default LaporanBulanan;
