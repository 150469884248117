import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/filter-data/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../query-request/filter-data";
import { useQuery } from "react-query";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { getFilterData } from "../../../api/filter-data/_request";
import { initialQueryState } from "../../../models/global";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "filter-data")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "filter-data"),
    [state]
  );

  const {
    data: listFilterData,
    isFetching: isLoadingFetchListFilterData,
    refetch: refetchListFilterData,
  } = useQuery(
    `${QUERIES.GET_FILTER_DATA}-${query}`,
    () => getFilterData(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const value = useMemo(
    () => ({
      listFilterData,
      refetchListFilterData,
      isLoadingFetchListFilterData,
      query,
    }),
    [listFilterData, refetchListFilterData, isLoadingFetchListFilterData, query]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
