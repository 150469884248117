import { Card } from "react-bootstrap";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import {
  useQueryResponsePagination,
  useQueryResponse,
} from "../../../../provider/query-response/absensi-gtk/rekap-absensi";
import { useQueryRequest } from "../../../../provider/query-request/absensi-gtk/rekap-absensi";
import { Header } from "../../../../hope-ui/components/table/Header";
import { useEffect, useState } from "react";
import { Column } from "react-table";
import NamaGTKCell from "../../../../hope-ui/components/table/NamaGTKCell";
import moment from "moment";
import KeteranganWarnaPresensiGTK from "../../../../hope-ui/components/keterangan-warna-presensi-gtk";

const RekapAbsensiGTK = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  const [Columns, setColumns] = useState<Array<Column<any>>>([]);
  const [arrTanggal, setArrTanggal] = useState<Array<any>>([]);
  useEffect(() => {
    const column = [
      {
        Header: (props) => (
          <Header
            tableProps={props}
            title="No."
            className="ps-3 fs-6 text-center"
            queryRequest={useQueryRequest}
          />
        ),
        id: "no",
        Cell: ({ ...props }) => {
          const { offset } = useQueryResponsePagination();
          let page: any = offset;
          return (
            <label className="fw-normal text-dark pe-3 text-center w-100">
              {props.row.index + 1 + page}
            </label>
          );
        },
      },
      {
        Header: (props) => (
          <Header
            tableProps={props}
            title="NIK"
            className="ps-3 fs-6 text-center"
            queryRequest={useQueryRequest}
            isSorting
          />
        ),
        id: "nik_gtk",
        Cell: ({ ...props }) => (
          <label className="fw-normal text-dark pe-3 text-center w-100">
            {" "}
            {props.data[props.row.index].nik_gtk}
          </label>
        ),
      },
      {
        Header: (props) => (
          <Header
            tableProps={props}
            title="Nama Lengkap"
            className="ps-3 fs-6 text-center"
            queryRequest={useQueryRequest}
            isSorting
          />
        ),
        id: "nama_gtk",
        Cell: ({ ...props }) => (
          <NamaGTKCell
            id={props.data[props.row.index].nik_gtk}
            label={props.data[props.row.index].nama_gtk}
          />
        ),
      },
      {
        Header: (props) => (
          <Header
            tableProps={props}
            title="Unit Kerja"
            className="ps-3 fs-6 text-center"
            queryRequest={useQueryRequest}
            isSorting
          />
        ),
        id: "unit_kerja",
        Cell: ({ ...props }) => (
          <label className="fw-normal text-dark pe-3 text-center w-100">
            {" "}
            {props.data[props.row.index].unit_kerja}
          </label>
        ),
      },
    ];
    if (listData && listData.data) {
      const array = listData?.data[0]?.absensi;
      array && setArrTanggal(array);
      array &&
        array.sort((a, b) => {
          return a.tanggal_absen > b.tanggal_absen ? 1 : -1;
        });
      array &&
        array.map((val, idx) => {
          const tanggal_absen = val.tanggal_absen;
          column.push({
            Header: (props) => (
              <Header
                tableProps={props}
                title={`${moment(tanggal_absen).format("DD MMM YYYY")}`}
                className="ps-3 fs-6 text-center"
                queryRequest={useQueryRequest}
              />
            ),
            id: `tanggal_absen${idx}`,
            Cell: ({ ...props }) =>
              props.data[props.row.index] &&
              props.data[props.row.index].absensi &&
              props.data[props.row.index].absensi[idx] &&
              props.data[props.row.index].absensi[idx].scan_masuk ? (
                <label className="fw-normal text-dark pe-3 text-center w-100">
                  {props.data[props.row.index].absensi[idx].scan_masuk}
                </label>
              ) : (
                <>-</>
              ),
          });
        });
    }
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="N"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "normal",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].N > 0 && "text-white bg-success"
          }`}
        >
          {props.data[props.row.index].N}
        </label>
      ),
    });
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="TTKD"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "TTKD",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].TTKD > 0 && "text-white bg-yellow"
          }`}
        >
          {props.data[props.row.index].TTKD}
        </label>
      ),
    });
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="TKD"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "TKD",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].TKD > 0 && "text-white bg-danger"
          }`}
        >
          {props.data[props.row.index].TKD}
        </label>
      ),
    });
    column.push({
      Header: (props) => (
        <Header
          tableProps={props}
          title="TH"
          className="ps-3 fs-6 text-center"
          queryRequest={useQueryRequest}
        />
      ),
      id: "TH",
      Cell: ({ ...props }) => (
        <label
          className={`fw-normal text-center w-100 ${
            props.data[props.row.index].TH > 0 && "text-white bg-gray"
          }`}
        >
          {props.data[props.row.index].TH}
        </label>
      ),
    });
    setColumns(column);
  }, [listData]);

  return (
    <Card.Body>
      <Toolbar jumlahHari={arrTanggal.length} />

      <DataTable
        isLoading={isLoadingFetchData}
        queryRequest={useQueryRequest}
        pagination={useQueryResponsePagination}
        header={Columns}
        data={listData?.data}
      />

      <KeteranganWarnaPresensiGTK className="mt-3" />
    </Card.Body>
  );
};

export default RekapAbsensiGTK;
