import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/status-aktivasi/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { postAktifkanGTK } from "../../../api/status-aktivasi/aktif/_request";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { postNonAktifkanGTK } from "../../../api/status-aktivasi/tidak-aktif/_request";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [statusPostAktifkanGTK, setStatusPostAktifkanGTK] = useState<boolean>(
    initialQueryResponse.statusPostAktifkanGTK
  );
  const [statusPostNonAktifkanGTK, setStatusPostNonAktifkanGTK] =
    useState<boolean>(initialQueryResponse.statusPostNonAktifkanGTK);

  const queryClient = useQueryClient();
  const { mutateAsync: PostAktifkanGTK, isLoading: isLoadingPostAktifkanGTK } =
    useMutation({
      mutationKey: `${QUERIES.POST_AKTIFKAN_GTK}`,
      mutationFn: ({ payload }: { payload: FormData; redirect_uri?: string }) =>
        postAktifkanGTK(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_AKTIFKAN_GTK}`]);
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostAktifkanGTK = useCallback(
    async (payload: any) => {
      await PostAktifkanGTK({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              setStatusPostAktifkanGTK(true);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostAktifkanGTK]
  );

  const {
    mutateAsync: PostNonAktifkanGTK,
    isLoading: isLoadingPostNonAktifkanGTK,
  } = useMutation({
    mutationKey: `${QUERIES.POST_AKTIFKAN_GTK}`,
    mutationFn: ({ payload }: { payload: FormData; redirect_uri?: string }) =>
      postNonAktifkanGTK(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_AKTIFKAN_GTK}`]);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostNonAktifkanGTK = useCallback(
    async (payload: any) => {
      await PostNonAktifkanGTK({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              setStatusPostNonAktifkanGTK(true);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostNonAktifkanGTK]
  );

  const value = useMemo(
    () => ({
      handlePostAktifkanGTK,
      PostAktifkanGTK,
      isLoadingPostAktifkanGTK,
      handlePostNonAktifkanGTK,
      PostNonAktifkanGTK,
      isLoadingPostNonAktifkanGTK,
      statusPostAktifkanGTK,
      statusPostNonAktifkanGTK,
      setStatusPostAktifkanGTK,
      setStatusPostNonAktifkanGTK,
    }),
    [
      handlePostAktifkanGTK,
      PostAktifkanGTK,
      isLoadingPostAktifkanGTK,
      handlePostNonAktifkanGTK,
      PostNonAktifkanGTK,
      isLoadingPostNonAktifkanGTK,
      statusPostAktifkanGTK,
      statusPostNonAktifkanGTK,
      setStatusPostAktifkanGTK,
      setStatusPostNonAktifkanGTK,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
