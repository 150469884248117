import { FC } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";

import { useQueryResponse } from "../../../../provider/query-response/data-gtk";
import { useQueryResponse as useQueryResponseMemo } from "../../../../provider/query-response/memo";
import { useQueryResponse as useQueryResponseHakAkses } from "../../../../provider/query-response/hak-akses";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";

type Props = {
  data: any;
};

const AksiCell: FC<Props> = ({ data }) => {
  const { setShowModalNonAktifkanGTK, handleResetPasswordGTK } =
    useQueryResponse();
  const { setShowModalMemo } = useQueryResponseMemo();
  const { setShowModalHakAkses } = useQueryResponseHakAkses();
  const { setNIKGTK } = useQueryResponseDetailGTK();

  return (
    <DropdownButton
      as={ButtonGroup}
      key={"end"}
      drop={"end"}
      variant="dark"
      title={` Pilih Aksi `}
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        onClick={(e) => {
          setShowModalMemo(true);
          setNIKGTK(data.nik_gtk);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Memo <i className="fa fa-message text-info ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={(e) => {
          setShowModalHakAkses(true);
          setNIKGTK(data.nik_gtk);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Hak Akses <i className="fa fa-user text-warning ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        onClick={(e) => {
          setShowModalNonAktifkanGTK(true);
          setNIKGTK(data.nik_gtk);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Non Aktifkan <i className="fa fa-ban text-danger ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="4"
        className="cursor-pointer"
        onClick={() => {
          handleResetPasswordGTK(data.nik_gtk, data.nama_gtk);
          setNIKGTK(data.nik_gtk);
        }}
      >
        <div className="fs-6">
          Reset Password <i className="fa fa-key text-primary ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="5"
        className="cursor-pointer"
        onClick={() => {
          window.open(
            process.env.REACT_APP_BASE_URL + "data-gtk/form/" + data.nik_gtk,
            "_blank"
          );
        }}
      >
        <div className="fs-6">
          Edit GTK<i className="fa fa-pencil text-dark ms-1"></i>
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default AksiCell;
