import { Card, Col, Form, Row } from "react-bootstrap";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../provider/query-response/detail-gtk";
import { useQueryResponse } from "../../../provider/query-response/riwayat-sertifikasi";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import { useEffect } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { useAuth } from "../../../provider/core/auth";
import Select2 from "../../../hope-ui/components/select-2";
import FileInputComponent from "../../../hope-ui/components/file-input";

const RiwayatSertifikasi = () => {
  const { currentUser } = useAuth();
  const { detailGTK } = useQueryResponseDetailGTK();
  const {
    submitting,
    setSubmitting,
    formikRiwayatSertifikasi,
    isLoadingPostRiwayatSertifikasi,
    handleDownloadDocRiwayatSertifikasi,
    isLoadingDownloadDocRiwayatSertifikasi,
  } = useQueryResponse();

  useEffect(() => {
    if (detailGTK?.riwayat_sertifikasi) {
      formikRiwayatSertifikasi?.setValues(detailGTK.riwayat_sertifikasi);
    }
  }, [detailGTK]);

  const optionJenisSertifikasi = [
    {
      value: "Tilawah",
      label: "Tilawah",
    },
    {
      value: "Tahsin",
      label: "Tahsin",
    },
    {
      value: "Tahfidz",
      label: "Tahfidz",
    },
    {
      value: "Supervisor Formal",
      label: "Supervisor Formal",
    },
    {
      value: "Supervisor Informal",
      label: "Supervisor Informal",
    },
  ];

  let doc_sertifikasi_pendidik_name;
  if (
    typeof formikRiwayatSertifikasi?.getFieldProps("doc_sertifikasi_pendidik")
      .value === "string"
  ) {
    const pecah = formikRiwayatSertifikasi
      ?.getFieldProps("doc_sertifikasi_pendidik")
      .value.split("/");
    doc_sertifikasi_pendidik_name = pecah[pecah.length - 1];
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formikRiwayatSertifikasi?.submitForm();
        setSubmitting(true);
      }}
    >
      {(isLoadingPostRiwayatSertifikasi ||
        isLoadingDownloadDocRiwayatSertifikasi) && <FetchLoading />}
      <Card.Body>
        <Row className="gy-4">
          {/* <Col lg="6">
            <Select2
              label="Jenis Sertifikasi"
              placeholder="Masukkan Jenis Sertifikasi"
              isRequired
              onChange={(e: any) => {
                const value = e?.value;
                formikRiwayatSertifikasi?.setFieldValue(
                  "jenis_sertifikasi",
                  value
                );
              }}
              value={
                formikRiwayatSertifikasi?.getFieldProps("jenis_sertifikasi")
                  .value
              }
              options={optionJenisSertifikasi}
            />
            {submitting &&
              formikRiwayatSertifikasi?.errors.jenis_sertifikasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatSertifikasi?.errors.jenis_sertifikasi}
                </div>
              )}
          </Col> */}
          {formikRiwayatSertifikasi?.getFieldProps("jenis_sertifikasi")
            .value === "Tahfidz" && (
            <Col lg="6">
              <NumberInputComponent
                label="Jumlah Juz"
                placeholder="Masukkan Jumlah Juz"
                required
                formik={formikRiwayatSertifikasi?.getFieldProps("jumlah_juz")}
                value={
                  formikRiwayatSertifikasi?.getFieldProps("jumlah_juz").value ??
                  ""
                }
              />
              {submitting && formikRiwayatSertifikasi?.errors.jumlah_juz && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatSertifikasi?.errors.jumlah_juz}
                </div>
              )}
            </Col>
          )}
          <Col lg="6">
            <TextInputComponent
              label="Nomor Sertifikasi"
              placeholder="Masukkan Nomor Sertifikasi"
              isRequired
              formik={formikRiwayatSertifikasi?.getFieldProps(
                "nomor_sertifikasi"
              )}
              value={
                formikRiwayatSertifikasi?.getFieldProps("nomor_sertifikasi")
                  .value ?? ""
              }
            />
            {submitting &&
              formikRiwayatSertifikasi?.errors.nomor_sertifikasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatSertifikasi?.errors.nomor_sertifikasi}
                </div>
              )}
          </Col>

          <Col lg="6">
            <TextInputComponent
              label="Bidang Studi"
              placeholder="Masukkan Bidang Studi"
              isRequired
              formik={formikRiwayatSertifikasi?.getFieldProps("bidang_studi")}
              value={
                formikRiwayatSertifikasi?.getFieldProps("bidang_studi").value ??
                ""
              }
            />
            {submitting && formikRiwayatSertifikasi?.errors.bidang_studi && (
              <div className="fs-6 text-danger mt-2">
                {formikRiwayatSertifikasi?.errors.bidang_studi}
              </div>
            )}
          </Col>

          <Col lg="6">
            <TextInputComponent
              label="NGRG"
              placeholder="Masukkan NGRG"
              isRequired
              formik={formikRiwayatSertifikasi?.getFieldProps("ngrg")}
              value={
                formikRiwayatSertifikasi?.getFieldProps("ngrg").value ?? ""
              }
            />
            {submitting && formikRiwayatSertifikasi?.errors.ngrg && (
              <div className="fs-6 text-danger mt-2">
                {formikRiwayatSertifikasi?.errors.ngrg}
              </div>
            )}
          </Col>

          <Col lg="6">
            <NumberInputComponent
              label="Tahun Sertifikasi"
              placeholder="Masukkan Tahun Sertifikasi"
              required
              formik={formikRiwayatSertifikasi?.getFieldProps(
                "tahun_sertifikasi"
              )}
              value={
                formikRiwayatSertifikasi?.getFieldProps("tahun_sertifikasi")
                  .value ?? ""
              }
            />
            {submitting &&
              formikRiwayatSertifikasi?.errors.tahun_sertifikasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatSertifikasi?.errors.tahun_sertifikasi}
                </div>
              )}
          </Col>
          <Col lg="6">
            <TextInputComponent
              label="Nomor Peserta"
              placeholder="Masukkan Nomor Peserta"
              isRequired
              formik={formikRiwayatSertifikasi?.getFieldProps("nomor_peserta")}
              value={
                formikRiwayatSertifikasi?.getFieldProps("nomor_peserta")
                  .value ?? ""
              }
            />
            {submitting && formikRiwayatSertifikasi?.errors.nomor_peserta && (
              <div className="fs-6 text-danger mt-2">
                {formikRiwayatSertifikasi?.errors.nomor_peserta}
              </div>
            )}
          </Col>
          <Col lg="6">
            <FileInputComponent
              acceptLabel="Format File PDF"
              label="Upload Dokumen"
              acceptedType={[".pdf"]}
              onChange={(e) => {
                if (e.target.files) {
                  formikRiwayatSertifikasi?.setFieldValue(
                    "doc_sertifikasi_pendidik",
                    e.target.files[0]
                  );
                } else
                  formikRiwayatSertifikasi?.setFieldValue(
                    "doc_sertifikasi_pendidik",
                    null
                  );
              }}
              fileNameLabel={
                formikRiwayatSertifikasi?.getFieldProps(
                  "doc_sertifikasi_pendidik"
                ).value?.name ?? doc_sertifikasi_pendidik_name
              }
              resetField={
                formikRiwayatSertifikasi?.getFieldProps(
                  "doc_sertifikasi_pendidik"
                ).value
                  ? false
                  : true
              }
              maxFileSize={25}
              linkPreview={
                typeof formikRiwayatSertifikasi?.getFieldProps(
                  "doc_sertifikasi_pendidik"
                ).value === "string"
                  ? formikRiwayatSertifikasi?.getFieldProps(
                      "doc_sertifikasi_pendidik"
                    ).value
                  : null
              }
              downloadPreview={() =>
                handleDownloadDocRiwayatSertifikasi(
                  formikRiwayatSertifikasi?.getFieldProps(
                    "doc_sertifikasi_pendidik"
                  ).value,
                  doc_sertifikasi_pendidik_name
                )
              }
            />
            {submitting &&
              formikRiwayatSertifikasi?.errors.doc_sertifikasi_pendidik && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatSertifikasi?.errors.doc_sertifikasi_pendidik}
                </div>
              )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className=" d-flex justify-content-end">
        <button type="submit" className="btn btn-sm btn-primary">
          <i className="far fa-floppy-disk me-2"></i>Simpan
        </button>
      </Card.Footer>
    </Form>
  );
};

export default RiwayatSertifikasi;
