import Logo from "../../../../assets/images/icons/logo jundezain.png";

const LogoJundezainRow = () => {
  return (
    <div className="d-flex flex-row align-items-center justify-content-between gap-2">
      Copyright &copy; 2023
      <img src={Logo} width={100} alt=""></img>
    </div>
  );
};

export default LogoJundezainRow;
