import React, { useState, useContext, memo, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";
import { useAuth } from "../../../../provider/core/auth";
import { getChild } from "../../../../helpers/helpers";

function CustomToggle({ children, eventKey, onClick, area_expanded }) {
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    onClick({ state: !isCurrentEventKey, eventKey: eventKey })
  );

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey || area_expanded ? "true" : "false"}
      // aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(e);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo((props) => {
  const [activeMenu, setActiveMenu] = useState(false);
  //location
  let location = useLocation();

  const { currentUser } = useAuth();
  const menus = currentUser?.data?.menus;

  return (
    <Fragment>
      <Accordion
        as="ul"
        className="navbar-nav iq-main-menu"
        style={{
          overflow: "hidden",
        }}
      >
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex={-1}>
            <span className="default-icon">Menu</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        {menus?.map((val, idx) => {
          const childs = getChild(val.id, menus);
          if (childs.length > 0) {
            const route_name = val.route_name ? val.route_name : "";
            const cek = location.pathname.includes(route_name);
            return (
              <Accordion.Item
                as="li"
                eventKey={route_name}
                bsPrefix={`nav-item ${cek ? "active" : ""} `}
                key={idx}
              >
                <CustomToggle
                  eventKey={route_name}
                  onClick={(activeKey) => setActiveMenu(activeKey)}
                  area_expanded={cek}
                >
                  <i className={val.icon} />
                  <span className="item-name">{val.label}</span>
                  <i className="right-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </i>
                </CustomToggle>
                {childs.map((child, child_idx) => {
                  return (
                    <Accordion.Collapse
                      eventKey={route_name}
                      className={cek ? "" : ""}
                      key={child_idx}
                    >
                      <ul className="sub-nav">
                        <li className="nav-item">
                          <Link
                            className={`${
                              location.pathname === child.route_name
                                ? "active"
                                : ""
                            } nav-link`}
                            to={child.route_name}
                          >
                            <i className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <g>
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="8"
                                    fill="currentColor"
                                  ></circle>
                                </g>
                              </svg>
                            </i>
                            <i className="sidenav-mini-icon">
                              {" "}
                              {child.label[0]}{" "}
                            </i>
                            <span className="item-name">{child.label}</span>
                          </Link>
                        </li>
                      </ul>
                    </Accordion.Collapse>
                  );
                })}
              </Accordion.Item>
            );
          } else {
            if (val.route_name && val.parent_id === 0) {
              const route_name: any = val.route_name;
              return (
                <li
                  className={`${
                    location.pathname === route_name ||
                    (location.pathname === "/" && route_name === "/dashboard")
                      ? "active"
                      : ""
                  } nav-item`}
                  key={idx}
                >
                  <Link
                    className={`${
                      location.pathname === route_name ||
                      (location.pathname === "/" && route_name === "/dashboard")
                        ? "active"
                        : ""
                    } nav-link`}
                    aria-current="page"
                    to={route_name}
                  >
                    <i className={val.icon} />
                    <span className="item-name">{val.label}</span>
                  </Link>
                </li>
              );
            }
          }
        })}
      </Accordion>
    </Fragment>
  );
});

export default VerticalNav;
