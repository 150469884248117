import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { initialQueryResponse } from "../../../../models/data-gtk/entry/_queryResponse";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { postDataGTK } from "../../../../api/data-gtk/_request";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { getDetailGTK } from "../../../../api/detail-gtk/_request";
import { useLocation } from "react-router-dom";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const [detailGTK, setDetailGTK] = useState<any>();
  const [useTab, setUseTab] = useState<boolean>(true);
  const handleGetDetailGTK1 = useQueryResponseDetailGTK().handleGetDetailGTK;

  const { pathname } = useLocation();
  const path = pathname.split("/")[1];

  const { mutateAsync: PostDataGTK, isLoading: isLoadingPostDataGTK } =
    useMutation({
      mutationKey: `${QUERIES.POST_DATA_GTK}`,
      mutationFn: ({ payload }: { payload: FormData }) => postDataGTK(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_DATA_GTK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostDataGTK = useCallback(
    async (payload: any) => {
      await PostDataGTK({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed) {
              const nik_gtk = res.data.nik_gtk;
              if (nik_gtk) {
                if (path === "data-gtk")
                  window.location.href = `${process.env.REACT_APP_BASE_URL}data-gtk/form/${nik_gtk}`;
                else handleGetDetailGTK1(nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostDataGTK]
  );

  const { mutateAsync: GetDetailGTK, isLoading: isLoadingGetDetailGTK } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_GTK}`,
      mutationFn: ({ id }: { id: string }) => getDetailGTK(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_GTK}`]);
          setDetailGTK(response);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailGTK = useCallback(
    async (id: string) => {
      await GetDetailGTK({ id }).catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
    },
    [GetDetailGTK]
  );

  const value = useMemo(
    () => ({
      handlePostDataGTK,
      isLoadingPostDataGTK,
      handleGetDetailGTK,
      isLoadingGetDetailGTK,
      detailGTK,
      setDetailGTK,
      useTab,
      setUseTab,
    }),
    [
      handlePostDataGTK,
      isLoadingPostDataGTK,
      handleGetDetailGTK,
      isLoadingGetDetailGTK,
      detailGTK,
      setDetailGTK,
      useTab,
      setUseTab,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
