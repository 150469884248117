import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormPendidikanPelatihanPayload = {
  id: number | string | null;
  id_pelatihan: string | null;
  nik_gtk: string | null;
  jenis_diklat: string | null;
  nama_diklat: string | null;
  penyelenggara: string | null;
  tahun: number | string | null;
  peran: string | null;
  tingkat: string | null;
  berapa_jam: number | string | null;
  doc_sertifikat: File | string | null;
};
export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalPendidikanPelatihan: boolean;
  setShowModalPendidikanPelatihan: Dispatch<SetStateAction<boolean>>;
  formikPendidikanPelatihan?: FormikProps<FormPendidikanPelatihanPayload>;
  formikPendidikanPelatihanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostPendidikanPelatihan: (data: FormData) => void;
  isLoadingPostPendidikanPelatihan: boolean;
  handleGetDetailPendidikanPelatihan: (id: string) => void;
  isLoadingGetDetailPendidikanPelatihan: boolean;
  detailPendidikanPelatihan: FormPendidikanPelatihanPayload;
  setDetailPendidikanPelatihan: Dispatch<
    SetStateAction<FormPendidikanPelatihanPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocPendidikanPelatihan: (url: string, fileName: string) => void;
  isLoadingDownloadDocPendidikanPelatihan: boolean;
  handleDeletePendidikanPelatihan: (id: string) => void;
  isLoadingDeletePendidikanPelatihan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalPendidikanPelatihan: false,
  setShowModalPendidikanPelatihan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostPendidikanPelatihan: () => {},
  isLoadingPostPendidikanPelatihan: false,
  handleGetDetailPendidikanPelatihan: () => {},
  isLoadingGetDetailPendidikanPelatihan: false,
  detailPendidikanPelatihan: {
    id: null,
    id_pelatihan: null,
    nik_gtk: null,
    jenis_diklat: "",
    nama_diklat: "",
    penyelenggara: "",
    tahun: "",
    peran: null,
    tingkat: null,
    berapa_jam: "",
    doc_sertifikat: null,
  },
  setDetailPendidikanPelatihan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocPendidikanPelatihan: () => {},
  isLoadingDownloadDocPendidikanPelatihan: false,
  handleDeletePendidikanPelatihan: () => {},
  isLoadingDeletePendidikanPelatihan: false,
};
