import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";
import { Dayjs } from "dayjs";

const getTotalJenjangBPI = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TOTAL_JENJANG_BPI_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getPerubahanStatusKepegawaian = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_PERUBAHAN_STATUS_KEPEGAWAIAN_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailPerubahanStatusKepegawaian = async (
  query: string
): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_PERUBAHAN_STATUS_KEPEGAWAIAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDashboardTotalGTK = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DASHBOARD_TOTAL_GTK_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDashboardPresensiGTK = async (
  tanggal: Date | Dayjs | string
): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DASHBOARD_PRESENSI_GTK}?tanggal=${tanggal}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailPresensiGTK = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_PRESENSI_GTK_DASHBOARD_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const exportPresensiGTK = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_EXCEL_PRESENSI_GTK_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "SIMBI Dashboard Presensi GTK.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export {
  getTotalJenjangBPI,
  getPerubahanStatusKepegawaian,
  getDetailPerubahanStatusKepegawaian,
  getDashboardTotalGTK,
  getDashboardPresensiGTK,
  getDetailPresensiGTK,
  exportPresensiGTK,
};
