import { ShowLimitDT } from "../../../../hope-ui/components/table/ShowLimit";
import { useQueryRequest } from "../../../../provider/query-request/absensi-gtk";
import { useQueryResponse as useQueryResponseAbsensiHariIni } from "../../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { useAuth } from "../../../../provider/core/auth";
import { SearchInputDT } from "../../../../hope-ui/components/table/SearchInput";
import dayjs from "dayjs";
import Datepicker from "../../../../hope-ui/components/datepicker";
import { Col } from "react-bootstrap";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  const { tanggalAbsen, setTanggalAbsen } = useQueryResponseAbsensiHariIni();

  return (
    <>
      <Col lg={6} className="bg-gray-100 p-3 mx-3">
        <Datepicker
          label="Tanggal"
          required
          value={tanggalAbsen}
          onChange={(e) => {
            const value: any = dayjs(e).format("YYYY-MM-DD");
            setTanggalAbsen(value);
            updateState({ tanggal: value });
          }}
        />
      </Col>

      <div className="row pt-4 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Toolbar;
