import { Card, Col, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/data-gtk";
import { useEffect } from "react";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import CheckInputComponent from "../../../../hope-ui/components/check-input/CheckInputComponent";
import { useQueryRequest } from "../../../../provider/query-request/data-gtk";

const ModalFilter = () => {
  const {
    isShowModal,
    setShowModal,
    handleGetAllTableFilterGTK,
    isLoadingGetAllTableFilterGTK,
    allTableFilterGTK,
    allTableFilterGTKChecked,
    setAllTableFilterGTKChecked,
  } = useQueryResponse();

  const { state, updateState } = useQueryRequest();

  useEffect(() => {
    if (handleGetAllTableFilterGTK && isShowModal) handleGetAllTableFilterGTK();
  }, [handleGetAllTableFilterGTK, isShowModal]);

  useEffect(() => {
    const filter = JSON.stringify(allTableFilterGTKChecked);
    if (allTableFilterGTKChecked.length > 0 && filter !== "[]") {
      updateState({ filter: filter });
    }
  }, [allTableFilterGTKChecked]);

  useEffect(() => {
    const find = allTableFilterGTKChecked.find((e) => e.table_name === "gtk");
    if (allTableFilterGTKChecked.length === 0 && !find) {
      const data: any = [];
      data.push({
        table_name: "gtk",
        column: ["nama_gtk", "nik_gtk", "unit_kerja"],
      });
      //setAllTableFilterGTKChecked(data);
    }
  }, [allTableFilterGTKChecked]);

  return (
    <Modal
      backdrop="static"
      show={isShowModal}
      onBackdropClick={() => {
        setShowModal(false);
      }}
      size="xl"
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filter Daftar GTK </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isLoadingGetAllTableFilterGTK && <FetchLoadingCompanyLogo />}
        {allTableFilterGTK?.map((val, idx) => {
          let table_name = val.table_name;
          if (table_name === "karia_buku")
            table_name = "buku_yang_pernah_ditulis";
          else if (table_name === "riwayat_pendidikan")
            table_name = "pendidikan_terakhir";
          else if (table_name === "penilaian") table_name = "penilaian_/_tes";
          else if (table_name === "riwayat_sertifikasi")
            table_name = "sertifikasi_pendidik";
          else if (table_name === "riwayat_pangkat")
            table_name = "riwayat_kepangkatan_/_golongan";

          if (table_name === "gtk") table_name = "biodata";
          const column = val.column;
          const words = table_name.split("_");
          for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
          }
          table_name = words.join(" ");

          const showColumn = allTableFilterGTKChecked.find(
            (element) => element.table_name === val.table_name
          );

          const current_table_name = val.table_name;

          return (
            <Card className="mb-3 border-0 bg-transparent">
              <Card.Header className="py-3 border border-b-1 bg-light">
                <Card.Title
                  style={{
                    fontSize: "16px",
                  }}
                  className="row"
                >
                  <div className="d-flex flex-row align-items-center gap-2">
                    <label>{idx + 1}.</label>
                    <CheckInputComponent
                      label={` ${table_name}`}
                      onChange={(e) => {
                        let data: any = [...allTableFilterGTKChecked];
                        if (e.target.checked) {
                          data.push({
                            table_name: val.table_name,
                            column: [],
                          });
                        } else {
                          const find = data?.find(
                            (element) => element.table_name === val.table_name
                          );
                          if (find) {
                            const index = data?.indexOf(find);
                            data?.splice(index, 1);
                          }
                        }

                        setAllTableFilterGTKChecked(data);
                      }}
                      id={val.table_name}
                      className="d-flex flex-row align-items-center"
                      labelClassName="mt-1"
                      checked={showColumn ? true : false}
                    />
                  </div>
                </Card.Title>
              </Card.Header>
              {showColumn && (
                <Card.Body className="pb-1">
                  <Card.Header className="py-3 border border-b-1 bg-light">
                    <label className=" text-dark">Nama Kolom</label>
                  </Card.Header>
                  <Row className="px-4 py-3 gx-4">
                    {column.map((val) => {
                      let checked;
                      if (showColumn.column)
                        checked = showColumn.column.find(
                          (element) => element === val
                        );
                      let column = val;
                      const words = column.split("_");
                      for (let i = 0; i < words.length; i++) {
                        words[i] =
                          words[i][0].toUpperCase() + words[i].substr(1);
                      }
                      column = words.join(" ");
                      const current_column = val;
                      return (
                        <Col md="6" lg="4">
                          <CheckInputComponent
                            id={`${table_name}.${val}`}
                            label={column}
                            labelClassName="text-dark text-wrap"
                            onChange={(e) => {
                              let data: any = [...allTableFilterGTKChecked];
                              const index = data.indexOf(showColumn);

                              if (e.target.checked) {
                                data.map((val, idx) => {
                                  if (val.table_name === current_table_name) {
                                    data[index].column.push(current_column);
                                  }
                                });
                              } else {
                                data[index].column = showColumn.column.filter(
                                  (element) => element !== current_column
                                );
                              }

                              setAllTableFilterGTKChecked(data);
                            }}
                            checked={checked ? true : false}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>
              )}
            </Card>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default ModalFilter;
