import { useQueryResponse as useQueryResponseModelCatatan } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { FC } from "react";

type Props = {
  data: any;
  nik_gtk: string;
  tanggal_absen: string;
  id_absensi_gtk: any;
};

const AksiCatatanTerlambatGTK: FC<Props> = ({
  data,
  nik_gtk,
  tanggal_absen,
  id_absensi_gtk,
}) => {
  const { handleCatatanAbsensiGtk, setCatatanGtk, tanggalAbsen } =
    useQueryResponseModelCatatan();

  return (
    <>
      {data.status_absen !== "On Time" ? (
        <button
          type="button"
          className="btn btn-sm btn-info float-start"
          onClick={() => {
            setCatatanGtk(true);
            handleCatatanAbsensiGtk(
              nik_gtk,
              tanggal_absen ?? tanggalAbsen,
              id_absensi_gtk
            );
          }}
        >
          <i className="fa fa-users me-2"></i> Catatan
        </button>
      ) : (
        data.status_absen
      )}
    </>
  );
};

export default AksiCatatanTerlambatGTK;
