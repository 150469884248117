import { FC, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import TextInputComponent from "../../../../../hope-ui/components/text-input/TextInputComponent";
import TextareaComponent from "../../../../../hope-ui/components/textarea-input";
import CheckInputComponent from "../../../../../hope-ui/components/check-input/CheckInputComponent";
import { FormMemoPayload } from "../../../../../models/memo/_queryResponse";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";
import { useQueryResponse } from "../../../../../provider/query-response/memo";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
  nik_pengirim: string;
  nik_tujuan: string;
};

const FormEntry: FC<Props> = ({ activeTab, nik_pengirim, nik_tujuan }) => {
  const { resetForm, setResetForm, handlePostMemo, isLoadingPostMemo } =
    useQueryResponse();
  const [submitting, setSubmitting] = useState(false);
  const [setujuKirim, setSetujuKirim] = useState(false);

  const formikMemoValidationSchema = Yup.object().shape({
    judul_pesan: Yup.string().required("Subject Tidak Boleh Kosong"),
    isi_pesan: Yup.string().required("Isi Pesan Tidak Boleh Kosong"),
  });
  const formikMemo = useFormik<FormMemoPayload>({
    initialValues: {
      id: null,
      id_pesan: null,
      nik_pengirim,
      nik_tujuan,
      judul_pesan: null,
      isi_pesan: null,
      status_pesan: null,
      updatedAt: null,
    },
    enableReinitialize: false,
    validationSchema: formikMemoValidationSchema,
    onSubmit: (values: any) => {
      if (!setujuKirim) {
        return SweetAlert({
          icon: "warning",
          title: "Info",
          text: "Harap Untuk Menyetujui Bahwa Pesan Akan Dikirim",
        });
      }
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostMemo) handlePostMemo(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikMemo.setFieldValue("judul_pesan", null);
      formikMemo.setFieldValue("isi_pesan", null);
      formikMemo.resetForm();
      setSetujuKirim(false);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  return (
    <Form
      onSubmit={(e) => {
        setSubmitting(true);
        formikMemo.submitForm();
        e.preventDefault();
      }}
      style={{
        display: activeTab === 1 ? "block" : "none",
      }}
      className="p-4 pb-0"
    >
      {isLoadingPostMemo && <FetchLoading />}
      <Row className="gy-3">
        <Col xs="12">
          <Form.Group>
            <TextInputComponent
              label="Subject"
              placeholder="Masukkan Subject"
              isRequired
              formik={formikMemo.getFieldProps("judul_pesan")}
              value={formikMemo.getFieldProps("judul_pesan").value ?? ""}
            />
          </Form.Group>
          {submitting && formikMemo.errors.judul_pesan && (
            <div className="text-danger mt-2">
              {formikMemo.errors.judul_pesan}
            </div>
          )}
        </Col>
        <Col xs="12">
          <Form.Group>
            <TextareaComponent
              label="Isi Pesan"
              placeholder="Masukkan Isi Pesan"
              required
              rows={8}
              formik={formikMemo.getFieldProps("isi_pesan")}
              value={formikMemo.getFieldProps("isi_pesan").value ?? ""}
            />
            {submitting && formikMemo.errors.isi_pesan && (
              <div className="text-danger mt-2">
                {formikMemo.errors.isi_pesan}
              </div>
            )}
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group>
            <CheckInputComponent
              id="check_setuju_kirim_pesan"
              label="Setuju Untuk Mengirim Pesan"
              onChange={(e) => {
                setSetujuKirim(setujuKirim ? false : true);
              }}
              checked={setujuKirim}
            />
          </Form.Group>
        </Col>
      </Row>
      <Modal.Footer className="pb-0 pt-3">
        <button type="submit" className="btn btn-primary ">
          Kirim
          <i className="fa-regular fa-paper-plane ms-2"></i>
        </button>
      </Modal.Footer>
    </Form>
  );
};

export default FormEntry;
