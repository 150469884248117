import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useQueryResponse } from "../../../../../provider/query-response/hak-akses/kelola-role-menu";
import { FC } from "react";
import CheckInputComponent from "../../../../../hope-ui/components/check-input/CheckInputComponent";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  data: any;
};

const AksiCell: FC<Props> = ({ data }) => {
  const { handlePostRolesMenu, isLoadingPostRolesMenu, appName, rolesId } =
    useQueryResponse();
  const checked = data.role_menu.length > 0 ? true : false;
  return (
    <div className="d-flex flex-row justify-content-center">
      {isLoadingPostRolesMenu && <FetchLoading />}
      <CheckInputComponent
        id={data.id}
        checked={checked}
        onChange={(e) => {
          const app_name: any = appName;
          const roles_id: any = rolesId;
          const payload = new FormData();
          payload.append("app_name", app_name);
          payload.append("rolesId", roles_id);
          payload.append("menusId", data.id);
          payload.append("status", checked ? "false" : "true");
          if (handlePostRolesMenu) handlePostRolesMenu(payload);
        }}
      />
    </div>
  );
};

export default AksiCell;
