import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/penilaian";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/penilaian";
import ModalPenilaian from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";
const Penilaian = () => {
  const { isLoadingFetchData, listData, isLoadingDeletePenilaian } =
    useQueryResponse();
  return (
    <>
      {isLoadingDeletePenilaian && <FetchLoading />}
      <Toolbar />
      <ModalPenilaian />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default Penilaian;
