import {
  Button,
  ButtonGroup,
  Dropdown,
  Card,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import TextareaComponent from "../../../hope-ui/components/textarea-input";
import Select2 from "../../../hope-ui/components/select-2";
import { useQueryResponse } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { useQueryRequest } from "../../../provider/query-request/absensi-gtk/absensi-hari-ini";
import { useQueryResponse as useQueryResponseModelCatatan } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { FC } from "react";
import GTKCard from "../../../hope-ui/components/gtk-card";
import { useQueryResponse as useQueryResponseAkses } from "../../../provider/query-response/hak-akses";
import FetchLoadingCompanyLogo from "../../../hope-ui/components/fetch-loading-with-company-logo";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import DetailGTK from "../../detail-gtk";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import {
  FormAbsensiGtkPayload,
  FormCatatanGtkPayload,
} from "../../../models/absensi-gtk/daftar-absensi-gtk/_queryResponse";
import InputRadioFullWidth from "../../../hope-ui/components/input-radio-full-width";
import InputRadio from "../../../hope-ui/components/input-radio";
import { useQueryResponse as useQueryResponseCatatanGtk } from "../../../provider/query-response/absensi-gtk/laporan-harian";

const ModelCatatanTerlambatGTK = () => {
  const {
    handleGetAllPrihalAlasan,
    isLoadingGetAllPrihalAlasan,
    allPrihalAlasan,
    setAllPrihalAlasan,
  } = useQueryResponseCatatanGtk();

  const listAlasan: Array<any> = [
    {
      id: "perihal_sakit",
      label: "Sakit",
      value: "Sakit",
    },
    {
      id: "perihal_izin",
      label: "Izin",
      value: "Izin",
    },
    {
      id: "perihal_alfa",
      label: "Alfa",
      value: "Alfa",
    },
  ];

  const listKeterangan: Array<any> = [
    {
      label: "Kemacetan Lalu Lintas",
      value: "Kemacetan Lalu Lintas",
    },
    {
      label: "Motor rusak(pecah ban, mogok, abis bensin)",
      value: "Motor rusak(pecah ban, mogok, abis bensin)",
    },
    {
      label: "Menjalani Cek Kesehatan",
      value: "Menjalani Cek Kesehatan",
    },
    {
      label: "Pertemuan Penting",
      value: "Pertemuan Penting",
    },
    {
      label: "Keadaan Darurat",
      value: "Keadaan Darurat",
    },
    {
      label: "Barang Ketinggalan",
      value: "Barang Ketinggalan",
    },
    {
      label: "Sakit ringan",
      value: "Sakit ringan",
    },
  ];

  const { state, updateState } = useQueryRequest();
  const {
    formikCatatanGTK,
    handleCatatanGtk,
    handlePutCatatanGtk,
    handlePostCatatanGtk,
    DetailAbsensiGtk,
    DetailCatatanAbsensiGtk,
    handleCatatanAbsensiGtk,
    isLoadingGetDetailCatatanGTK,
    nikGTK,
    setCatatanGtk,
    CatatanGtk,
    isLoadingGetDetailGTK,
    isLoadingPutCatatanGtk,
    isLoadingPostCatatanGtk,
    submitting,
    resetForm,
    setSubmitting,
    setResetForm,
  } = useQueryResponseModelCatatan();

  const [Keterangan, setKeterangan] = useState(false);

  const formikMemoValidationSchema = Yup.object().shape({
    keterangan: Yup.string().required("Keterangan Tidak Boleh Kosong"),
  });
  const formikCatat = useFormik<FormCatatanGtkPayload>({
    initialValues: DetailCatatanAbsensiGtk,
    enableReinitialize: false,
    validationSchema: formikMemoValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key] !== "null" && values[key]);
      });
      handlePostCatatanGtk(payload);
    },
  });

  useEffect(() => {
    if (DetailCatatanAbsensiGtk) {
      setSubmitting(false);
      setResetForm(false);

      formikCatat?.setValues(DetailCatatanAbsensiGtk);
    }
  }, [DetailCatatanAbsensiGtk]);

  const ListPrihal = allPrihalAlasan.map((val) => {
    return {
      label: val.alasan,
      value: val.alasan,
    };
  });

  useEffect(() => {
    if (CatatanGtk) {
      handleGetAllPrihalAlasan();
    } else setSubmitting(false);
  }, [CatatanGtk, handleGetAllPrihalAlasan]);

  return (
    <>
      <Modal
        backdrop="static"
        show={CatatanGtk}
        onBackdropClick={() => {
          setCatatanGtk(false);
          setResetForm(true);
        }}
        size="xl"
        onHide={() => {
          setCatatanGtk(false);
          setResetForm(true);
        }}
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setResetForm(true);
          }}
        >
          <Modal.Title>Catatan GTK</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={(e) => {
              setSubmitting(true);
              formikCatat.submitForm();
              e.preventDefault();
            }}
          >
            {isLoadingPostCatatanGtk && <FetchLoading />}

            {!DetailCatatanAbsensiGtk.id_absensi_gtk && (
              <Col lg="12">
                <Form.Group>
                  <InputRadio
                    label="Perihal"
                    data={listAlasan}
                    required
                    value={formikCatat.getFieldProps("status_absen").value}
                    onChange={(e) => {
                      const value = e.target.value;
                      formikCatat.setFieldValue("status_absen", value);
                    }}
                  />
                  {submitting && formikCatat?.errors.status_absen && (
                    <div className="fs-6 text-danger mt-2">
                      {formikCatat?.errors.status_absen}
                    </div>
                  )}
                </Form.Group>
              </Col>
            )}

            <Col lg="12">
              <Select2
                label="Keterangan Absen"
                placeholder="-- Pilih Alasan --"
                options={ListPrihal}
                isLoading={isLoadingGetAllPrihalAlasan}
                isRequired
                onChange={(e: any) => {
                  const value = e?.value;
                  formikCatat?.setFieldValue("keterangan", value);
                }}
                value={formikCatat?.getFieldProps("keterangan").value}
              />

              {submitting && formikCatat?.errors.keterangan && (
                <div className="fs-6 text-danger mt-2">
                  {formikCatat?.errors.keterangan}
                </div>
              )}
            </Col>

            <Col lg="12" className="mt-3">
              <Button type="submit" className="btn btn-primary float-end m-1">
                <i className="fa fa-file-import me-2"></i>
                Save
              </Button>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModelCatatanTerlambatGTK;
