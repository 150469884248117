import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormDataGTKPayload } from "../_queryResponse";

export type QueryResponseContextProps<T> = {
  handlePostDataGTK?: (data: FormData) => void;
  isLoadingPostDataGTK: boolean;
  handleGetDetailGTK?: (id: string) => void;
  isLoadingGetDetailGTK: boolean;
  detailGTK?: Response<T>;
  setDetailGTK: Dispatch<SetStateAction<FormDataGTKPayload>>;
  useTab?: boolean;
  setUseTab: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingPostDataGTK: false,
  isLoadingGetDetailGTK: false,
  setDetailGTK: () => {},
  setUseTab: () => {},
};
