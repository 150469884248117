import { FC, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { Columns } from "./list/columns/column";
import Toolbar from "./toolbar";
import { useFormik } from "formik";
import { FormAnggotaKelompokLT3QPayload } from "../../../../../models/absensi-lt3q/anggota-kelompok-lt3q/_queryResponse";
import * as Yup from "yup";
import { useAuth } from "../../../../../provider/core/auth";
import { useQueryResponse as useQueryResponseAbsensiLT3Q } from "../../../../../provider/query-response/absensi-lt3q";
import { useQueryResponse as useQueryResponseDataGTK } from "../../../../../provider/query-response/data-gtk";
import FetchLoading from "../../../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab: number;
};

const KelolaAnggotaKelompokLT3Q: FC<Props> = ({ activeTab }) => {
  const listDataGTK = useQueryResponseDataGTK().listData;
  let optionsDataGTK: any = listDataGTK?.data?.map((val) => {
    return {
      value: val.nik_gtk,
      label: `${val.nik_gtk} - ${val.nama_gtk}`,
    };
  });
  const isLoadingFetchDataDataGTK =
    useQueryResponseDataGTK().isLoadingFetchData;

  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiLT3Q();
  const {
    isLoadingFetchData,
    listData,
    allKelompokLT3Q,
    handleGetAllKelompokLT3Q,
    isLoadingGetAllKelompokLT3Q,
    handlePostAnggotaKelompokLT3Q,
    isLoadingPostAnggotaKelompokLT3Q,
    resetForm,
    setResetForm,
    resetDetailAnggotaKelompokLT3Q,
    detailAnggotaKelompokLT3Q,
    isLoadingGetDetailAnggotaKelompokLT3Q,
  } = useQueryResponse();

  let optionsKelompokLT3Q: any = allKelompokLT3Q.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_lt3q,
    };
  });

  const Data_table = listData?.data;
  const [submitting, setSubmitting] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      isShowModalTentukanKelompok &&
      activeTab === 2
    ) {
      const nik_gtk: any = currentUser.data.username;
      formikAnggotaKelompokLT3Q.setFieldValue("nik_tutor_lt3q", nik_gtk);
      if (handleGetAllKelompokLT3Q) handleGetAllKelompokLT3Q(nik_gtk);
    }
  }, [
    currentUser,
    isShowModalTentukanKelompok,
    handleGetAllKelompokLT3Q,
    activeTab,
  ]);

  const formikAnggotaKelompokLT3QValidationSchema = Yup.object().shape({
    id_kelompok_lt3q: Yup.string().required("Kelompok LT3Q Tidak Boleh Kosong"),
    nik_gtk: Yup.string().required("GTK Tidak Boleh Kosong"),
  });
  const formikAnggotaKelompokLT3Q = useFormik<FormAnggotaKelompokLT3QPayload>({
    initialValues: {
      id: null,
      id_kelompok_lt3q: null,
      nik_gtk: null,
      nik_tutor_lt3q: null,
    },
    enableReinitialize: false,
    validationSchema: formikAnggotaKelompokLT3QValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostAnggotaKelompokLT3Q) handlePostAnggotaKelompokLT3Q(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikAnggotaKelompokLT3Q.setFieldValue("id", null);
      formikAnggotaKelompokLT3Q.setFieldValue("id_kelompok_lt3q", null);
      formikAnggotaKelompokLT3Q.setFieldValue("nik_gtk", null);
      formikAnggotaKelompokLT3Q.setFieldValue("level_lt3q", null);
      setSubmitting(false);
      setResetForm(false);
      resetDetailAnggotaKelompokLT3Q();
    }
  }, [resetForm]);

  useEffect(() => {
    if (detailAnggotaKelompokLT3Q && detailAnggotaKelompokLT3Q.id) {
      formikAnggotaKelompokLT3Q.setValues(detailAnggotaKelompokLT3Q);
    }
  }, [detailAnggotaKelompokLT3Q]);

  return (
    <Col
      style={{
        display: activeTab === 2 ? "block" : "none",
      }}
      className="p-4"
    >
      {(isLoadingPostAnggotaKelompokLT3Q ||
        isLoadingGetDetailAnggotaKelompokLT3Q) && <FetchLoading />}
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikAnggotaKelompokLT3Q.submitForm();
          e.preventDefault();
        }}
      >
        <h6>Tambah Data Anggota Kelompok LT3Q</h6>
        <hr className="bg-light" />
        <Row className="gy-3 mb-3">
          <Col lg="6">
            <Form.Group>
              <Select2
                label="Kelompok LT3Q"
                placeholder="Pilih Kelompok LT3Q"
                isRequired
                isLoading={isLoadingGetAllKelompokLT3Q}
                options={optionsKelompokLT3Q}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokLT3Q.setFieldValue(
                    "id_kelompok_lt3q",
                    value
                  );
                }}
                value={
                  formikAnggotaKelompokLT3Q.getFieldProps("id_kelompok_lt3q")
                    .value
                }
              />
              {submitting &&
                formikAnggotaKelompokLT3Q.errors.id_kelompok_lt3q && (
                  <div className="text-danger mt-2">
                    {formikAnggotaKelompokLT3Q.errors.id_kelompok_lt3q}
                  </div>
                )}
            </Form.Group>
          </Col>

          <Col lg="6">
            <Form.Group>
              <Select2
                label="GTK"
                placeholder="Pilih GTK"
                isRequired
                options={optionsDataGTK}
                isLoading={isLoadingFetchDataDataGTK}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnggotaKelompokLT3Q.setFieldValue("nik_gtk", value);
                }}
                disabled={
                  detailAnggotaKelompokLT3Q && detailAnggotaKelompokLT3Q.id
                    ? true
                    : false
                }
                value={formikAnggotaKelompokLT3Q.getFieldProps("nik_gtk").value}
              />
              {submitting && formikAnggotaKelompokLT3Q.errors.nik_gtk && (
                <div className="text-danger mt-2">
                  {formikAnggotaKelompokLT3Q.errors.nik_gtk}
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Card.Footer
          className={`p-3 d-flex flex-row justify-content-${
            detailAnggotaKelompokLT3Q?.id ? "between" : "end"
          }`}
        >
          {detailAnggotaKelompokLT3Q?.id && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => setResetForm(true)}
            >
              <i className="fas fa-undo me-2"></i>
              Reset Form
            </button>
          )}

          <button
            type="submit"
            className={`btn btn-${
              detailAnggotaKelompokLT3Q?.id ? "dark" : "primary"
            } btn-sm `}
          >
            <i
              className={`far fa-${
                detailAnggotaKelompokLT3Q?.id ? "edit" : "floppy-disk"
              } me-2`}
            ></i>
            {detailAnggotaKelompokLT3Q?.id ? "Edit" : "Simpan"}
          </button>
        </Card.Footer>
      </Form>

      <Col className="mt-4">
        <h6>Daftar Anggota Kelompok LT3Q</h6>
        <hr className="bg-light" />
        <Toolbar />
        <DataTable
          isLoading={isLoadingFetchData}
          data={Data_table}
          header={Columns}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Col>
    </Col>
  );
};

export default KelolaAnggotaKelompokLT3Q;
