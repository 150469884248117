import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";
import moment from "moment";

const getAbsensiGTK = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ABSENSI_HARI_INI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAbsensiGtk = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ABSENSI_GTK_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailCatatanAbsensiGtk = async (
  nik_gtk: string,
  tanggal_absen: string,
  id_absensi_gtk?: string
): Promise<any> => {
  return client
    .get(
      `${ApiConfig.GET_DETAIL_CATATAN_ABSENSI_GTK_URL}?nik_gtk=${nik_gtk}&tanggal_absen=${tanggal_absen}&id_absensi_gtk=${id_absensi_gtk}`
    )
    .then((response: AxiosResponse<any>) => response.data);
};

const putCatatanGtk = async (data: FormData, id: string): Promise<any> => {
  return client
    .put(`${ApiConfig.PUT_CATATAN_GTK_URL}/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const postCatatanGtk = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_CATATAN_GTK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getLaporanAbsensiHarian = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LAPORAN_ABSENSI_HARIAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getGtkTidakHadir = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_GTK_TIDAK_HADIR_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getLaporanAbsensiBulanan = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LAPORAN_ABSENSI_BULANAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postImportPresensiGTK = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_IMPORT_PRESENSI_GTK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const exportExcelAbsensiGTKInput = async (): Promise<any> => {
  return axios({
    url: `${ApiConfig.GET_EXPORT_INPUT_EXCEL_URL}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "Presensi GTK.xlsx"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

const getRekapAbsensiGTK = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_REKAP_ABSENSI_GTK_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const exportRekapPresensiGTK = async (query: string): Promise<any> => {
  return axios({
    url: `${ApiConfig.EXPORT_REKAP_ABSENSI_GTK_URL}?${query}`, //your url
    method: "GET",
    responseType: "blob", // important
    baseURL: process.env.REACT_APP_SIMBI_ENDPOINT_URL,
    timeout: 60000,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  }).then((response: AxiosResponse<any>) => {
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `Rekap Presensi GTK ${moment().format("DD MM YYYY")}.xlsx`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export {
  getAbsensiGTK,
  getLaporanAbsensiHarian,
  getDetailCatatanAbsensiGtk,
  getLaporanAbsensiBulanan,
  exportExcelAbsensiGTKInput,
  postImportPresensiGTK,
  getDetailAbsensiGtk,
  putCatatanGtk,
  getGtkTidakHadir,
  postCatatanGtk,
  getRekapAbsensiGTK,
  exportRekapPresensiGTK,
};
