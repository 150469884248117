import { Card, Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useLayout } from "../../layout/core/LayoutProvider";
import { useEffect } from "react";
import { FilterUnitKerjaListWrapper } from "../../modules/filter-unit-kerja";

const FilterUnitKerja = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Filter Unit Kerja";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Filter Unit Kerja";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <FilterUnitKerjaListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FilterUnitKerja;
