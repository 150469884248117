import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-lt3q/daftar-absensi";
import { initialQueryResponse } from "../../../../models/absensi-lt3q/daftar-absensi/_queryResponse";
import {
  deleteAbsensiLT3Q,
  getAbsensiLT3Q,
} from "../../../../api/absensi-lt3q/_request";
import { useQueryResponse as useQueryResponseAbsesnsiLT3Q } from "../../absensi-lt3q";
import { useAuth } from "../../../core/auth";
//import { useQueryResponse as useQueryResponseLaporanBulanan } from "../../absensi-lt3q/laporan-bulanan";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["riwayat_tanggal", "kelompok_lt3q.nama_kelompok_lt3q", "materi", "tempat", "batas_tilawah_halaman", "infaq"]`;
  const { currentUser } = useAuth();
  state.nik_gtk_tutor = currentUser?.data?.username;

  const { activeTab } = useQueryResponseAbsesnsiLT3Q();

  // const refetchListDataLaporanBulanan =
  //   useQueryResponseLaporanBulanan().refetchListData;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "absensi-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "absensi-lt3q"),
    [state]
  );

  const [bulan, setBulan] = useState<number | string | null>(null);
  const [tahun, setTahun] = useState<number | null>(null);
  const [idKelompokLT3Q, setIdKelompokLT3Q] = useState<Array<any>>([]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_ABSENSI_LT3Q}-${query}`,
    () => getAbsensiLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: activeTab === 1,
    }
  );

  const {
    mutateAsync: DeleteAbsensiLT3Q,
    isLoading: isLoadingDeleteAbsensiLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.POST_BUAT_ABSENSI_LT3Q}`,
    mutationFn: ({ id }: { id: string }) => deleteAbsensiLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_BUAT_ABSENSI_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteAbsensiLT3Q = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (response) => {
        if (response.isConfirmed) {
          await DeleteAbsensiLT3Q({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                html: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                  // if (refetchListDataLaporanBulanan)
                  //   refetchListDataLaporanBulanan();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteAbsensiLT3Q]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      bulan,
      setBulan,
      tahun,
      setTahun,
      idKelompokLT3Q,
      setIdKelompokLT3Q,
      refetchListData,
      handleDeleteAbsensiLT3Q,
      isLoadingDeleteAbsensiLT3Q,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      bulan,
      setBulan,
      tahun,
      setTahun,
      idKelompokLT3Q,
      setIdKelompokLT3Q,
      refetchListData,
      handleDeleteAbsensiLT3Q,
      isLoadingDeleteAbsensiLT3Q,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
