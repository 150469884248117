import { AxiosResponse } from "axios";
import { client } from "../../apiClient";
import { ApiConfig } from "../../apiConfig";

const getStatusAktivasiAktif = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_STATUS_AKTIVASI_AKTIF_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postAktifkanGTK = async (payload: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_AKTIFKAN_GTK_URL}`, payload)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getStatusAktivasiAktif, postAktifkanGTK };
