import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormRiwayatSertifikasiPayload = {
  id: number | string | null;
  id_sertifikasi: string | null;
  nik_gtk: string | null;
  jenis_sertifikasi: string | null;
  nomor_sertifikasi: string | null;
  bidang_studi: string | null;
  tahun_sertifikasi: number | string | null;
  ngrg: string | null;
  nomor_peserta: string | null;
  jumlah_juz: number | string | null;
  doc_sertifikasi_pendidik: File | string | null;
};

export type QueryResponseContextProps<T> = {
  formikRiwayatSertifikasi?: FormikProps<FormRiwayatSertifikasiPayload>;
  formikRiwayatSertifikasiValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostRiwayatSertifikasi: (data: FormData) => void;
  isLoadingPostRiwayatSertifikasi: boolean;
  jenisSertifikasi: any;
  setJenisSertifikasi: Dispatch<SetStateAction<any>>;
  handleDownloadDocRiwayatSertifikasi: (url: string, fileName: string) => void;
  isLoadingDownloadDocRiwayatSertifikasi: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostRiwayatSertifikasi: false,
  handlePostRiwayatSertifikasi: () => {},
  jenisSertifikasi: null,
  setJenisSertifikasi: () => {},
  handleDownloadDocRiwayatSertifikasi: () => {},
  isLoadingDownloadDocRiwayatSertifikasi: false,
};
