import { Card } from "react-bootstrap";
import Toolbar from "./toolbar/index.";
import {
  useQueryResponse,
  QueryResponseProvider,
  useQueryResponsePagination,
} from "../../../../provider/query-response/hak-akses/kelola-role";
import {
  useQueryRequest,
  QueryRequestProvider,
} from "../../../../provider/query-request/hak-akses/kelola-role";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns";
import { useQueryResponse as useQueryResponseHakAkses } from "../../../../provider/query-response/hak-akses";

const KelolaRole = () => {
  const { listData, isLoadingFetchData } = useQueryResponse();
  const Data_table = listData?.data;
  const { activeTab } = useQueryResponseHakAkses();
  return (
    <div
      style={{
        display: activeTab === 1 ? "block" : "none",
      }}
    >
      <Toolbar />
      <Card.Body>
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={Data_table}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </div>
  );
};

export const KelolaRoleWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <KelolaRole></KelolaRole>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
