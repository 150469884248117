import { Fragment, useEffect } from "react";
import { Card } from "react-bootstrap";
import Toolbar from "./toolbar";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns/columns";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../../provider/query-request/data-gtk";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/data-gtk";

import {
  QueryRequestProvider as QueryRequestProviderMemo,
  useQueryRequest as useQueryRequestMemo,
} from "../../../provider/query-request/memo";
import { QueryResponseProvider as QueryResponseProviderMemo } from "../../../provider/query-response/memo";

import FetchLoading from "../../../hope-ui/components/fetch-loading";
import ToolbarChecklistFilter from "./toolbar-checklist-filter";
import ModalFilter from "./modal-filter";
import ModalMemo from "./modal-memo";
import { QueryResponseProvider as QueryResponseProviderHakAkses } from "../../../provider/query-response/hak-akses";
import ModalHakAksesUser from "./modal-hak-akses-user";
import ModalNonAktifkanGTK from "./modal-non-aktifkan-gtk";

const DataGTKList = () => {
  const {
    listData,
    isLoadingFetchData,
    isLoadingPutDataGTK,
    isLoadingResetPasswordGTK,
  } = useQueryResponse();
  const Data_table = listData?.data;

  return (
    <Fragment>
      {(isLoadingPutDataGTK || isLoadingResetPasswordGTK) && <FetchLoading />}
      {/* <Toolbar /> */}
      <ToolbarChecklistFilter />
      <ModalFilter />
      <ModalMemo />
      <ModalHakAksesUser />
      <ModalNonAktifkanGTK />
      <Card.Body className="pt-0">
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={Data_table}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </Fragment>
  );
};

export const DataGTKListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryRequestProviderMemo>
        <QueryResponseProviderMemo>
          <QueryResponseProviderHakAkses>
            <DataGTKList />
          </QueryResponseProviderHakAkses>
        </QueryResponseProviderMemo>
      </QueryRequestProviderMemo>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
