import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type FormKelompokBPIPayload = {
  id: number | null;
  nama_kelompok_bpi: string | null;
  nik_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  handlePostKelompokBPI?: (data: FormData) => void;
  isLoadingPostKelompokBPI: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetDetailKelompokBPI?: (id: string) => void;
  isLoadingGetDetailKelompokBPI: boolean;
  handleDeleteKelompokBPI?: (id: string) => void;
  isLoadingDeleteKelompokBPI?: boolean;
  detailKelompokBPI?: FormKelompokBPIPayload;
  setDetailKelompokBPI: Dispatch<SetStateAction<FormKelompokBPIPayload>>;
  resetDetailKelompokBPI: () => void;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostKelompokBPI: false,
  resetForm: false,
  setResetForm: () => {},
  isLoadingGetDetailKelompokBPI: false,
  isLoadingDeleteKelompokBPI: false,
  setDetailKelompokBPI: () => {},
  resetDetailKelompokBPI: () => {},
};
