import { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../layout/core/LayoutProvider";
import { HakAksesWrapper } from "../../modules/hak-akses";

const HakAkses = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Hak Akses";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Hak Akses";
      return jasper;
    });
  }, []);

  return (
    <Row>
      <Col md="12" lg="12">
        <Card>
          <HakAksesWrapper />
        </Card>
      </Col>
    </Row>
  );
};

export default HakAkses;
