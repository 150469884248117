import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListTugasTambahan = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_TUGAS_TAMBAHAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postTugasTambahan = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_TUGAS_TAMBAHAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailTugasTambahan = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_TUGAS_TAMBAHAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocTugasTambahan = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deleteTugasTambahan = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_TUGAS_TAMBAHAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListTugasTambahan,
  postTugasTambahan,
  getDetailTugasTambahan,
  downloadDocTugasTambahan,
  deleteTugasTambahan,
};
