import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type FormImportGTKPayload = {
  file: File[] | null;
};

export type QueryResponseContextProps<T> = {
  handlePostImportGTK?: (data: FormData) => void;
  isLoadingPostImportGTK: boolean;
  setGagalImportGTK: Dispatch<SetStateAction<Response<Array<T>>>>;
  gagalImportGTK: Array<any>;
  handleDownloadTemplateImportGTK?: () => void;
  isLoadingDownloadTemplateImportGTK: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingPostImportGTK: false,
  isLoadingDownloadTemplateImportGTK: false,
  setGagalImportGTK: () => {},
  gagalImportGTK: [],
};
