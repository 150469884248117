import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListKesejahteraan = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_KESEJAHTERAAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postKesejahteraan = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_KESEJAHTERAAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailKesejahteraan = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_KESEJAHTERAAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocKesejahteraan = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deleteKesejahteraan = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_KESEJAHTERAAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListKesejahteraan,
  postKesejahteraan,
  getDetailKesejahteraan,
  downloadDocKesejahteraan,
  deleteKesejahteraan,
};
