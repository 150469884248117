import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormKaryaTulisPayload = {
  id: number | string | null;
  id_karya_tulis: string | null;
  nik_gtk: string | null;
  judul: string | null;
  tahun_pembuatan: number | string | null;
  publikasi: string | null;
  keterangan: string | null;
  url: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalKaryaTulis: boolean;
  setShowModalKaryaTulis: Dispatch<SetStateAction<boolean>>;
  formikKaryaTulis?: FormikProps<FormKaryaTulisPayload>;
  formikKaryaTulisValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostKaryaTulis: (data: FormData) => void;
  isLoadingPostKaryaTulis: boolean;
  handleGetDetailKaryaTulis: (id: string) => void;
  isLoadingGetDetailKaryaTulis: boolean;
  detailKaryaTulis: FormKaryaTulisPayload;
  setDetailKaryaTulis: Dispatch<SetStateAction<FormKaryaTulisPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocKaryaTulis: (url: string, fileName: string) => void;
  isLoadingDownloadDocKaryaTulis: boolean;
  handleDeleteKaryaTulis: (id: string) => void;
  isLoadingDeleteKaryaTulis: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalKaryaTulis: false,
  setShowModalKaryaTulis: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostKaryaTulis: () => {},
  isLoadingPostKaryaTulis: false,
  handleGetDetailKaryaTulis: () => {},
  isLoadingGetDetailKaryaTulis: false,
  detailKaryaTulis: {
    id: null,
    id_karya_tulis: null,
    nik_gtk: null,
    judul: "",
    tahun_pembuatan: "",
    keterangan: "",
    publikasi: "",
    url: "",
  },
  setDetailKaryaTulis: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocKaryaTulis: () => {},
  isLoadingDownloadDocKaryaTulis: false,
  handleDeleteKaryaTulis: () => {},
  isLoadingDeleteKaryaTulis: false,
};
