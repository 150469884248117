import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";

export type FormRolesPayload = {
  id: number | null;
  name: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handlePostHakAkses?: (data: FormData) => void;
  isLoadingPostHakAkses?: boolean;
  formikRoles?: FormikProps<FormRolesPayload>;
  formikRolesValidationSchema?: any;
  isShowModal?: boolean;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
  resetForm?: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  submitting?: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  detailRoles: FormRolesPayload;
  setDetailRoles: Dispatch<SetStateAction<FormRolesPayload>>;
  handleGetDetailHakAkses?: (id: string) => void;
  isLoadingGetDetailHakAkses?: boolean;
  handleDeleteHakAkses?: (id: string) => void;
  isLoadingDeleteHakAkses: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  refetchListData: () => {},
  setIsShowModal: () => {},
  setResetForm: () => {},
  setSubmitting: () => {},
  detailRoles: {
    id: null,
    name: "",
  },
  setDetailRoles: () => {},
  isLoadingGetDetailHakAkses: false,
  isLoadingDeleteHakAkses: false,
};
