import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
  Table,
} from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/kepegawaian";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import moment from "moment";
import GTKCard from "../../../../hope-ui/components/gtk-card";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const ModalDetailKepegawaian = () => {
  const {
    showModalDetailKepegawaian,
    setShowModalDetailKepegawaian,
    handleDeleteKepegawaian,
    isLoadingDeleteKepegawaian,
  } = useQueryResponse();
  const { isLoadingGetDetailGTK, detailGTK } = useQueryResponseDetailGTK();
  const kepegawaian: any = detailGTK?.kepegawaian;

  let i = 0;
  return (
    <Modal
      backdrop="static"
      show={showModalDetailKepegawaian}
      onBackdropClick={() => {
        setShowModalDetailKepegawaian(false);
      }}
      size="lg"
      onHide={() => {
        setShowModalDetailKepegawaian(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Kelola Kepegawaian</Modal.Title>
      </Modal.Header>
      {isLoadingGetDetailGTK && <FetchLoadingCompanyLogo />}
      {isLoadingDeleteKepegawaian && <FetchLoading />}
      {!isLoadingGetDetailGTK && (
        <Modal.Body>
          <GTKCard data={detailGTK} />
          <div className="d-flex justify-content-end align-items-center w-100 pb-3">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BASE_URL}data-gtk/kepegawaian/form/${detailGTK?.nik_gtk}`
                );
              }}
            >
              <i className="far fa-add me-2"></i>
              Tambah Kepegawaian
            </button>
          </div>

          <Table responsive className="fs-6" data-toggle="data-table">
            <thead>
              <tr>
                <td>No.</td>
                <td>Aksi</td>
                <td>No. SK</td>
                <td>NIY NIGK</td>
                <td>NUPTK</td>
                <td>Jenis PTK</td>
                <td>Status Golongan</td>
                <td>TMT Pengangkatan</td>
                <td>Tgl Berakhir SK</td>
                <td>Kompetensi Khusus</td>
                <td>Nilai Teori</td>
                <td>Nilai Praktek</td>
                <td>Jumlah Juz</td>
                <td>Toefl</td>
              </tr>
            </thead>
            <tbody>
              {kepegawaian &&
                kepegawaian.map((val) => {
                  i++;
                  return (
                    <tr>
                      <td>{i}</td>
                      <td>
                        <DropdownButton
                          as={ButtonGroup}
                          key={"end"}
                          drop={"end"}
                          variant="dark"
                          title={` Pilih Aksi `}
                          size="sm"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={(e) => {}}
                            className="cursor-pointer"
                          >
                            <div
                              className="fs-6"
                              onClick={() => {
                                window.open(
                                  `${process.env.REACT_APP_BASE_URL}data-gtk/kepegawaian/form/${detailGTK?.nik_gtk}/${val.id}`
                                );
                              }}
                            >
                              Edit <i className="fa fa-pen text-info ms-1"></i>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2"
                            onClick={(e) => {
                              const id: any = val.id;
                              if (handleDeleteKepegawaian)
                                handleDeleteKepegawaian(id);
                            }}
                            className="cursor-pointer"
                          >
                            <div className="fs-6">
                              Hapus{" "}
                              <i className="fa fa-trash text-warning ms-1"></i>
                            </div>
                          </Dropdown.Item>
                        </DropdownButton>
                      </td>
                      <td>{val.nomor_sk_pengangkatan}</td>
                      <td>{val.niy_nigk}</td>
                      <td>{val.nuptk}</td>
                      <td>{val.jenis_ptk}</td>
                      <td>{val.status_golongan_kepegawaian}</td>
                      <td>
                        {moment(val.tmt_pengangkatan).format("DD MMM YYYY")}
                      </td>
                      <td>
                        {moment(val.masa_berlaku_sk).format("DD MMM YYYY")}
                      </td>
                      <td>{val.kompetensi_khusus}</td>
                      <td>{val.nilai_teori}</td>
                      <td>{val.nilai_praktek}</td>
                      <td>{val.jumlah_juz}</td>
                      <td>{val.toefl}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ModalDetailKepegawaian;
