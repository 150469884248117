import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getAbsensiLT3Q = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ABSENSI_LT3Q_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getLaporanAbsensiLT3Q = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LAPORAN_ABSENSI_LT3Q_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllKelompokLT3Q = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_KELOMPOK_LT3Q_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKelompokLT3Q = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KELOMPOK_LT3Q_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailKelompokLT3Q = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_KELOMPOK_LT3Q_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteKelompokLT3Q = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_KELOMPOK_LT3Q_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postKelompokLT3Q = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_KELOMPOK_LT3Q_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAnggotaKelompokLT3Q = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ANGGOTA_KELOMPOK_LT3Q_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getListAnggotaKelompokLT3Q = async (
  id_kelompok_lt3q: string
): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ANGGOTA_KELOMPOK_LT3Q_URL}/${id_kelompok_lt3q}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAnggotaKelompokLT3Q = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ANGGOTA_KELOMPOK_LT3Q_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteAnggotaKelompokLT3Q = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ANGGOTA_KELOMPOK_LT3Q_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postAnggotaKelompokLT3Q = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_ANGGOTA_KELOMPOK_LT3Q_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllMC = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_MC_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKehadiranLT3Q = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KEHADIRAN_LT3Q_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postBuatAbsensiLT3Q = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_BUAT_ABSENSI_LT3Q_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAbsensiLT3Q = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ABSENSI_LT3Q_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getTotalInfaq = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TOTAL_INFAQ_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteAbsensiLT3Q = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ABSENSI_LT3Q_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getPindahTutorLT3Q = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_PINDAH_TUTOR_LT3Q_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postPindahTutorLT3Q = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_PINDAH_TUTOR_LT3Q_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getTutorLT3Q = async (nik_gtk: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_TUTOR_LT3Q_URL}/${nik_gtk}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllNilaiTahsin = async (group?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_NILAI_TAHSIN_URL}?group_by=${group}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postNilaiTahsin = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_NILAI_TAHSIN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getAbsensiLT3Q,
  getLaporanAbsensiLT3Q,
  getKelompokLT3Q,
  postKelompokLT3Q,
  getDetailKelompokLT3Q,
  getAnggotaKelompokLT3Q,
  postAnggotaKelompokLT3Q,
  getDetailAnggotaKelompokLT3Q,
  getAllKelompokLT3Q,
  deleteKelompokLT3Q,
  deleteAnggotaKelompokLT3Q,
  getAllMC,
  getKehadiranLT3Q,
  postBuatAbsensiLT3Q,
  getDetailAbsensiLT3Q,
  getTotalInfaq,
  deleteAbsensiLT3Q,
  getListAnggotaKelompokLT3Q,
  getPindahTutorLT3Q,
  postPindahTutorLT3Q,
  getTutorLT3Q,
  getAllNilaiTahsin,
  postNilaiTahsin,
};
