import { Card } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../provider/query-response/hak-akses";
import { KelolaRoleWrapper } from "./kelola-role/list";
import { QueryRequestProvider as QueryRequestProviderKelolaRole } from "../../provider/query-request/hak-akses/kelola-role";
import { QueryResponseProvider as QueryResponseProviderKelolaRole } from "../../provider/query-response/hak-akses/kelola-role";
import { KelolaRoleMenuWrapper } from "./kelola-role-menu/list";
import { QueryRequestProvider as QueryRequestProviderKelolaRoleMenu } from "../../provider/query-request/hak-akses/kelola-role-menu";
import { QueryResponseProvider as QueryResponseProviderKelolaRoleMenu } from "../../provider/query-response/hak-akses/kelola-role-menu";
import { QueryResponseProvider as QueryResponseProviderMenus } from "../../provider/query-response/menu";
import { QueryRequestProvider as QueryRequestProviderMenus } from "../../provider/query-request/menu";

const HakAkses = () => {
  const { activeTab, setActiveTab } = useQueryResponse();
  return (
    <>
      <Card.Header className="border-bottom py-3">
        <button
          type="button"
          className={` ${
            activeTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Kelola Level Akses
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 2
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Kelola Hak Akses Menu
        </button>
      </Card.Header>
      <KelolaRoleWrapper />
      <KelolaRoleMenuWrapper />
    </>
  );
};

export const HakAksesWrapper = () => (
  <QueryResponseProvider>
    <QueryRequestProviderKelolaRole>
      <QueryResponseProviderKelolaRole>
        <QueryRequestProviderKelolaRoleMenu>
          <QueryResponseProviderKelolaRoleMenu>
            <QueryRequestProviderMenus>
              <QueryResponseProviderMenus>
                <HakAkses />
              </QueryResponseProviderMenus>
            </QueryRequestProviderMenus>
          </QueryResponseProviderKelolaRoleMenu>
        </QueryRequestProviderKelolaRoleMenu>
      </QueryResponseProviderKelolaRole>
    </QueryRequestProviderKelolaRole>
  </QueryResponseProvider>
);
