import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/dashboard/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useQueryRequest as useQueryRequestPerubahanStatusKepegawaian } from "../../query-request/dashboard/detail-perubahan-status-kepegawaian";
import { useQueryRequest as useQueryRequestPresensiGTK } from "../../query-request/dashboard/detail-presensi-gtk";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import {
  getTotalJenjangBPI,
  getPerubahanStatusKepegawaian,
  getDetailPerubahanStatusKepegawaian,
  getDashboardTotalGTK,
  getDashboardPresensiGTK,
  getDetailPresensiGTK,
  exportPresensiGTK,
} from "../../../api/dashboard/_request";
import { initialQueryState } from "../../../models/global";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { Dayjs } from "dayjs";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const stateDetailPerubahanStatusKepegawaian =
    useQueryRequestPerubahanStatusKepegawaian().state;
  stateDetailPerubahanStatusKepegawaian.col_header = `["nik_gtk", "nama_gtk", "unit_kerja", "tmt_pengangkatan"]`;

  const [
    queryDetailPerubahanStatusKepegawaian,
    setQueryPerubahanStatusKepegawaian,
  ] = useState<string>(
    stringifyRequestQuery(stateDetailPerubahanStatusKepegawaian, "data-gtk")
  );
  const updatedQueryDetailPerubahanStatusKepegawaian = useMemo(
    () =>
      stringifyRequestQuery(stateDetailPerubahanStatusKepegawaian, "data-gtk"),
    [stateDetailPerubahanStatusKepegawaian]
  );

  useEffect(() => {
    if (
      queryDetailPerubahanStatusKepegawaian !==
      updatedQueryDetailPerubahanStatusKepegawaian
    ) {
      setQueryPerubahanStatusKepegawaian(
        updatedQueryDetailPerubahanStatusKepegawaian
      );
    }
  }, [
    queryDetailPerubahanStatusKepegawaian,
    updatedQueryDetailPerubahanStatusKepegawaian,
  ]);

  const queryClient = useQueryClient();
  const [totalSemuaGTK, setTotalSemuaGTK] = useState<number>(
    initialQueryResponse.totalSemuaGTK
  );
  const [totalGTKLaki, setTotalGTKLaki] = useState<number>(
    initialQueryResponse.totalGTKLaki
  );
  const [totalGTKPerempuan, setTotalGTKPerempuan] = useState<number>(
    initialQueryResponse.totalGTKPerempuan
  );

  const [totalGTKAkunAktif, setTotalGTKAkunAktif] = useState<number>(0);
  const [totalGTKAkunTidakAktif, setTotalGTKAkunTidakAktif] =
    useState<number>(0);

  const [totalGTKBekerja, setTotalGTKBekerja] = useState<number>(0);
  const [totalGTKTidakBekerja, setTotalGTKTidakBekerja] = useState<number>(0);

  const [totalJenjangBPI, setTotalJenjangBPI] = useState<any>(
    initialQueryResponse.totalJenjangBPI
  );

  const [perubahanStatusKepegawaian, setPerubahanStatusKepegawaian] =
    useState<any>(initialQueryResponse.perubahanStatusKepegawaian);

  const [currentStatusGolongan, setCurrentStatusGolongan] = useState<any>(
    initialQueryResponse.currentStatusGolongan
  );

  const [
    detailPerubahanStatusKepegawaian,
    setDetailPerubahanStatusKepegawaian,
  ] = useState<any>(initialQueryResponse.detailPerubahanStatusKepegawaian);

  const [
    isShowDetailPerubahanStatusKepegawaian,
    setIsShowDetailPerubahanStatusKepegawaian,
  ] = useState<boolean>(
    initialQueryResponse.isShowDetailPerubahanStatusKepegawaian
  );

  //PRESENSI GTK
  const stateDetailPresensiGTK = useQueryRequestPresensiGTK().state;
  stateDetailPresensiGTK.col_header = `["nik_gtk", "nama_gtk", "unit_kerja"]`;
  const [queryDetailPresensiGTK, setQueryPresensiGTK] = useState<string>(
    stringifyRequestQuery(stateDetailPresensiGTK, "data-gtk")
  );
  const updatedQueryDetailPresensiGTK = useMemo(
    () => stringifyRequestQuery(stateDetailPresensiGTK, "data-gtk"),
    [stateDetailPresensiGTK]
  );

  useEffect(() => {
    if (queryDetailPresensiGTK !== updatedQueryDetailPresensiGTK) {
      setQueryPresensiGTK(updatedQueryDetailPresensiGTK);
    }
  }, [queryDetailPresensiGTK, updatedQueryDetailPresensiGTK]);

  const [presensiGTK, setPresensiGTK] = useState<any>();
  const [detailPresensiGTK, setDetailPresensiGTK] = useState<any>(
    initialQueryResponse.detailPresensiGTK
  );

  const [isShowDetailPresensiGTK, setIsShowDetailPresensiGTK] =
    useState<boolean>(
      initialQueryResponse.isShowDetailPerubahanStatusKepegawaian
    );

  const {
    mutateAsync: GetDetailPresensiGTK,
    isLoading: isLoadingGetDetailPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_PRESENSI_GTK_DASHBOARD}-${queryDetailPresensiGTK}`,
    mutationFn: () => getDetailPresensiGTK(queryDetailPresensiGTK),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_PRESENSI_GTK_DASHBOARD}-${queryDetailPresensiGTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailPresensiGTK = useCallback(async () => {
    await GetDetailPresensiGTK()
      .then((res) => {
        if (res.success) {
          setDetailPresensiGTK(res);
        }
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetDetailPresensiGTK]);
  const {
    mutateAsync: ExportPresensiGTK,
    isLoading: isLoadingExportPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.EXPORT_PRESENSI_GTK_DASHBOARD}-${queryDetailPresensiGTK}`,
    mutationFn: () => exportPresensiGTK(queryDetailPresensiGTK),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.EXPORT_PRESENSI_GTK_DASHBOARD}-${queryDetailPresensiGTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleExportPresensiGTK = useCallback(async () => {
    await ExportPresensiGTK()
      .then((res) => {})
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [ExportPresensiGTK]);
  useEffect(() => {
    if (
      handleGetDetailPresensiGTK &&
      isShowDetailPresensiGTK &&
      queryDetailPresensiGTK === updatedQueryDetailPresensiGTK
    ) {
      handleGetDetailPresensiGTK();
    }
  }, [
    handleGetDetailPresensiGTK,
    isShowDetailPresensiGTK,
    queryDetailPresensiGTK,
    updatedQueryDetailPresensiGTK,
  ]);

  //PRESENSI GTK

  const { mutateAsync: GetDataGTK, isLoading: isLoadingTotalGTK } = useMutation(
    {
      mutationKey: `${QUERIES.GET_DATA_GTK}`,
      mutationFn: ({ query, type }: { query: string; type: string }) =>
        getDashboardTotalGTK(),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DATA_GTK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    }
  );

  const handleGetTotalGTK = useCallback(
    async (query: string, type: string) => {
      await GetDataGTK({ query, type })
        .then((res) => {
          if (res.success) {
            const totalLaki = res.data.total_laki;
            const totalPerempuan = res.data.total_perempuan;
            const totalAll = res.data.total_all;
            setTotalGTKLaki(totalLaki);
            setTotalGTKPerempuan(totalPerempuan);
            setTotalSemuaGTK(totalAll);
            setTotalGTKAkunAktif(res.data.total_akun_aktif);
            setTotalGTKAkunTidakAktif(res.data.total_akun_tidak_aktif);
            setTotalGTKBekerja(res.data.total_bekerja);
            setTotalGTKTidakBekerja(res.data.total_tidak_bekerja);
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDataGTK]
  );

  const {
    mutateAsync: GetTotalJenjangBPI,
    isLoading: isLoadingTotalJenjangBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_TOTAL_JENJANG_BPI}`,
    mutationFn: () => getTotalJenjangBPI(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_TOTAL_JENJANG_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetTotalJenjangBPI = useCallback(async () => {
    await GetTotalJenjangBPI()
      .then((res) => {
        if (res.success) {
          setTotalJenjangBPI(res);
        }
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetTotalJenjangBPI]);

  const {
    mutateAsync: GetPerubahanStatusKepegawaian,
    isLoading: isLoadingPerubahanStatusKepegawaian,
  } = useMutation({
    mutationKey: `${QUERIES.GET_PERUBAHAN_STATUS_KEPEGAWAIAN}`,
    mutationFn: () => getPerubahanStatusKepegawaian(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_PERUBAHAN_STATUS_KEPEGAWAIAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetPerubahanStatusKepegawaian = useCallback(async () => {
    await GetPerubahanStatusKepegawaian()
      .then((res) => {
        if (res.success) {
          setPerubahanStatusKepegawaian(res);
        }
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetPerubahanStatusKepegawaian]);

  const {
    mutateAsync: GetDetailPerubahanStatusKepegawaian,
    isLoading: isLoadingDetailPerubahanStatusKepegawaian,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_PERUBAHAN_STATUS_KEPEGAWAIAN}-${queryDetailPerubahanStatusKepegawaian}`,
    mutationFn: () =>
      // getDetailPerubahanStatusKepegawaian(
      //   `unit_kerja=${unit_kerja}&status_golongan_kepegawaian=${status_golongan_kepegawaian}`
      // ),
      getDetailPerubahanStatusKepegawaian(
        queryDetailPerubahanStatusKepegawaian
      ),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_PERUBAHAN_STATUS_KEPEGAWAIAN}-${queryDetailPerubahanStatusKepegawaian}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailPerubahanStatusKepegawaian = useCallback(async () => {
    await GetDetailPerubahanStatusKepegawaian()
      .then((res) => {
        if (res.success) {
          setDetailPerubahanStatusKepegawaian(res);
        }
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetDetailPerubahanStatusKepegawaian]);

  const { mutateAsync: GetPresensiGTK, isLoading: isLoadingGetPresensiGTK } =
    useMutation({
      mutationKey: `${QUERIES.GET_DASHBOARD_PRESENSI_GTK}`,
      mutationFn: ({ tanggal }: { tanggal: Date | Dayjs | string }) =>
        getDashboardPresensiGTK(tanggal),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.GET_DASHBOARD_PRESENSI_GTK}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetPresensiGTK = useCallback(
    async (tanggal: Date | Dayjs | string) => {
      await GetPresensiGTK({ tanggal })
        .then((res) => {
          if (res.success) {
            setPresensiGTK({
              data: res.data,
              total_footer: res.total_footer,
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetPresensiGTK]
  );

  useEffect(() => {
    if (
      handleGetDetailPerubahanStatusKepegawaian &&
      isShowDetailPerubahanStatusKepegawaian &&
      queryDetailPerubahanStatusKepegawaian ===
        updatedQueryDetailPerubahanStatusKepegawaian
    ) {
      handleGetDetailPerubahanStatusKepegawaian();
    }
  }, [
    handleGetDetailPerubahanStatusKepegawaian,
    isShowDetailPerubahanStatusKepegawaian,
    queryDetailPerubahanStatusKepegawaian,
    updatedQueryDetailPerubahanStatusKepegawaian,
  ]);

  const value = useMemo(
    () => ({
      queryDetailPerubahanStatusKepegawaian,
      totalSemuaGTK,
      totalGTKLaki,
      totalGTKPerempuan,
      handleGetTotalGTK,
      setTotalSemuaGTK,
      setTotalGTKLaki,
      setTotalGTKPerempuan,
      isLoadingTotalGTK,
      handleGetTotalJenjangBPI,
      isLoadingTotalJenjangBPI,
      totalJenjangBPI,
      setTotalJenjangBPI,
      perubahanStatusKepegawaian,
      setPerubahanStatusKepegawaian,
      handleGetPerubahanStatusKepegawaian,
      isLoadingPerubahanStatusKepegawaian,
      isShowDetailPerubahanStatusKepegawaian,
      setIsShowDetailPerubahanStatusKepegawaian,
      handleGetDetailPerubahanStatusKepegawaian,
      isLoadingDetailPerubahanStatusKepegawaian,
      detailPerubahanStatusKepegawaian,
      setDetailPerubahanStatusKepegawaian,
      currentStatusGolongan,
      setCurrentStatusGolongan,
      totalGTKAkunAktif,
      setTotalGTKAkunAktif,
      totalGTKAkunTidakAktif,
      setTotalGTKAkunTidakAktif,
      totalGTKBekerja,
      setTotalGTKBekerja,
      totalGTKTidakBekerja,
      setTotalGTKTidakBekerja,
      handleGetPresensiGTK,
      isLoadingGetPresensiGTK,
      presensiGTK,
      setPresensiGTK,
      handleGetDetailPresensiGTK,
      isLoadingGetDetailPresensiGTK,
      setDetailPresensiGTK,
      detailPresensiGTK,
      isShowDetailPresensiGTK,
      setIsShowDetailPresensiGTK,
      handleExportPresensiGTK,
      isLoadingExportPresensiGTK,
    }),
    [
      queryDetailPerubahanStatusKepegawaian,
      totalSemuaGTK,
      totalGTKLaki,
      totalGTKPerempuan,
      handleGetTotalGTK,
      setTotalSemuaGTK,
      setTotalGTKLaki,
      setTotalGTKPerempuan,
      isLoadingTotalGTK,
      handleGetTotalJenjangBPI,
      isLoadingTotalJenjangBPI,
      totalJenjangBPI,
      setTotalJenjangBPI,
      perubahanStatusKepegawaian,
      setPerubahanStatusKepegawaian,
      handleGetPerubahanStatusKepegawaian,
      isLoadingPerubahanStatusKepegawaian,
      isShowDetailPerubahanStatusKepegawaian,
      setIsShowDetailPerubahanStatusKepegawaian,
      handleGetDetailPerubahanStatusKepegawaian,
      isLoadingDetailPerubahanStatusKepegawaian,
      detailPerubahanStatusKepegawaian,
      setDetailPerubahanStatusKepegawaian,
      currentStatusGolongan,
      setCurrentStatusGolongan,
      totalGTKAkunAktif,
      setTotalGTKAkunAktif,
      totalGTKAkunTidakAktif,
      setTotalGTKAkunTidakAktif,
      totalGTKBekerja,
      setTotalGTKBekerja,
      totalGTKTidakBekerja,
      setTotalGTKTidakBekerja,
      handleGetPresensiGTK,
      isLoadingGetPresensiGTK,
      presensiGTK,
      setPresensiGTK,
      handleGetDetailPresensiGTK,
      isLoadingGetDetailPresensiGTK,
      setDetailPresensiGTK,
      detailPresensiGTK,
      isShowDetailPresensiGTK,
      setIsShowDetailPresensiGTK,
      handleExportPresensiGTK,
      isLoadingExportPresensiGTK,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePaginationDetailPerubahanStatusKepegawaian = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { detailPerubahanStatusKepegawaian } = useQueryResponse();

  return detailPerubahanStatusKepegawaian ?? defaultPaginationState;
};

const useQueryResponsePaginationDetailPresensiGTK = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { detailPresensiGTK } = useQueryResponse();

  return detailPresensiGTK ?? defaultPaginationState;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePaginationDetailPerubahanStatusKepegawaian,
  useQueryResponsePaginationDetailPresensiGTK,
};
