import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormRolesPayload } from "./kelola-role/_queryResponse";
import { FormikProps } from "formik";
import { UserDetail } from "../users/_queryResponse";

export type FormUserHakAksesPayload = {
  rolesId: string | number | null;
  username: string | null;
};

export type QueryResponseContextProps<T> = {
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  showModalHakAkses: boolean;
  setShowModalHakAkses: Dispatch<SetStateAction<boolean>>;
  handleGetAllHakAkses: () => void;
  isLoadingGetAllHakAkses: boolean;
  handleGetUserHakAkses: (nik_gtk: string) => void;
  isLoadingGetUserHakAkses: boolean;
  allHakAkses: Array<FormRolesPayload>;
  setAllHakAkses: Dispatch<SetStateAction<Array<FormRolesPayload>>>;
  formikUserHakAkses?: FormikProps<FormUserHakAksesPayload>;
  formikUserHakAksesValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  userHakAkses?: UserDetail;
  setUserHakAkses: Dispatch<SetStateAction<UserDetail>>;
  handlePostUserHakAkses: (data: FormData) => void;
  isLoadingPostUserHakAkses: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  activeTab: 1,
  setActiveTab: () => {},
  showModalHakAkses: false,
  setShowModalHakAkses: () => {},
  handleGetAllHakAkses: () => {},
  isLoadingGetAllHakAkses: false,
  allHakAkses: [],
  setAllHakAkses: () => {},
  handleGetUserHakAkses: () => {},
  isLoadingGetUserHakAkses: false,
  submitting: false,
  setSubmitting: () => {},
  setUserHakAkses: () => {},
  handlePostUserHakAkses: () => {},
  isLoadingPostUserHakAkses: false,
};
