import {
    FC,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from "react";
  import {
    FormPrihalAlasanPayload,
    initialQueryResponse,
  } from "../../../models/prihal-alasan/_queryResponse";
  import { WithChildren } from "../../../helpers/react18MigrationHelpers";
  import { useMutation, useQuery, useQueryClient } from "react-query";
  import { QUERIES } from "../../../helpers/crud-helpers/const";
  import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
  import { stringifyRequestQuery } from "../../../helpers/helpers";
  import { useQueryRequest } from "../../query-request/prihal-alasan";
  import {
    getListPrihalAlasan,
    postStore,
    getDetailPrihalAlasan,
    postDeletePrihalAlasan,
  } from "../../../api/prihal-alasan/_request";
  import { initialQueryState } from "../../../models/global";
  import { useAuth } from "../../core/auth";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";
  
  const QueryResponseContext = createContext(initialQueryResponse);
  const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
    const { handleGetDetailGTK } = useQueryResponseDetailGTK();
    const { state, updateState } = useQueryRequest();
    const queryClient = useQueryClient();
    state.col_header = `["prihal","alasan"]`;
  
    const [showModalPrihalAlasan, setShowModalPrihalAlasan] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [resetForm, setResetForm] = useState<boolean>(false);
  
    const [query, setQuery] = useState<string>(
      stringifyRequestQuery(state, "default-query-nik-gtk")
    );
    const updatedQuery = useMemo(
      () => stringifyRequestQuery(state, "default-query-nik-gtk"),
      [state]
    );

  
    const [detailPrihalAlasan, setDetailPrihalAlasan] = useState<FormPrihalAlasanPayload>(
      initialQueryResponse.detailPrihalAlasan
    );

  
    useEffect(() => {
      if (query !== updatedQuery) {
        setQuery(updatedQuery);
      }
    }, [query, updatedQuery]);
  
    useEffect(() => {
      if (resetForm) {
        formikPrihalAlasan.setValues(initialQueryResponse.detailPrihalAlasan);
        setDetailPrihalAlasan(initialQueryResponse.detailPrihalAlasan);
        setSubmitting(false);
        setResetForm(false);
      }
    }, [resetForm]);
  
    const {
      data: listData,
      isFetching: isLoadingFetchData,
      refetch: refetchListData,
    } = useQuery(
      `${QUERIES.GET_LIST_DATA_PRIHAL_ALASAN}-${query}`,
      () => getListPrihalAlasan(query),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (err) => {
          console.error(err);
        },
        enabled: query !== "?undefined",
      }
    );
  
    const formikPrihalAlasanValidationSchema = Yup.object().shape({
      prihal: Yup.string().required("Prihal Tidak Boleh Kosong"),
      alasan: Yup.string().required("Alasan Tidak Boleh Kosong"),
    });
  
    const formikPrihalAlasan = useFormik<FormPrihalAlasanPayload>({
      initialValues: initialQueryResponse.detailPrihalAlasan,
      enableReinitialize: false,
      validationSchema: formikPrihalAlasanValidationSchema,
      onSubmit: (values: any) => {
        const payload = new FormData();
        Object.keys(values).map((key, idx) => {
          payload.append(key, values[key]);
        });
        handlePostPrihalAlasan(payload);
      },
    });
  
    const { mutateAsync: PostPrihalAlasan, isLoading: isLoadingPostPrihalAlasan } = useMutation(
      {
        mutationKey: `${QUERIES.POST_STORE_PRIHAL_ALASAN}`,
        mutationFn: ({ payload }: { payload: FormData }) => postStore(payload),
        onSuccess: async (res: any, variables) => {
          const response = res;
          try {
            await queryClient.invalidateQueries([`${QUERIES.POST_STORE_PRIHAL_ALASAN}`]);
            return response;
          } catch (err) {
            console.log(err);
          }
        },
      }
    );
  
    const handlePostPrihalAlasan = useCallback(
      async (payload: any) => {
        await PostPrihalAlasan({ payload })
          .then((res) => {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed && res.success) {
                if (refetchListData) {
                  setShowModalPrihalAlasan(false);
                  setResetForm(true);
                  refetchListData();
                }
              }
            });
          })
          .catch((error) => {
            SweetAlert({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: error.data ? error.data.message : error,
              allowOutsideClick: false,
            }).then((res: any) => {
              if (res.isConfirmed) {
              }
            });
          });
      },
      [PostPrihalAlasan]
    );
  
    const { mutateAsync: GetDetailPrihalAlasan, isLoading: isLoadingGetDetailPrihalAlasan } =
      useMutation({
        mutationKey: `${QUERIES.GET_DETAIL_PRIHAL_ALASAN}`,
        mutationFn: ({ id }: { id: string }) => getDetailPrihalAlasan(id),
        onSuccess: async (res: any, variables) => {
          const response = res;
          try {
            await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_PRIHAL_ALASAN}`]);
            return response;
          } catch (err) {
            console.log(err);
          }
        },
      });
  
    const handleGetDetailPrihalAlasan = useCallback(
      async (id: string) => {
        await GetDetailPrihalAlasan({ id })
          .then((res) => {
            setDetailPrihalAlasan(res.data);
          })
          .catch((error) => {
            SweetAlert({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: error.data ? error.data.message : error,
              allowOutsideClick: false,
            }).then((res: any) => {
              if (res.isConfirmed) {
              }
            });
          });
      },
      [GetDetailPrihalAlasan]
    );

  
    const { mutateAsync: DeletePrihalAlasan, isLoading: isLoadingDeletePrihalAlasan } =
      useMutation({
        mutationKey: `${QUERIES.POST_DETELE_PRIHAL_ALASAN}`,
        mutationFn: ({ id }: { id: string }) => postDeletePrihalAlasan(id),
        onSuccess: async (res: any, variables) => {
          const response = res;
          try {
            await queryClient.invalidateQueries([`${QUERIES.POST_DETELE_PRIHAL_ALASAN}`]);
            return response;
          } catch (err) {
            console.log(err);
          }
        },
      });
  
    const handleDeletePrihalAlasan = useCallback(
      async (id: string) => {
        SweetAlert({
          icon: "warning",
          title: "Info",
          text: "Apakah Anda Yakin Ingin Menghapus Data?",
          allowOutsideClick: false,
          confirmButtonText: "Lanjutkan",
          cancelButtonText: "Batal",
          isShowCancelButton: true,
        }).then(async (swal: any) => {
          if (swal.isConfirmed) {
            await DeletePrihalAlasan({ id })
              .then((res) => {
                SweetAlert({
                  icon: res.success ? "success" : "warning",
                  title: "Info",
                  text: res.message,
                  allowOutsideClick: false,
                }).then((swal: any) => {
                  if (swal.isConfirmed) {
                    if (refetchListData) refetchListData();
                  }
                });
              })
              .catch((error) => {
                SweetAlert({
                  icon: "error",
                  title: "Terjadi Kesalahan",
                  text: error.data ? error.data.message : error,
                  allowOutsideClick: false,
                }).then((res: any) => {
                  if (res.isConfirmed) {
                  }
                });
              });
          }
        });
      },
      [DeletePrihalAlasan]
    );
  
    const value = useMemo(
      () => ({
        query,
        listData,
        isLoadingFetchData,
        refetchListData,
        showModalPrihalAlasan,
        setShowModalPrihalAlasan,
        formikPrihalAlasan,
        formikPrihalAlasanValidationSchema,
        submitting,
        setSubmitting,
        handlePostPrihalAlasan,
        isLoadingPostPrihalAlasan,
        detailPrihalAlasan,
        setDetailPrihalAlasan,
        handleGetDetailPrihalAlasan,
        isLoadingGetDetailPrihalAlasan,
        resetForm,
        setResetForm,
        handleDeletePrihalAlasan,
        isLoadingDeletePrihalAlasan,
      }),
      [
        query,
        listData,
        isLoadingFetchData,
        refetchListData,
        showModalPrihalAlasan,
        setShowModalPrihalAlasan,
        formikPrihalAlasan,
        formikPrihalAlasanValidationSchema,
        submitting,
        setSubmitting,
        handlePostPrihalAlasan,
        isLoadingPostPrihalAlasan,
        detailPrihalAlasan,
        setDetailPrihalAlasan,
        handleGetDetailPrihalAlasan,
        isLoadingGetDetailPrihalAlasan,
        resetForm,
        setResetForm,
        handleDeletePrihalAlasan,
        isLoadingDeletePrihalAlasan,
      ]
    );
    return (
      <QueryResponseContext.Provider value={value}>
        {children}
      </QueryResponseContext.Provider>
    );
  };
  
  const useQueryResponse = () => useContext(QueryResponseContext);
  const useQueryResponsePagination = () => {
    const defaultPaginationState = {
      ...initialQueryState,
    };
  
    const { listData } = useQueryResponse();
  
    return listData ?? defaultPaginationState;
  };
  export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
  