import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListMapel = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_MAPEL_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllMapel = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_MAPEL_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postMapel = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_MAPEL_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailMapel = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_MAPEL_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocMapel = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deleteMapel = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_MAPEL_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListMapel,
  postMapel,
  getDetailMapel,
  downloadDocMapel,
  deleteMapel,
  getAllMapel,
};
