import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/kepegawaian/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../models/global";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import {
  deleteKepegawaian,
  downloadDocKepegawaian,
  getDetailKepegawaian,
  postKepegawaian,
} from "../../../api/kepegawaian/_request";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";
import { useQueryResponse as useQueryResponseDataGTK } from "../data-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { refetchListData } = useQueryResponseDataGTK();
  const queryClient = useQueryClient();

  const [detailKepegawaian, setDetailKepegawaian] = useState<any>();
  const [showModalDetailKepegawaian, setShowModalDetailKepegawaian] =
    useState<boolean>(false);

  const {
    mutateAsync: GetDetailKepegawaian,
    isLoading: isLoadingGetDetailKepegawaian,
  } = useMutation({
    mutationKey: `${QUERIES.PUT_DATA_GTK}`,
    mutationFn: ({ id }: { id: string }) => getDetailKepegawaian(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.PUT_DATA_GTK}`]);
        setDetailKepegawaian(response);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKepegawaian = useCallback(
    async (id: string) => {
      await GetDetailKepegawaian({ id }).catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
    },
    [GetDetailKepegawaian]
  );

  const { mutateAsync: PostKepegawaian, isLoading: isLoadingPostKepegawaian } =
    useMutation({
      mutationKey: `${QUERIES.PUT_DATA_GTK}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postKepegawaian(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.PUT_DATA_GTK}`]);
          setDetailKepegawaian(response);
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostKepegawaian = useCallback(
    async (payload: FormData) => {
      await PostKepegawaian({ payload })
        .then((res) => {
          const response = res;
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed && response.success) {
              // if (handleGetDetailKepegawaian)
              //   handleGetDetailKepegawaian(response.data.id);
              window.location.href =
                process.env.REACT_APP_BASE_URL +
                `data-gtk/kepegawaian/form/${response.data.nik_gtk}/${response.data.id}`;
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKepegawaian]
  );

  const {
    mutateAsync: DeleteKepegawaian,
    isLoading: isLoadingDeleteKepegawaian,
  } = useMutation({
    mutationKey: `${QUERIES.PUT_DATA_GTK}`,
    mutationFn: ({ id }: { id: string }) => deleteKepegawaian(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.PUT_DATA_GTK}`]);
        setDetailKepegawaian(response);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteKepegawaian = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "info",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        isShowCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Lanjutkan",
      }).then(async (res: any) => {
        if (res.isConfirmed) {
          await DeleteKepegawaian({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKepegawaian]
  );

  const {
    mutateAsync: DownloadDocKepegawaian,
    isLoading: isLoadingDownloadDocKepegawaian,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_RIWAYAT_PENDIDIKAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocKepegawaian(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_RIWAYAT_PENDIDIKAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocKepegawaian = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocKepegawaian({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocKepegawaian]
  );

  const value = useMemo(
    () => ({
      handleGetDetailKepegawaian,
      isLoadingGetDetailKepegawaian,
      detailKepegawaian,
      setDetailKepegawaian,
      handlePostKepegawaian,
      isLoadingPostKepegawaian,
      handleDeleteKepegawaian,
      isLoadingDeleteKepegawaian,
      handleDownloadDocKepegawaian,
      isLoadingDownloadDocKepegawaian,
      showModalDetailKepegawaian,
      setShowModalDetailKepegawaian,
    }),
    [
      handleGetDetailKepegawaian,
      isLoadingGetDetailKepegawaian,
      detailKepegawaian,
      setDetailKepegawaian,
      handlePostKepegawaian,
      isLoadingPostKepegawaian,
      handleDeleteKepegawaian,
      isLoadingDeleteKepegawaian,
      handleDownloadDocKepegawaian,
      isLoadingDownloadDocKepegawaian,
      showModalDetailKepegawaian,
      setShowModalDetailKepegawaian,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

// const useQueryResponsePagination = () => {
//   const defaultPaginationState = {
//     ...initialQueryState,
//   };

//   const { listData } = useQueryResponse();

//   return listData ?? defaultPaginationState;
// };

const useQueryResponseDetailKepegawaian = () => {
  const { detailKepegawaian } = useQueryResponse();
  return detailKepegawaian?.data ?? {};
};

export {
  QueryResponseProvider,
  useQueryResponse,
  //useQueryResponsePagination
  useQueryResponseDetailKepegawaian,
};
