import { Card, Col, Spinner, Table } from "react-bootstrap";
import ColumnHeader from "./columns/header";
import { useEffect } from "react";
import ColumnFooter from "./columns/footer";
import ColumnBody from "./columns/body";
import { useQueryResponse } from "../../../provider/query-response/dashboard";
import Detail from "./detail";

const PerubahanStatusKepegawaian = () => {
  const {
    handleGetPerubahanStatusKepegawaian,
    isLoadingPerubahanStatusKepegawaian,
    perubahanStatusKepegawaian,
  } = useQueryResponse();

  useEffect(() => {
    if (handleGetPerubahanStatusKepegawaian)
      handleGetPerubahanStatusKepegawaian();
  }, [handleGetPerubahanStatusKepegawaian]);

  return (
    <Col md="12" lg="12" xs="12">
      <Detail modalTitle="Detail Perubahan Status Kepegawaian" />
      <Card>
        <Card.Header className="border border-gray-100">
          <Card.Title>Proyeksi Perubahan Status Kepegawaian</Card.Title>
        </Card.Header>
        <Card.Body>
          {isLoadingPerubahanStatusKepegawaian && (
            <div
              className="d-flex flex-row justify-content-center align-items-center my-0"
              style={{ height: "30vh" }}
            >
              <Spinner
                animation="border"
                variant="success"
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          )}
          {!isLoadingPerubahanStatusKepegawaian && (
            <Table
              responsive
              id="datatable"
              className="fs-6"
              data-toggle="data-table"
              bordered
            >
              <ColumnHeader data={perubahanStatusKepegawaian?.data} />
              <tbody>
                {perubahanStatusKepegawaian?.data?.map((val) => {
                  if (val.nama_golongan !== "TotalPerUnitKerja") {
                    return (
                      <tr>
                        <td>{val.nama_golongan}</td>
                        <td>{val.menuju}</td>
                        <td>
                          {val.tmt_pengangkatan > 0
                            ? val.tmt_pengangkatan + " Bulan"
                            : ""}{" "}
                        </td>
                        <ColumnBody
                          data={val.data}
                          jumlah={val.jumlah}
                          status_golongan_kepegawaian={val.nama_golongan}
                          menuju={val.menuju}
                          className={val.className}
                        />
                      </tr>
                    );
                  } else return <></>;
                })}
              </tbody>

              {!isLoadingPerubahanStatusKepegawaian && (
                <ColumnFooter data={perubahanStatusKepegawaian?.data} />
              )}
            </Table>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export const PerubahanStatusKepegawaianWrapper = () => (
  <PerubahanStatusKepegawaian />
);
