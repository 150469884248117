import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";
import { FormKepegawaianPayload } from "../kepegawaian/_queryResponse";

export type domisili_gtk = {
  id: number | null;
  nik_gtk: string | null;
  alamat_domisili: string | null;
  rt_domisili: string | null;
  rw_domisili: string | null;
  nama_dusun_kota_domisili: string | null;
  kelurahan_domisili: string | null;
  kecamatan_domisili: string | null;
  kode_pos_domisili: string | null;
};

export type FormDataGTKPayload = {
  id: string | null;
  nik_gtk: string | null;
  nama_gtk: string | null;
  unit_kerja: string | null;
  email: string | null;
  jenis_kelamin: string | null;
  tempat_lahir: string | null;
  tanggal_lahir: Date | string | null;
  nomor_hp: string | null;
  nama_ibu_kandung: string | null;
  alamat: string | null;
  rt: string | null;
  rw: string | null;
  nama_dusun_kota: string | null;
  kelurahan: string | null;
  kecamatan: string | null;
  kode_pos: string | null;
  npwp: string | null;
  nama_wajib_pajak: string | null;
  warga_negara: string | null;
  status_perkawinan: string | null;
  nama_pasangan_hidup: string | null;
  pekerjaan_pasangan_hidup: string | null;
  jumlah_penghasilan_suami: string | null;
  no_bpjs_kesehatan: string | null;
  no_bpjs_ketenagakerjaan: string | null;
  rekening_atas_nama: string | null;
  id_bank: string | null;
  no_rekening_bank: string | null;
  rekening_atas_nama_non_gaji: string | null;
  id_bank_non_gaji: string | null;
  no_rekening_bank_non_gaji: string | null;
  domisili_gtk: domisili_gtk | null;
  tinggi_badan: number | null;
  berat_badan: number | null;
  golongan_darah: string | null;
  inpassing?: any;
  riwayat_sertifikasi?: any;
  riwayat_gaji_berkala?: any;
  riwayat_pangkat?: any;
  validasi_gtk?: any;
  status_aktivasi?: boolean;
  history_login?: any;
  poto?: File | string | null;
  progress_bar?: number;
  kepegawaian?: Array<FormKepegawaianPayload>;
  is_mutasi?: boolean;
  is_punya_anak?: boolean;
  is_penulis_buku?: boolean;
  is_riwayat_pekerjaan?: boolean;
  is_karya_tulis?: boolean;
  is_kesejahteraan?: boolean;
  is_penghargaan?: boolean;
  is_tugas_tambahan?: boolean;
  is_mapel?: boolean;
  is_pendidikan_pelatihan?: boolean;
  is_penilaian?: boolean;
  is_inpassing?: boolean;
  is_sertifikasi_keterampilan_khusus?: boolean;
  is_riwayat_sertifikasi?: boolean;
  mutasi?: any;
  anak?: any;
  karia_buku?: any;
  riwayat_pekerjaan?: any;
  pendidikan_pelatihan?: any;
  riwayat_pendidikan?: any;
  karya_tulis?: any;
  kesejahteraan?: any;
  penghargaan?: any;
  penilaian?: any;
  tugas_tambahan?: any;
  mapel?: any;
  sertifikasi_keterampilan_khusus?: any;
  perubahan_status_kepegawaian?: string | null;
};

export type FormSebabNonAktifPayload = {
  sebab_non_aktif: string | null;
  nik_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  handlePutDataGTK?: (data: FormData, id: string) => void;
  isLoadingPutDataGTK: boolean;
  refetchListData?: () => void;
  handlePostDataGTK?: (data: FormData) => void;
  isLoadingPostDataGTK: boolean;
  handleGetDetailGTK?: (id: string) => void;
  isLoadingGetDetailGTK: boolean;
  detailGTK?: Response<T>;
  setDetailGTK: Dispatch<SetStateAction<FormDataGTKPayload>>;
  isShowModal?: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleGetAllTableFilterGTK?: () => void;
  isLoadingGetAllTableFilterGTK: boolean;
  allTableFilterGTK?: Array<any>;
  setAllTableFilterGTK: Dispatch<SetStateAction<Array<any>>>;
  allTableFilterGTKChecked: Array<any>;
  setAllTableFilterGTKChecked: Dispatch<SetStateAction<Array<any>>>;
  handleExportExcelDataGTK?: () => void;
  isLoadingExportExcelDataGTK?: boolean;
  handleExportPDFDataGTK?: () => void;
  isLoadingExportPDFDataGTK?: boolean;
  handleGetAllDataGTK?: () => void;
  isLoadingGetAllDataGTK?: boolean;
  showModalNonAktifkanGTK: boolean;
  setShowModalNonAktifkanGTK: Dispatch<SetStateAction<boolean>>;
  handleGetAllSebabNonAktif: () => void;
  isLoadingGetAllSebabNonAktif: boolean;
  allSebabNonAktif: Array<FormSebabNonAktifPayload>;
  setAllSebabNonAktif: Dispatch<
    SetStateAction<Array<FormSebabNonAktifPayload>>
  >;
  formikSebabNonAktif?: FormikProps<FormSebabNonAktifPayload>;
  formikSebabNonAktifValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  isLoadingPostSebabNonAktif: boolean;
  handleResetPasswordGTK: (id: string, nama_gtk: string) => void;
  isLoadingResetPasswordGTK: boolean;
  statusAktivasi: boolean;
  setStatusAktivasi: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  statusAktivasi: true,
  setStatusAktivasi: () => {},
  isLoadingFetchData: false,
  isLoadingPutDataGTK: false,
  isLoadingPostDataGTK: false,
  isLoadingGetDetailGTK: false,
  isLoadingGetAllTableFilterGTK: false,
  handlePutDataGTK: () => {},
  refetchListData: () => {},
  setDetailGTK: () => {},
  setShowModal: () => {},
  setAllTableFilterGTK: () => {},
  setAllTableFilterGTKChecked: () => {},
  allTableFilterGTKChecked: [],
  handleExportExcelDataGTK: () => {},
  isLoadingExportExcelDataGTK: false,
  handleExportPDFDataGTK: () => {},
  isLoadingExportPDFDataGTK: false,
  handleGetAllDataGTK: () => {},
  isLoadingGetAllDataGTK: false,
  showModalNonAktifkanGTK: false,
  setShowModalNonAktifkanGTK: () => {},
  handleGetAllSebabNonAktif: () => {},
  isLoadingGetAllSebabNonAktif: false,
  allSebabNonAktif: [],
  setAllSebabNonAktif: () => {},
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostSebabNonAktif: false,
  handleResetPasswordGTK: () => {},
  isLoadingResetPasswordGTK: false,
};
