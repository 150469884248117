import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListKaryaTulis = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_KARYA_TULIS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postKaryaTulis = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_KARYA_TULIS_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailKaryaTulis = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_KARYA_TULIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocKaryaTulis = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deleteKaryaTulis = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_KARYA_TULIS_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListKaryaTulis,
  postKaryaTulis,
  getDetailKaryaTulis,
  downloadDocKaryaTulis,
  deleteKaryaTulis,
};
