import { FC } from "react";
import { useQueryResponse } from "../../../../provider/query-response/detail-gtk";

type Props = {
  id?: string;
  label?: string;
  onClick?: () => {};
};
const NamaGTKCell: FC<Props> = ({ id = "", label }) => {
  const { setShowModal, setNIKGTK } = useQueryResponse();
  return (
    <div
      className="d-flex flex-column gap-2 justify-content-center align-items-start cursor-pointer hover-primary"
      onClick={() => {
        setShowModal(true);
        setNIKGTK(id);
      }}
    >
      <label className="fw-normal text-dark pe-3 text-start w-100 cursor-pointer ">
        {label}
      </label>
    </div>
  );
};

export default NamaGTKCell;
