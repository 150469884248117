import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/absensi-bpi";
import NumberInputComponent from "../../../../hope-ui/components/number-input";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import Select2 from "../../../../hope-ui/components/select-2";
import { useQueryResponse as useQueryResponseAnggotaKelompokBPI } from "../../../../provider/query-response/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../provider/core/auth";
import { useQueryRequest as useQueryRequestBuatAbsensi } from "../../../../provider/query-request/absensi-bpi/buat-absensi";
import {
  useQueryResponse as useQueryResponseBuatAbsensi,
  useQueryResponsePagination,
} from "../../../../provider/query-response/absensi-bpi/buat-absensi";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/column";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import Datepicker from "../../../../hope-ui/components/datepicker";
import dayjs from "dayjs";

const ModalBuatAbsensi = () => {
  const { setIsShowModalBuatAbsensi, isShowModalBuatAbsensi } =
    useQueryResponse();

  const { updateState } = useQueryRequestBuatAbsensi();
  const {
    handleGetAllKelompokBPI,
    allKelompokBPI,
    isLoadingGetAllKelompokBPI,
  } = useQueryResponseAnggotaKelompokBPI();

  const {
    allMC,
    isLoadingGetAllMC,
    handleGetAllMC,
    listData,
    isLoadingFetchData,
    formikBuatAbsensi,
    submitting,
    setSubmitting,
    isLoadingPostBuatAbsensiBPI,
    isLoadingGetDetailAbsensiBPI,
    detailAbsensiBPI,
    setResetForm,
    resetForm,
    handleGetTotalInfaq,
    isLoadingGetTotalInfaq,
    totalInfaq,
  } = useQueryResponseBuatAbsensi();

  const Data_table = listData?.data;
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.data && isShowModalBuatAbsensi) {
      const nik_gtk: any = currentUser.data.username;
      if (handleGetAllKelompokBPI) handleGetAllKelompokBPI(nik_gtk);
    }
  }, [handleGetAllKelompokBPI, currentUser, isShowModalBuatAbsensi]);

  let optionsKelompokBPI: any = allKelompokBPI.map((val) => {
    return {
      value: val.id,
      label: val.nama_kelompok_bpi,
    };
  });
  const [query, setQuery] = useState<string>();
  const [nikTutorBPI, setNikTutorBPI] = useState<string>();
  const [idKelompokBPI, setIdKelompokBPI] = useState<string>();
  const [idRiwayatBPI, setIdRiwayatBPI] = useState<string>();

  useEffect(() => {
    if (
      currentUser &&
      currentUser.data &&
      formikBuatAbsensi &&
      formikBuatAbsensi?.getFieldProps("id_kelompok_bpi").value &&
      isShowModalBuatAbsensi
    ) {
      const nik_tutor_bpi: any = currentUser.data.username;
      const id_kelompok_bpi =
        formikBuatAbsensi?.getFieldProps("id_kelompok_bpi").value ??
        idKelompokBPI;
      setQuery(
        `nik_tutor_bpi=${nik_tutor_bpi}&id_kelompok_bpi=${id_kelompok_bpi}&id_riwayat_bpi=${
          idRiwayatBPI ?? ""
        }`
      );
      setNikTutorBPI(nik_tutor_bpi);
      setIdKelompokBPI(id_kelompok_bpi);
    } else {
      if (!detailAbsensiBPI) {
        setQuery("");
        setNikTutorBPI("");
        setIdKelompokBPI("");
      }
    }
  }, [
    handleGetAllMC,
    currentUser,
    formikBuatAbsensi,
    isShowModalBuatAbsensi,
    detailAbsensiBPI,
    idKelompokBPI,
  ]);

  useEffect(() => {
    if (handleGetAllMC && isShowModalBuatAbsensi) {
      if (!formikBuatAbsensi?.getFieldProps("mc").value)
        formikBuatAbsensi?.setFieldValue("mc", detailAbsensiBPI.mc ?? null);
      handleGetAllMC(query ?? "");
    }
  }, [handleGetAllMC, query, isShowModalBuatAbsensi]);

  useEffect(() => {
    if (idKelompokBPI && nikTutorBPI) {
      const obj = {
        size: 999999999999,
        nik_tutor_bpi: nikTutorBPI,
        id_kelompok_bpi: idKelompokBPI,
      };

      if (idRiwayatBPI) obj["id_riwayat_bpi"] = idRiwayatBPI;
      updateState(obj);
      if (!formikBuatAbsensi?.getFieldProps("nik_gtk_tutor").value)
        formikBuatAbsensi?.setFieldValue("nik_gtk_tutor", nikTutorBPI);
    } else {
      updateState({
        nik_tutor_bpi: null,
        id_kelompok_bpi: null,
      });
    }
  }, [idKelompokBPI, nikTutorBPI, idRiwayatBPI]);

  useEffect(() => {
    if (handleGetTotalInfaq) handleGetTotalInfaq(idKelompokBPI ?? "0");
  }, [handleGetTotalInfaq, idKelompokBPI]);

  useEffect(() => {
    if (formikBuatAbsensi && allMC) {
      let mc: any = allMC.map((val) => {
        return {
          id_kelompok_bpi: val.id_kelompok_bpi,
          level_bpi: val.level_bpi,
          nik_gtk: val.nik_gtk ?? val.gtk.nik_gtk,
          nik_tutor_bpi: val.nik_tutor_bpi,
        };
      });

      if (detailAbsensiBPI) {
        detailAbsensiBPI.kehadiran_bpi.map((val) => {
          const find = mc.find((element) => element.nik_gtk === val.nik_gtk);
          console.log(find, mc);
          if (find) mc = detailAbsensiBPI.kehadiran_bpi;
        });
      }

      formikBuatAbsensi?.setFieldValue("kehadiran_bpi", mc);
    }
  }, [allMC, detailAbsensiBPI]);

  let optionsMC: any = allMC.map((val) => {
    return {
      value: val.nik_gtk ?? val.gtk.nik_gtk,
      label: `${val.nik_gtk ?? val.gtk.nik_gtk} - ${val.gtk.nama_gtk}`,
    };
  });

  useEffect(() => {
    if (
      !isShowModalBuatAbsensi &&
      !isLoadingGetDetailAbsensiBPI &&
      detailAbsensiBPI &&
      detailAbsensiBPI.id
    ) {
      const id_kelompok_bpi: any = detailAbsensiBPI.id_kelompok_bpi;
      const nik_gtk_tutor: any = detailAbsensiBPI.nik_gtk_tutor;
      const id_riwayat_bpi: any = detailAbsensiBPI.id;
      setIdKelompokBPI(id_kelompok_bpi);
      setNikTutorBPI(nik_gtk_tutor);
      setIdRiwayatBPI(id_riwayat_bpi);
      updateState({
        size: 999999999999,
        nik_tutor_bpi: nik_gtk_tutor,
        id_kelompok_bpi: id_kelompok_bpi,
        id_riwayat_bpi,
      });
      if (!resetForm) {
        setIsShowModalBuatAbsensi(true);
        formikBuatAbsensi?.setValues(detailAbsensiBPI);
      }
    }
  }, [isShowModalBuatAbsensi, isLoadingGetDetailAbsensiBPI, detailAbsensiBPI]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      setIdKelompokBPI("0");
      setIsShowModalBuatAbsensi(false);
    }
  }, [resetForm]);

  //console.log(formikBuatAbsensi?.values);

  return (
    <Modal
      backdrop="static"
      show={isShowModalBuatAbsensi}
      onBackdropClick={() => {
        setIsShowModalBuatAbsensi(false);
        setResetForm(true);
      }}
      size="xl"
      onHide={() => {
        setIsShowModalBuatAbsensi(false);
        setResetForm(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {detailAbsensiBPI && detailAbsensiBPI.id ? "Edit" : "Tambah"}{" "}
          Notulensi BPI
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(isLoadingPostBuatAbsensiBPI || isLoadingGetTotalInfaq) && (
          <FetchLoading />
        )}
        <Form
          onSubmit={(e) => {
            setSubmitting(true);
            formikBuatAbsensi?.submitForm();
            e.preventDefault();
          }}
          className="px-4 py-2"
        >
          <Card.Header className="pb-1 d-flex flex-row justify-content-end">
            <button type="submit" className="btn btn-sm btn-primary">
              <i className="fas fa-clipboard-user me-2"></i>
              Simpan
            </button>
          </Card.Header>
          <Row className="gy-3 ">
            <Col lg="6">
              <Form.Group>
                <Datepicker
                  label="Tanggal"
                  required
                  value={dayjs(
                    formikBuatAbsensi?.getFieldProps("riwayat_tanggal").value
                  ).format("YYYY-MM-DD")}
                  onChange={(e: any) => {
                    const value = dayjs(e).format("YYYY-MM-DD");
                    formikBuatAbsensi?.setFieldValue("riwayat_tanggal", value);
                  }}
                />
                {submitting && formikBuatAbsensi?.errors.riwayat_tanggal && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.riwayat_tanggal}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <Select2
                  label="Kelompok BPI"
                  isRequired
                  placeholder="Pilih Kelompok BPI"
                  options={optionsKelompokBPI}
                  isLoading={isLoadingGetAllKelompokBPI}
                  onChange={(e: any) => {
                    const value = e?.value;
                    formikBuatAbsensi?.setFieldValue(
                      "id_kelompok_bpi",
                      value ?? "0"
                    );
                  }}
                  value={
                    formikBuatAbsensi?.getFieldProps("id_kelompok_bpi").value
                  }
                />
                {submitting && formikBuatAbsensi?.errors.id_kelompok_bpi && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.id_kelompok_bpi}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <Select2
                  label="MC"
                  isRequired
                  placeholder="Pilih MC"
                  options={optionsMC}
                  isLoading={isLoadingGetAllMC}
                  onChange={(e: any) => {
                    const value = e?.value;
                    formikBuatAbsensi?.setFieldValue("mc", value);
                  }}
                  value={formikBuatAbsensi?.getFieldProps("mc").value}
                />
                {submitting && formikBuatAbsensi?.errors.mc && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.mc}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <TextInputComponent
                  label="Tempat"
                  isRequired
                  placeholder="Masukkan Tempat"
                  formik={formikBuatAbsensi?.getFieldProps("tempat")}
                  value={formikBuatAbsensi?.getFieldProps("tempat").value ?? ""}
                />
                {submitting && formikBuatAbsensi?.errors.tempat && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.tempat}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <NumberInputComponent
                  label="Batas Tilawah Halaman"
                  required
                  placeholder="Masukkan Batas Tilawah Halaman"
                  formik={formikBuatAbsensi?.getFieldProps(
                    "batas_tilawah_halaman"
                  )}
                  value={
                    formikBuatAbsensi?.getFieldProps("batas_tilawah_halaman")
                      .value ?? ""
                  }
                />
                {submitting &&
                  formikBuatAbsensi?.errors.batas_tilawah_halaman && (
                    <div className="text-danger mt-2">
                      {formikBuatAbsensi?.errors.batas_tilawah_halaman}
                    </div>
                  )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <NumberInputComponent
                  label="Infaq"
                  required
                  placeholder="Masukkan Infaq"
                  formik={formikBuatAbsensi?.getFieldProps("infaq")}
                  value={formikBuatAbsensi?.getFieldProps("infaq").value ?? ""}
                />
                {submitting && formikBuatAbsensi?.errors.infaq && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.infaq}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <NumberInputComponent
                  label="Total Infaq"
                  placeholder="Total Infaq"
                  disabled
                  formik={formikBuatAbsensi?.getFieldProps("total_infaq")}
                  value={
                    formikBuatAbsensi?.getFieldProps("total_infaq").value ?? 0
                  }
                />
                {submitting && formikBuatAbsensi?.errors.total_infaq && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.total_infaq}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group>
                <TextareaComponent
                  label="Materi"
                  required
                  placeholder="Masukkan Materi"
                  formik={formikBuatAbsensi?.getFieldProps("materi")}
                  value={formikBuatAbsensi?.getFieldProps("materi").value ?? ""}
                />
                {submitting && formikBuatAbsensi?.errors.materi && (
                  <div className="text-danger mt-2">
                    {formikBuatAbsensi?.errors.materi}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Col className="mt-4">
            <h6>Kehadiran BPI</h6>
            <hr className="bg-light" />
            <Toolbar />
            <DataTable
              isLoading={isLoadingFetchData}
              data={Data_table}
              header={Columns}
              pagination={useQueryResponsePagination}
              queryRequest={useQueryRequestBuatAbsensi}
              isPagination={false}
            />
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBuatAbsensi;
