import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormPenghargaanPayload = {
  id: number | string | null;
  id_penghargaan: string | null;
  nik_gtk: string | null;
  tingkat_penghargaan: string | null;
  jenis_penghargaan: string | null;
  nama_penghargaan: string | null;
  tahun: number | string | null;
  instansi: number | string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalPenghargaan: boolean;
  setShowModalPenghargaan: Dispatch<SetStateAction<boolean>>;
  formikPenghargaan?: FormikProps<FormPenghargaanPayload>;
  formikPenghargaanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostPenghargaan: (data: FormData) => void;
  isLoadingPostPenghargaan: boolean;
  handleGetDetailPenghargaan: (id: string) => void;
  isLoadingGetDetailPenghargaan: boolean;
  detailPenghargaan: FormPenghargaanPayload;
  setDetailPenghargaan: Dispatch<SetStateAction<FormPenghargaanPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocPenghargaan: (url: string, fileName: string) => void;
  isLoadingDownloadDocPenghargaan: boolean;
  handleDeletePenghargaan: (id: string) => void;
  isLoadingDeletePenghargaan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalPenghargaan: false,
  setShowModalPenghargaan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostPenghargaan: () => {},
  isLoadingPostPenghargaan: false,
  handleGetDetailPenghargaan: () => {},
  isLoadingGetDetailPenghargaan: false,
  detailPenghargaan: {
    id: null,
    id_penghargaan: null,
    nik_gtk: null,
    instansi: "",
    jenis_penghargaan: "",
    nama_penghargaan: "",
    tahun: "",
    tingkat_penghargaan: "",
  },
  setDetailPenghargaan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocPenghargaan: () => {},
  isLoadingDownloadDocPenghargaan: false,
  handleDeletePenghargaan: () => {},
  isLoadingDeletePenghargaan: false,
};
