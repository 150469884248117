import { Card } from "react-bootstrap";
import { DataTable } from "../../hope-ui/components/table/DataTable";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../provider/query-request/data-gtk";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePagination,
} from "../../provider/query-response/data-gtk";

import { QueryResponseProvider as QueryResponseProviderKepegawaian } from "../../provider/query-response/kepegawaian";

import { Columns } from "./list/columns/columns";
import Toolbar from "./list/toolbar";
import ModalDetailKepegawaian from "./modal/detail-kepegawaian";

const Kepegawaian = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  return (
    <Card.Body className="pt-0">
      <ModalDetailKepegawaian />
      <Toolbar />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </Card.Body>
  );
};

export const KepegawaianWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryResponseProviderKepegawaian>
        <Kepegawaian />
      </QueryResponseProviderKepegawaian>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
