import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/mapel";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import Select2 from "../../../../hope-ui/components/select-2";

const ModalMapel = () => {
  const {
    showModalMapel,
    setShowModalMapel,
    formikMapel,
    submitting,
    setSubmitting,
    detailMapel,
    setResetForm,
    isLoadingGetDetailMapel,
    isLoadingPostMapel,
    handleGetAllMapel,
    isLoadingGetAllMapel,
    allMapel,
  } = useQueryResponse();

  const optionsMapel = allMapel.map((val) => {
    return {
      value: val.nama_mapel,
      label: val.nama_mapel,
    };
  });

  useEffect(() => {
    if (detailMapel.id) {
      formikMapel?.setValues(detailMapel);
    }
  }, [detailMapel]);

  useEffect(() => {
    if (showModalMapel) {
      handleGetAllMapel();
    }
  }, [showModalMapel]);

  return (
    <Modal
      backdrop="static"
      show={showModalMapel}
      onBackdropClick={() => {
        setShowModalMapel(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalMapel(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikMapel?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{detailMapel.id ? "Edit" : "Tambah"} Mapel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailMapel || isLoadingPostMapel) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="12">
              <Select2
                label="Nama Mata Pelajaran"
                placeholder="Pilih Nama Mata Pelajaran"
                isRequired
                value={formikMapel?.getFieldProps("nama_mapel").value ?? ""}
                isLoading={isLoadingGetAllMapel}
                options={optionsMapel}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikMapel?.setFieldValue("nama_mapel", value);
                }}
              />
              {submitting && formikMapel?.errors.nama_mapel && (
                <div className="fs-6 text-danger mt-2">
                  {formikMapel?.errors.nama_mapel}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalMapel;
