import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FormRiwayatPangkatPayload,
  initialQueryResponse,
} from "../../../models/riwayat-pangkat/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { postRiwayatPangkat } from "../../../api/riwayat-pangkat/_request";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";
import { useAuth } from "../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const nik_gtk: any = currentUser?.data?.username;
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const formikRiwayatPangkatValidationSchema = Yup.object().shape({
    pangkat_golongan: Yup.string().required(
      "Pangkat Golongan Tidak Boleh Kosong"
    ),
    nomor_sk: Yup.string().required("Nomor SK Tidak Boleh Kosong"),
    tanggal_sk: Yup.string().required("Tanggal SK Tidak Boleh Kosong"),
    tmt_sk: Yup.string().required("TMT SK Tidak Boleh Kosong"),
    masa_kerja: Yup.string().required("Masa Kerja Tidak Boleh Kosong"),
  });

  const formikRiwayatPangkat = useFormik<FormRiwayatPangkatPayload>({
    initialValues: {
      id: null,
      id_riwayat_pangkat: null,
      nomor_sk: "",
      tanggal_sk: null,
      pangkat_golongan: "",
      masa_kerja: "",
      tmt_sk: null,
      nik_gtk,
    },
    enableReinitialize: false,
    validationSchema: formikRiwayatPangkatValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostRiwayatPangkat(payload);
    },
  });

  const {
    mutateAsync: PostRiwayatPangkat,
    isLoading: isLoadingPostRiwayatPangkat,
  } = useMutation({
    mutationKey: `${QUERIES.POST_RIWAYAT_PANGKAT}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postRiwayatPangkat(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_RIWAYAT_PANGKAT}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostRiwayatPangkat = useCallback(
    async (payload: any) => {
      await PostRiwayatPangkat({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailGTK(res.data.nik_gtk);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostRiwayatPangkat]
  );

  const value = useMemo(
    () => ({
      formikRiwayatPangkat,
      formikRiwayatPangkatValidationSchema,
      submitting,
      setSubmitting,
      handlePostRiwayatPangkat,
      isLoadingPostRiwayatPangkat,
    }),
    [
      formikRiwayatPangkat,
      formikRiwayatPangkatValidationSchema,
      submitting,
      setSubmitting,
      handlePostRiwayatPangkat,
      isLoadingPostRiwayatPangkat,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
