import { Alert } from "react-bootstrap";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/data-saya-kepegawaian";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/data-saya-kepegawaian";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";

const DataSayaKepegawaian = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  return (
    <>
      {listData?.data?.length === 0 ? (
        <Alert key={"danger"} variant="danger" className="fs-6">
          <i className="fa fa-warning me-2"></i>
          DATA KEPEGAWAIAN ANDA SAAT INI KOSONG, SILAHKAN HUBUNGI ATASAN ANDA
        </Alert>
      ) : (
        <>
          <Toolbar />
          <DataTable
            isLoading={isLoadingFetchData}
            header={Columns}
            data={listData?.data}
            pagination={useQueryResponsePagination}
            queryRequest={useQueryRequest}
          />
        </>
      )}
    </>
  );
};

export default DataSayaKepegawaian;
