import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/notifikasi/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { FormMemoPayload } from "../../../models/memo/_queryResponse";
import io from "socket.io-client";
import { useAuth } from "../../core/auth";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { getDetailMemoNotif } from "../../../api/memo/_request";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const url: any = process.env.REACT_APP_SIMBI_ENDPOINT_URL;
  const socket = io(url);
  const [isShowNotifikasi, setShowNotifikasi] = useState<boolean>(false);
  const [isShowDetailMemo, setShowDetailMemo] = useState<boolean>(false);
  const [totalPesan, setTotalPesan] = useState<number>(0);
  const [listPesan, setListPesan] = useState<Array<FormMemoPayload>>([]);
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const [detailMemo, setDetailMemo] = useState<FormMemoPayload>({
    id: null,
    id_pesan: null,
    nik_pengirim: null,
    nik_tujuan: null,
    judul_pesan: null,
    isi_pesan: null,
    status_pesan: null,
    updatedAt: null,
  });

  useEffect(() => {
    if (currentUser) {
      socket.emit(`check-memo`, currentUser.data?.username);
      socket.on(`check-memo-${currentUser.data?.username}`, (data) => {
        setListPesan(data.data);
        setTotalPesan(data.totalPesan);
      });
    }
  }, [currentUser]);

  const { mutateAsync: GetDetailMemo, isLoading: isLoadingGetDetailMemo } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_MEMO_NOTIF}`,
      mutationFn: ({ id }: { id: number }) => getDetailMemoNotif(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.GET_DETAIL_MEMO_NOTIF}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailMemo = useCallback(
    async (id: number) => {
      await GetDetailMemo({ id })
        .then((res) => {
          if (res.success) {
            const data = res.data;
            console.log(data);
            setDetailMemo(data);
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailMemo]
  );

  const value = useMemo(
    () => ({
      isShowNotifikasi,
      setShowNotifikasi,
      totalPesan,
      setTotalPesan,
      listPesan,
      setListPesan,
      handleGetDetailMemo,
      isLoadingGetDetailMemo,
      detailMemo,
      setDetailMemo,
      isShowDetailMemo,
      setShowDetailMemo,
    }),
    [
      isShowNotifikasi,
      setShowNotifikasi,
      totalPesan,
      setTotalPesan,
      listPesan,
      setListPesan,
      handleGetDetailMemo,
      isLoadingGetDetailMemo,
      detailMemo,
      setDetailMemo,
      isShowDetailMemo,
      setShowDetailMemo,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
