import { Fragment, useEffect } from "react";
import { useLayout } from "../../../layout/core/LayoutProvider";
import { useParams, useSearchParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { FormEntryKepegawaianWrapper } from "../../../modules/kepegawaian/entry";

const FormEntryKepegawaian = () => {
  //const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  //const id = searchParams.get("id");
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = `Sistem Informasi Bina Ilmi | Form ${
      id ? "Edit" : "Tambah"
    } Kepegawaian`;
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = `Form ${id ? "Edit" : "Tambah"} Kepegawaian`;
      return jasper;
    });
  }, []);
  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <FormEntryKepegawaianWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FormEntryKepegawaian;
