import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/tugas-tambahan";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import moment from "moment";
import dayjs from "dayjs";
import FileInputComponent from "../../../../hope-ui/components/file-input";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import NumberInputComponent from "../../../../hope-ui/components/number-input";

const ModalTugasTambahan = () => {
  const {
    showModalTugasTambahan,
    setShowModalTugasTambahan,
    formikTugasTambahan,
    submitting,
    setSubmitting,
    detailTugasTambahan,
    setResetForm,
    handleDownloadDocTugasTambahan,
    isLoadingGetDetailTugasTambahan,
    isLoadingPostTugasTambahan,
    isLoadingDownloadDocTugasTambahan,
  } = useQueryResponse();

  useEffect(() => {
    if (detailTugasTambahan.id) {
      formikTugasTambahan?.setValues(detailTugasTambahan);
    }
  }, [detailTugasTambahan]);

  let doc_tugas_tambahan_name;
  if (
    typeof formikTugasTambahan?.getFieldProps("doc_tugas_tambahan").value ===
    "string"
  ) {
    const pecah = formikTugasTambahan
      ?.getFieldProps("doc_tugas_tambahan")
      .value.split("/");
    doc_tugas_tambahan_name = pecah[pecah.length - 1];
  }

  return (
    <Modal
      backdrop="static"
      show={showModalTugasTambahan}
      onBackdropClick={() => {
        setShowModalTugasTambahan(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalTugasTambahan(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikTugasTambahan?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailTugasTambahan.id ? "Edit" : "Tambah"} Tugas Tambahan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailTugasTambahan ||
            isLoadingPostTugasTambahan ||
            isLoadingDownloadDocTugasTambahan) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Tugas Tambahan"
                placeholder="Masukkan Tugas Tambahan"
                isRequired
                formik={formikTugasTambahan?.getFieldProps("tugas_tambahan")}
                value={
                  formikTugasTambahan?.getFieldProps("tugas_tambahan").value ??
                  ""
                }
              />
              {submitting && formikTugasTambahan?.errors.tugas_tambahan && (
                <div className="fs-6 text-danger mt-2">
                  {formikTugasTambahan?.errors.tugas_tambahan}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Nomor Surat"
                placeholder="Masukkan Nomor Surat"
                isRequired
                formik={formikTugasTambahan?.getFieldProps("nomor_surat")}
                value={
                  formikTugasTambahan?.getFieldProps("nomor_surat").value ?? ""
                }
              />
              {submitting && formikTugasTambahan?.errors.nomor_surat && (
                <div className="fs-6 text-danger mt-2">
                  {formikTugasTambahan?.errors.nomor_surat}
                </div>
              )}
            </Col>

            <Col lg="6">
              <Datepicker
                label="Tanggal Mulai"
                required
                value={moment(
                  formikTugasTambahan?.getFieldProps("tanggal_mulai").value
                ).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  const value = dayjs(e).format("YYYY-MM-DD");
                  formikTugasTambahan?.setFieldValue("tanggal_mulai", value);
                }}
              />
              {submitting && formikTugasTambahan?.errors.tanggal_mulai && (
                <div className="fs-6 text-danger mt-2">
                  {formikTugasTambahan?.errors.tanggal_mulai}
                </div>
              )}
            </Col>

            <Col lg="6">
              <Datepicker
                label="Tanggal Selesai"
                required
                value={moment(
                  formikTugasTambahan?.getFieldProps("tanggal_selesai").value
                ).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  const value = dayjs(e).format("YYYY-MM-DD");
                  formikTugasTambahan?.setFieldValue("tanggal_selesai", value);
                }}
              />
              {submitting && formikTugasTambahan?.errors.tanggal_selesai && (
                <div className="fs-6 text-danger mt-2">
                  {formikTugasTambahan?.errors.tanggal_selesai}
                </div>
              )}
            </Col>

            <Col lg="12">
              <FileInputComponent
                acceptLabel="Format File PDF"
                label="Upload Dokumen"
                acceptedType={[".pdf"]}
                required
                onChange={(e) => {
                  if (e.target.files) {
                    formikTugasTambahan?.setFieldValue(
                      "doc_tugas_tambahan",
                      e.target.files[0]
                    );
                  } else
                    formikTugasTambahan?.setFieldValue(
                      "doc_tugas_tambahan",
                      null
                    );
                }}
                fileNameLabel={
                  formikTugasTambahan?.getFieldProps("doc_tugas_tambahan").value
                    ?.name ?? doc_tugas_tambahan_name
                }
                resetField={
                  formikTugasTambahan?.getFieldProps("doc_tugas_tambahan").value
                    ? false
                    : true
                }
                maxFileSize={25}
                linkPreview={
                  typeof formikTugasTambahan?.getFieldProps(
                    "doc_tugas_tambahan"
                  ).value === "string"
                    ? formikTugasTambahan?.getFieldProps("doc_tugas_tambahan")
                        .value
                    : null
                }
                downloadPreview={() =>
                  handleDownloadDocTugasTambahan(
                    formikTugasTambahan?.getFieldProps("doc_tugas_tambahan")
                      .value,
                    doc_tugas_tambahan_name
                  )
                }
              />
              {submitting && formikTugasTambahan?.errors.doc_tugas_tambahan && (
                <div className="fs-6 text-danger mt-2">
                  {formikTugasTambahan?.errors.doc_tugas_tambahan}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalTugasTambahan;
