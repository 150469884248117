import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormMutasiPayload = {
  id: number | string | null;
  id_mutasi: string | null;
  nik_gtk: string | null;
  nomor_surat_mutasi: string | null;
  unit_kerja_tujuan: string | null;
  tanggal_mutasi: Date | Dayjs | null;
  tmt_mutasi: Date | Dayjs | null;
  unit_kerja_sebelumnya: string | null;
  alasan_mutasi: string | null;
  doc_mutasi: File | string | null;
};
export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalMutasi: boolean;
  setShowModalMutasi: Dispatch<SetStateAction<boolean>>;
  formikMutasi?: FormikProps<FormMutasiPayload>;
  formikMutasiValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostMutasi: (data: FormData) => void;
  isLoadingPostMutasi: boolean;
  handleGetDetailMutasi: (id: string) => void;
  isLoadingGetDetailMutasi: boolean;
  detailMutasi: FormMutasiPayload;
  setDetailMutasi: Dispatch<SetStateAction<FormMutasiPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocMutasi: (url: string, fileName: string) => void;
  isLoadingDownloadDocMutasi: boolean;
  handleDeleteMutasi: (id: string) => void;
  isLoadingDeleteMutasi: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalMutasi: false,
  setShowModalMutasi: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostMutasi: () => {},
  isLoadingPostMutasi: false,
  handleGetDetailMutasi: () => {},
  isLoadingGetDetailMutasi: false,
  detailMutasi: {
    id: null,
    id_mutasi: null,
    nik_gtk: null,
    nomor_surat_mutasi: "",
    tanggal_mutasi: null,
    tmt_mutasi: null,
    alasan_mutasi: "",
    doc_mutasi: "",
    unit_kerja_sebelumnya: "",
    unit_kerja_tujuan: "",
  },
  setDetailMutasi: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocMutasi: () => {},
  isLoadingDownloadDocMutasi: false,
  handleDeleteMutasi: () => {},
  isLoadingDeleteMutasi: false,
};
