import { Card, Col, Row } from "react-bootstrap";
import Select2 from "../../../../../hope-ui/components/select-2";
import { useQueryRequest } from "../../../../../provider/query-request/hak-akses/kelola-role-menu";
import { useQueryRequest as useQueryRequestMenus } from "../../../../../provider/query-request/menu";
import { useQueryResponse } from "../../../../../provider/query-response/hak-akses/kelola-role-menu";
import { ShowLimitDT } from "../../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../../hope-ui/components/table/SearchInput";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  const updateStateMenu = useQueryRequestMenus().updateState;
  const {
    appName,
    setAppName,
    rolesId,
    setRolesId,
    allApp,
    isLoadingAllApp,
    allHakAkses,
    isLoadingAllHakAkses,
    setIsShowModal,
  } = useQueryResponse();
  const optionsApp = allApp?.data?.map((val) => {
    return {
      value: val.app_name,
      label: val.app_name,
    };
  });

  const optionsHakAkses = allHakAkses?.data?.map((val) => {
    return {
      value: val.id,
      label: val.name,
    };
  });

  return (
    <Card.Header className="pt-0 px-0">
      <Row className="gy-3 mb-4">
        <Col md="6">
          <Select2
            label="Aplikasi"
            placeholder="Pilih Aplikasi"
            isRequired
            onChange={(e: any) => {
              const value = e?.value;
              setAppName(value);
              updateState({
                app_name: value,
              });
              updateStateMenu({
                app_name: value,
              });
            }}
            value={appName}
            options={optionsApp}
            isLoading={isLoadingAllApp}
          />
        </Col>
        <Col md="6">
          <Select2
            label="Level Akses"
            placeholder="Pilih Level Akses"
            isRequired
            onChange={(e: any) => {
              const value = e?.value;
              setRolesId(value);
              updateState({
                rolesId: value,
              });
            }}
            value={rolesId}
            options={optionsHakAkses}
            isLoading={isLoadingAllHakAkses}
          />
        </Col>
      </Row>

      {!appName || !rolesId || appName.length === 0 || rolesId === 0 ? (
        <></>
      ) : (
        <>
          <div className="d-flex flex-row justify-content-end align-items-center mb-1 gap-2">
            {/* <button
              type="button"
              className="btn btn-sm btn-warning"
              onClick={() => {
                setIsShowModal(true);
              }}
            >
              <i className="fas fa-gear me-2"></i> Kelola Hak Akses Menu
            </button> */}
            {/* <button type="button" className="btn btn-sm btn-primary">
              <i className="fas fa-floppy-disk me-2"></i>
              Simpan
            </button> */}
          </div>
          <div className="row pt-3 px-0">
            <div className="col-md-6 col-12">
              <ShowLimitDT
                onChange={(e) =>
                  updateState({ size: parseInt(e.target.value) })
                }
                limit={state.size}
              />
            </div>
            <div className="col-md-6 col-12 ">
              <SearchInputDT
                state={(keyword: string) => {
                  updateState({ search: keyword });
                }}
                value={state.search ? state.search : ""}
              />
            </div>
          </div>
        </>
      )}
    </Card.Header>
  );
};

export default Toolbar;
