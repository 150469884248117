import { Column } from "react-table";
import { Header } from "../../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../../provider/query-request/memo";
import { useQueryResponse } from "../../../../../../provider/query-response/memo";
import moment from "moment";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Subject"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "judul_pesan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].judul_pesan}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tanggal & Jam"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "createdAt",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <i className="far fa-calendar"></i>
          <div>
            {props.data[props.row.index].updatedAt
              ? moment(props.data[props.row.index].updatedAt).format(
                  "DD MMM YYYY"
                )
              : moment(props.data[props.row.index].createdAt).format(
                  "DD MMM YYYY"
                )}
          </div>
        </div>

        <div className="d-flex flex-row justify-content-center align-items-center gap-2">
          <i className="far fa-clock"></i>
          <div>
            {props.data[props.row.index].updatedAt
              ? moment(props.data[props.row.index].updatedAt).format("HH:mm")
              : moment(props.data[props.row.index].createdAt).format("HH:mm")}
          </div>
        </div>
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Status"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "status_pesan",
    Cell: ({ ...props }) => {
      const status_pesan = props.data[props.row.index].status_pesan;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {status_pesan === "Belum Dibaca" && (
            <>
              <i className="fas fa-check-double text-gray-100"></i>{" "}
              {status_pesan}
            </>
          )}

          {status_pesan === "Dilihat" && (
            <>
              <i className="fas fa-check-double text-info"></i> {status_pesan}
            </>
          )}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Detail"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action_detail_memo",
    Cell: ({ ...props }) => {
      const { handleGetDetailMemo, setShowDetailMemo } = useQueryResponse();
      const id = props.data[props.row.index].id;
      return (
        <button
          type="button"
          className="btn btn-sm btn-info"
          onClick={() => {
            setShowDetailMemo(true);
            if (handleGetDetailMemo) handleGetDetailMemo(id);
          }}
        >
          Lihat Detail
          <i className="fa-solid fa-angles-right ms-2"></i>
        </button>
      );
    },
  },
];
