import { FC } from "react";
import { useQueryResponse } from "../../../../provider/query-response/kepegawaian";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";

type Props = {
  data: any;
};

const AksiCell: FC<Props> = ({ data }) => {
  const { setShowModalDetailKepegawaian } = useQueryResponse();
  const { setNIKGTK, handleGetDetailGTK } = useQueryResponseDetailGTK();

  return (
    <div className="px-3">
      <button
        type="button"
        className="btn btn-sm btn-info"
        onClick={() => {
          setShowModalDetailKepegawaian(true);
          setNIKGTK(data.nik_gtk);
          handleGetDetailGTK(data.nik_gtk);
        }}
      >
        Kepegawaian
        <i className="fa fa-pen ms-2"></i>
      </button>
    </div>
  );
};

export default AksiCell;
