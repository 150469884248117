import { FC } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useQueryResponse } from "../../../../../provider/query-response/karya-tulis";

type Props = {
  data: any;
};
const AksiCell: FC<Props> = ({ data }) => {
  const {
    setShowModalKaryaTulis,
    handleGetDetailKaryaTulis,
    handleDeleteKaryaTulis,
  } = useQueryResponse();
  return (
    <DropdownButton
      as={ButtonGroup}
      key={"end"}
      drop={"end"}
      variant="primary"
      title={` Pilih Aksi `}
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        onClick={(e) => {
          setShowModalKaryaTulis(true);
          handleGetDetailKaryaTulis(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Edit <i className="fa fa-pencil text-info ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={(e) => {
          handleDeleteKaryaTulis(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Hapus <i className="fa fa-trash text-danger ms-1"></i>
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default AksiCell;
