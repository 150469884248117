import { FC } from "react";
import { useQueryResponse } from "../../../../provider/query-response/dashboard";
import { useQueryRequest } from "../../../../provider/query-request/dashboard/detail-presensi-gtk";
import { LooseValue } from "react-calendar/dist/cjs/shared/types";

type Props = {
  data?: any;
  tanggal: LooseValue;
};

const ColumnBody: FC<Props> = ({ data, tanggal }) => {
  const { setIsShowDetailPresensiGTK } = useQueryResponse();

  const { updateState } = useQueryRequest();

  return (
    <tbody className="fs-6 text-center">
      {data &&
        data.data.map((val) => {
          return (
            <tr>
              <td
                className="cursor-pointer"
                onClick={() => {
                  let filter = [
                    {
                      name: "unit_kerja",
                      value: `${val.unit_kerja}`,
                      table: "gtk",
                    },
                    {
                      name: "status_absen",
                      value: null,
                      table: "",
                    },
                    {
                      name: "tanggal_absen",
                      value: tanggal,
                      table: "absensi_gtk",
                    },
                  ];

                  updateState({
                    search: "",
                    size: 10,
                    page: 1,
                    sort_by: "",
                    sort_type: "asc",
                    filter: JSON.stringify(filter),
                  });
                  setIsShowDetailPresensiGTK(true);
                }}
              >
                {val.unit_kerja}
              </td>
              <td
                className={` ${
                  val.N > 0 ? "bg-success text-white cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (val.N > 0) {
                    let filter = [
                      {
                        name: "unit_kerja",
                        value: `${val.unit_kerja}`,
                        table: "gtk",
                      },
                      {
                        name: "status_absen",
                        value: `N`,
                        table: "",
                      },
                      {
                        name: "tanggal_absen",
                        value: tanggal,
                        table: "absensi_gtk",
                      },
                    ];

                    updateState({
                      search: "",
                      size: 10,
                      page: 1,
                      sort_by: "",
                      sort_type: "asc",
                      filter: JSON.stringify(filter),
                    });
                    setIsShowDetailPresensiGTK(true);
                  }
                }}
              >
                {val.N}
              </td>
              <td
                className={` ${
                  val.TTKD > 0 ? "bg-yellow text-white cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (val.TTKD > 0) {
                    let filter = [
                      {
                        name: "unit_kerja",
                        value: `${val.unit_kerja}`,
                        table: "gtk",
                      },
                      {
                        name: "status_absen",
                        value: `TTKD`,
                        table: "",
                      },
                      {
                        name: "tanggal_absen",
                        value: tanggal,
                        table: "absensi_gtk",
                      },
                    ];

                    updateState({
                      search: "",
                      size: 10,
                      page: 1,
                      sort_by: "",
                      sort_type: "asc",
                      filter: JSON.stringify(filter),
                    });
                    setIsShowDetailPresensiGTK(true);
                  }
                }}
              >
                {val.TTKD}
              </td>
              <td
                className={` ${
                  val.TKD > 0 ? "bg-danger text-white cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (val.TKD > 0) {
                    let filter = [
                      {
                        name: "unit_kerja",
                        value: `${val.unit_kerja}`,
                        table: "gtk",
                      },
                      {
                        name: "status_absen",
                        value: `TKD`,
                        table: "",
                      },
                      {
                        name: "tanggal_absen",
                        value: tanggal,
                        table: "absensi_gtk",
                      },
                    ];

                    updateState({
                      search: "",
                      size: 10,
                      page: 1,
                      sort_by: "",
                      sort_type: "asc",
                      filter: JSON.stringify(filter),
                    });
                    setIsShowDetailPresensiGTK(true);
                  }
                }}
              >
                {val.TKD}
              </td>
              <td
                className={` ${
                  val.TH > 0 ? "bg-gray text-white cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (val.TH > 0) {
                    let filter = [
                      {
                        name: "unit_kerja",
                        value: `${val.unit_kerja}`,
                        table: "gtk",
                      },
                      {
                        name: "status_absen",
                        value: `TH`,
                        table: "",
                      },
                      {
                        name: "tanggal_absen",
                        value: tanggal,
                        table: "absensi_gtk",
                      },
                    ];

                    updateState({
                      search: "",
                      size: 10,
                      page: 1,
                      sort_by: "",
                      sort_type: "asc",
                      filter: JSON.stringify(filter),
                    });
                    setIsShowDetailPresensiGTK(true);
                  }
                }}
              >
                {val.TH}
              </td>
              <td>{val.total}</td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default ColumnBody;
