import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/karya-buku";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/karya-buku";
import ModalKaryaBuku from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";
const KaryaBuku = () => {
  const { isLoadingFetchData, listData, isLoadingDeleteKaryaBuku } =
    useQueryResponse();
  return (
    <>
      {isLoadingDeleteKaryaBuku && <FetchLoading />}
      <Toolbar />
      <ModalKaryaBuku />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default KaryaBuku;
