import { Card } from "react-bootstrap";
import {
  useQueryResponsePagination,
  QueryResponseProvider,
  useQueryResponse,
} from "../../provider/query-response/prihal-alasan";

import { 
  QueryRequestProvider, 
  useQueryRequest
} from "../../provider/query-request/prihal-alasan";
import { DataTable } from "../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";
import ModalTambahPrihalAlasan from "./model/model-tambah-data";

const DaftarPrihalAlasanPresensiGTK = () => {
  const {listData, isLoadingFetchData} = useQueryResponse();
  return (
    <>
      <ModalTambahPrihalAlasan />
      <Toolbar />
      <Card.Body className="pt-0">
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={listData?.data}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </>
  );
};

export const DaftarPrihalAlasanPresensiGTKWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
    <DaftarPrihalAlasanPresensiGTK />
    </QueryResponseProvider>
  </QueryRequestProvider>
);
