import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FormInpassingPayload,
  initialQueryResponse,
} from "../../../models/inpassing/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { postInpassing } from "../../../api/inpassing/_request";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";
import { useAuth } from "../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const nik_gtk: any = currentUser?.data?.username;
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const formikInpassingValidationSchema = Yup.object().shape({
    pangkat_golongan: Yup.string().required(
      "Pangkat Golongan Tidak Boleh Kosong"
    ),
    nomor_sk_inpassing: Yup.string().required(
      "Nomor SK Inpassing Tidak Boleh Kosong"
    ),
    tanggal_sk_inpassing: Yup.string().required(
      "Tanggal SK Inpassing Tidak Boleh Kosong"
    ),
    tmt_sk_inpassing: Yup.string().required(
      "TMT SK Inpassing Tidak Boleh Kosong"
    ),
    angka_kredit: Yup.string().required("Angka Kredit Tidak Boleh Kosong"),
    masa_kerja: Yup.string().required("Masa Kerja Tidak Boleh Kosong"),
  });

  const formikInpassing = useFormik<FormInpassingPayload>({
    initialValues: {
      id: null,
      id_inpassing: null,
      nomor_sk_inpassing: "",
      tanggal_sk_inpassing: null,
      pangkat_golongan: "",
      masa_kerja: "",
      tmt_sk_inpassing: null,
      angka_kredit: "",
      nik_gtk,
    },
    enableReinitialize: false,
    validationSchema: formikInpassingValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostInpassing(payload);
    },
  });

  const { mutateAsync: PostInpassing, isLoading: isLoadingPostInpassing } =
    useMutation({
      mutationKey: `${QUERIES.POST_INPASSING}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postInpassing(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_INPASSING}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostInpassing = useCallback(
    async (payload: any) => {
      await PostInpassing({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailGTK(res.data.nik_gtk);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostInpassing]
  );

  const value = useMemo(
    () => ({
      formikInpassing,
      formikInpassingValidationSchema,
      submitting,
      setSubmitting,
      handlePostInpassing,
      isLoadingPostInpassing,
    }),
    [
      formikInpassing,
      formikInpassingValidationSchema,
      submitting,
      setSubmitting,
      handlePostInpassing,
      isLoadingPostInpassing,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
