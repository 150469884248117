import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormRiwayatPekerjaanPayload = {
  id: number | string | null;
  id_riwayat_pekerjaan: string | null;
  nik_gtk: string | null;
  jenis_lembaga: string | null;
  posisi_pekerjaan: string | null;
  jenjang_kepegawaian: string | null;
  tahun_masuk: number | string | null;
  tahun_keluar: number | string | null;
  alasan_resign: string | null;
  doc_riwayat_pekerjaan: File | string | null;
};
export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalRiwayatPekerjaan: boolean;
  setShowModalRiwayatPekerjaan: Dispatch<SetStateAction<boolean>>;
  formikRiwayatPekerjaan?: FormikProps<FormRiwayatPekerjaanPayload>;
  formikRiwayatPekerjaanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostRiwayatPekerjaan: (data: FormData) => void;
  isLoadingPostRiwayatPekerjaan: boolean;
  handleGetDetailRiwayatPekerjaan: (id: string) => void;
  isLoadingGetDetailRiwayatPekerjaan: boolean;
  detailRiwayatPekerjaan: FormRiwayatPekerjaanPayload;
  setDetailRiwayatPekerjaan: Dispatch<
    SetStateAction<FormRiwayatPekerjaanPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocRiwayatPekerjaan: (url: string, fileName: string) => void;
  isLoadingDownloadDocRiwayatPekerjaan: boolean;
  handleDeleteRiwayatPekerjaan: (id: string) => void;
  isLoadingDeleteRiwayatPekerjaan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalRiwayatPekerjaan: false,
  setShowModalRiwayatPekerjaan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostRiwayatPekerjaan: () => {},
  isLoadingPostRiwayatPekerjaan: false,
  handleGetDetailRiwayatPekerjaan: () => {},
  isLoadingGetDetailRiwayatPekerjaan: false,
  detailRiwayatPekerjaan: {
    id: null,
    id_riwayat_pekerjaan: null,
    nik_gtk: null,
    jenis_lembaga: "",
    jenjang_kepegawaian: "",
    posisi_pekerjaan: "",
    tahun_keluar: "",
    tahun_masuk: "",
    doc_riwayat_pekerjaan: null,
    alasan_resign: null,
  },
  setDetailRiwayatPekerjaan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocRiwayatPekerjaan: () => {},
  isLoadingDownloadDocRiwayatPekerjaan: false,
  handleDeleteRiwayatPekerjaan: () => {},
  isLoadingDeleteRiwayatPekerjaan: false,
};
