import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/penilaian";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import NumberInputComponent from "../../../../hope-ui/components/number-input";

const ModalPenilaian = () => {
  const {
    showModalPenilaian,
    setShowModalPenilaian,
    formikPenilaian,
    submitting,
    setSubmitting,
    detailPenilaian,
    setResetForm,
    isLoadingGetDetailPenilaian,
    isLoadingPostPenilaian,
  } = useQueryResponse();

  useEffect(() => {
    if (detailPenilaian.id) {
      formikPenilaian?.setValues(detailPenilaian);
    }
  }, [detailPenilaian]);

  return (
    <Modal
      backdrop="static"
      show={showModalPenilaian}
      onBackdropClick={() => {
        setShowModalPenilaian(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalPenilaian(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikPenilaian?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailPenilaian.id ? "Edit" : "Tambah"} Penilaian
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailPenilaian || isLoadingPostPenilaian) && (
            <FetchLoading />
          )}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Penyelenggara"
                placeholder="Masukkan Penyelenggara"
                isRequired
                formik={formikPenilaian?.getFieldProps("penyelenggara")}
                value={
                  formikPenilaian?.getFieldProps("penyelenggara").value ?? ""
                }
              />
              {submitting && formikPenilaian?.errors.penyelenggara && (
                <div className="fs-6 text-danger mt-2">
                  {formikPenilaian?.errors.penyelenggara}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Jenis Tes"
                placeholder="Masukkan Jenis Tes"
                isRequired
                formik={formikPenilaian?.getFieldProps("jenis_tes")}
                value={formikPenilaian?.getFieldProps("jenis_tes").value ?? ""}
              />
              {submitting && formikPenilaian?.errors.jenis_tes && (
                <div className="fs-6 text-danger mt-2">
                  {formikPenilaian?.errors.jenis_tes}
                </div>
              )}
            </Col>

            <Col lg="6">
              <NumberInputComponent
                label="Skor"
                placeholder="Masukkan Skor"
                required
                formik={formikPenilaian?.getFieldProps("skor")}
                value={formikPenilaian?.getFieldProps("skor").value ?? ""}
              />
              {submitting && formikPenilaian?.errors.skor && (
                <div className="fs-6 text-danger mt-2">
                  {formikPenilaian?.errors.skor}
                </div>
              )}
            </Col>

            <Col lg="6">
              <NumberInputComponent
                label="Tahun"
                placeholder="Masukkan Tahun"
                required
                formik={formikPenilaian?.getFieldProps("tahun")}
                value={formikPenilaian?.getFieldProps("tahun").value ?? ""}
              />
              {submitting && formikPenilaian?.errors.tahun && (
                <div className="fs-6 text-danger mt-2">
                  {formikPenilaian?.errors.tahun}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalPenilaian;
