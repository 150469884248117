import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../provider/query-request/riwayat-pekerjaan";
import AksiCell from "./AksiCell";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jurusan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jurusan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].jurusan}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenjang Pendidikan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jenjang_pendidikan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].jenjang_pendidikan}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Perguruan Tinggi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_perguruan_tinggi",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nama_perguruan_tinggi}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tahun Masuk"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tahun_masuk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].tahun_masuk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tahun Lulus"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tahun_lulus",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].tahun_lulus}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Gelar Akademik"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "gelar_akademik",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].gelar_akademik}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="IPK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "ipk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].ipk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No. Ijazah"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nomor_ijazah",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nomor_ijazah}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
      />
    ),
    id: "action_kirim_memo",
    Cell: ({ ...props }) => {
      return <AksiCell data={props.data[props.row.index]} />;
    },
  },
];
