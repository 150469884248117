import { FC } from "react";

type Props = {
  data?: any;
};

const ColumnFooter: FC<Props> = (data) => {
  const unit_kerja = data.data ? data.data[data.data.length - 1]["data"] : [];
  const jumlah = data.data ? data.data[data.data.length - 1]["jumlah"] : 0;
  return (
    <tfoot>
      <tr>
        <th colSpan={3} className="text-center">
          Jumlah
        </th>
        {unit_kerja?.map((val, idx) => {
          return <th className="text-center">{val.total_gtk}</th>;
        })}
        <th className="text-center">{jumlah} </th>
      </tr>
    </tfoot>
  );
};

export default ColumnFooter;
