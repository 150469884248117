import { Column } from "react-table";
import { Header } from "../../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-bpi/daftar-absensi";
import NamaGTKCell from "../../../../../../hope-ui/components/table/NamaGTKCell";
import moment from "moment";
import AksiCell from "./AksiCell";
import { formatRupiah } from "../../../../../../helpers/helpers";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => <AksiCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tanggal"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "riwayat_tanggal",
    Cell: ({ ...props }) => (
      <label>
        <i className="far fa-calendar me-2"></i>
        {moment(props.data[props.row.index].riwayat_tanggal).format(
          "DD MMM YYYY"
        )}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Kelompok BPI"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "kelompok_bpi.nama_kelompok_bpi",
    Cell: ({ ...props }) => (
      <label>
        {" "}
        {props.data[props.row.index].kelompok_bpi.nama_kelompok_bpi}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="MC"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "gtk_mc.nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].gtk_mc.nik_gtk}
        label={props.data[props.row.index].gtk_mc.nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Materi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "materi",
    Cell: ({ ...props }) => (
      <label> {props.data[props.row.index].materi}</label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tempat"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tempat",
    Cell: ({ ...props }) => (
      <label> {props.data[props.row.index].tempat}</label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Batas Tilawah Halaman"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "batas_tilawah_halaman",
    Cell: ({ ...props }) => (
      <label>
        {" "}
        {formatRupiah(
          props.data[props.row.index].batas_tilawah_halaman.toString()
        )}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Infaq"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "infaq",
    Cell: ({ ...props }) => (
      <label>
        {" "}
        {formatRupiah(props.data[props.row.index].infaq.toString(), "Rp ")}
      </label>
    ),
  },
];
