import { 
  FC, 
  createContext, 
  useCallback, 
  useContext, 
  useEffect, 
  useMemo, 
  useState 
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { initialQueryState } from "../../../../models/global";
import { 
    useMutation, 
    useQuery, 
    useQueryClient 
  } from "react-query";
import { useQueryRequest } from "../../../query-request/absensi-gtk";
import { 
  FormAbsensiGtkPayload, 
  FormCatatanGtkPayload, 
  FormPrihalAlasanPayload, 
  initialQueryResponse 
} from "../../../../models/absensi-gtk/laporan-harian/_queryResponse";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { 
  getDetailAbsensiGtk, 
  postCatatanGtk,
  getGtkTidakHadir
} from "../../../../api/absensi-gtk/_request";


import { count } from "console";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { getReadPrihalAlasan } from "../../../../api/prihal-alasan/_request";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  
    const queryClient = useQueryClient();
    const { state, updateState } = useQueryRequest();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [resetForm, setResetForm] = useState<boolean>(false);
    const [DetailAbsensiGtk, setDetailAbsensiGtk] =
    useState<FormAbsensiGtkPayload>(initialQueryResponse.DetailAbsensiGtk);
    const [DetailCatatanAbsensiGtk, setDetailCatatanAbsensiGtk] =
    useState<FormCatatanGtkPayload>(initialQueryResponse.DetailCatatanAbsensiGtk);
    const [allPrihalAlasan, setAllPrihalAlasan] = useState<Array<FormPrihalAlasanPayload>>([]);

    const [CatatanGtk, setCatatanGtk] =
    useState<boolean>(false);

    state.col_header = `["nik_gtk", "nama_gtk", "unit_kerja", "email", "nomor_hp", "absensi_gtk.jam_masuk", "absensi_gtk.jam_keluar", "absensi_gtk.scan_masuk", "absensi_gtk.scan_keluar", "absensi_gtk.status_absen", "absensi_gtk.tanggal_absen", "absensi_gtk.keterangan"]`;
   
    const [query, setQuery] = useState<string>(
        stringifyRequestQuery(state, "gtk-tidak-hadir")
      );
      const updatedQuery = useMemo(
        () => stringifyRequestQuery(state, "gtk-tidak-hadir"),
        [state]
      );
    
      useEffect(() => {
        if (query !== updatedQuery) {
          setQuery(updatedQuery);
        }
      }, [query, updatedQuery]);
  
      const {
        data: listData,
        isFetching: isLoadingFetchData,
        refetch: refetchListData,
      } = useQuery(`${QUERIES.GET_GTK_TIDAK_HADIR}-${query}`, () => getGtkTidakHadir(query), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (err) => {
          console.error(err);
        },
        enabled: query !== "?undefined",
      });





    const {
      mutateAsync: PostCatatanGtk,
      isLoading: isLoadingPostCatatanGtk,
    } = useMutation({
      mutationKey: `${QUERIES.POST_CATATAN_ABSENSI_GTK}`,
      mutationFn: ({ payload }: { payload: FormData;}) =>
      postCatatanGtk(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_CATATAN_ABSENSI_GTK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });


    const handlePostCatatanGtk = useCallback(
      async (payload: any,) => {
        await PostCatatanGtk({payload})
          .then((res) => {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                if (refetchListData && res.success) {
                  setCatatanGtk(false);
                  setResetForm(true);
                  refetchListData();
                }
              }
            });
          })
          .catch((error) => {
            SweetAlert({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: error.data ? error.data.message : error,
              allowOutsideClick: false,
            }).then((res: any) => {
              if (res.isConfirmed) {
              }
            });
          });
      },
      [postCatatanGtk]
    );





    const {
      mutateAsync: GetDetailAbsensiGTK,
      isLoading: isLoadingGetDetailGTK,
    } = useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_ABSENSI_GTK}`,
      mutationFn: ({ nik_gtk }: { nik_gtk: string }) => getDetailAbsensiGtk(nik_gtk),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.GET_DETAIL_ABSENSI_GTK}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });
  
    const handleCatatanGtk = useCallback(
      async (nik_gtk: string) => {
        await GetDetailAbsensiGTK({ nik_gtk })
          .then((res) => {
            if (res.success) {
              setDetailAbsensiGtk(res.data);
            } else {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                html: res.message,
                allowOutsideClick: false,
              });
              return;
            }
          })
          .catch((error) => {
            SweetAlert({
              icon: "error",
              title: "Terjadi Kesalahan",
              text: error.data ? error.data.message : error,
              allowOutsideClick: false,
            }).then((res: any) => {
              if (res.isConfirmed) {
              }
            });
          });
      },
      [GetDetailAbsensiGTK]
    );

    const { mutateAsync: GetPrihalAlasan, isLoading: isLoadingGetAllPrihalAlasan } =
    useMutation({
      mutationKey: `${QUERIES.GET_READ_PRIHAL_ALASAN}`,
      mutationFn: () => getReadPrihalAlasan(),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_READ_PRIHAL_ALASAN}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetAllPrihalAlasan = useCallback(async () => {
    await GetPrihalAlasan()
      .then((res) => {
        if (!res.success) {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          });

          return;
        }
        setAllPrihalAlasan(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetPrihalAlasan]);


  const [ActiveTab, setActiveTab] = useState<number>(1);
  const value = useMemo(
    () => ({
      isLoadingFetchData,
      ActiveTab,
      setActiveTab,
      DetailAbsensiGtk,
      handleCatatanGtk,
      handlePostCatatanGtk,
      setDetailAbsensiGtk,
      CatatanGtk,
      setCatatanGtk,
      isLoadingPostCatatanGtk,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      DetailCatatanAbsensiGtk,
      setDetailCatatanAbsensiGtk,
      query,
      listData, 
      refetchListData,

      handleGetAllPrihalAlasan,
      isLoadingGetAllPrihalAlasan,
      allPrihalAlasan,
      setAllPrihalAlasan,

    }),
    [
      isLoadingFetchData,
      ActiveTab,
      setActiveTab,
      DetailAbsensiGtk,
      handleCatatanGtk,
      handlePostCatatanGtk,
      setDetailAbsensiGtk,
      CatatanGtk,
      setCatatanGtk,
      isLoadingPostCatatanGtk,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      DetailCatatanAbsensiGtk,
      setDetailCatatanAbsensiGtk,
      query,
      listData, 
      refetchListData,

      handleGetAllPrihalAlasan,
      isLoadingGetAllPrihalAlasan,
      allPrihalAlasan,
      setAllPrihalAlasan,

    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
