import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormSebabNonAktifPayload,
  initialQueryResponse,
} from "../../../models/data-gtk/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../query-request/data-gtk";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import {
  exportExcelDataGTK,
  exportPDFDataGTK,
  postDataGTK,
  putDataGTK,
  getAllTableFilterGTK,
  getDataGTKChecklistFilter,
  getAllDataGTK,
  getAllSebabNonAktif,
  postSebabNonAktif,
  putResetPasswordGTK,
} from "../../../api/data-gtk/_request";
import { initialQueryState } from "../../../models/global";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { getDetailGTK } from "../../../api/detail-gtk/_request";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { status } = useParams();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nik_gtk", "nama_gtk", "unit_kerja", "sebab_non_aktif"]`;

  const [statusAktivasi, setStatusAktivasi] = useState<boolean>(true);
  useEffect(() => {
    setStatusAktivasi(status === "aktif" ? true : false);
  }, [status]);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "data-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "data-gtk"),
    [state]
  );

  const [detailGTK, setDetailGTK] = useState<any>();
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showModalNonAktifkanGTK, setShowModalNonAktifkanGTK] =
    useState<boolean>(false);
  const [allTableFilterGTK, setAllTableFilterGTK] = useState<Array<any>>([]);
  const [allTableFilterGTKChecked, setAllTableFilterGTKChecked] = useState<
    Array<any>
  >([
    {
      table_name: "gtk",
      column: ["nama_gtk", "nik_gtk", "unit_kerja"],
    },
  ]);

  const [allSebabNonAktif, setAllSebabNonAktif] = useState<
    Array<FormSebabNonAktifPayload>
  >(initialQueryResponse.allSebabNonAktif);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_DATA_GTK_CHECKLIST_FILTER}-${query}&status_bekerja=${status}`,
    () => getDataGTKChecklistFilter(`${query}&status_bekerja=${status}`),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const { mutateAsync: PutDataGTK, isLoading: isLoadingPutDataGTK } =
    useMutation({
      mutationKey: `${QUERIES.PUT_DATA_GTK}`,
      mutationFn: ({ payload, id }: { payload: FormData; id: string }) =>
        putDataGTK(payload, id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.PUT_DATA_GTK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePutDataGTK = useCallback(
    async (payload: any, id: string) => {
      await PutDataGTK({ payload, id })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData) refetchListData();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PutDataGTK]
  );

  const { mutateAsync: PostDataGTK, isLoading: isLoadingPostDataGTK } =
    useMutation({
      mutationKey: `${QUERIES.POST_DATA_GTK}`,
      mutationFn: ({ payload }: { payload: FormData }) => postDataGTK(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_DATA_GTK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostDataGTK = useCallback(
    async (payload: any) => {
      await PostDataGTK({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed) {
              const nik_gtk = res.nik_gtk;
              if (nik_gtk)
                window.location.href = `${process.env.REACT_APP_BASE_URL}data-gtk/form/${nik_gtk}`;
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostDataGTK]
  );

  const { mutateAsync: GetDetailGTK, isLoading: isLoadingGetDetailGTK } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_GTK}`,
      mutationFn: ({ id }: { id: string }) => getDetailGTK(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_GTK}`]);
          setDetailGTK(response);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailGTK = useCallback(
    async (id: string) => {
      await GetDetailGTK({ id }).catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
    },
    [GetDetailGTK]
  );

  const {
    mutateAsync: GetAllTableFilterGTK,
    isLoading: isLoadingGetAllTableFilterGTK,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_TABLE_FILTER_GTK}`,
    mutationFn: () => getAllTableFilterGTK(),
    onSuccess: async (res: any, variables) => {
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_TABLE_FILTER_GTK}`,
        ]);
        setAllTableFilterGTK(res.data);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllTableFilterGTK = useCallback(async () => {
    await GetAllTableFilterGTK().catch((error) => {
      SweetAlert({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: error.data ? error.data.message : error,
        allowOutsideClick: false,
      }).then((res: any) => {
        if (res.isConfirmed) {
        }
      });
    });
  }, [GetAllTableFilterGTK]);

  const {
    mutateAsync: ExportExcelDataGTK,
    isLoading: isLoadingExportExcelDataGTK,
  } = useMutation({
    mutationKey: `${QUERIES.EXPORT_EXCEL_DATA_GTK}`,
    mutationFn: () => exportExcelDataGTK(query),
    onSuccess: async (res: any, variables) => {
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.EXPORT_EXCEL_DATA_GTK}`,
        ]);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleExportExcelDataGTK = useCallback(async () => {
    await ExportExcelDataGTK().catch((error) => {
      SweetAlert({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: error.data ? error.data.message : error,
        allowOutsideClick: false,
      }).then((res: any) => {
        if (res.isConfirmed) {
        }
      });
    });
  }, [ExportExcelDataGTK]);

  const {
    mutateAsync: ExportPDFDataGTK,
    isLoading: isLoadingExportPDFDataGTK,
  } = useMutation({
    mutationKey: `${QUERIES.EXPORT_PDF_DATA_GTK}`,
    mutationFn: () => exportPDFDataGTK(query),
    onSuccess: async (res: any, variables) => {
      try {
        await queryClient.invalidateQueries([`${QUERIES.EXPORT_PDF_DATA_GTK}`]);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleExportPDFDataGTK = useCallback(async () => {
    await ExportPDFDataGTK().catch((error) => {
      SweetAlert({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: error.data ? error.data.message : error,
        allowOutsideClick: false,
      }).then((res: any) => {
        if (res.isConfirmed) {
        }
      });
    });
  }, [ExportPDFDataGTK]);

  const {
    mutateAsync: GetAllSebabNonAktif,
    isLoading: isLoadingGetAllSebabNonAktif,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_SEBAB_NON_AKTIF}`,
    mutationFn: () => getAllSebabNonAktif(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_SEBAB_NON_AKTIF}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllSebabNonAktif = useCallback(async () => {
    await GetAllSebabNonAktif()
      .then((res) => {
        setAllSebabNonAktif(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetAllSebabNonAktif]);

  const {
    mutateAsync: PostSebabNonAktif,
    isLoading: isLoadingPostSebabNonAktif,
  } = useMutation({
    mutationKey: `${QUERIES.POST_NON_AKTIFKAN_GTK}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postSebabNonAktif(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_NON_AKTIFKAN_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostSebabNonAktif = useCallback(
    async (payload: any) => {
      SweetAlert({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        html: `Mohon diperiksa kembali bahwa tenaga kerja yang akan dinonaktifkan benar adanya disebabkan karena <b>${payload.get(
          "sebab_non_aktif"
        )}</b>`,
        allowOutsideClick: false,
        isShowCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Lanjutkan",
      }).then(async (swal: any) => {
        if (swal.isConfirmed)
          await PostSebabNonAktif({ payload })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  setShowModalNonAktifkanGTK(false);
                  if (refetchListData) {
                    refetchListData();
                  }
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
      });
    },
    [PostSebabNonAktif]
  );

  const {
    mutateAsync: ResetPasswordGTK,
    isLoading: isLoadingResetPasswordGTK,
  } = useMutation({
    mutationKey: `${QUERIES.PUT_RESET_PASSWORD_GTK}`,
    mutationFn: ({ id }: { id: string }) => putResetPasswordGTK(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.PUT_RESET_PASSWORD_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleResetPasswordGTK = useCallback(
    async (id: string, nama_gtk: string) => {
      SweetAlert({
        icon: "warning",
        title: "Apakah Anda Yakin Ingin Reset Password?",
        html: `
        <div class="px-5">
        <table class="text-start table">
          <tr>
            <td>NIK</td>
            <td>:</td>
            <td>${id}</td>
          </tr>
          <tr>
            <td>Nama</td>
            <td>:</td>
            <td>${nama_gtk}</td>
          </tr>
        </table>
        </div>
        `,
        allowOutsideClick: false,
        isShowCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Lanjutkan",
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await ResetPasswordGTK({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [ResetPasswordGTK]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const formikSebabNonAktifValidationSchema = Yup.object().shape({
    sebab_non_aktif: Yup.string().required(
      "Sebab Non Aktif Tidak Boleh Kosong"
    ),
  });

  const formikSebabNonAktif = useFormik<FormSebabNonAktifPayload>({
    initialValues: {
      sebab_non_aktif: null,
      nik_gtk: null,
    },
    enableReinitialize: false,
    validationSchema: formikSebabNonAktifValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostSebabNonAktif(payload);
    },
  });

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePutDataGTK,
      isLoadingPutDataGTK,
      handlePostDataGTK,
      isLoadingPostDataGTK,
      handleGetDetailGTK,
      isLoadingGetDetailGTK,
      detailGTK,
      setDetailGTK,
      isShowModal,
      setShowModal,
      handleGetAllTableFilterGTK,
      isLoadingGetAllTableFilterGTK,
      allTableFilterGTK,
      setAllTableFilterGTK,
      allTableFilterGTKChecked,
      setAllTableFilterGTKChecked,
      handleExportExcelDataGTK,
      isLoadingExportExcelDataGTK,
      handleExportPDFDataGTK,
      isLoadingExportPDFDataGTK,
      showModalNonAktifkanGTK,
      setShowModalNonAktifkanGTK,
      handleGetAllSebabNonAktif,
      isLoadingGetAllSebabNonAktif,
      allSebabNonAktif,
      setAllSebabNonAktif,
      formikSebabNonAktif,
      formikSebabNonAktifValidationSchema,
      submitting,
      setSubmitting,
      isLoadingPostSebabNonAktif,
      handleResetPasswordGTK,
      isLoadingResetPasswordGTK,
      statusAktivasi,
      setStatusAktivasi,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePutDataGTK,
      isLoadingPutDataGTK,
      handlePostDataGTK,
      isLoadingPostDataGTK,
      handleGetDetailGTK,
      isLoadingGetDetailGTK,
      detailGTK,
      setDetailGTK,
      isShowModal,
      setShowModal,
      handleGetAllTableFilterGTK,
      isLoadingGetAllTableFilterGTK,
      allTableFilterGTK,
      setAllTableFilterGTK,
      allTableFilterGTKChecked,
      setAllTableFilterGTKChecked,
      handleExportExcelDataGTK,
      isLoadingExportExcelDataGTK,
      handleExportPDFDataGTK,
      isLoadingExportPDFDataGTK,
      showModalNonAktifkanGTK,
      setShowModalNonAktifkanGTK,
      handleGetAllSebabNonAktif,
      isLoadingGetAllSebabNonAktif,
      allSebabNonAktif,
      setAllSebabNonAktif,
      formikSebabNonAktif,
      formikSebabNonAktifValidationSchema,
      submitting,
      setSubmitting,
      isLoadingPostSebabNonAktif,
      handleResetPasswordGTK,
      isLoadingResetPasswordGTK,
      statusAktivasi,
      setStatusAktivasi,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
