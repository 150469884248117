import { Card, Col, Form, Row } from "react-bootstrap";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../provider/query-response/detail-gtk";
import { useQueryResponse } from "../../../provider/query-response/inpassing";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import Datepicker from "../../../hope-ui/components/datepicker";
import { useEffect } from "react";
import moment from "moment";
import dayjs from "dayjs";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { useAuth } from "../../../provider/core/auth";

const Inpassing = () => {
  const { currentUser } = useAuth();
  const { detailGTK } = useQueryResponseDetailGTK();
  const { submitting, setSubmitting, formikInpassing, isLoadingPostInpassing } =
    useQueryResponse();

  useEffect(() => {
    if (detailGTK?.inpassing) {
      formikInpassing?.setValues(detailGTK.inpassing);
    }
  }, [detailGTK]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        formikInpassing?.submitForm();
        setSubmitting(true);
      }}
    >
      {isLoadingPostInpassing && <FetchLoading />}
      <Card.Body>
        <Row className="gy-4">
          <Col lg="6">
            <TextInputComponent
              label="Pangkat Golongan"
              placeholder="Masukkan Pangkat Golongan"
              isRequired
              formik={formikInpassing?.getFieldProps("pangkat_golongan")}
              value={
                formikInpassing?.getFieldProps("pangkat_golongan").value ?? ""
              }
            />
            {submitting && formikInpassing?.errors.pangkat_golongan && (
              <div className="fs-6 text-danger mt-2">
                {formikInpassing?.errors.pangkat_golongan}
              </div>
            )}
          </Col>
          <Col lg="6">
            <TextInputComponent
              label="Nomor SK Inpassing"
              placeholder="Masukkan Nomor SK Inpassing"
              isRequired
              formik={formikInpassing?.getFieldProps("nomor_sk_inpassing")}
              value={
                formikInpassing?.getFieldProps("nomor_sk_inpassing").value ?? ""
              }
            />
            {submitting && formikInpassing?.errors.nomor_sk_inpassing && (
              <div className="fs-6 text-danger mt-2">
                {formikInpassing?.errors.nomor_sk_inpassing}
              </div>
            )}
          </Col>
          <Col lg="6">
            <Datepicker
              label="Tanggal SK Inpassing"
              required
              value={moment(
                formikInpassing?.getFieldProps("tanggal_sk_inpassing").value
              ).format("YYYY-MM-DD")}
              onChange={(e: any) => {
                const value = dayjs(e).format("YYYY-MM-DD");
                formikInpassing?.setFieldValue("tanggal_sk_inpassing", value);
              }}
            />
            {submitting && formikInpassing?.errors.tanggal_sk_inpassing && (
              <div className="fs-6 text-danger mt-2">
                {formikInpassing?.errors.tanggal_sk_inpassing}
              </div>
            )}
          </Col>
          <Col lg="6">
            <Datepicker
              label="TMT SK Inpassing"
              required
              value={moment(
                formikInpassing?.getFieldProps("tmt_sk_inpassing").value
              ).format("YYYY-MM-DD")}
              onChange={(e: any) => {
                const value = dayjs(e).format("YYYY-MM-DD");
                formikInpassing?.setFieldValue("tmt_sk_inpassing", value);
              }}
            />
            {submitting && formikInpassing?.errors.tmt_sk_inpassing && (
              <div className="fs-6 text-danger mt-2">
                {formikInpassing?.errors.tmt_sk_inpassing}
              </div>
            )}
          </Col>
          <Col lg="6">
            <NumberInputComponent
              label="Angka Kredit"
              placeholder="Masukkan Angka Kredit"
              required
              formik={formikInpassing?.getFieldProps("angka_kredit")}
              value={formikInpassing?.getFieldProps("angka_kredit").value ?? ""}
            />
            {submitting && formikInpassing?.errors.angka_kredit && (
              <div className="fs-6 text-danger mt-2">
                {formikInpassing?.errors.angka_kredit}
              </div>
            )}
          </Col>
          <Col lg="6">
            <TextInputComponent
              label="Masa Kerja"
              placeholder="Masukkan Masa Kerja"
              isRequired
              formik={formikInpassing?.getFieldProps("masa_kerja")}
              value={formikInpassing?.getFieldProps("masa_kerja").value ?? ""}
            />
            {submitting && formikInpassing?.errors.masa_kerja && (
              <div className="fs-6 text-danger mt-2">
                {formikInpassing?.errors.masa_kerja}
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className=" d-flex justify-content-end">
        <button type="submit" className="btn btn-sm btn-primary">
          <i className="far fa-floppy-disk me-2"></i>Simpan
        </button>
      </Card.Footer>
    </Form>
  );
};

export default Inpassing;
