import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormKesejahteraanPayload,
  initialQueryResponse,
} from "../../../models/kesejahteraan/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/kesejahteraan";
import {
  deleteKesejahteraan,
  downloadDocKesejahteraan,
  getDetailKesejahteraan,
  getListKesejahteraan,
  postKesejahteraan,
} from "../../../api/kesejahteraan/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama_kesejahteraan", "jenis_kesejahteraan", "penyelenggara", "ditahun", "sampai_tahun", "status"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalKesejahteraan, setShowModalKesejahteraan] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailKesejahteraan.nik_gtk = nik_gtk;

  const [detailKesejahteraan, setDetailKesejahteraan] =
    useState<FormKesejahteraanPayload>(
      initialQueryResponse.detailKesejahteraan
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikKesejahteraan.setValues(initialQueryResponse.detailKesejahteraan);
      setDetailKesejahteraan(initialQueryResponse.detailKesejahteraan);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_KESEJAHTERAAN}-${query}`,
    () => getListKesejahteraan(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikKesejahteraanValidationSchema = Yup.object().shape({
    nama_kesejahteraan: Yup.string().required(
      "Nama Kesejahteraan Tidak Boleh Kosong"
    ),
    jenis_kesejahteraan: Yup.string().required(
      "Jenis Kesejahteraan Tidak Boleh Kosong"
    ),
    penyelenggara: Yup.string().required("Penyelenggara Tidak Boleh Kosong"),
    ditahun: Yup.string().required("Dari Tahun Tidak Boleh Kosong"),
    sampai_tahun: Yup.string().required("Sampai Tahun Tidak Boleh Kosong"),
    status: Yup.string().required("Status Tidak Boleh Kosong"),
  });

  const formikKesejahteraan = useFormik<FormKesejahteraanPayload>({
    initialValues: initialQueryResponse.detailKesejahteraan,
    enableReinitialize: false,
    validationSchema: formikKesejahteraanValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostKesejahteraan(payload);
    },
  });

  const {
    mutateAsync: PostKesejahteraan,
    isLoading: isLoadingPostKesejahteraan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KESEJAHTERAAN}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postKesejahteraan(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_KESEJAHTERAAN}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostKesejahteraan = useCallback(
    async (payload: any) => {
      await PostKesejahteraan({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalKesejahteraan(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKesejahteraan]
  );

  const {
    mutateAsync: GetDetailKesejahteraan,
    isLoading: isLoadingGetDetailKesejahteraan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KESEJAHTERAAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailKesejahteraan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KESEJAHTERAAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKesejahteraan = useCallback(
    async (id: string) => {
      await GetDetailKesejahteraan({ id })
        .then((res) => {
          setDetailKesejahteraan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailKesejahteraan]
  );

  const {
    mutateAsync: DownloadDocKesejahteraan,
    isLoading: isLoadingDownloadDocKesejahteraan,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_KESEJAHTERAAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocKesejahteraan(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_KESEJAHTERAAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocKesejahteraan = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocKesejahteraan({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocKesejahteraan]
  );

  const {
    mutateAsync: DeleteKesejahteraan,
    isLoading: isLoadingDeleteKesejahteraan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KESEJAHTERAAN}`,
    mutationFn: ({ id }: { id: string }) => deleteKesejahteraan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_KESEJAHTERAAN}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteKesejahteraan = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteKesejahteraan({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKesejahteraan]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalKesejahteraan,
      setShowModalKesejahteraan,
      formikKesejahteraan,
      formikKesejahteraanValidationSchema,
      submitting,
      setSubmitting,
      handlePostKesejahteraan,
      isLoadingPostKesejahteraan,
      detailKesejahteraan,
      setDetailKesejahteraan,
      handleGetDetailKesejahteraan,
      isLoadingGetDetailKesejahteraan,
      resetForm,
      setResetForm,
      handleDownloadDocKesejahteraan,
      isLoadingDownloadDocKesejahteraan,
      handleDeleteKesejahteraan,
      isLoadingDeleteKesejahteraan,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalKesejahteraan,
      setShowModalKesejahteraan,
      formikKesejahteraan,
      formikKesejahteraanValidationSchema,
      submitting,
      setSubmitting,
      handlePostKesejahteraan,
      isLoadingPostKesejahteraan,
      detailKesejahteraan,
      setDetailKesejahteraan,
      handleGetDetailKesejahteraan,
      isLoadingGetDetailKesejahteraan,
      resetForm,
      setResetForm,
      handleDownloadDocKesejahteraan,
      isLoadingDownloadDocKesejahteraan,
      handleDeleteKesejahteraan,
      isLoadingDeleteKesejahteraan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
