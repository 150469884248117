import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormDataGTKPayload } from "../data-gtk/_queryResponse";

export type QueryResponseContextProps<T> = {
  listData?: Response<object>;
  isLoadingFetchData: boolean;
  nikGTK?: string;
  setNIKGTK: Dispatch<SetStateAction<string>>;
  isShowModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  handleGetDetailGTK: (id: string) => void;
  isLoadingGetDetailGTK: boolean;
  detailGTK?: FormDataGTKPayload;
  setDetailGTK: Dispatch<SetStateAction<FormDataGTKPayload | undefined>>;
  refetchListData?: () => void;
  showRiwayatLoginModal: boolean;
  setShowRiwayatLoginModal: Dispatch<SetStateAction<boolean>>;
  handlePostDetailGTK: (data: FormData) => void;
  isLoadingPostDetailGTK: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isShowModal: false,
  setNIKGTK: () => {},
  setShowModal: () => {},
  handleGetDetailGTK: () => {},
  isLoadingGetDetailGTK: false,
  setDetailGTK: () => {},
  showRiwayatLoginModal: false,
  setShowRiwayatLoginModal: () => {},
  handlePostDetailGTK: () => {},
  isLoadingPostDetailGTK: false,
};
