import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const postProfil = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.POST_PROFIL_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export { postProfil };
