import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";

export type QueryResponseContextProps<T> = {
  query?: string;
  listFilterData?: Response<Array<T>>;
  isLoadingFetchListFilterData: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchListFilterData: false,
};
