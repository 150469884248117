import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormKaryaBukuPayload,
  initialQueryResponse,
} from "../../../models/karya-buku/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/karya-buku";
import {
  deleteKaryaBuku,
  downloadDocKaryaBuku,
  getDetailKaryaBuku,
  getListKaryaBuku,
  postKaryaBuku,
} from "../../../api/karya-buku/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["judul_buku", "isbn", "penerbit", "tahun"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalKaryaBuku, setShowModalKaryaBuku] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailKaryaBuku.nik_gtk = nik_gtk;

  const [detailKaryaBuku, setDetailKaryaBuku] = useState<FormKaryaBukuPayload>(
    initialQueryResponse.detailKaryaBuku
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikKaryaBuku.setValues(initialQueryResponse.detailKaryaBuku);
      setDetailKaryaBuku(initialQueryResponse.detailKaryaBuku);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_KARYA_BUKU}-${query}`,
    () => getListKaryaBuku(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikKaryaBukuValidationSchema = Yup.object().shape({
    judul_buku: Yup.string().required("Judul Buku Tidak Boleh Kosong"),
    isbn: Yup.string().required("ISBN Tidak Boleh Kosong"),
    penerbit: Yup.string().required("Penerbit Tidak Boleh Kosong"),
    tahun: Yup.string().required("Tahun Terbit Tidak Boleh Kosong"),
  });

  const formikKaryaBuku = useFormik<FormKaryaBukuPayload>({
    initialValues: initialQueryResponse.detailKaryaBuku,
    enableReinitialize: false,
    validationSchema: formikKaryaBukuValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostKaryaBuku(payload);
    },
  });

  const { mutateAsync: PostKaryaBuku, isLoading: isLoadingPostKaryaBuku } =
    useMutation({
      mutationKey: `${QUERIES.POST_KARYA_BUKU}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postKaryaBuku(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_KARYA_BUKU}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostKaryaBuku = useCallback(
    async (payload: any) => {
      await PostKaryaBuku({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalKaryaBuku(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKaryaBuku]
  );

  const {
    mutateAsync: GetDetailKaryaBuku,
    isLoading: isLoadingGetDetailKaryaBuku,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KARYA_BUKU}`,
    mutationFn: ({ id }: { id: string }) => getDetailKaryaBuku(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KARYA_BUKU}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKaryaBuku = useCallback(
    async (id: string) => {
      await GetDetailKaryaBuku({ id })
        .then((res) => {
          setDetailKaryaBuku(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailKaryaBuku]
  );

  const {
    mutateAsync: DownloadDocKaryaBuku,
    isLoading: isLoadingDownloadDocKaryaBuku,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_KARYA_BUKU}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocKaryaBuku(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_KARYA_BUKU}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocKaryaBuku = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocKaryaBuku({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocKaryaBuku]
  );

  const { mutateAsync: DeleteKaryaBuku, isLoading: isLoadingDeleteKaryaBuku } =
    useMutation({
      mutationKey: `${QUERIES.POST_KARYA_BUKU}`,
      mutationFn: ({ id }: { id: string }) => deleteKaryaBuku(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_KARYA_BUKU}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDeleteKaryaBuku = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteKaryaBuku({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKaryaBuku]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalKaryaBuku,
      setShowModalKaryaBuku,
      formikKaryaBuku,
      formikKaryaBukuValidationSchema,
      submitting,
      setSubmitting,
      handlePostKaryaBuku,
      isLoadingPostKaryaBuku,
      detailKaryaBuku,
      setDetailKaryaBuku,
      handleGetDetailKaryaBuku,
      isLoadingGetDetailKaryaBuku,
      resetForm,
      setResetForm,
      handleDownloadDocKaryaBuku,
      isLoadingDownloadDocKaryaBuku,
      handleDeleteKaryaBuku,
      isLoadingDeleteKaryaBuku,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalKaryaBuku,
      setShowModalKaryaBuku,
      formikKaryaBuku,
      formikKaryaBukuValidationSchema,
      submitting,
      setSubmitting,
      handlePostKaryaBuku,
      isLoadingPostKaryaBuku,
      detailKaryaBuku,
      setDetailKaryaBuku,
      handleGetDetailKaryaBuku,
      isLoadingGetDetailKaryaBuku,
      resetForm,
      setResetForm,
      handleDownloadDocKaryaBuku,
      isLoadingDownloadDocKaryaBuku,
      handleDeleteKaryaBuku,
      isLoadingDeleteKaryaBuku,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
