import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListRiwayatPekerjaan = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_RIWAYAT_PEKERJAAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postRiwayatPekerjaan = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_RIWAYAT_PEKERJAAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailRiwayatPekerjaan = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_RIWAYAT_PEKERJAAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocRiwayatPekerjaan = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deleteRiwayatPekerjaan = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_RIWAYAT_PEKERJAAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListRiwayatPekerjaan,
  postRiwayatPekerjaan,
  getDetailRiwayatPekerjaan,
  downloadDocRiwayatPekerjaan,
  deleteRiwayatPekerjaan,
};
