import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormAnggotaKelompokLT3QPayload } from "../anggota-kelompok-lt3q/_queryResponse";
import { FormikProps } from "formik";

export type FormAbsensiLT3QPayload = {
  id: number | null;
  id_absensi_lt3q: number | null;
  id_riwayat_lt3q: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
};

export type FormRiwayatLT3QPayload = {
  id: number | null;
  riwayat_tanggal: Date | null;
  batas_tilawah_halaman: number | string | null;
  tempat: string | null;
  materi: string | null;
  nik_gtk_tutor: string | null;
  id_kelompok_lt3q: number | null;
  kehadiran_lt3q: Array<FormAbsensiLT3QPayload>;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  formikBuatAbsensi?: FormikProps<FormRiwayatLT3QPayload>;
  formikBuatAbsensiValidationSchema?: any;
  currentOffset: number;
  setCurrentOffset: Dispatch<SetStateAction<number>>;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostBuatAbsensiLT3Q?: (payload: FormData) => void;
  isLoadingPostBuatAbsensiLT3Q: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetDetailAbsensiLT3Q: (id: string) => void;
  isLoadingGetDetailAbsensiLT3Q: boolean;
  detailAbsensiLT3Q: FormRiwayatLT3QPayload;
  setDetailAbsensiLT3Q: Dispatch<SetStateAction<FormRiwayatLT3QPayload>>;
  handleGetTotalInfaq?: (id_kelompok_lt3q: string) => void;
  isLoadingGetTotalInfaq: boolean;
  totalInfaq: number;
  setTotalInfaq: Dispatch<SetStateAction<number>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  currentOffset: 0,
  setCurrentOffset: () => {},
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostBuatAbsensiLT3Q: false,
  resetForm: false,
  setResetForm: () => {},
  handleGetDetailAbsensiLT3Q: () => {},
  isLoadingGetDetailAbsensiLT3Q: false,
  detailAbsensiLT3Q: {
    id: null,
    riwayat_tanggal: null,
    batas_tilawah_halaman: "",
    tempat: "",
    materi: "",
    nik_gtk_tutor: "",
    id_kelompok_lt3q: null,
    kehadiran_lt3q: [],
  },
  setDetailAbsensiLT3Q: () => {},
  isLoadingGetTotalInfaq: false,
  totalInfaq: 0,
  setTotalInfaq: () => {},
};
