import { FieldInputProps } from "formik";
import { FC, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { SweetAlert } from "../sweet-alert";

type Props = {
  label?: string;
  formik?: FieldInputProps<any>;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileList?: File[] | null | string[];
  fileNameLabel?: string;
  acceptLabel?: string;
  acceptedType?: Array<string>;
  maxFileSize?: number;
  value?: any;
  ref?: HTMLInputElement | null;
  resetField?: boolean;
  linkPreview?: string;
  downloadPreview?: () => void;
};

const FileInputComponent: FC<Props> = ({
  label,
  formik,
  required,
  onChange,
  fileList,
  fileNameLabel,
  acceptLabel,
  acceptedType,
  maxFileSize,
  value,
  ref,
  resetField,
  linkPreview,
  downloadPreview,
}) => {
  let hiddenFileInput = useRef<HTMLInputElement | null>(ref ?? null);

  useEffect(() => {
    if (hiddenFileInput && hiddenFileInput.current && resetField) {
      hiddenFileInput.current.files = null;
      hiddenFileInput.current.value = "";
    }
  }, [hiddenFileInput, resetField]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (maxFileSize && selectedFile.size > maxFileSize * 1024 * 1024) {
        SweetAlert({
          icon: "error",
          title: `File melebihi batas maksimum ${maxFileSize}MB`,
          allowOutsideClick: false,
        });
        return;
      }
      const name = selectedFile.name;
      const lastDot = name.lastIndexOf(".");
      const ext = "." + name.substring(lastDot + 1);
      if (acceptedType && acceptedType.includes(ext) === false) {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          html: `Format File Yang Diizinkan Adalah <b> ${acceptedType.join(
            " "
          )} <b>`,
          allowOutsideClick: false,
        });
        return;
      }

      if (onChange) onChange(event);
    }
  };

  return (
    <>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger"> * </span>}
        </Form.Label>
      )}
      <div className="d-flex flex-row justify-content-between">
        <label
          className="ps-0 d-flex align-items-center overflow-hidden border flex-grow-1"
          style={{
            cursor: "pointer",
          }}
        >
          <Form.Control
            ref={hiddenFileInput}
            type="file"
            onChange={handleInputChange}
            accept={acceptedType?.join(",")}
            {...formik}
            value={value}
            style={{
              display: "none",
            }}
          ></Form.Control>
          <span
            className="fs-6 p-2 bg-light text-dark rounded-1 text-center"
            style={{
              minWidth: "100px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Pilih File
          </span>
          <div className="d-block overflow-hidden w-100">
            <div className="file-input-form d-flex flex-row align-items-center gap-2 w-100">
              <span
                className="fs-6 px-1"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  lineClamp: 1,
                  flexGrow: 1,
                }}
              >
                {fileNameLabel && fileNameLabel.length > 0
                  ? fileNameLabel
                  : "Tidak Ada File Dipilih"}
              </span>
            </div>
          </div>
        </label>
        {linkPreview && (
          <div
            className="d-flex flex-row justify-content-between  input-group-text cursor-pointer"
            style={{
              height: "40px",
              borderRadius: 0,
            }}
            onClick={() => {
              if (downloadPreview) downloadPreview();
            }}
          >
            <i className="fas fa-download"></i>
          </div>
        )}
      </div>
      <div
        className="text-muted mt-1"
        style={{
          fontStyle: "",
          fontSize: 13,
        }}
      >
        <span className="text-danger">*</span> Format File Yang Diizinkan{" "}
        {acceptedType?.join(", ")}
      </div>
    </>
  );
};

export default FileInputComponent;
