import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../provider/query-request/dashboard/detail-presensi-gtk";
import NamaGTKCell from "../../../../../hope-ui/components/table/NamaGTKCell";
import { useQueryResponsePaginationDetailPresensiGTK } from "../../../../../provider/query-response/dashboard";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePaginationDetailPresensiGTK();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={
          props.data[props.row.index].gtk
            ? props.data[props.row.index].gtk.nik_gtk
            : props.data[props.row.index].nik_gtk
        }
        label={
          props.data[props.row.index].gtk
            ? props.data[props.row.index].gtk.nama_gtk
            : props.data[props.row.index].nama_gtk
        }
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Unit Kerja"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].gtk
          ? props.data[props.row.index].gtk.unit_kerja
          : props.data[props.row.index].unit_kerja}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Status"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "status_absen",
    Cell: ({ ...props }) => {
      let status = props.data[props.row.index].status_absen;
      let className;
      if (status === "N") {
        className = "bg-success";
        status = "Normal";
      } else if (status === "TKD") {
        className = "bg-danger";
        status = "Terlambat Kena Denda";
      } else if (status === "TTKD") {
        className = "bg-yellow";
        status = "Terlambat Tidak Kena Denda";
      } else if (status === "TH") {
        className = "bg-gray";
        status = "Tidak Hadir";
      }

      return (
        <label
          className={`fw-normal p-2 text-center w-100 text-white  ${className}`}
        >
          {status}
        </label>
      );
    },
  },
];
