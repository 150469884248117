import { FC, JSXElementConstructor } from "react";
import { Column, ColumnInstance } from "react-table";

type Props = {
  column: ColumnInstance<any>;
};

export const TableHeader: FC<Props> = ({ column }) => (
  <>
    {column.Header && typeof column.Header === "string" ? (
      <th {...column.getHeaderProps()}>{column.render("Header")}</th>
    ) : (
      column.render("Header")
    )}
  </>
);
