import { Column } from "react-table";

import { Header } from "../../../../../../hope-ui/components/table/Header";
import NamaGTKCell from "../../../../../../hope-ui/components/table/NamaGTKCell";
import { useQueryRequest } from "../../../../../../provider/query-request/absensi-lt3q/nilai-tahsin";
import { NilaiTilawahCell } from "./NilaiTilawahCell";
import { NilaiTeoriCell } from "./NilaiTeoriCell";
import { KeteranganCell } from "./KeteranganCell";
import { IsUjianCell } from "./IsUjianCell";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      //const { offset } = useQueryResponsePagination();
      //let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1}
        </label>
      );
    },
  },

  // {
  //   Header: (props) => (
  //     <Header
  //       tableProps={props}
  //       title="Nama Kelompok"
  //       className="ps-3 fs-6 text-center"
  //       queryRequest={useQueryRequest}
  //       isSorting={true}
  //     />
  //   ),
  //   id: "nama_kelompok_lt3q",
  //   Cell: ({ ...props }) => (
  //     <label className="fw-normal text-dark pe-3 text-center w-100">
  //       {props.data[props.row.index].kelompok_lt3q.nama_kelompok_lt3q}
  //     </label>
  //   ),
  // },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].gtk.nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Unit Kerja"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].gtk.unit_kerja}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Total Absensi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "total_absensi",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].total_absensi} Pertemuan
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Ikut Ujian"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "is_ujian",
    Cell: ({ ...props }) => (
      <IsUjianCell
        nik_gtk={props.data[props.row.index].nik_gtk}
        data={props.data[props.row.index]}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nilai Tilawah"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action_nilai_tilawah",
    Cell: ({ ...props }) => (
      <NilaiTilawahCell
        nik_gtk={props.data[props.row.index].nik_gtk}
        data={props.data[props.row.index]}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nilai Teori"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action_nilai_teori",
    Cell: ({ ...props }) => (
      <NilaiTeoriCell
        nik_gtk={props.data[props.row.index].nik_gtk}
        data={props.data[props.row.index]}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Keterangan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action_keterangan",
    Cell: ({ ...props }) => (
      <KeteranganCell
        nik_gtk={props.data[props.row.index].nik_gtk}
        data={props.data[props.row.index]}
      />
    ),
  },
];
