import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/tugas-tambahan";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/tugas-tambahan";
import ModalTugasTambahan from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";

const TugasTambahan = () => {
  const {
    isLoadingFetchData,
    listData,
    isLoadingDownloadDocTugasTambahan,
    isLoadingDeleteTugasTambahan,
  } = useQueryResponse();
  return (
    <>
      {(isLoadingDownloadDocTugasTambahan || isLoadingDeleteTugasTambahan) && (
        <FetchLoading />
      )}
      <Toolbar />
      <ModalTugasTambahan />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default TugasTambahan;
