import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useQueryResponse } from "../../../../../provider/query-response/hak-akses/kelola-role";
import { FC } from "react";

type Props = {
  data: any;
};

const AksiCell: FC<Props> = ({ data }) => {
  const { handleGetDetailHakAkses, setIsShowModal, handleDeleteHakAkses } =
    useQueryResponse();
  return (
    <Dropdown as={ButtonGroup}>
      <Button type="button" size="sm" variant={"info"}>
        Pilih Aksi
      </Button>
      <Dropdown.Toggle type="button" split variant={"info"}>
        <span className="visually-hidden"></span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          href="#"
          onClick={() => {
            setIsShowModal(true);
            if (handleGetDetailHakAkses) handleGetDetailHakAkses(data.id);
          }}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          href="#"
          onClick={() => {
            if (handleDeleteHakAkses) handleDeleteHakAkses(data.id);
          }}
        >
          Hapus
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AksiCell;
