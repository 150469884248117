import { FC, createContext, useContext, useMemo } from "react";
import { initialQueryResponse } from "../../../models/master-data/jenis-kepegawaian/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useQuery } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { getStatusPerkawinan } from "../../../api/master-data/_request";
import { initialQueryState } from "../../../models/global";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_STATUS_PERKAWINAN}`,
    () => getStatusPerkawinan(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
    }),
    [listData, refetchListData, isLoadingFetchData]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
