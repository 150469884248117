import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-bpi/kelompok-bpi/pindah-tutor";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  FormPindahTutorBPIPayload,
  TutorBPI,
  initialQueryResponse,
} from "../../../../../models/absensi-bpi/pindah-tutor/_queryResponse";
import {
  getAllKelompokBPI,
  getPindahTutorBPI,
  getTutorBPI,
  postPindahTutorBPI,
} from "../../../../../api/absensi-bpi/_request";

import { useQueryResponse as useQueryResponseAbsensiBPI } from "../../../absensi-bpi";
import { useAuth } from "../../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormKelompokBPIPayload } from "../../../../../models/absensi-bpi/kelompok-bpi/_queryResponse";
import { useQueryResponse as useQueryResponseAnggotaKelompok } from "../kelola-anggota-kelompok";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const refetchListDataAnggotaKelompok =
    useQueryResponseAnggotaKelompok().refetchListData;
  const { state } = useQueryRequest();
  state.col_header = `["nik_gtk", "nik_tutor_lama", "nik_tutor_tujuan"]`;
  const { activeTab } = useQueryResponseAbsensiBPI();
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [allTutorBPI, setAllTutorBPI] = useState<Array<TutorBPI>>(
    initialQueryResponse.allTutorBPI
  );

  const [allKelompokTutorBPITujuan, setAllKelompokTutorBPITujuan] = useState<
    Array<FormKelompokBPIPayload>
  >(initialQueryResponse.allKelompokTutorBPITujuan);

  const nik_tutor_bpi_lama: any = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);

  const {
    mutateAsync: PostPindahTutorBPI,
    isLoading: isLoadingPostPindahTutorBPI,
  } = useMutation({
    mutationKey: `${QUERIES.POST_PINDAH_TUTOR_BPI}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postPindahTutorBPI(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_PINDAH_TUTOR_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostPindahTutorBPI = useCallback(
    async (payload: any) => {
      await PostPindahTutorBPI({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData && refetchListDataAnggotaKelompok) {
                refetchListData();
                refetchListDataAnggotaKelompok();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPindahTutorBPI]
  );

  const { mutateAsync: GetTutorBPI, isLoading: isLoadingGetTutorBPI } =
    useMutation({
      mutationKey: `${QUERIES.GET_TUTOR_BPI}`,
      mutationFn: () => getTutorBPI(nik_tutor_bpi_lama),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_TUTOR_BPI}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetTutorBPI = useCallback(async () => {
    await GetTutorBPI()
      .then((res) => {
        setAllTutorBPI(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetTutorBPI]);

  const formikPindahTutorBPIValidationSchema = Yup.object().shape({
    id_kelompok_bpi: Yup.string().required("Kelompok BPI Boleh Kosong"),
    id_anggota_kelompok_bpi: Yup.string().required(
      "Anggota Kelompok BPI Tidak Boleh Kosong"
    ),
    nik_tutor_bpi_tujuan: Yup.string().required(
      "Tutor BPI Tujuan Tidak Boleh Kosong"
    ),
    id_kelompok_bpi_tujuan: Yup.string().required(
      "Kelompok BPI Tujuan Tidak Boleh Kosong"
    ),
  });

  const formikPindahTutorBPI = useFormik<FormPindahTutorBPIPayload>({
    initialValues: {
      id: null,
      id_anggota_kelompok_bpi: null,
      id_kelompok_bpi: null,
      id_kelompok_bpi_tujuan: null,
      nik_tutor_bpi_lama,
      nik_tutor_bpi_tujuan: null,
      keterangan: null,
    },
    enableReinitialize: false,
    validationSchema: formikPindahTutorBPIValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostPindahTutorBPI) handlePostPindahTutorBPI(payload);
    },
  });

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kelompok-bpi")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kelompok-bpi"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      formikPindahTutorBPI.setValues({
        id: null,
        id_anggota_kelompok_bpi: null,
        id_kelompok_bpi: null,
        id_kelompok_bpi_tujuan: null,
        nik_tutor_bpi_lama,
        nik_tutor_bpi_tujuan: null,
        keterangan: null,
      });
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_PINDAH_TUTOR_BPI}-${query}`,
    () => getPindahTutorBPI(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    mutateAsync: GetAllKelompokBPITujuan,
    isLoading: isLoadingGetAllKelompokBPITujuan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_BPI}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokBPI(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokBPITujuan = useCallback(
    async (nik_gtk: string, type?: string) => {
      await GetAllKelompokBPITujuan({ nik_gtk })
        .then((res) => {
          if (type && type === "tujuan") {
            return setAllKelompokTutorBPITujuan(res.data);
          }
          setAllKelompokTutorBPITujuan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokBPITujuan]
  );

  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPindahTutorBPI,
      formikPindahTutorBPIValidationSchema,
      handlePostPindahTutorBPI,
      isLoadingPostPindahTutorBPI,
      submitting,
      setSubmitting,
      handleGetTutorBPI,
      isLoadingGetTutorBPI,
      allTutorBPI,
      setAllTutorBPI,
      allKelompokTutorBPITujuan,
      setAllKelompokTutorBPITujuan,
      handleGetAllKelompokBPITujuan,
      isLoadingGetAllKelompokBPITujuan,
      resetForm,
      setResetForm,
    }),
    [
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPindahTutorBPI,
      formikPindahTutorBPIValidationSchema,
      handlePostPindahTutorBPI,
      isLoadingPostPindahTutorBPI,
      submitting,
      setSubmitting,
      handleGetTutorBPI,
      isLoadingGetTutorBPI,
      allTutorBPI,
      setAllTutorBPI,
      allKelompokTutorBPITujuan,
      setAllKelompokTutorBPITujuan,
      handleGetAllKelompokBPITujuan,
      isLoadingGetAllKelompokBPITujuan,
      resetForm,
      setResetForm,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
