import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FC } from "react";
import { Form } from "react-bootstrap";
import { FieldInputProps } from "formik";

type Props = {
  label?: string;
  required?: boolean;
  value?: any;
  disabled?: boolean;
  onChange?: (value: any) => void;
  ref?: any;
  formik?: FieldInputProps<any>;
  minDate?: any;
};

const Datepicker: FC<Props> = ({
  label,
  required,
  value,
  disabled,
  onChange,
  ref,
  formik,
  minDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger"> * </span>}{" "}
        </Form.Label>
      )}
      <DatePicker
        minDate={minDate}
        value={dayjs(value)}
        className="form-control"
        format="DD/MM/YYYY"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root.Mui-focused": { color: "#979797" }, //styles the label
          "& .MuiOutlinedInput-root": {
            "&:hover > fieldset": { borderColor: "#C7C8CD" },
            height: "42px",
            borderRadius: "6px",
          },
        }}
        slotProps={{
          textField: {
            error: false,
          },
        }}
        disabled={disabled}
        onChange={onChange}
        ref={ref}
        {...formik}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
