import moment from "moment";
import { FC } from "react";
import { Alert, Badge, Col, Image } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useQueryResponse } from "../../../provider/query-response/detail-gtk";
import {
  useQueryResponse as useQueryResponseProfil,
  QueryResponseProvider,
} from "../../../provider/query-response/profil";
import { RiwayatLoginWrapper } from "../../../modules/riwayat-login";
import avatars1 from "../../../../assets/images/avatars/01.png";
import ModalProfil from "../../../modules/profil";
import { useAuth } from "../../../provider/core/auth";

type Props = {
  data?: any;
  poto?: string;
};

const GTKCard: FC<Props> = ({ poto, data }) => {
  const { currentUser } = useAuth();
  const { setShowRiwayatLoginModal, detailGTK } = useQueryResponse();
  const { setShowModalProfil } = useQueryResponseProfil();
  const history_login: any = detailGTK?.history_login;
  return (
    <div className="row bg-gray-100 py-3 mt-n3 mb-3 gy-3">
      <ModalProfil />
      <RiwayatLoginWrapper />
      <div className="col-md-4 col-12">
        <div className="ms-3 mb-0 mb-lg-0 profile-logo profile-logo1 d-flex flex-row justify-content-center ">
          <div
            className={`profile-wrap`}
            onClick={() => {
              setShowModalProfil(true);
            }}
          >
            <div className="work-info">
              <label className="fs-6">
                <i
                  className={`fa fa-${
                    detailGTK &&
                    currentUser?.data?.username === detailGTK.nik_gtk
                      ? "pencil"
                      : "eye"
                  } me-1`}
                ></i>{" "}
                {detailGTK && currentUser?.data?.username === detailGTK.nik_gtk
                  ? "Edit"
                  : "Lihat Foto"}
              </label>
            </div>

            <Image
              className={`rounded-pill  cursor-pointer`}
              style={{
                width: 130,
                height: 130,
                objectFit: "cover",
              }}
              src={detailGTK?.poto?.toString() ?? avatars1}
              alt="profile-pic"
            />
          </div>
        </div>
      </div>
      <div className="col-md-7 col-12 d-flex flex-column justify-content-center gap-3 ">
        <div className="d-flex flex-wrap flex-column  mb-3 mb-sm-0 gtk-card-profile">
          <h4 className="me-2 h4">{detailGTK ? detailGTK.nama_gtk : ""}</h4>
          <span> {detailGTK ? detailGTK.nik_gtk : ""}</span>
          <span>Unit Kerja {detailGTK ? detailGTK.unit_kerja : ""}</span>
        </div>
        <ProgressBar
          variant="success"
          now={detailGTK?.progress_bar}
          style={{
            width: "100%",
          }}
          label={`${detailGTK?.progress_bar}%`}
        ></ProgressBar>
        <div className="">
          {history_login && history_login.length ? (
            <div className="">
              <div className="d-flex align-items-center justify-content-start gap-2 mb-2 ">
                Terakhir Login :<i className="far fa-calendar"></i>
                <div>
                  {moment(history_login[0].time_login).format("DD MMM YYYY")}
                </div>
                <i className="far fa-clock ms-1"></i>
                {moment(history_login[0].time_login).format("H:mm")}
              </div>
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() => setShowRiwayatLoginModal(true)}
              >
                <i className="fas fa-list me-2"></i>
                Riwayat Login
              </button>
            </div>
          ) : (
            <label className="text-danger">Tidak Ada Riwayat Login</label>
          )}
        </div>
        {detailGTK?.perubahan_status_kepegawaian && (
          <Alert variant="warning" className="fs-6 p-1 my-1">
            <span>
              <Badge className="me-1" bg="warning">
                !
              </Badge>
            </span>
            {`Status Kepegawaian ${
              currentUser && currentUser.data?.username === detailGTK.nik_gtk
                ? "Anda"
                : "GTK Ini"
            } Akan Mengalami Perubahan`}{" "}
            <b>{detailGTK.perubahan_status_kepegawaian}</b>
            {currentUser &&
              currentUser.data?.username === detailGTK.nik_gtk &&
              `, Segera Hubungi LP3K Untuk Informasi Lebih Lanjut`}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default GTKCard;
