import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";
import { Dayjs } from "dayjs";

export type FormImportPersensiGTKPayload = {
  filter_tanggal: Date | null;
  file: File[] | null;
};

export type FormAbsensiGtkPayload = {
  id: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
  tanggal_absen: Date | null;
};

export type FormCatatanGtkPayload = {
  id: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
  id_absensi_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  formikCatatanGTK?: FormikProps<FormAbsensiGtkPayload>;
  nikGTK?: string;
  setNIKGTK: Dispatch<SetStateAction<string>>;
  handlePostImportPresensiGTK?: (data: FormData) => void;
  isLoadingPostImportPresensiGTK: boolean;
  setGagalImportPresensiGTK: Dispatch<SetStateAction<Response<Array<T>>>>;
  gagalImportPresensiGTK: Array<any>;
  DetailAbsensiGtk: FormAbsensiGtkPayload;
  setDetailAbsensiGtk: Dispatch<SetStateAction<FormAbsensiGtkPayload>>;
  DetailCatatanAbsensiGtk: FormCatatanGtkPayload;
  setDetailCatatanAbsensiGtk: Dispatch<SetStateAction<FormCatatanGtkPayload>>;
  isLoadingGetDetailCatatanGTK: boolean;
  isShowModalBuatAbsensiGTK: boolean;
  setShowModalImportAbsensiGTK: Dispatch<SetStateAction<boolean>>;
  CatatanGtk: boolean;
  isLoadingGetDetailGTK: boolean;
  setCatatanGtk: Dispatch<SetStateAction<boolean>>;
  handleCatatanGtk: (nik_gtk: string) => void;
  handleCatatanAbsensiGtk: (
    nik_gtk: string,
    tanggal_absen: string,
    id_absensi_gtk?: string
  ) => void;
  handlePutCatatanGtk?: (data: FormData, nik_gtk: string) => void;
  handlePostCatatanGtk: (data: FormData) => void;
  isLoadingPutCatatanGtk: boolean;
  isLoadingPostCatatanGtk: boolean;
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  ActiveTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  handleExportExcelAbsensiGTKInput?: () => void;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  tanggalAbsen: Date | Dayjs | string | null;
  setTanggalAbsen: Dispatch<SetStateAction<Date | Dayjs | string | null>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  tanggalAbsen: new Date(),
  setTanggalAbsen: () => {},
  isLoadingPostImportPresensiGTK: false,
  setGagalImportPresensiGTK: () => {},
  gagalImportPresensiGTK: [],
  DetailAbsensiGtk: {
    id: null,
    nik_gtk: null,
    status_absen: null,
    keterangan: null,
    tanggal_absen: null,
  },
  handleCatatanGtk: () => {},
  handlePostCatatanGtk: () => {},
  setDetailAbsensiGtk: () => {},
  setNIKGTK: () => {},
  CatatanGtk: false,
  setCatatanGtk: () => {},
  handlePutCatatanGtk: () => {},
  isLoadingGetDetailGTK: false,
  isShowModalBuatAbsensiGTK: false,
  isLoadingPutCatatanGtk: false,
  isLoadingPostCatatanGtk: false,
  setShowModalImportAbsensiGTK: () => {},
  isLoadingFetchData: false,
  ActiveTab: 1,
  setActiveTab: () => {},
  submitting: false,
  setSubmitting: () => {},
  resetForm: false,
  setResetForm: () => {},
  DetailCatatanAbsensiGtk: {
    id: null,
    nik_gtk: null,
    status_absen: null,
    keterangan: null,
    id_absensi_gtk: null,
  },
  setDetailCatatanAbsensiGtk: () => {},
  handleCatatanAbsensiGtk: () => {},
  isLoadingGetDetailCatatanGTK: false,
};
