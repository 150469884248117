import { Col, Form } from "react-bootstrap";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import { FC } from "react";

type Props = {
  formik?: any;
  ref?: any;
  submitting?: boolean;
};

const SertifikatTilawah: FC<Props> = ({ formik, ref, submitting }) => {
  const formikKepegawaian = formik;
  return (
    <>
      <Col md="6">
        <Form.Group>
          <NumberInputComponent
            placeholder="Masukkan Nilai Teori"
            label="Nilai Teori"
            value={formikKepegawaian.getFieldProps("nilai_teori").value}
            required
            ref={(element) => (ref.current[9] = element)}
            formik={formikKepegawaian.getFieldProps("nilai_teori")}
          />
          {submitting && formikKepegawaian.errors.nilai_teori && (
            <div className="fs-6 text-danger mt-2">
              {formikKepegawaian.errors.nilai_teori}
            </div>
          )}
        </Form.Group>
      </Col>

      <Col md="6">
        <Form.Group>
          <NumberInputComponent
            placeholder="Masukkan Nilai Praktek"
            label="Nilai Praktek"
            value={formikKepegawaian.getFieldProps("nilai_praktek").value}
            required
            ref={(element) => (ref.current[10] = element)}
            formik={formikKepegawaian.getFieldProps("nilai_praktek")}
          />
          {submitting && formikKepegawaian.errors.nilai_praktek && (
            <div className="fs-6 text-danger mt-2">
              {formikKepegawaian.errors.nilai_praktek}
            </div>
          )}
        </Form.Group>
      </Col>
    </>
  );
};

export default SertifikatTilawah;
