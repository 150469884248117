import axios, { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListMemo = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_MEMO_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailMemo = async (id: number): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_MEMO_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postMemo = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_MEMO_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailMemoNotif = async (id: number): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_MEMO_NOTIF_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getListMemo, getDetailMemo, postMemo, getDetailMemoNotif };
