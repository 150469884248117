import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../../models/hak-akses/kelola-role-menu/_queryResponse";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import {
  getAllAppName,
  getAllHakAkses,
  getHakAksesMenu,
  getAllMenus,
  postRolesMenu,
} from "../../../../api/hak-akses/_request";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { useQueryRequest } from "../../../query-request/hak-akses/kelola-role-menu";
import { useQueryResponse as useQueryResponseHakAkses } from "../../../query-response/hak-akses";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useAuth } from "../../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { refetchCurrentUser } = useAuth();
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { activeTab } = useQueryResponseHakAkses();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["app_name", "label", "route_name"]`;
  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "hak-akses-menu")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "hak-akses-menu"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const [appName, setAppName] = useState<string>("");
  const [rolesId, setRolesId] = useState<number>(0);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_HAK_AKSES}-${query}`,
    () => getHakAksesMenu(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: activeTab === 2,
    }
  );

  const {
    data: allApp,
    isFetching: isLoadingAllApp,
    refetch: refetchAllApp,
  } = useQuery(`${QUERIES.GET_ALL_APP}`, () => getAllAppName(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
    enabled: activeTab === 2,
  });

  const {
    data: allHakAkses,
    isFetching: isLoadingAllHakAkses,
    refetch: refetchAllHakAkses,
  } = useQuery(`${QUERIES.GET_ALL_HAK_AKSES}`, () => getAllHakAkses(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
    enabled: activeTab === 2,
  });

  const [allMenus, setAllMenus] = useState<Array<any>>([]);

  const { mutateAsync: GetAllMenus, isLoading: isLoadingGetAllMenus } =
    useMutation({
      mutationKey: `${QUERIES.GET_LIST_MENU}`,
      mutationFn: ({
        app_name,
        rolesId,
      }: {
        app_name: string;
        rolesId: number;
      }) => getAllMenus(app_name, rolesId),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_LIST_MENU}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetAllMenus = useCallback(
    async (app_name: string, rolesId: number) => {
      await GetAllMenus({ app_name, rolesId })
        .then((res) => {
          if (!res.success) {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            });
            return;
          }
          setAllMenus(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllMenus]
  );

  const { mutateAsync: PostRolesMenu, isLoading: isLoadingPostRolesMenu } =
    useMutation({
      mutationKey: `${QUERIES.POST_ROLES_MENU}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postRolesMenu(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_ROLES_MENU}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostRolesMenu = useCallback(
    async (payload: FormData) => {
      await PostRolesMenu({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal) => {
            if (
              swal.isConfirmed &&
              res.success &&
              refetchListData &&
              refetchCurrentUser
            ) {
              refetchListData();
              refetchCurrentUser();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          });
        });
    },
    [PostRolesMenu]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      appName,
      setAppName,
      rolesId,
      setRolesId,
      allApp,
      isLoadingAllApp,
      allHakAkses,
      isLoadingAllHakAkses,
      refetchAllApp,
      refetchAllHakAkses,
      isShowModal,
      setIsShowModal,
      resetForm,
      setResetForm,
      submitting,
      setSubmitting,
      handleGetAllMenus,
      isLoadingGetAllMenus,
      allMenus,
      setAllMenus,
      handlePostRolesMenu,
      isLoadingPostRolesMenu,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      appName,
      setAppName,
      rolesId,
      setRolesId,
      allApp,
      isLoadingAllApp,
      allHakAkses,
      isLoadingAllHakAkses,
      refetchAllApp,
      refetchAllHakAkses,
      isShowModal,
      setIsShowModal,
      resetForm,
      setResetForm,
      submitting,
      setSubmitting,
      handleGetAllMenus,
      isLoadingGetAllMenus,
      allMenus,
      setAllMenus,
      handlePostRolesMenu,
      isLoadingPostRolesMenu,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
