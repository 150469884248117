import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormRiwayatPangkatPayload = {
  id: number | string | null;
  id_riwayat_pangkat: string | null;
  nik_gtk: string | null;
  pangkat_golongan: string | null;
  nomor_sk: string | null;
  tanggal_sk: Date | Dayjs | null;
  tmt_sk: Date | Dayjs | null;
  masa_kerja: number | string | null;
};

export type QueryResponseContextProps<T> = {
  formikRiwayatPangkat?: FormikProps<FormRiwayatPangkatPayload>;
  formikRiwayatPangkatValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostRiwayatPangkat: (data: FormData) => void;
  isLoadingPostRiwayatPangkat: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostRiwayatPangkat: false,
  handlePostRiwayatPangkat: () => {},
};
