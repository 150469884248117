import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { AksiCell } from "./AksiCell";
import { useQueryRequest } from "../../../../../provider/query-request/status-aktivasi/aktif";
import { Button } from "react-bootstrap";
import NamaGTKCell from "../../../../../hope-ui/components/table/NamaGTKCell";
import { useQueryResponsePagination } from "../../../../../provider/query-response/status-aktivasi/aktif";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Unit Kerja"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].unit_kerja}
      </label>
    ),
  },
  /*
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenis PTK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "kepegawaian.jenis_ptk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].kepegawaian.jenis_ptk}
        <Button variant="btn btn-sm btn-danger">Detail</Button>
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenjang"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "kepegawaian.jenjang",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].jenjang}
      </label>
    ),
  },
  */
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
      />
    ),
    id: "action",
    Cell: ({ ...props }) => (
      <AksiCell id={props.data[props.row.index].nik_gtk} data={props.data} />
    ),
  },
];
