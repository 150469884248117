import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../provider/query-request/dashboard/detail-perubahan-status-kepegawaian";
import NamaGTKCell from "../../../../../hope-ui/components/table/NamaGTKCell";
import { AksiCell } from "./AksiCell";
import moment from "moment";
import {
  useQueryResponse,
  useQueryResponsePaginationDetailPerubahanStatusKepegawaian,
} from "../../../../../provider/query-response/dashboard";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } =
        useQueryResponsePaginationDetailPerubahanStatusKepegawaian();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Unit Kerja"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].unit_kerja}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="TMT Pengangkatan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tmt_pengangkatan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].kepegawaian.length > 0
          ? moment(
              props.data[props.row.index].kepegawaian[
                props.data[props.row.index].kepegawaian.length - 1
              ]["tmt_pengangkatan"]
            ).format("DD MMM YYYY") !== "Invalid date" &&
            moment(
              props.data[props.row.index].kepegawaian[
                props.data[props.row.index].kepegawaian.length - 1
              ]["tmt_pengangkatan"]
            ).format("DD MMM YYYY")
          : ""}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
      />
    ),
    id: "action",
    Cell: ({ ...props }) => {
      const { currentStatusGolongan } = useQueryResponse();
      return (
        <AksiCell
          id={
            props.data[props.row.index].kepegawaian[
              props.data[props.row.index].kepegawaian.length - 1
            ]["id"]
          }
          nik_gtk={
            props.data[props.row.index].kepegawaian[
              props.data[props.row.index].kepegawaian.length - 1
            ]["nik_gtk"]
          }
          menuju={currentStatusGolongan.menuju}
        />
      );
    },
  },
];
