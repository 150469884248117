import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormAnggotaKelompokBPIPayload } from "../../absensi-bpi/anggota-kelompok-bpi/_queryResponse";

export type FormKelompokLT3QPayload = {
  id: number | null;
  nama_kelompok_lt3q: string | null;
  nik_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  handlePostKelompokLT3Q?: (data: FormData) => void;
  isLoadingPostKelompokLT3Q: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetDetailKelompokLT3Q?: (id: string) => void;
  isLoadingGetDetailKelompokLT3Q: boolean;
  handleDeleteKelompokLT3Q?: (id: string) => void;
  isLoadingDeleteKelompokLT3Q?: boolean;
  detailKelompokLT3Q?: FormKelompokLT3QPayload;
  setDetailKelompokLT3Q: Dispatch<SetStateAction<FormKelompokLT3QPayload>>;
  resetDetailKelompokLT3Q: () => void;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostKelompokLT3Q: false,
  resetForm: false,
  setResetForm: () => {},
  isLoadingGetDetailKelompokLT3Q: false,
  isLoadingDeleteKelompokLT3Q: false,
  setDetailKelompokLT3Q: () => {},
  resetDetailKelompokLT3Q: () => {},
};
