import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-lt3q/nilai-tahsin";
import { useAuth } from "../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormNilaiTahsinLT3QPayload,
  initialQueryResponse,
} from "../../../../models/absensi-lt3q/nilai-tahsin/_queryResponse";
import {
  getAllKelompokLT3Q,
  getAllNilaiTahsin,
  getAnggotaKelompokLT3Q,
  postNilaiTahsin,
} from "../../../../api/absensi-lt3q/_request";
import { useQueryResponse as useQueryReponseAbsensiLT3Q } from "../../absensi-lt3q";
import { useQueryResponse as useQueryReponseDetailGTK } from "../../detail-gtk";
import { FormKelompokLT3QPayload } from "../../../../models/absensi-lt3q/kelompok-lt3q/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const nik_tutor_lt3q: any = currentUser?.data?.username;
  const queryClient = useQueryClient();
  const { state, updateState } = useQueryRequest();
  const { activeTab } = useQueryReponseAbsensiLT3Q();

  const [allKelompokLT3Q, setAllKelompokLT3Q] = useState<
    Array<FormKelompokLT3QPayload>
  >([]);

  const { handleGetDetailGTK, detailGTK } = useQueryReponseDetailGTK();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  useEffect(() => {
    if (activeTab === 3) {
      setResetForm(true);
    }
  }, [activeTab]);

  const [allNilaiTahsin, setAllNilaiTahsin] = useState<
    Array<FormNilaiTahsinLT3QPayload>
  >([]);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "nilai-tahsin-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "nilai-tahsin-lt3q"),
    [state]
  );
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_ANGGOTA_KELOMPOK_LT3Q}-${query}-nilai-tahsin`,
    () => getAnggotaKelompokLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: activeTab === 3,
    }
  );

  const {
    mutateAsync: GetAllKelompokLT3Q,
    isLoading: isLoadingGetAllKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_LT3Q}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokLT3Q(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokLT3Q = useCallback(
    async (nik_gtk: string, type?: string) => {
      await GetAllKelompokLT3Q({ nik_gtk })
        .then((res) => {
          setAllKelompokLT3Q(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokLT3Q]
  );

  const {
    mutateAsync: GetAllNilaiTahsin,
    isLoading: isLoadingGetAllNilaiTahsin,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_NILAI_TAHSIN}`,
    mutationFn: ({ group }: { group: string }) => getAllNilaiTahsin(group),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_NILAI_TAHSIN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllNilaiTahsin = useCallback(
    async (group?: string) => {
      const _group: any = group;
      await GetAllNilaiTahsin({ group: _group })
        .then((res) => {
          setAllNilaiTahsin(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllNilaiTahsin]
  );

  const formikNilaiTahsinLT3QValidationSchema = Yup.object().shape({
    nama_ujian: Yup.string().required("Nama Ujian / Remidi Tidak Boleh Kosong"),
    tanggal: Yup.string().required("Tanggal Tidak Boleh Kosong"),
    id_kelompok_lt3q: Yup.string().required("Kelompok LT3Q Tidak Boleh Kosong"),
  });

  const formikNilaiTahsinLT3Q = useFormik<FormNilaiTahsinLT3QPayload>({
    initialValues: {
      id: null,
      nama_ujian: null,
      keterangan: null,
      nik_tutor_lt3q,
      tanggal: null,
      detail: [],
      id_kelompok_lt3q: null,
    },
    enableReinitialize: false,
    validationSchema: formikNilaiTahsinLT3QValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      let errors: any = [];
      const detail = values["detail"];
      detail.map((val, idx) => {
        if (val.is_ujian === true && (!val.nilai_teori || !val.nilai_tilawah)) {
          //handleGetDetailGTK(val.nik_gtk);
          errors.push({
            nik_gtk: val.nik_gtk,
            nama_gtk: val.nama_gtk,
          });
        }
      });

      if (errors.length > 0) {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          html: `
               <div class="px-5 mt-2">
                  <table class="text-start table">
                    <tr>
                      <td>NIK</td>
                      <td>:</td>
                      <td>${errors[0].nik_gtk}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>:</td>
                      <td>${errors[0].nama_gtk}</td>
                    </tr>
                  </table>
                </div>
                Nilai Teori atau Nilai Tilawah Untuk GTK Tersebut Tidak Boleh Kosong
              `,
          allowOutsideClick: false,
        });
      } else {
        Object.keys(values).map((key, idx) => {
          if (key === "detail") {
            payload.append(key, JSON.stringify(detail));
          } else payload.append(key, values[key]);
        });
        if (handlePostNilaiTahsin) handlePostNilaiTahsin(payload);
      }
    },
  });

  const { mutateAsync: PostNilaiTahsin, isLoading: isLoadingPostNilaiTahsin } =
    useMutation({
      mutationKey: `${QUERIES.POST_NILAI_TAHSIN}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postNilaiTahsin(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_NILAI_TAHSIN}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostNilaiTahsin = useCallback(
    async (payload: FormData) => {
      await PostNilaiTahsin({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            html: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              setResetForm(true);
              updateState({
                search: null,
                id_kelompok_lt3q: null,
                size: 999999999999,
              });
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostNilaiTahsin]
  );

  useEffect(() => {
    if (resetForm) {
      formikNilaiTahsinLT3Q.setValues({
        id: null,
        nama_ujian: null,
        keterangan: null,
        nik_tutor_lt3q,
        tanggal: null,
        detail: [],
        id_kelompok_lt3q: null,
      });
      setResetForm(false);
      setSubmitting(false);
      if (handleGetAllNilaiTahsin) handleGetAllNilaiTahsin("nama_ujian");
    }
  }, [resetForm, handleGetAllNilaiTahsin]);

  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      refetchListData,
      handleGetAllKelompokLT3Q,
      isLoadingGetAllKelompokLT3Q,
      allKelompokLT3Q,
      setAllKelompokLT3Q,
      formikNilaiTahsinLT3Q,
      formikNilaiTahsinLT3QValidationSchema,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      handleGetAllNilaiTahsin,
      isLoadingGetAllNilaiTahsin,
      allNilaiTahsin,
      setAllNilaiTahsin,
      handlePostNilaiTahsin,
      isLoadingPostNilaiTahsin,
    }),
    [
      listData,
      isLoadingFetchData,
      refetchListData,
      handleGetAllKelompokLT3Q,
      isLoadingGetAllKelompokLT3Q,
      allKelompokLT3Q,
      setAllKelompokLT3Q,
      formikNilaiTahsinLT3Q,
      formikNilaiTahsinLT3QValidationSchema,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      handleGetAllNilaiTahsin,
      isLoadingGetAllNilaiTahsin,
      allNilaiTahsin,
      setAllNilaiTahsin,
      handlePostNilaiTahsin,
      isLoadingPostNilaiTahsin,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};
const useQueryResponse = () => useContext(QueryResponseContext);

export { QueryResponseProvider, useQueryResponse };
