import { SearchInputDT } from "../../../../../hope-ui/components/table/SearchInput";
import { ShowLimitDT } from "../../../../../hope-ui/components/table/ShowLimit";
import { useQueryRequest } from "../../../../../provider/query-request/mutasi";
import { useQueryResponse } from "../../../../../provider/query-response/mutasi";

const Toolbar = () => {
  const { updateState, state } = useQueryRequest();
  const { setShowModalMutasi } = useQueryResponse();

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          type="button"
          className="btn btn-sm btn-info"
          onClick={() => setShowModalMutasi(true)}
        >
          <i className="far fa-add me-2"></i>Tambah Mutasi
        </button>
      </div>
      <div className="row mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Toolbar;
