import { Dayjs } from "dayjs";

type Links = {
  first: null | string;
  last: null | string;
  next: null | string;
  prev: null | string;
};

type MetaLinks = {
  url: null | string;
  label: null | string;
  active: boolean;
};

export type Meta = {
  current_page: number;
  from: number;
  last_page: number;
  links: Array<MetaLinks>;
  path: string;
  per_page: number;
  to: number;
  total: number;
};

export type Response<T> = {
  data?: T;
  links: Links;
  meta: Meta;
  size: number;
  page: number;
  count?: number;
  total_rows?: number;
  offset?: number;
  group_by?: string | null;
  nik_pengirim?: string | null;
  nik_tujuan?: string | null;
  nik_gtk?: string | null;
  nik_tutor_bpi?: string | null;
  nik_tutor_lt3q?: string | null;
  nik_gtk_tutor?: string | null;
  id_riwayat_bpi?: string | null;
  id_riwayat_lt3q?: string | null;
  id_kelompok_bpi?: Array<number> | string | null;
  id_kelompok_lt3q?: Array<number> | string | null;
  app_name: string | null;
  rolesId: number | null;
  jumlah_hari?: number | null;
};

type SortState = {
  sort_by?: string | null;
  sort_type?: "asc" | "desc" | null;
};

type FilterState = {
  search?: string | null;
  col_header?: string | null;
  filter?: string | null;
  table_name?: string | null;
  bulan?: string | number | null;
  tahun?: number | null;
  status_aktivasi?: string | null;
  status_bekerja?: string | null;
  filter_dashboard?: string | null;
  filter_tanggal?: Date | string | null;
  tanggal?: Date | Dayjs | string | null;
  tanggal_awal?: Date | Dayjs | string | null;
  tanggal_akhir?: Date | Dayjs | string | null;
  unit_kerja?: string | null;
};

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>>;
  isLoading: boolean;
  query: string;
  refetch: () => void;
};

export type QueryState = Response<any> & SortState & FilterState;

export const initialQueryState: QueryState = {
  links: {
    first: null,
    last: null,
    next: null,
    prev: null,
  },
  meta: {
    current_page: 1,
    from: 1,
    last_page: 1,
    links: [],
    path: "",
    per_page: 10,
    to: 5,
    total: 0,
  },
  search: "",
  size: 10,
  page: 1,
  sort_by: null,
  sort_type: null,
  count: 0,
  col_header: null,
  total_rows: 0,
  offset: 0,
  filter: null,
  group_by: null,
  table_name: null,
  nik_pengirim: null,
  nik_tujuan: null,
  nik_gtk: null,
  nik_tutor_bpi: null,
  nik_tutor_lt3q: null,
  nik_gtk_tutor: null,
  id_kelompok_bpi: null,
  id_kelompok_lt3q: null,
  bulan: null,
  tahun: null,
  id_riwayat_bpi: null,
  id_riwayat_lt3q: null,
  app_name: null,
  rolesId: null,
  status_aktivasi: null,
  status_bekerja: null,
  filter_dashboard: null,
  filter_tanggal: null,
  tanggal: null,
  tanggal_awal: null,
  tanggal_akhir: null,
  unit_kerja: null,
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};
