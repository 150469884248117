import { Card } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePagination,
} from "../../provider/query-response/daftar-unit-kerja";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../provider/query-request/daftar-unit-kerja";
import { DataTable } from "../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns";
import ModalDaftarUnitKerja from "./entry";
import Toolbar from "./list/toolbar";

const DaftarUnitKerjaList = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  return (
    <>
      <Toolbar />
      <ModalDaftarUnitKerja />
      <Card.Body className="pt-0">
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={listData?.data}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </>
  );
};

export const DaftarUnitKerjaListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <DaftarUnitKerjaList />
    </QueryResponseProvider>
  </QueryRequestProvider>
);
