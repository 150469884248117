import { Response } from "../global";

type UserRoles = {
  id: string;
  name: string;
  guard_name: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
};

type UserMenus = {
  id: string;
  app_name: string;
  parent_id: number;
  top_parent: number;
  label: string;
  icon: string;
  route_name: string | null;
  status: boolean;
  order: number;
  child: Array<UserMenus> | null;
};

type currentUser = {
  id: string;
  username: string | null;
  name: string | null;
  email: string | null;
  password: string;
  no_telepon: string | null;
  rolesId: number;
  access_token: string | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
  roles: UserRoles;
  menus: Array<UserMenus>;
  poto: string | null;
};

export type QueryResponseContextProps<T> = {
  currentUser?: Response<currentUser>;
  isLoadingFetchCurrentUser: boolean;
  handleAuthenticate: (data: FormData) => void;
  isLoadingPostAuthenticate: boolean;
  handleLogout: () => void;
  isLoadingLogout: boolean;
  refetchCurrentUser?: () => void;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchCurrentUser: false,
  handleAuthenticate: () => {},
  isLoadingPostAuthenticate: false,
  handleLogout: () => {},
  isLoadingLogout: false,
};
