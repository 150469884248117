import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-lt3q/kelompok-lt3q/pindah-tutor";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  FormPindahTutorLT3QPayload,
  TutorLT3Q,
  initialQueryResponse,
} from "../../../../../models/absensi-lt3q/pindah-tutor/_queryResponse";
import {
  getAllKelompokLT3Q,
  getPindahTutorLT3Q,
  getTutorLT3Q,
  postPindahTutorLT3Q,
} from "../../../../../api/absensi-lt3q/_request";

import { useQueryResponse as useQueryResponseAbsensiLT3Q } from "../../../absensi-lt3q";
import { useAuth } from "../../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormKelompokLT3QPayload } from "../../../../../models/absensi-lt3q/kelompok-lt3q/_queryResponse";
import { useQueryResponse as useQueryResponseAnggotaKelompok } from "../kelola-anggota-kelompok";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const refetchListDataAnggotaKelompok =
    useQueryResponseAnggotaKelompok().refetchListData;
  const { state } = useQueryRequest();
  state.col_header = `["nik_gtk", "nik_tutor_lama", "nik_tutor_tujuan"]`;
  const { activeTab } = useQueryResponseAbsensiLT3Q();
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [allTutorLT3Q, setAllTutorLT3Q] = useState<Array<TutorLT3Q>>(
    initialQueryResponse.allTutorLT3Q
  );

  const [allKelompokTutorLT3QTujuan, setAllKelompokTutorLT3QTujuan] = useState<
    Array<FormKelompokLT3QPayload>
  >(initialQueryResponse.allKelompokTutorLT3QTujuan);

  const nik_tutor_lt3q_lama: any = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);

  const {
    mutateAsync: PostPindahTutorLT3Q,
    isLoading: isLoadingPostPindahTutorLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.POST_PINDAH_TUTOR_LT3Q}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postPindahTutorLT3Q(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_PINDAH_TUTOR_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostPindahTutorLT3Q = useCallback(
    async (payload: any) => {
      await PostPindahTutorLT3Q({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData && refetchListDataAnggotaKelompok) {
                refetchListData();
                refetchListDataAnggotaKelompok();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPindahTutorLT3Q]
  );

  const { mutateAsync: GetTutorLT3Q, isLoading: isLoadingGetTutorLT3Q } =
    useMutation({
      mutationKey: `${QUERIES.GET_TUTOR_LT3Q}`,
      mutationFn: () => getTutorLT3Q(nik_tutor_lt3q_lama),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_TUTOR_LT3Q}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetTutorLT3Q = useCallback(async () => {
    await GetTutorLT3Q()
      .then((res) => {
        setAllTutorLT3Q(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetTutorLT3Q]);

  const formikPindahTutorLT3QValidationSchema = Yup.object().shape({
    id_kelompok_lt3q: Yup.string().required("Kelompok LT3Q Boleh Kosong"),
    id_anggota_kelompok_lt3q: Yup.string().required(
      "Anggota Kelompok LT3Q Tidak Boleh Kosong"
    ),
    nik_tutor_lt3q_tujuan: Yup.string().required(
      "Tutor LT3Q Tujuan Tidak Boleh Kosong"
    ),
    id_kelompok_lt3q_tujuan: Yup.string().required(
      "Kelompok LT3Q Tujuan Tidak Boleh Kosong"
    ),
  });

  const formikPindahTutorLT3Q = useFormik<FormPindahTutorLT3QPayload>({
    initialValues: {
      id: null,
      id_anggota_kelompok_lt3q: null,
      id_kelompok_lt3q: null,
      id_kelompok_lt3q_tujuan: null,
      nik_tutor_lt3q_lama,
      nik_tutor_lt3q_tujuan: null,
      keterangan: null,
    },
    enableReinitialize: false,
    validationSchema: formikPindahTutorLT3QValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostPindahTutorLT3Q) handlePostPindahTutorLT3Q(payload);
    },
  });

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kelompok-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kelompok-lt3q"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      formikPindahTutorLT3Q.setValues({
        id: null,
        id_anggota_kelompok_lt3q: null,
        id_kelompok_lt3q: null,
        id_kelompok_lt3q_tujuan: null,
        nik_tutor_lt3q_lama,
        nik_tutor_lt3q_tujuan: null,
        keterangan: null,
      });
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_PINDAH_TUTOR_LT3Q}-${query}`,
    () => getPindahTutorLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const {
    mutateAsync: GetAllKelompokLT3QTujuan,
    isLoading: isLoadingGetAllKelompokLT3QTujuan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_LT3Q}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokLT3Q(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokLT3QTujuan = useCallback(
    async (nik_gtk: string, type?: string) => {
      await GetAllKelompokLT3QTujuan({ nik_gtk })
        .then((res) => {
          if (type && type === "tujuan") {
            return setAllKelompokTutorLT3QTujuan(res.data);
          }
          setAllKelompokTutorLT3QTujuan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokLT3QTujuan]
  );

  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPindahTutorLT3Q,
      formikPindahTutorLT3QValidationSchema,
      handlePostPindahTutorLT3Q,
      isLoadingPostPindahTutorLT3Q,
      submitting,
      setSubmitting,
      handleGetTutorLT3Q,
      isLoadingGetTutorLT3Q,
      allTutorLT3Q,
      setAllTutorLT3Q,
      allKelompokTutorLT3QTujuan,
      setAllKelompokTutorLT3QTujuan,
      handleGetAllKelompokLT3QTujuan,
      isLoadingGetAllKelompokLT3QTujuan,
      resetForm,
      setResetForm,
    }),
    [
      listData,
      isLoadingFetchData,
      refetchListData,
      formikPindahTutorLT3Q,
      formikPindahTutorLT3QValidationSchema,
      handlePostPindahTutorLT3Q,
      isLoadingPostPindahTutorLT3Q,
      submitting,
      setSubmitting,
      handleGetTutorLT3Q,
      isLoadingGetTutorLT3Q,
      allTutorLT3Q,
      setAllTutorLT3Q,
      allKelompokTutorLT3QTujuan,
      setAllKelompokTutorLT3QTujuan,
      handleGetAllKelompokLT3QTujuan,
      isLoadingGetAllKelompokLT3QTujuan,
      resetForm,
      setResetForm,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
