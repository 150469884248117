import { FC } from "react";
import { Spinner } from "react-bootstrap";
import NamaGTKCell from "../../../../../hope-ui/components/table/NamaGTKCell";

type Props = {
  rows?: Array<any>;
  allowed_columns: Array<any>;
  isLoading?: boolean;
};

const TableBody: FC<Props> = ({ rows, allowed_columns, isLoading }) => {
  //const allowed_columns = ["nik_gtk", "nama_gtk"];
  if (rows) {
    rows = rows.map((val) => {
      return {
        nik_gtk: val.nik_gtk,
        gtk: val.gtk,
        kelompok_lt3q: val.riwayat_lt3q.kelompok_lt3q.nama_kelompok_lt3q,
        hadir: val.hadir,
        sakit: val.sakit,
        izin: val.izin,
        alfa: val.alfa,
      };
    });
  }
  return (
    <tbody>
      {!isLoading &&
        rows?.map((row, i) => {
          let keys = Object.keys(row);
          return (
            <tr className="fs-7 border-bottom border-gray-300 table-row">
              {keys.map((key, idx) => {
                const validate_column = allowed_columns.find((e) => e === key);
                return (
                  validate_column && (
                    <td className="table-cell">
                      {" "}
                      <div
                        style={{ fontSize: 14 }}
                        className="text-center w-100 table-cell cursor-pointer"
                      >
                        {validate_column === "gtk" ? (
                          <NamaGTKCell
                            id={row["nik_gtk"]}
                            label={row[validate_column]["nama_gtk"]}
                          />
                        ) : (
                          row[validate_column]
                        )}
                      </div>
                    </td>
                  )
                );
              })}
            </tr>
          );
        })}

      {isLoading && (
        <tr>
          <td colSpan={allowed_columns.length}>
            <div className="d-flex flex-row justify-content-center my-0">
              <Spinner
                animation="border"
                variant="success"
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          </td>
        </tr>
      )}

      {rows && rows.length === 0 && !isLoading && (
        <tr>
          <td colSpan={allowed_columns.length}>
            <label className="fs-6 text-danger w-100 text-center">
              Tidak Ada Data Ditemukan
            </label>
          </td>
        </tr>
      )}

      {rows && rows.length === 1 && !isLoading && (
        <>
          <tr className="border-0">
            <td className="border-0" colSpan={allowed_columns.length}></td>
          </tr>

          <tr className="border-0">
            <td className="border-0" colSpan={allowed_columns.length}></td>
          </tr>
        </>
      )}
    </tbody>
  );
};

export default TableBody;
