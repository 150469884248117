import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormRolesPayload,
  initialQueryResponse,
} from "../../../../models/hak-akses/kelola-role/_queryResponse";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import {
  getHakAkses,
  postHakAkses,
  getDetailHakAkses,
  deleteHakAkses,
} from "../../../../api/hak-akses/_request";
import { initialQueryState } from "../../../../models/global";
import { useQueryRequest } from "../../../query-request/hak-akses/kelola-role";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryResponse as useQueryResponseHakAkses } from "../../../query-response/hak-akses";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [detailRoles, setDetailRoles] = useState<FormRolesPayload>(
    initialQueryResponse.detailRoles
  );

  const { activeTab } = useQueryResponseHakAkses();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["name"]`;
  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "hak-akses")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "hak-akses"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);
  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(`${QUERIES.GET_HAK_AKSES}-${query}`, () => getHakAkses(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
    enabled: activeTab === 1,
  });

  const { mutateAsync: PostHakAkses, isLoading: isLoadingPostHakAkses } =
    useMutation({
      mutationKey: `${QUERIES.POST_HAK_AKSES}`,
      mutationFn: ({ payload }: { payload: FormData }) => postHakAkses(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_HAK_AKSES}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostHakAkses = useCallback(
    async (payload: any) => {
      await PostHakAkses({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success && refetchListData) {
              refetchListData();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostHakAkses]
  );

  const formikRolesValidationSchema = Yup.object().shape({
    name: Yup.string().required("Nama Level Tidak Boleh Kosong"),
  });

  const formikRoles = useFormik<FormRolesPayload>({
    initialValues: {
      id: null,
      name: null,
    },
    enableReinitialize: false,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      if (handlePostHakAkses) handlePostHakAkses(payload);
    },
    validationSchema: formikRolesValidationSchema,
  });

  useEffect(() => {
    if (resetForm) {
      setDetailRoles(initialQueryResponse.detailRoles);
      formikRoles.setValues(initialQueryResponse.detailRoles);
      setResetForm(false);
      setSubmitting(false);
    }
  }, [resetForm]);

  const {
    mutateAsync: GetDetailHakAkses,
    isLoading: isLoadingGetDetailHakAkses,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_HAK_AKSES}`,
    mutationFn: ({ id }: { id: string }) => getDetailHakAkses(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_HAK_AKSES}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailHakAkses = useCallback(
    async (id: string) => {
      await GetDetailHakAkses({ id })
        .then((res) => {
          if (!res.success) {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            });
            return;
          }
          setDetailRoles(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailHakAkses]
  );

  const { mutateAsync: DeleteHakAkses, isLoading: isLoadingDeleteHakAkses } =
    useMutation({
      mutationKey: `${QUERIES.DELETE_HAK_AKSES}`,
      mutationFn: ({ id }: { id: string }) => deleteHakAkses(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.DELETE_HAK_AKSES}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDeleteHakAkses = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        isShowCancelButton: true,
        cancelButtonText: "Batalkan",
        confirmButtonText: "Lanjutkan",
      }).then(async (swal) => {
        if (swal.isConfirmed)
          await DeleteHakAkses({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success && refetchListData) {
                  refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
      });
    },
    [DeleteHakAkses]
  );

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      handlePostHakAkses,
      isLoadingPostHakAkses,
      formikRoles,
      formikRolesValidationSchema,
      isShowModal,
      setIsShowModal,
      resetForm,
      setResetForm,
      submitting,
      setSubmitting,
      detailRoles,
      setDetailRoles,
      handleGetDetailHakAkses,
      isLoadingGetDetailHakAkses,
      handleDeleteHakAkses,
      isLoadingDeleteHakAkses,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      handlePostHakAkses,
      isLoadingPostHakAkses,
      formikRoles,
      formikRolesValidationSchema,
      isShowModal,
      setIsShowModal,
      resetForm,
      setResetForm,
      submitting,
      setSubmitting,
      detailRoles,
      setDetailRoles,
      handleGetDetailHakAkses,
      isLoadingGetDetailHakAkses,
      handleDeleteHakAkses,
      isLoadingDeleteHakAkses,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
