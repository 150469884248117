import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormRiwayatPendidikanPayload = {
  id: number | string | null;
  id_pendidikan: string | null;
  nik_gtk: string | null;
  jurusan: string | null;
  jenjang_pendidikan: string | null;
  nama_perguruan_tinggi: string | null;
  tahun_masuk: number | string | null;
  tahun_lulus: number | string | null;
  gelar_akademik: string | null;
  ipk: number | string | null;
  nomor_ijazah: string | null;
  doc_transkrip_nilai: File | string | null;
  doc_ijazah: File | string | null;
};
export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalRiwayatPendidikan: boolean;
  setShowModalRiwayatPendidikan: Dispatch<SetStateAction<boolean>>;
  formikRiwayatPendidikan?: FormikProps<FormRiwayatPendidikanPayload>;
  formikRiwayatPendidikanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostRiwayatPendidikan: (data: FormData) => void;
  isLoadingPostRiwayatPendidikan: boolean;
  handleGetDetailRiwayatPendidikan: (id: string) => void;
  isLoadingGetDetailRiwayatPendidikan: boolean;
  detailRiwayatPendidikan: FormRiwayatPendidikanPayload;
  setDetailRiwayatPendidikan: Dispatch<
    SetStateAction<FormRiwayatPendidikanPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocRiwayatPendidikan: (url: string, fileName: string) => void;
  isLoadingDownloadDocRiwayatPendidikan: boolean;
  handleDeleteRiwayatPendidikan: (id: string) => void;
  isLoadingDeleteRiwayatPendidikan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalRiwayatPendidikan: false,
  setShowModalRiwayatPendidikan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostRiwayatPendidikan: () => {},
  isLoadingPostRiwayatPendidikan: false,
  handleGetDetailRiwayatPendidikan: () => {},
  isLoadingGetDetailRiwayatPendidikan: false,
  detailRiwayatPendidikan: {
    id: null,
    id_pendidikan: null,
    nik_gtk: null,
    jurusan: "",
    jenjang_pendidikan: "",
    nama_perguruan_tinggi: "",
    tahun_masuk: "",
    tahun_lulus: "",
    gelar_akademik: "",
    ipk: "",
    nomor_ijazah: "",
    doc_transkrip_nilai: null,
    doc_ijazah: null,
  },
  setDetailRiwayatPendidikan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocRiwayatPendidikan: () => {},
  isLoadingDownloadDocRiwayatPendidikan: false,
  handleDeleteRiwayatPendidikan: () => {},
  isLoadingDeleteRiwayatPendidikan: false,
};
