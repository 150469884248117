import { FC } from "react";
import { useQueryResponse } from "../../../../../provider/query-response/memo";
import FetchLoadingCompanyLogo from "../../../../../hope-ui/components/fetch-loading-with-company-logo";
import { Table } from "react-bootstrap";
import moment from "moment";

type Props = {};

const DetailMemo: FC<Props> = () => {
  const {
    isShowDetailMemo,
    setShowDetailMemo,
    isLoadingGetDetailMemo,
    detailMemo,
  } = useQueryResponse();

  const isi_pesan: any = detailMemo && detailMemo.isi_pesan;

  return (
    <div
      style={{
        display: isShowDetailMemo ? "block" : "none",
      }}
      className="px-2 py-0 "
    >
      <button
        type="button"
        className="bg-light text-dark border border-light cursor-pointer btn-md me-2 py-2 px-4 rounded-pill "
        onClick={() => setShowDetailMemo(false)}
      >
        <i
          className="fas fa-chevron-left me-2"
          onClick={() => setShowDetailMemo(false)}
        ></i>{" "}
        Kembali
      </button>
      {isLoadingGetDetailMemo && <FetchLoadingCompanyLogo />}
      {!isLoadingGetDetailMemo && detailMemo && (
        <div className="p-4 py-2 border mt-3">
          <div className="d-flex flex-row justify-content-end align-items-center gap-2 mb-3">
            <i className="far fa-calendar"></i>
            <div>{moment(detailMemo.updatedAt).format("DD MMM YYYY")}</div>

            <i className="far fa-clock"></i>
            <div>{moment(detailMemo.updatedAt).format("HH:mm")}</div>
          </div>

          <div className="d-flex flex-row justify-content-start align-items-start mb-2">
            <label
              className="text-dark"
              style={{
                fontSize: "16px",
                fontWeight: "600",
                minWidth: "100px",
              }}
            >
              Subject
            </label>
            <div
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {detailMemo.judul_pesan}
            </div>
          </div>

          <div className="d-flex flex-row justify-content-start align-items-start">
            <label
              className="text-dark"
              style={{
                fontSize: "16px",
                fontWeight: "600",
                minWidth: "100px",
              }}
            >
              Isi Pesan
            </label>
            {/* <div
              dangerouslySetInnerHTML={{
                __html: isi_pesan.replace(/(?:\\[rn])+/g, "<br/>"),
              }}
            ></div> */}
            <label style={{ whiteSpace: "pre-line" }}>
              {detailMemo.isi_pesan}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailMemo;
