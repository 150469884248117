import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormPenghargaanPayload,
  initialQueryResponse,
} from "../../../models/penghargaan/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/penghargaan";
import {
  deletePenghargaan,
  downloadDocPenghargaan,
  getDetailPenghargaan,
  getListPenghargaan,
  postPenghargaan,
} from "../../../api/penghargaan/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama_penghargaan", "jenis_penghargaan", "tingkat_penghargaan", "tahun", "instansi"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalPenghargaan, setShowModalPenghargaan] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailPenghargaan.nik_gtk = nik_gtk;

  const [detailPenghargaan, setDetailPenghargaan] =
    useState<FormPenghargaanPayload>(initialQueryResponse.detailPenghargaan);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikPenghargaan.setValues(initialQueryResponse.detailPenghargaan);
      setDetailPenghargaan(initialQueryResponse.detailPenghargaan);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_PENGHARGAAN}-${query}`,
    () => getListPenghargaan(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikPenghargaanValidationSchema = Yup.object().shape({
    nama_penghargaan: Yup.string().required(
      "Nama Penghargaan Tidak Boleh Kosong"
    ),
    jenis_penghargaan: Yup.string().required(
      "Jenis Penghargaan Tidak Boleh Kosong"
    ),
    tingkat_penghargaan: Yup.string().required(
      "Tingkat Penghargaan Tidak Boleh Kosong"
    ),
    tahun: Yup.string().required("Tahun Tidak Boleh Kosong"),
    instansi: Yup.string().required("Instansi Tidak Boleh Kosong"),
  });

  const formikPenghargaan = useFormik<FormPenghargaanPayload>({
    initialValues: initialQueryResponse.detailPenghargaan,
    enableReinitialize: false,
    validationSchema: formikPenghargaanValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostPenghargaan(payload);
    },
  });

  const { mutateAsync: PostPenghargaan, isLoading: isLoadingPostPenghargaan } =
    useMutation({
      mutationKey: `${QUERIES.POST_PENGHARGAAN}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postPenghargaan(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_PENGHARGAAN}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostPenghargaan = useCallback(
    async (payload: any) => {
      await PostPenghargaan({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalPenghargaan(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPenghargaan]
  );

  const {
    mutateAsync: GetDetailPenghargaan,
    isLoading: isLoadingGetDetailPenghargaan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_PENGHARGAAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailPenghargaan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_PENGHARGAAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailPenghargaan = useCallback(
    async (id: string) => {
      await GetDetailPenghargaan({ id })
        .then((res) => {
          setDetailPenghargaan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailPenghargaan]
  );

  const {
    mutateAsync: DownloadDocPenghargaan,
    isLoading: isLoadingDownloadDocPenghargaan,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_PENGHARGAAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocPenghargaan(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_PENGHARGAAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocPenghargaan = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocPenghargaan({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocPenghargaan]
  );

  const {
    mutateAsync: DeletePenghargaan,
    isLoading: isLoadingDeletePenghargaan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_PENGHARGAAN}`,
    mutationFn: ({ id }: { id: string }) => deletePenghargaan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_PENGHARGAAN}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeletePenghargaan = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeletePenghargaan({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeletePenghargaan]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalPenghargaan,
      setShowModalPenghargaan,
      formikPenghargaan,
      formikPenghargaanValidationSchema,
      submitting,
      setSubmitting,
      handlePostPenghargaan,
      isLoadingPostPenghargaan,
      detailPenghargaan,
      setDetailPenghargaan,
      handleGetDetailPenghargaan,
      isLoadingGetDetailPenghargaan,
      resetForm,
      setResetForm,
      handleDownloadDocPenghargaan,
      isLoadingDownloadDocPenghargaan,
      handleDeletePenghargaan,
      isLoadingDeletePenghargaan,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalPenghargaan,
      setShowModalPenghargaan,
      formikPenghargaan,
      formikPenghargaanValidationSchema,
      submitting,
      setSubmitting,
      handlePostPenghargaan,
      isLoadingPostPenghargaan,
      detailPenghargaan,
      setDetailPenghargaan,
      handleGetDetailPenghargaan,
      isLoadingGetDetailPenghargaan,
      resetForm,
      setResetForm,
      handleDownloadDocPenghargaan,
      isLoadingDownloadDocPenghargaan,
      handleDeletePenghargaan,
      isLoadingDeletePenghargaan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
