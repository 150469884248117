import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC } from "react";
import { Form } from "react-bootstrap";

type Props = {
  label?: string;
  data?: Array<any>;
  required?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: any;
  formik?: FieldInputProps<HTMLInputElement>;
};

const InputRadio: FC<Props> = ({
  label,
  required,
  data,
  value,
  onChange,
  formik,
}) => {
  return (
    <>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger"> * </span>}
        </Form.Label>
      )}

      <div className="d-flex flex-row justify-content-start align-items-center gap-4">
        {data?.map((val, idx) => {
          return (
            <div className="d-flex flex-row justify-content-start align-items-center gap-2">
              <input
                type="radio"
                className="form-check"
                onChange={onChange}
                checked={value === val.value}
                id={val.id}
                value={val.value}
                {...formik}
              ></input>
              <label className={``} htmlFor={val.id}>
                {val.label}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default InputRadio;
