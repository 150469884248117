import { Card, Col, Row, Spinner, Table } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../provider/query-response/backup-data";
import FetchLoading from "../../hope-ui/components/fetch-loading";
import moment from "moment";

const BackupDataList = () => {
  const {
    handleDownloadDatabaseGTK,
    isLoadingDownloadDatabaseGTK,
    isLoadingPostHistoryBackup,
    listData,
    isLoadingFetchData,
    handleDownloadDatabaseSSO,
    isLoadingDownloadDatabaseSSO,
  } = useQueryResponse();
  return (
    <Card.Body className="p-4">
      {(isLoadingDownloadDatabaseGTK ||
        isLoadingPostHistoryBackup ||
        isLoadingDownloadDatabaseSSO) && <FetchLoading />}
      <Row className="gy-3 gx-1">
        <Col md="4">
          <button
            type="button"
            className="btn btn-info btn-sm"
            onClick={() => handleDownloadDatabaseGTK()}
          >
            <i className="fa fa-download me-2"></i>Download Database GTK ( .sql
            )
          </button>
        </Col>

        <Col md="4">
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => {
              if (handleDownloadDatabaseSSO) handleDownloadDatabaseSSO();
            }}
          >
            <i className="fa fa-download me-2"></i>Download Database SSO ( .sql
            )
          </button>
        </Col>
      </Row>

      <Table responsive className="fs-6 mt-4">
        <thead>
          <tr>
            <th>No.</th>
            <th>Database</th>
            <th>Diunduh Oleh</th>
            <th>Tanggal & Jam</th>
          </tr>
        </thead>
        <tbody>
          {isLoadingFetchData && (
            <tr>
              <td colSpan={4}>
                <div className="d-flex flex-row justify-content-center my-0">
                  <Spinner
                    animation="border"
                    variant="success"
                    style={{
                      width: 50,
                      height: 50,
                    }}
                  />
                </div>
              </td>
            </tr>
          )}
          {!isLoadingFetchData &&
            listData?.data?.map((val, idx) => {
              return (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{val.app_name}</td>
                  <td>{val.users.name}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <i className="far fa-calendar me-1"></i>
                      {moment(val.createdAt).format("DD MMM YYYY")}
                    </div>

                    <div className="d-flex align-items-center">
                      <i className="far fa-clock me-1"></i>
                      {moment(val.createdAt).format("HH:mm")}
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Card.Body>
  );
};

export const BackupDataListWrapper = () => (
  <QueryResponseProvider>
    <BackupDataList />
  </QueryResponseProvider>
);
