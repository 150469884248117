import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  appName: string | null;
  setAppName: Dispatch<SetStateAction<string>>;
  rolesId: number | null;
  setRolesId: Dispatch<SetStateAction<number>>;
  allApp?: Response<Array<T>>;
  allHakAkses?: Response<Array<T>>;
  isLoadingAllApp: boolean;
  isLoadingAllHakAkses: boolean;
  isShowModal?: boolean;
  setIsShowModal: Dispatch<SetStateAction<boolean>>;
  resetForm?: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  submitting?: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handleGetAllMenus: (app_name: string, rolesId: number) => void;
  isLoadingGetAllMenus: boolean;
  allMenus?: Array<any>;
  setAllMenus: Dispatch<SetStateAction<Array<any>>>;
  handlePostRolesMenu?: (data: FormData) => void;
  isLoadingPostRolesMenu: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  appName: "",
  setAppName: () => {},
  rolesId: 0,
  setRolesId: () => {},
  isLoadingAllApp: false,
  isLoadingAllHakAkses: false,
  setIsShowModal: () => {},
  setResetForm: () => {},
  setSubmitting: () => {},
  handleGetAllMenus: () => {},
  isLoadingGetAllMenus: false,
  setAllMenus: () => {},
  isLoadingPostRolesMenu: false,
};
