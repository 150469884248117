import { Card } from "react-bootstrap";
import { useQueryRequest } from "../../../../../provider/query-request/hak-akses/kelola-role";
import { useQueryResponse } from "../../../../../provider/query-response/hak-akses/kelola-role";
import { ShowLimitDT } from "../../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../../hope-ui/components/table/SearchInput";
import ModalEntryRoles from "../../entry";

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  const { setIsShowModal } = useQueryResponse();
  return (
    <>
      <ModalEntryRoles />
      <Card.Header className="pt-3">
        <div className="d-flex flex-row justify-content-end align-items-center mb-1 gap-2">
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => setIsShowModal(true)}
          >
            <i className="far fa-plus"></i> Tambah Level Akses
          </button>
        </div>

        <div className="row pt-3 px-0">
          <div className="col-md-6 col-12">
            <ShowLimitDT
              onChange={(e) => updateState({ size: parseInt(e.target.value) })}
              limit={state.size}
            />
          </div>
          <div className="col-md-6 col-12 ">
            <SearchInputDT
              state={(keyword: string) => {
                updateState({ search: keyword });
              }}
              value={state.search ? state.search : ""}
            />
          </div>
        </div>
      </Card.Header>
    </>
  );
};
export default Toolbar;
