import { useEffect } from "react";
import { useLayout } from "../../layout/core/LayoutProvider";

const User = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Kelola User";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Kelola User";
      return jasper;
    });
  }, []);

  return <></>;
};

export default User;
