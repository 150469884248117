import { Dispatch, SetStateAction } from "react";

export type FormAktifkanGTKPayload = {
  nik_gtk: string | null;
  status: boolean;
};
export type QueryResponseContextProps<T> = {
  handlePostAktifkanGTK?: (data: FormData) => void;
  isLoadingPostAktifkanGTK?: boolean;
  handlePostNonAktifkanGTK?: (data: FormData) => void;
  isLoadingPostNonAktifkanGTK?: boolean;
  statusPostAktifkanGTK: boolean;
  statusPostNonAktifkanGTK: boolean;
  setStatusPostAktifkanGTK: Dispatch<SetStateAction<boolean>>;
  setStatusPostNonAktifkanGTK: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  handlePostAktifkanGTK: () => {},
  isLoadingPostAktifkanGTK: false,
  handlePostNonAktifkanGTK: () => {},
  isLoadingPostNonAktifkanGTK: false,
  statusPostAktifkanGTK: false,
  statusPostNonAktifkanGTK: false,
  setStatusPostAktifkanGTK: () => {},
  setStatusPostNonAktifkanGTK: () => {},
};
