import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import { FormDaftarUnitKerjaPayload } from "../daftar-unit-kerja/_queryResponse";
import { FormRolesPayload } from "../hak-akses/kelola-role/_queryResponse";

export type FormFilterUnitKerjaPayload = {
  id: number | string | null;
  hak_akses: string | null;
  id_unit_kerja: number | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  formikFilterUnitKerja?: FormikProps<FormFilterUnitKerjaPayload>;
  formikFilterUnitKerjaValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostFilterUnitKerja: (data: FormData) => void;
  isLoadingPostFilterUnitKerja: boolean;
  handleGetDetailFilterUnitKerja: (id: string) => void;
  isLoadingGetDetailFilterUnitKerja: boolean;
  detailFilterUnitKerja: FormFilterUnitKerjaPayload;
  setDetailFilterUnitKerja: Dispatch<
    SetStateAction<FormFilterUnitKerjaPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteFilterUnitKerja: (id: string) => void;
  isLoadingDeleteFilterUnitKerja: boolean;
  handleGetAllDaftarUnitKerja: () => void;
  isLoadingGetAllDaftarUnitKerja: boolean;
  allDaftarUnitKerja: Array<FormDaftarUnitKerjaPayload>;
  setAllDaftarUnitKerja: Dispatch<
    SetStateAction<Array<FormDaftarUnitKerjaPayload>>
  >;
  allHakAkses: Array<FormRolesPayload>;
  setAllHakAkses: Dispatch<SetStateAction<Array<FormRolesPayload>>>;
  handleGetAllHakAkses: () => void;
  isLoadingGetAllHakAkses: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  handleGetAllHakAkses: () => {},
  isLoadingGetAllHakAkses: false,
  isLoadingFetchData: false,
  showModal: false,
  setShowModal: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostFilterUnitKerja: () => {},
  isLoadingPostFilterUnitKerja: false,
  handleGetDetailFilterUnitKerja: () => {},
  isLoadingGetDetailFilterUnitKerja: false,
  detailFilterUnitKerja: {
    id: null,
    id_unit_kerja: null,
    hak_akses: null,
  },
  setDetailFilterUnitKerja: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleGetAllDaftarUnitKerja: () => {},
  isLoadingGetAllDaftarUnitKerja: false,
  handleDeleteFilterUnitKerja: () => {},
  isLoadingDeleteFilterUnitKerja: false,
  allDaftarUnitKerja: [],
  setAllDaftarUnitKerja: () => {},
  allHakAkses: [],
  setAllHakAkses: () => {},
};
