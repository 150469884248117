import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/pendidikan-pelatihan";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/pendidikan-pelatihan";
import ModalPendidikanPelatihan from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";

const PendidikanPelatihan = () => {
  const {
    isLoadingFetchData,
    listData,
    isLoadingDownloadDocPendidikanPelatihan,
    isLoadingDeletePendidikanPelatihan,
  } = useQueryResponse();
  return (
    <>
      {(isLoadingDownloadDocPendidikanPelatihan ||
        isLoadingDeletePendidikanPelatihan) && <FetchLoading />}
      <Toolbar />
      <ModalPendidikanPelatihan />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default PendidikanPelatihan;
