import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-bpi/kelompok-bpi/kelola-kelompok";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  FormKelompokBPIPayload,
  initialQueryResponse,
} from "../../../../../models/absensi-bpi/kelompok-bpi/_queryResponse";
import {
  getKelompokBPI,
  postKelompokBPI,
  getDetailKelompokBPI,
  deleteKelompokBPI,
} from "../../../../../api/absensi-bpi/_request";

import { useQueryResponse as useQueryResponseAbsensiBPI } from "../../../absensi-bpi";
import { useAuth } from "../../../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiBPI();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["nama_kelompok_bpi"]`;
  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailKelompokBPI, setDetailKelompokBPI] = useState<any>();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kelompok-bpi")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kelompok-bpi"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const { mutateAsync: PostKelompokBPI, isLoading: isLoadingPostKelompokBPI } =
    useMutation({
      mutationKey: `${QUERIES.POST_KELOMPOK_BPI}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postKelompokBPI(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_KELOMPOK_BPI}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostKelompokBPI = useCallback(
    async (payload: any) => {
      await PostKelompokBPI({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              if (refetchListData) {
                refetchListData();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostKelompokBPI]
  );

  const {
    mutateAsync: GetDetailKelompokBPI,
    isLoading: isLoadingGetDetailKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => getDetailKelompokBPI(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailKelompokBPI = useCallback(
    async (id: string) => {
      await GetDetailKelompokBPI({ id })
        .then((res) => {
          if (res.success) setDetailKelompokBPI(res.data);
          else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                //setDetailKelompokBPI(res.data);
              }
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailKelompokBPI]
  );

  const {
    mutateAsync: DeleteKelompokBPI,
    isLoading: isLoadingDeleteKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => deleteKelompokBPI(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteKelompokBPI = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          await DeleteKelompokBPI({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              console.log(error);
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteKelompokBPI]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_KELOMPOK_BPI}-${query}`,
    () => getKelompokBPI(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalTentukanKelompok,
    }
  );

  const resetDetailKelompokBPI = () => {
    setDetailKelompokBPI({
      id: null,
      nama_kelompok_bpi: null,
      nik_gtk: null,
    });
  };
  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostKelompokBPI,
      isLoadingPostKelompokBPI,
      resetForm,
      setResetForm,
      handleGetDetailKelompokBPI,
      isLoadingGetDetailKelompokBPI,
      detailKelompokBPI,
      setDetailKelompokBPI,
      resetDetailKelompokBPI,
      handleDeleteKelompokBPI,
      isLoadingDeleteKelompokBPI,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostKelompokBPI,
      isLoadingPostKelompokBPI,
      resetForm,
      setResetForm,
      handleGetDetailKelompokBPI,
      isLoadingGetDetailKelompokBPI,
      detailKelompokBPI,
      setDetailKelompokBPI,
      resetDetailKelompokBPI,
      handleDeleteKelompokBPI,
      isLoadingDeleteKelompokBPI,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
