import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-lt3q/kelompok-lt3q/kelola-anggota-kelompok";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  DetailAnggotaKelompokLT3Q,
  FormAnggotaKelompokLT3QPayload,
  initialQueryResponse,
} from "../../../../../models/absensi-lt3q/anggota-kelompok-lt3q/_queryResponse";
import {
  getAnggotaKelompokLT3Q,
  postAnggotaKelompokLT3Q,
  getDetailAnggotaKelompokLT3Q,
  getAllKelompokLT3Q,
  deleteAnggotaKelompokLT3Q,
  getListAnggotaKelompokLT3Q,
} from "../../../../../api/absensi-lt3q/_request";

import { useQueryResponse as useQueryResponseAbsensiLT3Q } from "../../../absensi-lt3q";
import { useQueryResponse as useQueryResponsePindahTutor } from "../../../absensi-lt3q/kelompok-lt3q/pindah-tutor";
import { useAuth } from "../../../../core/auth";
import { FormKelompokLT3QPayload } from "../../../../../models/absensi-lt3q/kelompok-lt3q/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiLT3Q();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["kelompok_lt3q.nama_kelompok_lt3q", "nik_gtk", "gtk.nama_gtk", "nik_tutor_lt3q", "level_lt3q"]`;
  const { currentUser } = useAuth();
  state.nik_tutor_lt3q = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailAnggotaKelompokLT3Q, setDetailAnggotaKelompokLT3Q] =
    useState<any>();
  const [allKelompokLT3Q, setAllKelompokLT3Q] = useState<
    Array<FormKelompokLT3QPayload>
  >([]);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "anggota-kelompok-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "anggota-kelompok-lt3q"),
    [state]
  );

  const [anggotaKelompokLT3Q, setAnggotaKelompokLT3Q] = useState<
    Array<DetailAnggotaKelompokLT3Q>
  >([]);

  const { setAllKelompokTutorLT3QTujuan } = useQueryResponsePindahTutor();

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    mutateAsync: PostAnggotaKelompokLT3Q,
    isLoading: isLoadingPostAnggotaKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KELOMPOK_LT3Q}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postAnggotaKelompokLT3Q(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_KELOMPOK_LT3Q}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostAnggotaKelompokLT3Q = useCallback(
    async (payload: any) => {
      await PostAnggotaKelompokLT3Q({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                refetchListData();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostAnggotaKelompokLT3Q]
  );

  const {
    mutateAsync: GetDetailAnggotaKelompokLT3Q,
    isLoading: isLoadingGetDetailAnggotaKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
    mutationFn: ({ id }: { id: string }) => getDetailAnggotaKelompokLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailAnggotaKelompokLT3Q = useCallback(
    async (id: string) => {
      await GetDetailAnggotaKelompokLT3Q({ id })
        .then((res) => {
          if (res.success) setDetailAnggotaKelompokLT3Q(res.data);
          else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                //setDetailAnggotaKelompokLT3Q(res.data);
              }
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAnggotaKelompokLT3Q]
  );

  const {
    mutateAsync: GetAnggotaKelompokLT3Q,
    isLoading: isLoadingGetAnggotaKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => getListAnggotaKelompokLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAnggotaKelompokLT3Q = useCallback(
    async (id: string) => {
      await GetAnggotaKelompokLT3Q({ id })
        .then((res) => {
          setAnggotaKelompokLT3Q(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAnggotaKelompokLT3Q]
  );

  const {
    mutateAsync: DeleteAnggotaKelompokLT3Q,
    isLoading: isLoadingDeleteAnggotaKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
    mutationFn: ({ id }: { id: string }) => deleteAnggotaKelompokLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteAnggotaKelompokLT3Q = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          await DeleteAnggotaKelompokLT3Q({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteAnggotaKelompokLT3Q]
  );

  const {
    mutateAsync: GetAllKelompokLT3Q,
    isLoading: isLoadingGetAllKelompokLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_LT3Q}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokLT3Q(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokLT3Q = useCallback(
    async (nik_gtk: string, type?: string) => {
      await GetAllKelompokLT3Q({ nik_gtk })
        .then((res) => {
          if (type && type === "tujuan") {
            return setAllKelompokTutorLT3QTujuan(res.data);
          }
          setAllKelompokLT3Q(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokLT3Q]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_ANGGOTA_KELOMPOK_LT3Q}-${query}`,
    () => getAnggotaKelompokLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalTentukanKelompok,
    }
  );

  const resetDetailAnggotaKelompokLT3Q = () => {
    setDetailAnggotaKelompokLT3Q({
      id: null,
      nama_kelompok_lt3q: null,
      nik_gtk: null,
      level_lt3q: null,
    });
  };

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostAnggotaKelompokLT3Q,
      isLoadingPostAnggotaKelompokLT3Q,
      resetForm,
      setResetForm,
      handleGetDetailAnggotaKelompokLT3Q,
      isLoadingGetDetailAnggotaKelompokLT3Q,
      detailAnggotaKelompokLT3Q,
      setDetailAnggotaKelompokLT3Q,
      resetDetailAnggotaKelompokLT3Q,
      handleGetAllKelompokLT3Q,
      isLoadingGetAllKelompokLT3Q,
      allKelompokLT3Q,
      setAllKelompokLT3Q,
      handleDeleteAnggotaKelompokLT3Q,
      isLoadingDeleteAnggotaKelompokLT3Q,
      handleGetAnggotaKelompokLT3Q,
      isLoadingGetAnggotaKelompokLT3Q,
      anggotaKelompokLT3Q,
      setAnggotaKelompokLT3Q,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostAnggotaKelompokLT3Q,
      isLoadingPostAnggotaKelompokLT3Q,
      resetForm,
      setResetForm,
      handleGetDetailAnggotaKelompokLT3Q,
      isLoadingGetDetailAnggotaKelompokLT3Q,
      detailAnggotaKelompokLT3Q,
      setDetailAnggotaKelompokLT3Q,
      resetDetailAnggotaKelompokLT3Q,
      handleGetAllKelompokLT3Q,
      isLoadingGetAllKelompokLT3Q,
      allKelompokLT3Q,
      setAllKelompokLT3Q,
      handleDeleteAnggotaKelompokLT3Q,
      isLoadingDeleteAnggotaKelompokLT3Q,
      handleGetAnggotaKelompokLT3Q,
      isLoadingGetAnggotaKelompokLT3Q,
      anggotaKelompokLT3Q,
      setAnggotaKelompokLT3Q,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
