import { FC } from "react";

type Props = {
  data?: any;
};

const ColumnHeader: FC<Props> = (data) => {
  const unit_kerja = data.data ? data.data[0]["data"] : [];
  return (
    <thead>
      <tr>
        <th rowSpan={2} className="align-middle">
          Dari
        </th>
        <th rowSpan={2} className="align-middle">
          Ke
        </th>
        <th rowSpan={2} className="align-middle">
          Masa Kerja
        </th>
        <th
          colSpan={unit_kerja?.length + 1}
          className="align-middle text-center"
        >
          Unit
        </th>
      </tr>
      <tr>
        {unit_kerja?.map((val, idx) => {
          return <th>{val.unit_kerja}</th>;
        })}
        <th>Jumlah</th>
      </tr>
    </thead>
  );
};

export default ColumnHeader;
