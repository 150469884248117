import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";

export type FormDaftarUnitKerjaPayload = {
  id: string | number | null;
  nama_unit_kerja: string | null;
};

export type QueryResponseContextProps<T> = {
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  formikDaftarUnitKerja?: FormikProps<FormDaftarUnitKerjaPayload>;
  formikDaftarUnitKerjaValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostDaftarUnitKerja: (data: FormData) => void;
  isLoadingPostDaftarUnitKerja: boolean;
  handleGetDetailDaftarUnitKerja: (id: string) => void;
  isLoadingGetDetailDaftarUnitKerja: boolean;
  detailDaftarUnitKerja: FormDaftarUnitKerjaPayload;
  setDetailDaftarUnitKerja: Dispatch<
    SetStateAction<FormDaftarUnitKerjaPayload>
  >;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteDaftarUnitKerja: (id: string) => void;
  isLoadingDeleteDaftarUnitKerja: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModal: false,
  setShowModal: () => {},
  detailDaftarUnitKerja: {
    id: null,
    nama_unit_kerja: null,
  },
  setDetailDaftarUnitKerja: () => {},
  handleDeleteDaftarUnitKerja: () => {},
  isLoadingDeleteDaftarUnitKerja: false,
  handleGetDetailDaftarUnitKerja: () => {},
  isLoadingGetDetailDaftarUnitKerja: false,
  handlePostDaftarUnitKerja: () => {},
  isLoadingPostDaftarUnitKerja: false,
  resetForm: false,
  setResetForm: () => {},
  submitting: false,
  setSubmitting: () => {},
};
