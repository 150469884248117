import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListPendidikanPelatihan = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_PENDIDIKAN_PELATIHAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postPendidikanPelatihan = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_PENDIDIKAN_PELATIHAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailPendidikanPelatihan = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_PENDIDIKAN_PELATIHAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocPendidikanPelatihan = async (
  url: string,
  fileName: string
): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deletePendidikanPelatihan = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_PENDIDIKAN_PELATIHAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListPendidikanPelatihan,
  postPendidikanPelatihan,
  getDetailPendidikanPelatihan,
  downloadDocPendidikanPelatihan,
  deletePendidikanPelatihan,
};
