import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../provider/query-response/daftar-unit-kerja";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";

const ModalDaftarUnitKerja = () => {
  const {
    showModal,
    setShowModal,
    formikDaftarUnitKerja,
    submitting,
    setSubmitting,
    detailDaftarUnitKerja,
    setResetForm,
    isLoadingGetDetailDaftarUnitKerja,
    isLoadingPostDaftarUnitKerja,
  } = useQueryResponse();

  useEffect(() => {
    if (detailDaftarUnitKerja.id) {
      formikDaftarUnitKerja?.setValues(detailDaftarUnitKerja);
    }
  }, [detailDaftarUnitKerja]);

  return (
    <Modal
      backdrop="static"
      show={showModal}
      onBackdropClick={() => {
        setShowModal(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModal(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikDaftarUnitKerja?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailDaftarUnitKerja.id ? "Edit" : "Tambah"} Unit Kerja
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailDaftarUnitKerja ||
            isLoadingPostDaftarUnitKerja) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="12">
              <TextInputComponent
                label="Nama Unit Kerja"
                placeholder="Masukkan Nama Unit Kerja"
                isRequired
                formik={formikDaftarUnitKerja?.getFieldProps("nama_unit_kerja")}
                value={
                  formikDaftarUnitKerja?.getFieldProps("nama_unit_kerja")
                    .value ?? ""
                }
              />
              {submitting && formikDaftarUnitKerja?.errors.nama_unit_kerja && (
                <div className="fs-6 text-danger mt-2">
                  {formikDaftarUnitKerja?.errors.nama_unit_kerja}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalDaftarUnitKerja;
