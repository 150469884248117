import Logo from "../../../../assets/images/icons/logo jundezain.png";

const LogoJundezain = () => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <img src={Logo} width={100} alt=""></img>
      Copyright &copy; 2023
    </div>
  );
};

export default LogoJundezain;
