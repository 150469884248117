import { FC } from "react";
import { Outlet } from "react-router";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";

const Content: FC<WithChildren> = ({ children }) => {
  return (
    <div
      className="py-0 conatiner-fluid content-inner "
      style={{
        marginTop: -20,
      }}
    >
      {children}
    </div>
  );
};

export { Content };
