import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/karya-tulis";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import NumberInputComponent from "../../../../hope-ui/components/number-input";

const ModalKaryaTulis = () => {
  const {
    showModalKaryaTulis,
    setShowModalKaryaTulis,
    formikKaryaTulis,
    submitting,
    setSubmitting,
    detailKaryaTulis,
    setResetForm,
    isLoadingGetDetailKaryaTulis,
    isLoadingPostKaryaTulis,
  } = useQueryResponse();

  useEffect(() => {
    if (detailKaryaTulis.id) {
      formikKaryaTulis?.setValues(detailKaryaTulis);
    }
  }, [detailKaryaTulis]);

  return (
    <Modal
      backdrop="static"
      show={showModalKaryaTulis}
      onBackdropClick={() => {
        setShowModalKaryaTulis(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalKaryaTulis(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikKaryaTulis?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailKaryaTulis.id ? "Edit" : "Tambah"} Karya Tulis
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailKaryaTulis || isLoadingPostKaryaTulis) && (
            <FetchLoading />
          )}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Judul"
                placeholder="Masukkan Judul"
                isRequired
                formik={formikKaryaTulis?.getFieldProps("judul")}
                value={formikKaryaTulis?.getFieldProps("judul").value ?? ""}
              />
              {submitting && formikKaryaTulis?.errors.judul && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaTulis?.errors.judul}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="Publikasi"
                placeholder="Masukkan Publikasi"
                isRequired
                formik={formikKaryaTulis?.getFieldProps("publikasi")}
                value={formikKaryaTulis?.getFieldProps("publikasi").value ?? ""}
              />
              {submitting && formikKaryaTulis?.errors.publikasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaTulis?.errors.publikasi}
                </div>
              )}
            </Col>

            <Col lg="6">
              <NumberInputComponent
                label="Tahun Pembuatan"
                placeholder="Masukkan Tahun Pembuatan"
                required
                formik={formikKaryaTulis?.getFieldProps("tahun_pembuatan")}
                value={
                  formikKaryaTulis?.getFieldProps("tahun_pembuatan").value ?? ""
                }
              />
              {submitting && formikKaryaTulis?.errors.tahun_pembuatan && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaTulis?.errors.tahun_pembuatan}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="URL"
                placeholder="Masukkan URL"
                isRequired
                formik={formikKaryaTulis?.getFieldProps("url")}
                value={formikKaryaTulis?.getFieldProps("url").value ?? ""}
              />
              {submitting && formikKaryaTulis?.errors.url && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaTulis?.errors.url}
                </div>
              )}
            </Col>

            <Col lg="12">
              <TextareaComponent
                label="Keterangan"
                placeholder="Masukkan Keterangan"
                formik={formikKaryaTulis?.getFieldProps("keterangan")}
                value={
                  formikKaryaTulis?.getFieldProps("keterangan").value ?? ""
                }
              />
              {submitting && formikKaryaTulis?.errors.keterangan && (
                <div className="fs-6 text-danger mt-2">
                  {formikKaryaTulis?.errors.keterangan}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalKaryaTulis;
