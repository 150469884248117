import { useEffect } from "react";
import { LoginFormWrapper } from "../../modules/login";

const Login = () => {
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Login";
  }, []);
  return <LoginFormWrapper />;
};

export default Login;
