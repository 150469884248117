import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormTugasTambahanPayload,
  initialQueryResponse,
} from "../../../models/tugas-tambahan/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/tugas-tambahan";
import {
  deleteTugasTambahan,
  downloadDocTugasTambahan,
  getDetailTugasTambahan,
  getListTugasTambahan,
  postTugasTambahan,
} from "../../../api/tugas-tambahan/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nomor_surat", "tugas_tambahan", "tanggal_mulai", "tanggal_selesai"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalTugasTambahan, setShowModalTugasTambahan] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailTugasTambahan.nik_gtk = nik_gtk;

  const [detailTugasTambahan, setDetailTugasTambahan] =
    useState<FormTugasTambahanPayload>(
      initialQueryResponse.detailTugasTambahan
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikTugasTambahan.setValues(initialQueryResponse.detailTugasTambahan);
      setDetailTugasTambahan(initialQueryResponse.detailTugasTambahan);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_TUGAS_TAMBAHAN}-${query}`,
    () => getListTugasTambahan(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikTugasTambahanValidationSchema = Yup.object().shape({
    nomor_surat: Yup.string().required("Nomor Surat Tidak Boleh Kosong"),
    tugas_tambahan: Yup.string().required("Tugas Tambahan Tidak Boleh Kosong"),
    tanggal_mulai: Yup.string().required("Tanggal Mulai Tidak Boleh Kosong"),
    tanggal_selesai: Yup.string().required(
      "Tanggal Selesai Tidak Boleh Kosong"
    ),
    doc_tugas_tambahan: Yup.mixed().required("Dokumen Tidak Boleh Kosong"),
  });

  const formikTugasTambahan = useFormik<FormTugasTambahanPayload>({
    initialValues: initialQueryResponse.detailTugasTambahan,
    enableReinitialize: false,
    validationSchema: formikTugasTambahanValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostTugasTambahan(payload);
    },
  });

  const {
    mutateAsync: PostTugasTambahan,
    isLoading: isLoadingPostTugasTambahan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_TUGAS_TAMBAHAN}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postTugasTambahan(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_TUGAS_TAMBAHAN}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostTugasTambahan = useCallback(
    async (payload: any) => {
      await PostTugasTambahan({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalTugasTambahan(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostTugasTambahan]
  );

  const {
    mutateAsync: GetDetailTugasTambahan,
    isLoading: isLoadingGetDetailTugasTambahan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_TUGAS_TAMBAHAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailTugasTambahan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_TUGAS_TAMBAHAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailTugasTambahan = useCallback(
    async (id: string) => {
      await GetDetailTugasTambahan({ id })
        .then((res) => {
          setDetailTugasTambahan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailTugasTambahan]
  );

  const {
    mutateAsync: DownloadDocTugasTambahan,
    isLoading: isLoadingDownloadDocTugasTambahan,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_TUGAS_TAMBAHAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocTugasTambahan(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_TUGAS_TAMBAHAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocTugasTambahan = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocTugasTambahan({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocTugasTambahan]
  );

  const {
    mutateAsync: DeleteTugasTambahan,
    isLoading: isLoadingDeleteTugasTambahan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_TUGAS_TAMBAHAN}`,
    mutationFn: ({ id }: { id: string }) => deleteTugasTambahan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_TUGAS_TAMBAHAN}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteTugasTambahan = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteTugasTambahan({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteTugasTambahan]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalTugasTambahan,
      setShowModalTugasTambahan,
      formikTugasTambahan,
      formikTugasTambahanValidationSchema,
      submitting,
      setSubmitting,
      handlePostTugasTambahan,
      isLoadingPostTugasTambahan,
      detailTugasTambahan,
      setDetailTugasTambahan,
      handleGetDetailTugasTambahan,
      isLoadingGetDetailTugasTambahan,
      resetForm,
      setResetForm,
      handleDownloadDocTugasTambahan,
      isLoadingDownloadDocTugasTambahan,
      handleDeleteTugasTambahan,
      isLoadingDeleteTugasTambahan,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalTugasTambahan,
      setShowModalTugasTambahan,
      formikTugasTambahan,
      formikTugasTambahanValidationSchema,
      submitting,
      setSubmitting,
      handlePostTugasTambahan,
      isLoadingPostTugasTambahan,
      detailTugasTambahan,
      setDetailTugasTambahan,
      handleGetDetailTugasTambahan,
      isLoadingGetDetailTugasTambahan,
      resetForm,
      setResetForm,
      handleDownloadDocTugasTambahan,
      isLoadingDownloadDocTugasTambahan,
      handleDeleteTugasTambahan,
      isLoadingDeleteTugasTambahan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
