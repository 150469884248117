import { FieldInputProps } from "formik";
import { ChangeEvent, FC } from "react";
import { Form } from "react-bootstrap";
import Select, { SingleValue } from "react-select";

type Props = {
  onChange?: (newValue: SingleValue<ChangeEvent<HTMLSelectElement>>) => any;
  options?: Array<any>;
  isLoading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
  label?: string;
  isRequired?: boolean;
  formik?: FieldInputProps<any>;
  ref?: any;
  value?: any;
  isClearable?: boolean;
  isMulti?: boolean;
};

const Select2: FC<Props> = ({
  onChange,
  options,
  isLoading = false,
  placeholder = "-- Pilihan --",
  disabled = false,
  defaultValue,
  label,
  isRequired,
  formik,
  ref,
  value,
  isClearable = true,
  isMulti,
}) => {
  return (
    <>
      {label && (
        <Form.Label>
          {label} {isRequired && <span className="text-danger"> *</span>}
        </Form.Label>
      )}
      <Select
        styles={{
          control: (base) => ({
            ...base,
            height: 41,
            minHeight: 41,
          }),
        }}
        onChange={onChange}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        isClearable={isClearable}
        escapeClearsValue={true}
        isDisabled={disabled}
        value={
          options?.find((option) => option.value === value)
            ? options?.find((option) => option.value === value)
            : null
        }
        {...formik}
        ref={ref}
        isMulti={isMulti}
      />
    </>
  );
};

export default Select2;
