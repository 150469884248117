import { FC } from "react";

type Props = {
  id?: number;
  nik_gtk?: string;
  menuju?: string;
};

export const AksiCell: FC<Props> = ({ id, nik_gtk, menuju }) => {
  return (
    <>
      {menuju && menuju.length > 0 && (
        <button
          type="button"
          className="btn btn-info btn-sm"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL}data-gtk/kepegawaian/form/${nik_gtk}`
            );
          }}
        >
          <i className="fas fa-briefcase me-2"></i>Update Ke {menuju}
        </button>
      )}
    </>
  );
};
