import { AxiosResponse } from "axios";
import { client } from "../../apiClient";
import { ApiConfig } from "../../apiConfig";

const getStatusAktivasiTidakAktif = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_STATUS_AKTIVASI_TIDAK_AKTIF_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postNonAktifkanGTK = async (payload: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_NON_AKTIFKAN_GTK_URL}`, payload)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getStatusAktivasiTidakAktif, postNonAktifkanGTK };
