import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../models/backup-data/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import {
  downloadDatabaseGTK,
  downloadDatabaseSSO,
  getHistoryBackup,
  postHistoryBackup,
} from "../../../api/backup-data/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const {
    mutateAsync: DownloadDatabaseGTK,
    isLoading: isLoadingDownloadDatabaseGTK,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DB_GTK}`,
    mutationFn: () => downloadDatabaseGTK(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.DOWNLOAD_DB_GTK}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDatabaseGTK = useCallback(async () => {
    await DownloadDatabaseGTK()
      .then((res) => {
        const payload = new FormData();
        const username: any = currentUser?.data?.username;
        payload.append("username", username);
        payload.append("app_name", "GTK");
        if (handlePostHistoryBackup) handlePostHistoryBackup(payload);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [DownloadDatabaseGTK]);

  const {
    mutateAsync: DownloadDatabaseSSO,
    isLoading: isLoadingDownloadDatabaseSSO,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DB_SSO}`,
    mutationFn: () => downloadDatabaseSSO(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.DOWNLOAD_DB_SSO}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDatabaseSSO = useCallback(async () => {
    await DownloadDatabaseSSO()
      .then((res) => {
        const payload = new FormData();
        const username: any = currentUser?.data?.username;
        payload.append("username", username);
        payload.append("app_name", "SSO");
        if (handlePostHistoryBackup) handlePostHistoryBackup(payload);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [DownloadDatabaseSSO]);

  const {
    mutateAsync: PostHistoryBackup,
    isLoading: isLoadingPostHistoryBackup,
  } = useMutation({
    mutationKey: `${QUERIES.POST_HISTORY_BACKUP}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postHistoryBackup(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_HISTORY_BACKUP}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostHistoryBackup = useCallback(
    async (payload: any) => {
      await PostHistoryBackup({ payload })
        .then((res) => {
          if (refetchListData && res.success) {
            refetchListData();
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          });
        });
    },
    [PostHistoryBackup]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(`${QUERIES.GET_HISTORY_BACKUP}`, () => getHistoryBackup(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
  });

  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      refetchListData,
      handleDownloadDatabaseGTK,
      isLoadingDownloadDatabaseGTK,
      handlePostHistoryBackup,
      isLoadingPostHistoryBackup,
      handleDownloadDatabaseSSO,
      isLoadingDownloadDatabaseSSO,
    }),
    [
      handleDownloadDatabaseGTK,
      isLoadingDownloadDatabaseGTK,
      handlePostHistoryBackup,
      isLoadingPostHistoryBackup,
      listData,
      isLoadingFetchData,
      refetchListData,
      handleDownloadDatabaseSSO,
      isLoadingDownloadDatabaseSSO,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
