import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { QueryResponseProvider, useQueryResponse } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../provider/core/auth";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { useQueryRequest } from "../../../provider/query-request/absensi-bpi";
import dayjs from "dayjs";
import Calendar from "react-calendar";
import moment from "moment";
import FileInputComponent from "../../../hope-ui/components/file-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormImportPersensiGTKPayload } from "../../../models/absensi-gtk/daftar-absensi-gtk/_queryResponse";


/*
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
*/



const ModalImportAbsensi = () => {


  const ref = useRef<HTMLInputElement>();
  const {
    handlePostImportPresensiGTK,
    isLoadingPostImportPresensiGTK,
    gagalImportPresensiGTK,
  } = useQueryResponse();


  const { state, updateState } = useQueryRequest();
  const { isShowModalBuatAbsensiGTK, setShowModalImportAbsensiGTK } = useQueryResponse();

  //const [filterTanggal, setFilterTanggal] = useState<Value>(new Date());


  const [submitting, setSubmitting] = useState(false);

  const formikImportPresensiGTKValidationSchema = Yup.object().shape({
    file: Yup.mixed().required("File Import Data GTK Tidak Boleh Kosong"),
  });
  const formikImportPresensiGTK = useFormik<FormImportPersensiGTKPayload>({
    initialValues: {
      filter_tanggal: null,
      file: null,
    },
    enableReinitialize: false,
    onSubmit: (values: any) => {
      const payload = new FormData();
      payload.append("file", values["file"]);
      if (handlePostImportPresensiGTK) handlePostImportPresensiGTK(payload);
      formikImportPresensiGTK.setFieldValue("file", null);
    },
    validationSchema: formikImportPresensiGTKValidationSchema,
  });



  //console.log(formikBuatAbsensi?.values);
  console.log(isShowModalBuatAbsensiGTK)
  return (
  <>
    <Modal
        backdrop="static"
        show={isShowModalBuatAbsensiGTK}
        onBackdropClick={() => {
          setShowModalImportAbsensiGTK(false);
          //setResetForm(true);
        }}
        size="xl"
        onHide={() => {
          setShowModalImportAbsensiGTK(false);
          //setResetForm(true);
        }}
      >

      <Modal.Header closeButton>
        
        <Modal.Title>
          Upload Presensi
        </Modal.Title>
        
      </Modal.Header>
      
      <Modal.Body>
        <Form
          className="px-4 py-2"
          onSubmit={(e) => {
            setSubmitting(true);
            formikImportPresensiGTK.submitForm();
            e.preventDefault();
          }}
        >

          <Row className="gy-3 ">
            <Col lg="1" />
            <Col lg="10">

              {/*
                <div style={{fontSize:"17px"}}>
                    <Calendar
                        onChange={(e : any) => {
                            const tanggal : any = moment(e).format("YYYY-MM-DD")
                            setFilterTanggal(e)
                            updateState({
                                filter_tanggal : tanggal
                            })
                        }} 
                        value={filterTanggal} 
                        className="form-control"
                        maxDate={new Date()}
                    />
                </div>
              */}

            <Col md="8">
                {(isLoadingPostImportPresensiGTK) && (
                  <FetchLoading />
                )}
                    <Form.Group>
                      <FileInputComponent
                        label="Import Absensi GTK"
                        acceptedType={[".xlsx"]}
                        required
                        onChange={(e) => {
                          if (e.target.files) {
                            formikImportPresensiGTK.setFieldValue("file", e.target.files[0]);
                          } else formikImportPresensiGTK.setFieldValue("file", null);
                        }}
                        fileNameLabel={formikImportPresensiGTK.getFieldProps("file").value?.name}
                        ref={ref.current}
                        resetField={
                          formikImportPresensiGTK.getFieldProps("file").value ? false : true
                        }
                      />
                    </Form.Group>
            </Col>

            {gagalImportPresensiGTK && gagalImportPresensiGTK.length > 0 && (
              <Col md="8">
                <div className="mt-3 alert alert-warning">
                  <b className="mb-2">Pesan Sistem : </b>
                  {gagalImportPresensiGTK.map((val) => {
                    return <div className="fs-6 ">{val.message}</div>;
                  })}
                </div>
              </Col>
            )}

            <Button type="submit" className="btn btn-primary float-end">
              <i className="fa fa-file-import me-2"></i>
              Upload 
            </Button>

            </Col>
            <Col lg="1" />

          </Row>

        </Form>
      </Modal.Body>
    </Modal>
  </>

  );
};

export default ModalImportAbsensi;