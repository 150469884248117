import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select2 from "../../../hope-ui/components/select-2";
import {
  QueryRequestProvider as QueryRequestProviderDataGTK,
  useQueryRequest as useQueryRequestDataGTK,
} from "../../../provider/query-request/data-gtk";
import {
  QueryResponseProvider as QueryResponseProviderDataGTK,
  useQueryResponse as useQueryResponseDataGTK,
} from "../../../provider/query-response/data-gtk";
import {
  QueryResponseProvider as QueryResponseProviderJenisPTK,
  useQueryResponse as useQueryResponseJenisPTK,
} from "../../../provider/query-response/master-data/jenis-ptk";
import {
  QueryResponseProvider as QueryResponseProviderStatusGolonganKepegawaian,
  useQueryResponse as useQueryResponseStatusGolonganKepegawaian,
} from "../../../provider/query-response/master-data/status-golongan-kepegawaian";
import {
  QueryResponseProvider as QueryResponseProviderKompetensiKhusus,
  useQueryResponse as useQueryResponseKompetensiKhusus,
} from "../../../provider/query-response/master-data/kompetensi-khusus";
import {
  QueryResponseProvider as QueryResponseProviderJenisKepegawaian,
  useQueryResponse as useQueryResponseJenisKepegawaian,
} from "../../../provider/query-response/master-data/jenis-kepegawaian";
import {
  QueryResponseProvider as QueryResponseProviderKepegawaian,
  useQueryResponseDetailKepegawaian,
  useQueryResponse as useQueryResponseKepegawaian,
} from "../../../provider/query-response/kepegawaian";
import TextInputComponent from "../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../hope-ui/components/datepicker";
import moment from "moment";
import SertifikatTilawah from "./sertifikat-tilawah";
import NumberInputComponent from "../../../hope-ui/components/number-input";
import FileInputComponent from "../../../hope-ui/components/file-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormKepegawaianPayload } from "../../../models/kepegawaian/_queryResponse";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import FetchLoading from "../../../hope-ui/components/fetch-loading";

const FormEntry = () => {
  const { updateState } = useQueryRequestDataGTK();

  const listDataJenisPTK = useQueryResponseJenisPTK().listData;
  const isLoadingFetchDataJenisPTK =
    useQueryResponseJenisPTK().isLoadingFetchData;

  const listDataStatusGolonganKepegawaian =
    useQueryResponseStatusGolonganKepegawaian().listData;
  const isLoadingFetchDataStatusGolonganKepegawaian =
    useQueryResponseStatusGolonganKepegawaian().isLoadingFetchData;

  const listDataKompetensiKhusus = useQueryResponseKompetensiKhusus().listData;

  const listDataJenisKepegawaian = useQueryResponseJenisKepegawaian().listData;

  const isLoadingFetchDataKompetensiKhusus =
    useQueryResponseKompetensiKhusus().isLoadingFetchData;

  const [submitting, setSubmitting] = useState(false);

  const {
    handleGetDetailKepegawaian,
    isLoadingGetDetailKepegawaian,
    handlePostKepegawaian,
    isLoadingPostKepegawaian,
    handleDownloadDocKepegawaian,
    isLoadingDownloadDocKepegawaian,
  } = useQueryResponseKepegawaian();
  const detailKepegawaian = useQueryResponseDetailKepegawaian();

  const { id, nik_gtk } = useParams();

  useEffect(() => {
    updateState({
      size: 999999999999,
    });
    if (handleGetDetailKepegawaian && id)
      handleGetDetailKepegawaian(id ? id : "");
  }, [handleGetDetailKepegawaian]);

  const { listData, isLoadingFetchData } = useQueryResponseDataGTK();

  let optionsDataGTK: any = [];
  let optionsDataJenisPTK: any = [];
  let [
    optionsDataStatusGolonganKepegawaian,
    setOptionsDataStatusGolonganKepegawaian,
  ] = useState<any>([]);
  let optionsDataKompetensiKhusus: any = [];
  let optionsDataJenisKepegawaian: any = [];

  listData?.data?.map((val, idx) => {
    optionsDataGTK.push({
      value: val.nik_gtk,
      label: `${val.nik_gtk} -  ${val.nama_gtk}`,
    });
  });

  listDataJenisPTK?.data?.map((val, idx) => {
    optionsDataJenisPTK.push({
      value: val.name,
      label: `${val.name}`,
    });
  });

  listDataKompetensiKhusus?.data?.map((val, idx) => {
    optionsDataKompetensiKhusus.push({
      value: val.name,
      label: `${val.name}`,
    });
  });

  listDataJenisKepegawaian?.data?.map((val, idx) => {
    optionsDataJenisKepegawaian.push({
      value: val.name,
      label: `${val.name}`,
    });
  });

  const [selectedKompetensiKhusus, setSelectedKompetensiKhusus] =
    useState<any>();

  const formKepegawaianValidationSchema = Yup.object().shape({
    nik_gtk: Yup.string().required("Nama GTK Tidak Boleh Kosong"),
    //niy_nigk: Yup.string().required("NIY Tidak Boleh Kosong"),
    //nuptk: Yup.string().required("NUPTK Tidak Boleh Kosong"),
    jenis_ptk: Yup.string().required("Jenis PTK Tidak Boleh Kosong"),
    status_golongan_kepegawaian:
      optionsDataStatusGolonganKepegawaian.length > 0
        ? Yup.string().required("Jenjang Kepegawaian Tidak Boleh Kosong")
        : Yup.string().nullable(),
    nomor_sk_pengangkatan: Yup.string().required(
      "Nomor SK Pengangkatan Tidak Boleh Kosong"
    ),
    tmt_pengangkatan: Yup.string().required(
      "Tanggal Awal TMT Pengangkatan Tidak Boleh Kosong"
    ),
    masa_berlaku_sk: Yup.string().required(
      "Tanggal AKhir TMT Pengangkatan Tidak Boleh Kosong"
    ),
    // kompetensi_khusus: Yup.string().required(
    //   "Kompetensi Khusus Tidak Boleh Kosong"
    // ),
    nilai_teori:
      selectedKompetensiKhusus &&
      selectedKompetensiKhusus === "Sertifikat Tilawah"
        ? Yup.string()
            .matches(/^[0-9.]*$/, "Nilai Teori Tidak Valid")
            .min(1, `Nilai Teori Minimal 1`)
            .required(`Nilai Teori Tidak Boleh Kosong`)
            .typeError(`Nilai Teori Wajib Angka`)
        : Yup.string().nullable(),
    nilai_praktek:
      selectedKompetensiKhusus &&
      selectedKompetensiKhusus === "Sertifikat Tilawah"
        ? Yup.string()
            .matches(/^[0-9.]*$/, "Nilai Praktek Tidak Valid")
            .min(1, `Nilai Praktek Minimal 1`)
            .required(`Nilai Praktek Tidak Boleh Kosong`)
            .typeError(`Nilai Praktek Wajib Angka`)
        : Yup.string().nullable(),
    jumlah_juz:
      selectedKompetensiKhusus &&
      selectedKompetensiKhusus === "Sertifikat Hafalan"
        ? Yup.number()
            .min(1, `Jumlah Juz Minimal 1`)
            .required(`Jumlah Juz Tidak Boleh Kosong`)
            .typeError(`Jumlah Juz Wajib Angka`)
        : Yup.string().nullable(),
    toefl:
      selectedKompetensiKhusus && selectedKompetensiKhusus === "TOEFL"
        ? Yup.number()
            .min(1, `TOEFL Minimal 1`)
            .required(`TOEFL Tidak Boleh Kosong`)
            .typeError(`TOEFL Wajib Angka`)
        : Yup.string().nullable(),
    doc_kepegawaian: Yup.string().required("Dokumen SK Tidak Boleh Kosong"),
    jenis_kepegawaian: Yup.string().required(
      "Jenis Kepegawaian Tidak Boleh Kosong"
    ),
  });

  const formikKepegawaian = useFormik<FormKepegawaianPayload>({
    initialValues: {
      id: detailKepegawaian ? detailKepegawaian.id : "",
      id_kepegawaian: detailKepegawaian ? detailKepegawaian.id_kepegawaian : "",
      nik_gtk: detailKepegawaian ? detailKepegawaian.nik_gtk : "",
      niy_nigk: detailKepegawaian ? detailKepegawaian.niy_nigk : "",
      nuptk: detailKepegawaian ? detailKepegawaian.nuptk : "",
      jenis_ptk: detailKepegawaian ? detailKepegawaian.jenis_ptk : "",
      status_golongan_kepegawaian: detailKepegawaian
        ? detailKepegawaian.status_golongan_kepegawaian
        : "",
      nomor_sk_pengangkatan: detailKepegawaian
        ? detailKepegawaian.nomor_sk_pengangkatan
        : "",
      tmt_pengangkatan: detailKepegawaian
        ? moment(detailKepegawaian.tmt_pengangkatan).format("YYYY-MM-DD")
        : null,
      masa_berlaku_sk:
        detailKepegawaian && detailKepegawaian.masa_berlaku_sk
          ? moment(detailKepegawaian.tmt_pengangkatan).format("YYYY-MM-DD")
          : null,
      kompetensi_khusus: detailKepegawaian
        ? detailKepegawaian.kompetensi_khusus
        : "",
      nilai_teori: detailKepegawaian ? detailKepegawaian.nilai_teori : "",
      nilai_praktek: detailKepegawaian ? detailKepegawaian.nilai_praktek : "",
      jumlah_juz: detailKepegawaian ? detailKepegawaian.jumlah_juz : "",
      toefl: detailKepegawaian ? detailKepegawaian.toefl : "",
      doc_kepegawaian: null,
      jenis_kepegawaian: detailKepegawaian
        ? detailKepegawaian.jenis_kepegawaian
        : "",
    },
    validationSchema: formKepegawaianValidationSchema,
    enableReinitialize: false,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });

      if (handlePostKepegawaian) handlePostKepegawaian(payload);
    },
  });

  useEffect(() => {
    const nilai_teori = formikKepegawaian.getFieldProps("nilai_teori").value;
    if (nilai_teori)
      formikKepegawaian.values.nilai_teori = nilai_teori
        .toString()
        .replace(/[^0-9.,]/g, "");

    const nilai_praktek =
      formikKepegawaian.getFieldProps("nilai_praktek").value;
    if (nilai_praktek)
      formikKepegawaian.values.nilai_praktek = nilai_praktek
        .toString()
        .replace(/[^0-9.,]/g, "");

    const kompetensi_khusus =
      formikKepegawaian.getFieldProps("kompetensi_khusus").value;
    setSelectedKompetensiKhusus(kompetensi_khusus);
  }, [formikKepegawaian]);

  const handleJenisPTK = (jenis_ptk?: any) => {
    if (listDataJenisPTK && listDataJenisPTK.data && jenis_ptk) {
      const find_jenis_ptk = listDataJenisPTK.data.find(
        (element) => element.name === jenis_ptk
      );
      if (find_jenis_ptk) {
        const status_golongan_kepegawaian =
          find_jenis_ptk.status_golongan_kepegawaian;
        if (status_golongan_kepegawaian) {
          const data: any = [];
          status_golongan_kepegawaian.map((val) => {
            data.push({
              value: val.name,
              label: `${val.name}`,
            });
          });
          setOptionsDataStatusGolonganKepegawaian(data);
        } else {
          setOptionsDataStatusGolonganKepegawaian([]);
          formikKepegawaian.setFieldValue("status_golongan_kepegawaian", null);
        }
      }
    } else {
      setOptionsDataStatusGolonganKepegawaian([]);
      formikKepegawaian.setFieldValue("status_golongan_kepegawaian", null);
    }
  };

  useEffect(() => {
    if (detailKepegawaian && id) {
      formikKepegawaian.setValues(detailKepegawaian);
      handleJenisPTK(detailKepegawaian.jenis_ptk);
    }
  }, [detailKepegawaian, id]);

  useEffect(() => {
    if (nik_gtk) {
      formikKepegawaian.setFieldValue("nik_gtk", nik_gtk);
    }
  }, []);

  const ref = useRef(Array<any>);

  let doc_kepegawaian_name;
  if (
    typeof formikKepegawaian.getFieldProps("doc_kepegawaian").value === "string"
  ) {
    const pecah = formikKepegawaian
      .getFieldProps("doc_kepegawaian")
      .value.split("/");
    doc_kepegawaian_name = pecah[pecah.length - 1];
  }

  return (
    <Fragment>
      {isLoadingFetchData ||
      isLoadingFetchDataJenisPTK ||
      isLoadingFetchDataKompetensiKhusus ||
      isLoadingGetDetailKepegawaian ||
      isLoadingPostKepegawaian ||
      isLoadingDownloadDocKepegawaian ? (
        <FetchLoading />
      ) : (
        <></>
      )}
      <Form
        onSubmit={(e) => {
          formikKepegawaian.submitForm();
          setSubmitting(true);
          e.preventDefault();
        }}
      >
        {id && (
          <Card.Header className="py-3">
            <Card.Title
              className="p-0 "
              style={{
                fontSize: 16,
              }}
            >
              No. SK : {detailKepegawaian.nomor_sk_pengangkatan}
            </Card.Title>
          </Card.Header>
        )}
        <Card.Body className="border">
          <Row className="gy-4">
            <Col md="6">
              <Form.Group>
                <Select2
                  placeholder="-- Pilih Nama GTK --"
                  options={optionsDataGTK}
                  isLoading={isLoadingFetchData}
                  isRequired
                  label="Nama GTK"
                  onChange={(e: any) => {
                    if (e) formikKepegawaian.setFieldValue("nik_gtk", e.value);
                    else formikKepegawaian.setFieldValue("nik_gtk", null);
                  }}
                  ref={(element: any) => (ref.current[0] = element)}
                  value={formikKepegawaian.getFieldProps("nik_gtk").value}
                  disabled={true}
                />
                {submitting && formikKepegawaian.errors.nik_gtk && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.nik_gtk}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <TextInputComponent
                  label="NIY"
                  placeholder="Masukkan NIY"
                  type="text"
                  formik={formikKepegawaian.getFieldProps("niy_nigk")}
                  ref={(element: any) => (ref.current[1] = element)}
                />
                {submitting && formikKepegawaian.errors.niy_nigk && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.niy_nigk}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Select2
                  placeholder="-- Pilih Jenis Kepegawaian --"
                  options={optionsDataJenisKepegawaian}
                  isRequired
                  label="Jenis Kepegawaian"
                  onChange={(e: any) => {
                    if (e) {
                      formikKepegawaian.setFieldValue(
                        "jenis_kepegawaian",
                        e.value
                      );
                    } else {
                      formikKepegawaian.setFieldValue(
                        "jenis_kepegawaian",
                        null
                      );
                    }
                  }}
                  ref={(element: any) => (ref.current[8] = element)}
                  value={
                    formikKepegawaian.getFieldProps("jenis_kepegawaian").value
                  }
                />
                {submitting && formikKepegawaian.errors.jenis_kepegawaian && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.jenis_kepegawaian}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Select2
                  placeholder="-- Pilih Jenis PTK --"
                  options={optionsDataJenisPTK}
                  isRequired
                  label="Jenis PTK"
                  onChange={(e: any) => {
                    formikKepegawaian.setFieldValue(
                      "status_golongan_kepegawaian",
                      null
                    );

                    if (e) {
                      formikKepegawaian.setFieldValue("jenis_ptk", e.value);
                      handleJenisPTK(e.value);
                    } else {
                      formikKepegawaian.setFieldValue("jenis_ptk", null);
                      handleJenisPTK(null);
                    }
                  }}
                  ref={(element: any) => (ref.current[2] = element)}
                  value={formikKepegawaian.getFieldProps("jenis_ptk").value}
                />
                {submitting && formikKepegawaian.errors.jenis_ptk && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.jenis_ptk}
                  </div>
                )}
              </Form.Group>
            </Col>

            {optionsDataStatusGolonganKepegawaian.length > 0 && (
              <Col md="6">
                <Form.Group>
                  <Select2
                    placeholder="-- Pilih Jenjang Kepegawaian --"
                    options={optionsDataStatusGolonganKepegawaian}
                    isRequired
                    label="Jenjang Kepegawaian"
                    onChange={(e: any) => {
                      if (e)
                        formikKepegawaian.setFieldValue(
                          "status_golongan_kepegawaian",
                          e.value
                        );
                      else
                        formikKepegawaian.setFieldValue(
                          "status_golongan_kepegawaian",
                          null
                        );
                    }}
                    ref={(element: any) => (ref.current[3] = element)}
                    value={
                      formikKepegawaian.getFieldProps(
                        "status_golongan_kepegawaian"
                      ).value
                    }
                  />
                  {submitting &&
                    formikKepegawaian.errors.status_golongan_kepegawaian && (
                      <div className="fs-6 text-danger mt-2">
                        {formikKepegawaian.errors.status_golongan_kepegawaian}
                      </div>
                    )}
                </Form.Group>
              </Col>
            )}

            <Col md="6">
              <Form.Group>
                <TextInputComponent
                  label="NUPTK"
                  placeholder="Masukkan NUPTK"
                  type="text"
                  formik={formikKepegawaian.getFieldProps("nuptk")}
                  ref={(element: any) => (ref.current[4] = element)}
                />
                {submitting && formikKepegawaian.errors.nuptk && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.nuptk}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <TextInputComponent
                  label="Nomor SK Pengangkatan"
                  placeholder="Masukkan Nomor SK Pengangkatan"
                  isRequired
                  type="text"
                  formik={formikKepegawaian.getFieldProps(
                    "nomor_sk_pengangkatan"
                  )}
                  ref={(element: any) => (ref.current[5] = element)}
                />
                {submitting &&
                  formikKepegawaian.errors.nomor_sk_pengangkatan && (
                    <div className="fs-6 text-danger mt-2">
                      {formikKepegawaian.errors.nomor_sk_pengangkatan}
                    </div>
                  )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Datepicker
                  value={moment(
                    formikKepegawaian.getFieldProps("tmt_pengangkatan").value
                  ).format("YYYY-MM-DD")}
                  label="Tanggal Awal TMT Pengangkatan"
                  onChange={(e: any) => {
                    const value = dayjs(e).format("YYYY-MM-DD");
                    formikKepegawaian.setFieldValue("tmt_pengangkatan", value);
                  }}
                  ref={(element: any) => (ref.current[6] = element)}
                  required
                />
                {submitting && formikKepegawaian.errors.tmt_pengangkatan && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.tmt_pengangkatan}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Datepicker
                  value={moment(
                    formikKepegawaian.getFieldProps("masa_berlaku_sk").value
                  ).format("YYYY-MM-DD")}
                  label="Tanggal Akhir TMT Pengangkatan"
                  onChange={(e: any) => {
                    const value = dayjs(e).format("YYYY-MM-DD");
                    formikKepegawaian.setFieldValue("masa_berlaku_sk", value);
                  }}
                  ref={(element: any) => (ref.current[7] = element)}
                  required
                />
                {submitting && formikKepegawaian.errors.masa_berlaku_sk && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.masa_berlaku_sk}
                  </div>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                <Select2
                  placeholder="-- Pilih Kompetensi Khusus --"
                  options={optionsDataKompetensiKhusus}
                  label="Kompetensi Khusus"
                  onChange={(e: any) => {
                    if (e) {
                      formikKepegawaian.setFieldValue(
                        "kompetensi_khusus",
                        e.value
                      );
                      //setSelectedKompetensiKhusus(e.value);
                    } else {
                      formikKepegawaian.setFieldValue(
                        "kompetensi_khusus",
                        null
                      );
                      //setSelectedKompetensiKhusus(null);
                    }
                  }}
                  ref={(element: any) => (ref.current[8] = element)}
                  value={
                    formikKepegawaian.getFieldProps("kompetensi_khusus").value
                  }
                />
                {submitting && formikKepegawaian.errors.kompetensi_khusus && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.kompetensi_khusus}
                  </div>
                )}
              </Form.Group>
            </Col>

            {selectedKompetensiKhusus === "Sertifikat Tilawah" && (
              <SertifikatTilawah
                formik={formikKepegawaian}
                ref={ref}
                submitting={submitting}
              />
            )}

            {selectedKompetensiKhusus === "Sertifikat Hafalan" && (
              <Col md="6">
                <Form.Group>
                  <NumberInputComponent
                    placeholder="Masukkan Jumlah Juz"
                    label="Jumlah Juz"
                    required
                    formik={formikKepegawaian.getFieldProps("jumlah_juz")}
                    ref={(element: any) => (ref.current[11] = element)}
                  />
                  {submitting && formikKepegawaian.errors.jumlah_juz && (
                    <div className="fs-6 text-danger mt-2">
                      {formikKepegawaian.errors.jumlah_juz}
                    </div>
                  )}
                </Form.Group>
              </Col>
            )}

            {selectedKompetensiKhusus === "TOEFL" && (
              <Col md="6">
                <Form.Group>
                  <NumberInputComponent
                    placeholder="Masukkan TOEFL"
                    label="TOEFL"
                    required
                    formik={formikKepegawaian.getFieldProps("toefl")}
                    ref={(element: any) => (ref.current[12] = element)}
                  />
                  {submitting && formikKepegawaian.errors.toefl && (
                    <div className="fs-6 text-danger mt-2">
                      {formikKepegawaian.errors.toefl}
                    </div>
                  )}
                </Form.Group>
              </Col>
            )}

            <Col md="6">
              <Form.Group>
                <FileInputComponent
                  label="Upload Dokumen SK"
                  acceptedType={[".pdf"]}
                  onChange={(e) => {
                    if (e.target.files) {
                      formikKepegawaian.setFieldValue(
                        "doc_kepegawaian",
                        e.target.files[0]
                      );
                    } else
                      formikKepegawaian.setFieldValue("doc_kepegawaian", null);
                  }}
                  fileNameLabel={
                    formikKepegawaian.getFieldProps("doc_kepegawaian").value
                      ?.name ?? doc_kepegawaian_name
                  }
                  resetField={
                    formikKepegawaian.getFieldProps("doc_kepegawaian").value
                      ? false
                      : true
                  }
                  maxFileSize={25}
                  linkPreview={
                    typeof formikKepegawaian.getFieldProps("doc_kepegawaian")
                      .value === "string"
                      ? formikKepegawaian.getFieldProps("doc_kepegawaian").value
                      : null
                  }
                  downloadPreview={() =>
                    handleDownloadDocKepegawaian(
                      formikKepegawaian.getFieldProps("doc_kepegawaian").value,
                      doc_kepegawaian_name
                    )
                  }
                />
                {submitting && formikKepegawaian.errors.doc_kepegawaian && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKepegawaian.errors.doc_kepegawaian}
                  </div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border d-flex flex-row justify-content-end align-items-center">
          <Button type="submit" className="btn btn-md btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </Button>
        </Card.Footer>
      </Form>
    </Fragment>
  );
};

export const FormEntryKepegawaianWrapper = () => (
  <QueryRequestProviderDataGTK>
    <QueryResponseProviderDataGTK>
      <QueryResponseProviderJenisPTK>
        <QueryResponseProviderStatusGolonganKepegawaian>
          <QueryResponseProviderKompetensiKhusus>
            <QueryResponseProviderJenisKepegawaian>
              <QueryResponseProviderKepegawaian>
                <FormEntry />
              </QueryResponseProviderKepegawaian>
            </QueryResponseProviderJenisKepegawaian>
          </QueryResponseProviderKompetensiKhusus>
        </QueryResponseProviderStatusGolonganKepegawaian>
      </QueryResponseProviderJenisPTK>
    </QueryResponseProviderDataGTK>
  </QueryRequestProviderDataGTK>
);
