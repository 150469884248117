import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormInpassingPayload = {
  id: number | string | null;
  id_inpassing: string | null;
  nik_gtk: string | null;
  pangkat_golongan: string | null;
  nomor_sk_inpassing: string | null;
  tanggal_sk_inpassing: Date | Dayjs | null;
  tmt_sk_inpassing: Date | Dayjs | null;
  angka_kredit: number | string | null;
  masa_kerja: number | string | null;
};

export type QueryResponseContextProps<T> = {
  formikInpassing?: FormikProps<FormInpassingPayload>;
  formikInpassingValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostInpassing: (data: FormData) => void;
  isLoadingPostInpassing: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  submitting: false,
  setSubmitting: () => {},
  isLoadingPostInpassing: false,
  handlePostInpassing: () => {},
};
