import { Accordion, Card } from "react-bootstrap";
import { ShowLimitDT } from "../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../hope-ui/components/table/SearchInput";
import { useQueryRequest } from "../../../provider/query-request/absensi-gtk";
import { useQueryResponse } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { useEffect } from "react";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { useParams } from "react-router-dom";
const ExportAbsensiGTK = () => {
  const { state, updateState } = useQueryRequest();
  const {
    handleExportExcelAbsensiGTKInput,
    //isLoadingExportExcelAbsensiGTKInput
  } = useQueryResponse();
  const { status } = useParams();
  return (
    <div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            style={{float:"right", margin:"5px"}}
            onClick={() => {
              if (handleExportExcelAbsensiGTKInput) handleExportExcelAbsensiGTKInput();
            }}
          >
            <i className="far fa-file-excel me-1"></i> Download master
          </button>

  

    </div>
  );
};

export default ExportAbsensiGTK;
