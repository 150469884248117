import TextareaComponent from "../../../../../../hope-ui/components/textarea-input";
import { useQueryResponse } from "../../../../../../provider/query-response/absensi-lt3q/nilai-tahsin";

import { FC } from "react";

type Props = {
  nik_gtk: string;
  data: any;
};

export const KeteranganCell: FC<Props> = ({ nik_gtk, data }) => {
  let index: any;
  const { formikNilaiTahsinLT3Q } = useQueryResponse();
  const value = formikNilaiTahsinLT3Q?.getFieldProps("detail").value;

  const find = value?.find((element) => element.nik_gtk === data.nik_gtk);
  if (find) {
    index = value.indexOf(find);
  }

  const is_ujian = formikNilaiTahsinLT3Q?.getFieldProps(
    `detail[${index}].is_ujian`
  ).value;

  return (
    <div className="px-3">
      <TextareaComponent
        placeholder="Masukkan Keterangan"
        value={
          formikNilaiTahsinLT3Q?.getFieldProps(`detail[${index}].keterangan`)
            .value ?? ""
        }
        formik={formikNilaiTahsinLT3Q?.getFieldProps(
          `detail[${index}].keterangan`
        )}
        style={{
          minWidth: "250px",
        }}
        readOnly={!is_ujian}
      />
    </div>
  );
};
