import { Card, Col, Form} from "react-bootstrap";
import ModalDaftarDenda from "./modal/modal-daftar";
import { QueryResponseProvider, useQueryResponse } from "../../provider/query-response/denda-persensi-gtk/";
import { QueryRequestProvider as QueryRequestProviderProviderDendaPersensiGTK } from "../../provider/query-request/denda-persensi-gtk";
import { QueryResponseProvider as QueryResponseProviderDendaPersensiGTK } from "../../provider/query-response/denda-persensi-gtk";

import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";
import { DataTable } from "../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../provider/query-request/filter-data";
import { useQueryResponsePagination } from "../../provider/query-response/denda-persensi-gtk";



  
    /*const {
      GetDetailDendaPersensiGTK,
      saveDendaPersensiGTK,
      isLoadingFetchData,
      isLoadingDetailPersensiGTK,
      handleGetDetailDendaPersensiGTK,
      handlePostDendaPersensiGTK,
      
    } = useQueryResponseDataGTKEntry();


const formikGTK = useFormik<FormMasterDendaGTKPayload>({
    initialValues: {
      nik_gtk: detailGTK ? detailGTK?.data?.nik_gtk : null,
      nama_gtk: detailGTK ? detailGTK?.data?.nama_gtk : null,
      unit_kerja: detailGTK ? detailGTK?.data?.unit_kerja : null,
      email: detailGTK ? detailGTK?.data?.email : null,
      jenis_kelamin: detailGTK ? detailGTK?.data?.jenis_kelamin : null,
      tempat_lahir: detailGTK ? detailGTK?.data?.id : null,
      tanggal_lahir:
        detailGTK && detailGTK.data && detailGTK.data.tanggal_lahir
          ? dayjs(detailGTK?.data?.tanggal_lahir).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
      nomor_hp: detailGTK ? detailGTK?.data?.nomor_hp : null,
      nama_ibu_kandung: detailGTK ? detailGTK?.data?.nama_ibu_kandung : null,
      alamat: detailGTK ? detailGTK?.data?.alamat : null,
      rt: detailGTK ? detailGTK?.data?.rt : null,
      rw: detailGTK ? detailGTK?.data?.rw : null,
      nama_dusun_kota: detailGTK ? detailGTK?.data?.nama_dusun_kota : null,
      kelurahan: detailGTK ? detailGTK?.data?.kelurahan : null,
      kecamatan: detailGTK ? detailGTK?.data?.kecamatan : null,
      kode_pos: detailGTK ? detailGTK?.data?.kode_pos : null,
      npwp: detailGTK ? detailGTK?.data?.npwp : null,
      nama_wajib_pajak: detailGTK ? detailGTK?.data?.nama_wajib_pajak : null,
      warga_negara: detailGTK ? detailGTK?.data?.warga_negara : null,
      status_perkawinan: detailGTK ? detailGTK?.data?.status_perkawinan : null,
      nama_pasangan_hidup: detailGTK
        ? detailGTK?.data?.nama_pasangan_hidup
        : null,
      pekerjaan_pasangan_hidup: detailGTK
        ? detailGTK?.data?.pekerjaan_pasangan_hidup
        : null,
      jumlah_penghasilan_suami: detailGTK
        ? detailGTK?.data?.jumlah_penghasilan_suami
        : null,
      no_bpjs_kesehatan: detailGTK ? detailGTK?.data?.no_bpjs_kesehatan : null,
      no_bpjs_ketenagakerjaan: detailGTK
        ? detailGTK?.data?.no_bpjs_ketenagakerjaan
        : null,
      rekening_atas_nama: detailGTK
        ? detailGTK?.data?.rekening_atas_nama
        : null,
      id_bank: detailGTK ? detailGTK?.data?.id_bank : null,
      no_rekening_bank: detailGTK ? detailGTK?.data?.no_rekening_bank : null,
      rekening_atas_nama_non_gaji: detailGTK
        ? detailGTK?.data?.rekening_atas_nama_non_gaji
        : null,
      id_bank_non_gaji: detailGTK ? detailGTK?.data?.id_bank_non_gaji : null,
      no_rekening_bank_non_gaji: detailGTK
        ? detailGTK?.data?.no_rekening_bank_non_gaji
        : null,
      domisili_gtk: {
        id: detailGTK ? detailGTK?.data?.domisili_gtk?.id : null,
        nik_gtk: detailGTK ? detailGTK?.data?.domisili_gtk?.nik_gtk : null,
        alamat_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.alamat_domisili
          : null,
        rt_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.rt_domisili
          : null,
        rw_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.rw_domisili
          : null,
        nama_dusun_kota_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.nama_dusun_kota_domisili
          : null,
        kelurahan_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.kelurahan_domisili
          : null,
        kecamatan_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.kecamatan_domisili
          : null,
        kode_pos_domisili: detailGTK
          ? detailGTK?.data?.domisili_gtk?.kode_pos_domisili
          : null,
      },
    },
    validationSchema: formikGTKValidationSchema,
    enableReinitialize: false,
    onSubmit: (values: any) => {
      console.log(values);
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        let value = values[key];
        if (key === "domisili_gtk") {
          payload.append(key, JSON.stringify(value));
        } else {
          if (value) payload.append(key, value);
        }
      });

      if (handlePostDataGTK) handlePostDataGTK(payload);
    },
  });

*/



const MasterDendaGTK = () => {

  const {listData, isLoadingFetchData} = useQueryResponse();

  return(
  <>
  <Card.Body className="pt-3">
  <ModalDaftarDenda />
  <Toolbar/>


    <DataTable
      isLoading={isLoadingFetchData}
      queryRequest={useQueryRequest}
      pagination={useQueryResponsePagination}  
      header={Columns}
      data={listData?.data}
    />

  </Card.Body>
  </>)
}

export const MasterDendaGTKWrapper = () => (

  <QueryResponseProvider>
  <QueryRequestProviderProviderDendaPersensiGTK>
    <QueryResponseProviderDendaPersensiGTK>
      <MasterDendaGTK/>
    </QueryResponseProviderDendaPersensiGTK>
  </QueryRequestProviderProviderDendaPersensiGTK>
  </QueryResponseProvider>

)


