import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../provider/query-request/riwayat-pekerjaan";
import AksiCell from "./AksiCell";
import moment from "moment";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nomor Surat"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nomor_surat",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nomor_surat}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tugas Tambahan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tugas_tambahan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].tugas_tambahan}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tanggal Mulai"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tanggal_mulai",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {moment(props.data[props.row.index].tanggal_mulai).format(
          "DD MMM YYYY"
        )}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Tanggal Selesai"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tanggal_selesai",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {moment(props.data[props.row.index].tanggal_selesai).format(
          "DD MMM YYYY"
        )}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
      />
    ),
    id: "action_kirim_memo",
    Cell: ({ ...props }) => {
      return <AksiCell data={props.data[props.row.index]} />;
    },
  },
];
