import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../../provider/query-request/absensi-lt3q/daftar-absensi";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../provider/query-response/absensi-lt3q/daftar-absensi";
import { useQueryResponse as useQueryResponseBuatAbsensi } from "../../../../provider/query-response/absensi-lt3q/buat-absensi";
import { Columns } from "./list/columns";
import Toolbar from "./toolbar";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const DaftarAbsensi = () => {
  const { listData, isLoadingFetchData } = useQueryResponse();
  const { isLoadingGetDetailAbsensiLT3Q } = useQueryResponseBuatAbsensi();
  const Data_table = listData?.data;
  return (
    <div className="pt-3">
      {isLoadingGetDetailAbsensiLT3Q && <FetchLoading />}
      <Toolbar />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={Data_table}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </div>
  );
};

export default DaftarAbsensi;
