import axios, { AxiosResponse } from "axios";
import { client_sso, client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getFilterUnitKerja = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_FILTER_UNIT_KERJA_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailFilterUnitKerja = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_FILTER_UNIT_KERJA_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteFilterUnitKerja = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_FILTER_UNIT_KERJA_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postFilterUnitKerja = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_FILTER_UNIT_KERJA_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllHakAkses = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_FILTER_UNIT_KERJA_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllDaftarUnitKerja = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_DAFTAR_UNIT_KERJA_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getFilterUnitKerja,
  postFilterUnitKerja,
  getDetailFilterUnitKerja,
  deleteFilterUnitKerja,
  getAllDaftarUnitKerja,
  getAllHakAkses,
};
