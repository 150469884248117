import { ForwardedRef, Ref, forwardRef } from "react";
import { Link } from "react-router-dom";
import { FC } from "react";
type Props = {
  children: any;
  variant: any;
  onClick: any;
};

const CustomToggle = forwardRef<HTMLAnchorElement, Props>(
  ({ children, variant, onClick }, ref) => (
    <Link
      to="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={variant}
      style={{ color: "unset" }}
    >
      {children}
    </Link>
  )
);
export default CustomToggle;
