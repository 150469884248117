import { Column } from "react-table";
import { Header } from "../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../provider/query-request/absensi-gtk";
import { useQueryResponsePagination } from "../../../../provider/query-response/absensi-gtk/laporan-harian";
import { useQueryResponse } from "../../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import NamaGTKCell from "../../../../hope-ui/components/table/NamaGTKCell";
import moment from "moment";
import AksiCatatanTerlambatGTK from "../aksi-catatan-terlambat";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  //   {
  //     Header: (props) => (
  //       <Header
  //         tableProps={props}
  //         title="Aksi"
  //         className="ps-3 fs-6 text-center"
  //         queryRequest={useQueryRequest}
  //         isSorting={false}
  //       />
  //     ),
  //     id: "action",
  //     Cell: ({ ...props }) => <AksiCell id={props.data[props.row.index].id} />,
  //   },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-start"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label style={{ textAlign: "left", paddingLeft: "1rem" }}>
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NAMA GTK"
        className="ps-3 fs-6 text-start"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <div style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <label>{props.data[props.row.index].nama_gtk}</label>
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="TANGGAL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "tanggal",
    Cell: ({ ...props }) => {
      let tanggal: any = useQueryResponse().tanggalAbsen;
      tanggal = moment(tanggal).format("YYYY-MM-DD");
      return <div>{tanggal}</div>;
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="UNIT KERJA"
        className="ps-3 fs-6 text-start"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <div style={{ textAlign: "left", paddingLeft: "1rem" }}>
        <label>{props.data[props.row.index].unit_kerja}</label>
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => (
      <AksiCatatanTerlambatGTK
        data={props.data[props.row.index]}
        nik_gtk={props.data[props.row.index].nik_gtk}
        id_absensi_gtk={null}
        tanggal_absen={props.data[props.row.index].tanggal_absen}
      />
    ),
  },
];
