import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";

const Loader = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100"
      style={{ height: "100vh" }}
    >
      <FetchLoadingCompanyLogo />
    </div>
  );
};

export default Loader;
