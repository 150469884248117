import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { initialQueryResponse } from "../../../../models/status-aktivasi/tidak-aktif/_queryResponse";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../query-request/status-aktivasi/tidak-aktif";
import { useQuery } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { getStatusAktivasiTidakAktif } from "../../../../api/status-aktivasi/tidak-aktif/_request";
import { initialQueryState } from "../../../../models/global";
import { useQueryResponse as useQueryResponseStatusAktivasi } from "../../../query-response/status-aktivasi";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state } = useQueryRequest();
  state.col_header = `["nik_gtk", "nama_gtk", "unit_kerja"]`;

  const { statusPostAktifkanGTK, setStatusPostAktifkanGTK } =
    useQueryResponseStatusAktivasi();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "status-aktivasi-tidak-aktif")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "status-aktivasi-tidak-aktif"),
    [state]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_STATUS_AKTIVASI_TIDAK_AKTIF}-${query}`,
    () => getStatusAktivasiTidakAktif(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
    }
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (statusPostAktifkanGTK && refetchListData) {
      refetchListData();
      setStatusPostAktifkanGTK(false);
    }
  }, [statusPostAktifkanGTK, refetchListData]);

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
    }),
    [listData, refetchListData, isLoadingFetchData, query]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
