import { Accordion, Col, Row, Card, Spinner, Table } from "react-bootstrap";
import ColumnHeader from "./columns/header";
import { useQueryResponse } from "../../../provider/query-response/dashboard";
import Calendar from "react-calendar";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import ColumnBody from "./columns/body";
import { Dayjs } from "dayjs";
import { LooseValue } from "react-calendar/dist/cjs/shared/types";
import ColumnFooter from "./columns/footer";
import Detail from "./detail";
import KeteranganWarnaPresensiGTK from "../../../hope-ui/components/keterangan-warna-presensi-gtk";

const PresensiGTK = () => {
  const [filterTanggal, setFilterTanggal] = useState<LooseValue>(new Date());
  const { handleGetPresensiGTK, isLoadingGetPresensiGTK, presensiGTK } =
    useQueryResponse();

  useEffect(() => {
    if (handleGetPresensiGTK && filterTanggal)
      handleGetPresensiGTK(filterTanggal.toString());
  }, [handleGetPresensiGTK, filterTanggal]);

  return (
    <Col md="12" lg="12" xs="12">
      {<Detail />}
      <Card>
        <Card.Header className="border border-gray-100">
          <Card.Title>Presenting Kehadiran GTK & Pegawai Harian</Card.Title>
        </Card.Header>
        <Card.Body>
          <Accordion className="col-12 mb-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filter Tanggal</Accordion.Header>
              <Accordion.Body>
                <Row className="gy-3">
                  <Col md="6" lg="12">
                    <div style={{ fontSize: "12px" }}>
                      <Calendar
                        onChange={(e: any) => {
                          const tanggal: any = moment(e).format("YYYY-MM-DD");
                          setFilterTanggal(tanggal);
                        }}
                        value={filterTanggal}
                        className="form-control"
                        maxDate={new Date()}
                      />
                    </div>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {isLoadingGetPresensiGTK && (
            <div className="w-100 d-flex flex-row justify-content-center align-items-center">
              <Spinner
                animation="border"
                variant="success"
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          )}
          {!isLoadingGetPresensiGTK && (
            <>
              <KeteranganWarnaPresensiGTK className="mt-3" />

              <Table
                responsive
                id="datatable"
                data-toggle="data-table"
                bordered
                className="mt-3"
              >
                <ColumnHeader />
                <ColumnBody data={presensiGTK} tanggal={filterTanggal} />
                {presensiGTK && presensiGTK.total_footer && (
                  <ColumnFooter data={presensiGTK.total_footer} />
                )}
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export const PresensiGTKWrapper = () => <PresensiGTK />;
