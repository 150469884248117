import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../layout/core/LayoutProvider";
import { DaftarPersensiGTKListWrapper } from "../../modules/absensi-gtk/list";

const AbsensiGTK = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Olah Presensi GTK";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Presensi GTK";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <DaftarPersensiGTKListWrapper/>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AbsensiGTK;
