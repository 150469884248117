import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../../layout/core/LayoutProvider";
import { useQueryResponse } from "../../../provider/query-response/status-aktivasi";
import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { StatusAktivasiTidakAktifListWrapper } from "../../../modules/status-aktivasi/tidak-aktif/list";

const StatusAktivasiTidakAktif = () => {
  const { isLoadingPostAktifkanGTK, isLoadingPostNonAktifkanGTK } =
    useQueryResponse();
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title =
      "Sistem Informasi Bina Ilmi | Daftar GTK Yang Belum Diaktivasi";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Daftar GTK Yang Belum Diaktivasi";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      {(isLoadingPostAktifkanGTK || isLoadingPostNonAktifkanGTK) && (
        <FetchLoading />
      )}{" "}
      <Row>
        <Col md="12" lg="12">
          <Card>
            <StatusAktivasiTidakAktifListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StatusAktivasiTidakAktif;
