import { Column } from "react-table";
import { Header } from "../../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../../provider/query-request/data-saya-kepegawaian";
import AksiCell from "./AksiCell";
import moment from "moment";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.row.index + 1}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No. SK Pengangkatan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nomor_sk_pengangkatan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nomor_sk_pengangkatan}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIY NIGK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "niy_nigk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].niy_nigk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NUPTK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nuptk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nuptk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenis PTK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jenis_ptk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].jenis_ptk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenjang Kepegawaian"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "status_golongan_kepegawaian",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].status_golongan_kepegawaian}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="TMT Pengangkatan"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tmt_pengangkatan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {moment(props.data[props.row.index].tmt_pengangkatan).format(
          "DD MMM YYYY"
        )}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Masa Berlaku SK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "masa_berlaku_sk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {moment(props.data[props.row.index].masa_berlaku_sk).format(
          "DD MMM YYYY"
        )}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Kompetensi Khusus"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "kompetensi_khusus",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].kompetensi_khusus}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nilai Teori"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nilai_teori",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nilai_teori}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nilai Praktek"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nilai_praktek",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nilai_praktek}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jumlah Juz"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jumlah_juz",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].jumlah_juz}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="TOEFL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "toefl",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].toefl}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Jenis Kepegawaian"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "jenis_kepegawaian",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].jenis_kepegawaian}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
      />
    ),
    id: "action_kirim_memo",
    Cell: ({ ...props }) => {
      //return <AksiCell data={props.data[props.row.index]} />;
      return <></>;
    },
  },
];
