import { FC } from "react";

type Props = {
  data?: any;
};

const ColumnFooter: FC<Props> = ({ data }) => {
  return (
    <thead>
      <tr className="fs-6">
        <th className="text-center">Total</th>
        <th className="text-center">{data.N}</th>
        <th className="text-center">{data.TKD}</th>
        <th className="text-center">{data.TTKD}</th>
        <th className="text-center">{data.TH}</th>
        <th className="text-center">{data.total} </th>
      </tr>
    </thead>
  );
};

export default ColumnFooter;
