import { FC } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/pelajaran-yang-diampu";

type Props = {
  data: any;
};
const AksiCell: FC<Props> = ({ data }) => {
  const {
    setShowModal,
    handleGetDetailPelajaranDiampu,
    handleDeletePelajaranDiampu,
  } = useQueryResponse();
  return (
    <DropdownButton
      as={ButtonGroup}
      key={"down"}
      drop={"down"}
      variant="info"
      title={` Pilih Aksi `}
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        onClick={(e) => {
          setShowModal(true);
          handleGetDetailPelajaranDiampu(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Edit <i className="fa fa-pencil text-info ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={(e) => {
          handleDeletePelajaranDiampu(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Hapus <i className="fa fa-trash text-danger ms-1"></i>
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default AksiCell;
