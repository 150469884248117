import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC } from "react";
import { Form } from "react-bootstrap";

type Props = {
  label?: string;
  value?: any;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  formik?: FieldInputProps<any>;
  ref?: any;
  disabled?: boolean;
  style?: React.CSSProperties;
  readOnly?: boolean;
};

const NumberInputComponent: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  required,
  formik,
  ref,
  disabled,
  style,
  readOnly,
}) => {
  return (
    <>
      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger"> * </span>}
        </Form.Label>
      )}
      <Form.Control
        type="number"
        value={value}
        className="form-control"
        onChange={onChange}
        placeholder={placeholder}
        {...formik}
        ref={ref}
        onKeyDown={(e) => {
          if ([69].includes(e.keyCode)) {
            e.preventDefault();
          }
        }}
        onFocus={(e) =>
          e.target.addEventListener("wheel", function (e) {
            e.preventDefault();
          })
        }
        disabled={disabled}
        style={style}
        readOnly={readOnly}
      />
    </>
  );
};

export default NumberInputComponent;
