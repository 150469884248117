import { Response } from "../global";

export type FormMenuPayload = {
  id: number | string | null;
  parent_id: number | null;
  label: string | null;
  order: number | null;
  status: boolean | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handlePostMenu?: (data: FormData) => void;
  isLoadingPostMenu: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostMenu: false,
  refetchListData: () => {},
};
