import { Card } from "react-bootstrap";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import Toolbar from "./toolbar";
import { Columns } from "./columns/columns";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../../../provider/query-request/status-aktivasi/aktif";
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../provider/query-response/status-aktivasi/aktif";

import { QueryRequestProvider as QueryRequestProviderFilterData } from "../../../../provider/query-request/filter-data";
import { QueryResponseProvider as QueryResponseProviderFilterData } from "../../../../provider/query-response/filter-data";

import { QueryResponseProvider as QueryResponseProviderStatusAktivasi } from "../../../../provider/query-response/status-aktivasi";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

import { useQueryResponse as useQueryResponseStatusAktivasi } from "../../../../provider/query-response/status-aktivasi";

const StatusAktivasiAktifList = () => {
  const { listData, isLoadingFetchData } = useQueryResponse();
  const { isLoadingPostAktifkanGTK, isLoadingPostNonAktifkanGTK } =
    useQueryResponseStatusAktivasi();
  const Data_table = listData?.data;

  return (
    <div>
      {(isLoadingPostAktifkanGTK || isLoadingPostNonAktifkanGTK) && (
        <FetchLoading />
      )}{" "}
      <Toolbar />
      <Card.Body className="pt-0">
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={Data_table}
          pagination={useQueryResponsePagination}
          queryRequest={useQueryRequest}
        />
      </Card.Body>
    </div>
  );
};

export const StatusAktivasiAktifListWrapper = () => (
  <QueryRequestProviderFilterData>
    <QueryResponseProviderFilterData>
      <QueryResponseProviderStatusAktivasi>
        <QueryRequestProvider>
          <QueryResponseProvider>
            <StatusAktivasiAktifList />
          </QueryResponseProvider>
        </QueryRequestProvider>
      </QueryResponseProviderStatusAktivasi>
    </QueryResponseProviderFilterData>
  </QueryRequestProviderFilterData>
);
