import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormPenilaianPayload,
  initialQueryResponse,
} from "../../../models/penilaian/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/penilaian";
import {
  deletePenilaian,
  downloadDocPenilaian,
  getDetailPenilaian,
  getListPenilaian,
  postPenilaian,
} from "../../../api/penilaian/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["jenis_tes", "penyelenggara", "tahun", "skor"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalPenilaian, setShowModalPenilaian] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailPenilaian.nik_gtk = nik_gtk;

  const [detailPenilaian, setDetailPenilaian] = useState<FormPenilaianPayload>(
    initialQueryResponse.detailPenilaian
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikPenilaian.setValues(initialQueryResponse.detailPenilaian);
      setDetailPenilaian(initialQueryResponse.detailPenilaian);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_PENILAIAN}-${query}`,
    () => getListPenilaian(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikPenilaianValidationSchema = Yup.object().shape({
    jenis_tes: Yup.string().required("Jenis Tes Tidak Boleh Kosong"),
    penyelenggara: Yup.string().required("Penyelenggara Tidak Boleh Kosong"),
    skor: Yup.string().required("Skor Tidak Boleh Kosong"),
    tahun: Yup.string().required("Tahun Tidak Boleh Kosong"),
  });

  const formikPenilaian = useFormik<FormPenilaianPayload>({
    initialValues: initialQueryResponse.detailPenilaian,
    enableReinitialize: false,
    validationSchema: formikPenilaianValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostPenilaian(payload);
    },
  });

  const { mutateAsync: PostPenilaian, isLoading: isLoadingPostPenilaian } =
    useMutation({
      mutationKey: `${QUERIES.POST_PENILAIAN}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postPenilaian(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_PENILAIAN}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostPenilaian = useCallback(
    async (payload: any) => {
      await PostPenilaian({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalPenilaian(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPenilaian]
  );

  const {
    mutateAsync: GetDetailPenilaian,
    isLoading: isLoadingGetDetailPenilaian,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_PENILAIAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailPenilaian(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_PENILAIAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailPenilaian = useCallback(
    async (id: string) => {
      await GetDetailPenilaian({ id })
        .then((res) => {
          setDetailPenilaian(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailPenilaian]
  );

  const {
    mutateAsync: DownloadDocPenilaian,
    isLoading: isLoadingDownloadDocPenilaian,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_PENILAIAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocPenilaian(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_PENILAIAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocPenilaian = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocPenilaian({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocPenilaian]
  );

  const { mutateAsync: DeletePenilaian, isLoading: isLoadingDeletePenilaian } =
    useMutation({
      mutationKey: `${QUERIES.POST_PENILAIAN}`,
      mutationFn: ({ id }: { id: string }) => deletePenilaian(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_PENILAIAN}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDeletePenilaian = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeletePenilaian({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  handleGetDetailGTK(res.data.nik_gtk);
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeletePenilaian]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalPenilaian,
      setShowModalPenilaian,
      formikPenilaian,
      formikPenilaianValidationSchema,
      submitting,
      setSubmitting,
      handlePostPenilaian,
      isLoadingPostPenilaian,
      detailPenilaian,
      setDetailPenilaian,
      handleGetDetailPenilaian,
      isLoadingGetDetailPenilaian,
      resetForm,
      setResetForm,
      handleDownloadDocPenilaian,
      isLoadingDownloadDocPenilaian,
      handleDeletePenilaian,
      isLoadingDeletePenilaian,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalPenilaian,
      setShowModalPenilaian,
      formikPenilaian,
      formikPenilaianValidationSchema,
      submitting,
      setSubmitting,
      handlePostPenilaian,
      isLoadingPostPenilaian,
      detailPenilaian,
      setDetailPenilaian,
      handleGetDetailPenilaian,
      isLoadingGetDetailPenilaian,
      resetForm,
      setResetForm,
      handleDownloadDocPenilaian,
      isLoadingDownloadDocPenilaian,
      handleDeletePenilaian,
      isLoadingDeletePenilaian,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
