import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormAnakPayload,
  initialQueryResponse,
} from "../../../models/anak/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/anak";
import {
  deleteAnak,
  downloadDocAnak,
  getAllJenjangPendidikan,
  getDetailAnak,
  getListAnak,
  postAnak,
} from "../../../api/anak/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nama_anak", "jenis_pendidikan", "nisn", "jenis_kelamin_anak", "tempat_lahir", "tanggal_lahir", "tahun_masuk"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalAnak, setShowModalAnak] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [allJenjangPendidikan, setAllJenjangPendidikan] = useState<Array<any>>(
    []
  );

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailAnak.nik_gtk = nik_gtk;

  const [detailAnak, setDetailAnak] = useState<FormAnakPayload>(
    initialQueryResponse.detailAnak
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikAnak.setValues(initialQueryResponse.detailAnak);
      setDetailAnak(initialQueryResponse.detailAnak);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(`${QUERIES.GET_LIST_ANAK}-${query}`, () => getListAnak(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    onError: (err) => {
      console.error(err);
    },
    enabled: query !== "?undefined",
  });

  const formikAnakValidationSchema = Yup.object().shape({
    nama_anak: Yup.string().required("Nama Anak Tidak Boleh Kosong"),
    jenis_kelamin_anak: Yup.string().required(
      "Jenis Kelamin Tidak Boleh Kosong"
    ),
    jenis_pendidikan: Yup.string().required(
      "Jenjang Pendidikan Tidak Boleh Kosong"
    ),
    /*nisn: Yup.string().required("NISN Tidak Boleh Kosong"),
    tahun_masuk: Yup.string().required(
      "Tahun Masuk Sekolah Tidak Boleh Kosong"
    ),*/
    tanggal_lahir: Yup.string().required("Tanggal Lahir Tidak Boleh Kosong"),
    tempat_lahir: Yup.string().required("Tempat Lahir Tidak Boleh Kosong"),
    status_anak: Yup.string().required("Status Anak Tidak Boleh Kosong"),
  });

  const formikAnak = useFormik<FormAnakPayload>({
    initialValues: {
      id: null,
      id_anak: null,
      nama_anak: null,
      jenis_kelamin_anak: null,
      jenis_pendidikan: null,
      nik_gtk,
      nisn: null,
      tahun_masuk: null,
      tanggal_lahir: null,
      tempat_lahir: null,
      status_anak: null,
    },
    enableReinitialize: false,
    validationSchema: formikAnakValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostAnak(payload);
    },
  });

  const { mutateAsync: PostAnak, isLoading: isLoadingPostAnak } = useMutation({
    mutationKey: `${QUERIES.POST_ANAK}`,
    mutationFn: ({ payload }: { payload: FormData }) => postAnak(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_ANAK}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostAnak = useCallback(
    async (payload: any) => {
      await PostAnak({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalAnak(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostAnak]
  );

  const { mutateAsync: GetDetailAnak, isLoading: isLoadingGetDetailAnak } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_ANAK}`,
      mutationFn: ({ id }: { id: string }) => getDetailAnak(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_ANAK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailAnak = useCallback(
    async (id: string) => {
      await GetDetailAnak({ id })
        .then((res) => {
          setDetailAnak(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAnak]
  );

  const {
    mutateAsync: GetAllJenjangPendidikan,
    isLoading: isLoadingGetAllJenjangPendidikan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_JENJANG_PENDIDIKAN}`,
    mutationFn: () => getAllJenjangPendidikan(),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_JENJANG_PENDIDIKAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllJenjangPendidikan = useCallback(async () => {
    await GetAllJenjangPendidikan()
      .then((res) => {
        setAllJenjangPendidikan(res.data);
      })
      .catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
  }, [GetAllJenjangPendidikan]);

  const { mutateAsync: DownloadDocAnak, isLoading: isLoadingDownloadDocAnak } =
    useMutation({
      mutationKey: `${QUERIES.DOWNLOAD_DOC_ANAK}`,
      mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
        downloadDocAnak(url, fileName),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.DOWNLOAD_DOC_ANAK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDownloadDocAnak = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocAnak({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocAnak]
  );

  const { mutateAsync: DeleteAnak, isLoading: isLoadingDeleteAnak } =
    useMutation({
      mutationKey: `${QUERIES.POST_ANAK}`,
      mutationFn: ({ id }: { id: string }) => deleteAnak(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_ANAK}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDeleteAnak = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteAnak({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteAnak]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalAnak,
      setShowModalAnak,
      formikAnak,
      formikAnakValidationSchema,
      submitting,
      setSubmitting,
      handlePostAnak,
      isLoadingPostAnak,
      detailAnak,
      setDetailAnak,
      handleGetDetailAnak,
      isLoadingGetDetailAnak,
      resetForm,
      setResetForm,
      handleDownloadDocAnak,
      isLoadingDownloadDocAnak,
      handleDeleteAnak,
      isLoadingDeleteAnak,
      handleGetAllJenjangPendidikan,
      isLoadingGetAllJenjangPendidikan,
      allJenjangPendidikan,
      setAllJenjangPendidikan,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalAnak,
      setShowModalAnak,
      formikAnak,
      formikAnakValidationSchema,
      submitting,
      setSubmitting,
      handlePostAnak,
      isLoadingPostAnak,
      detailAnak,
      setDetailAnak,
      handleGetDetailAnak,
      isLoadingGetDetailAnak,
      resetForm,
      setResetForm,
      handleDownloadDocAnak,
      isLoadingDownloadDocAnak,
      handleDeleteAnak,
      isLoadingDeleteAnak,
      handleGetAllJenjangPendidikan,
      isLoadingGetAllJenjangPendidikan,
      allJenjangPendidikan,
      setAllJenjangPendidikan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
