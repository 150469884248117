import { FC } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useQueryResponse } from "../../../../../provider/query-response/riwayat-pendidikan";

type Props = {
  data: any;
};
const AksiCell: FC<Props> = ({ data }) => {
  const {
    setShowModalRiwayatPendidikan,
    handleGetDetailRiwayatPendidikan,
    handleDownloadDocRiwayatPendidikan,
    handleDeleteRiwayatPendidikan,
  } = useQueryResponse();
  return (
    <DropdownButton
      as={ButtonGroup}
      key={"end"}
      drop={"end"}
      variant="primary"
      title={` Pilih Aksi `}
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        onClick={(e) => {
          setShowModalRiwayatPendidikan(true);
          handleGetDetailRiwayatPendidikan(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Edit <i className="fa fa-pencil text-info ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={(e) => {
          handleDeleteRiwayatPendidikan(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Hapus <i className="fa fa-trash text-danger ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        onClick={(e) => {
          const pecah = data.doc_transkrip_nilai.split("/");
          const fileName = pecah[pecah.length - 1];
          handleDownloadDocRiwayatPendidikan(
            data.doc_transkrip_nilai,
            fileName
          );
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Unduh Transkrip Nilai
          <i className="fa fa-download text-primary ms-1"></i>
        </div>
      </Dropdown.Item>

      <Dropdown.Item
        eventKey="3"
        onClick={(e) => {
          const pecah = data.doc_ijazah.split("/");
          const fileName = pecah[pecah.length - 1];
          handleDownloadDocRiwayatPendidikan(data.doc_ijazah, fileName);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Unduh Ijazah
          <i className="fa fa-download text-primary ms-1"></i>
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default AksiCell;
