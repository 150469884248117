import { Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/hak-akses/kelola-role-menu";
import { useQueryResponse as useQueryResponseMenus } from "../../../../provider/query-response/menu";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import SortableTree from "@nosferatu500/react-sortable-tree";
import CheckInputComponent from "../../../../hope-ui/components/check-input/CheckInputComponent";

const ModalEntryKelolaRoleMenu = () => {
  const {
    isShowModal,
    setIsShowModal,
    setResetForm,
    setSubmitting,
    handleGetAllMenus,
    isLoadingGetAllMenus,
    appName,
    rolesId,
    allMenus,
  } = useQueryResponse();

  const listDataMenus = useQueryResponseMenus().listData;
  const isLoadingFetchDataMenus = useQueryResponseMenus().isLoadingFetchData;
  const [treeData, setTreeData] = useState<Array<any>>([]);

  useEffect(() => {
    if (listDataMenus && listDataMenus.data) {
      setTreeData(listDataMenus.data);
    }
  }, [listDataMenus]);

  useEffect(() => {
    if (isShowModal && handleGetAllMenus) {
      const app_name: any = appName;
      const roles_id: any = rolesId;
      handleGetAllMenus(app_name, roles_id);
    }
  }, [isShowModal, handleGetAllMenus]);

  return (
    <Modal
      backdrop="static"
      show={isShowModal}
      onBackdropClick={() => {
        setIsShowModal(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setIsShowModal(false);
        setResetForm(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Kelola Hak Akses Menu</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          e.preventDefault();
        }}
      >
        <Modal.Body
        //   style={{
        //     height:
        //       listDataMenus &&
        //       listDataMenus.data &&
        //       listDataMenus?.data?.length > 0
        //         ? "100vh"
        //         : "100px",
        //   }}
        >
          {isLoadingGetAllMenus && <FetchLoadingCompanyLogo />}
          {allMenus?.map((val) => {
            return (
              <>
                <CheckInputComponent
                  id={val.id}
                  labelClassName="text-dark text-wrap"
                  className="mt-3"
                  label={val.label}
                />
              </>
            );
          })}
          {/* {isLoadingFetchDataMenus && <FetchLoadingCompanyLogo />}
          {!isLoadingFetchDataMenus && treeData.length > 0 && (
            <SortableTree
              treeData={treeData}
              onChange={(treeData) => {
                setTreeData(treeData);
              }}
              generateNodeProps={(rowInfo) => ({
                buttons: [
                  <div className="d-flex flex-row justify-content-between align-items-center ">
                    <CheckInputComponent
                      id={rowInfo.node.label}
                      labelClassName="text-dark text-wrap"
                      className="mt-3"
                    />
                  </div>,
                ],
              })}
              canDrag={() => false}
            />
          )} */}
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default ModalEntryKelolaRoleMenu;
