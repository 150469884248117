import { Form, Card, Col, Image, Row, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import auth1 from "../../../assets/images/auth/login-banner.svg";
import { useLayout } from "../../layout/core/LayoutProvider";
import React, { useEffect, useState } from "react";
import TextInputComponent from "../../hope-ui/components/text-input/TextInputComponent";
import { useFormik } from "formik";
import { FormLoginPayload } from "../../models/auth/login/LoginInterface";
import CheckInputComponent from "../../hope-ui/components/check-input/CheckInputComponent";
import * as Yup from "yup";
import { useAuth } from "../../provider/core/auth";
import CompanyLogo from "../../hope-ui/components/company-logo";
import LogoJundezain from "../../hope-ui/components/logo-jundezain";

const LoginForm = () => {
  const { setting, setSetting } = useLayout();
  const [submitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Login";
  }, []);

  useEffect(() => {
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.app_name.value = "SIMBI";
      return jasper;
    });

    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.app_long_name = "Sistem Informasi dan Manajemen Bina Ilmi";
      return jasper;
    });
  }, []);

  const formLoginValidationSchema = Yup.object().shape({
    nik_gtk: Yup.string().required("NIK Tidak Boleh Kosong"),
    password: Yup.string().required("Password Tidak Boleh Kosong"),
  });

  const { handleAuthenticate, isLoadingPostAuthenticate } = useAuth();

  const formikLogin = useFormik<FormLoginPayload>({
    initialValues: {
      nik_gtk: null,
      password: null,
      remember_me: false,
    },
    validationSchema: formLoginValidationSchema,
    enableReinitialize: false,
    onSubmit: (values: any) => {
      const formData = new FormData();
      Object.keys(values).map((key, i) => {
        if (key === "nik_gtk") formData.append("username", values[key]);
        else formData.append(key, values[key]);
      });
      handleAuthenticate(formData);
    },
  });

  return (
    <section className="login-content">
      <Row className="m-0 align-items-center bg-white vh-100 ">
        <Col md="6">
          <Row className="justify-content-center">
            <Col md="10">
              <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card ">
                <Card.Body className="pt-0">
                  <Link
                    to="/dashboard"
                    className="navbar-brand d-flex align-items-center mb-3"
                  >
                    <CompanyLogo width={62} height={60} />
                    <h4 className="logo-title ms-3">
                      {setting.app_name.value}
                    </h4>
                  </Link>
                  <h2 className="mb-2 text-center">Log In</h2>
                  <p className="text-center">{setting.app_long_name}</p>
                  <Form
                    onSubmit={(e) => {
                      formikLogin.submitForm();
                      setIsSubmitting(true);
                      e.preventDefault();
                    }}
                  >
                    <Row>
                      <Col lg="12">
                        <Form.Group className="form-group">
                          <TextInputComponent
                            label="NIK"
                            id="nik_gtk"
                            placeholder="Masukkan NIK"
                            formik={formikLogin.getFieldProps("nik_gtk")}
                            isRequired={true}
                          />
                        </Form.Group>
                        {submitting &&
                          formikLogin?.touched &&
                          formikLogin?.errors &&
                          formikLogin?.touched.nik_gtk &&
                          formikLogin?.errors.nik_gtk && (
                            <div className="text-danger fs-6 mt-n2 mb-3">
                              {formikLogin.errors.nik_gtk}
                            </div>
                          )}
                      </Col>
                      <Col lg="12" md="12" className="">
                        <Form.Group className="form-group">
                          <TextInputComponent
                            label="Password"
                            id="password"
                            placeholder="Masukkan Password"
                            type="password"
                            formik={formikLogin.getFieldProps("password")}
                            isRequired={true}
                          />
                        </Form.Group>

                        {submitting &&
                          formikLogin?.touched &&
                          formikLogin?.errors &&
                          formikLogin?.touched.password &&
                          formikLogin?.errors.password && (
                            <div className="text-danger fs-6 mt-n2 mb-3">
                              {formikLogin.errors.password}
                            </div>
                          )}
                      </Col>
                      <Col lg="12" className="d-flex justify-content-between">
                        <CheckInputComponent
                          id="remember_me"
                          label="Ingat Saya"
                          onChange={(e) => {
                            const checked = e.target.checked;
                            formikLogin.setFieldValue("remember_me", checked);
                          }}
                        />
                        {/* <Link to="/auth/recoverpw">Lupa Password?</Link> */}
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center mb-3">
                      <Button
                        type="submit"
                        onClick={() => {
                          //formikLogin.submitForm();
                          //setIsSubmitting(true);
                        }}
                        className="col-12 btn btn-primary"
                        disabled={isLoadingPostAuthenticate}
                      >
                        Login
                        {isLoadingPostAuthenticate && (
                          <Spinner
                            animation="border"
                            variant="white"
                            size="sm"
                            className="ms-2"
                          />
                        )}
                      </Button>
                    </div>
                    <LogoJundezain />
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          md="6"
          className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden"
        >
          <Image
            src={auth1}
            className="Image-fluid gradient-main animated-scaleX"
            alt="images"
          />
        </Col>
      </Row>
    </section>
  );
};

export const LoginFormWrapper = () => <LoginForm />;
