import {
  FormProfilPayload,
  initialQueryResponse,
} from "../../../models/profil/_queryResponse";
import { useFormik } from "formik";
import * as Yup from "yup";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { postProfil } from "../../../api/profil/_request";
import { useAuth } from "../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { refetchCurrentUser } = useAuth();
  const [showModalProfil, setShowModalProfil] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailProfil, setDetailProfil] = useState<FormProfilPayload>(
    initialQueryResponse.detailProfil
  );

  useEffect(() => {
    if (resetForm) {
      setSubmitting(false);
      setResetForm(false);
      formikProfil.setValues(initialQueryResponse.detailProfil);
    }
  }, [resetForm]);

  const queryClient = useQueryClient();
  const { mutateAsync: PostProfil, isLoading: isLoadingPostProfil } =
    useMutation({
      mutationKey: `${QUERIES.POST_PROFIL}`,
      mutationFn: ({ payload }: { payload: FormData }) => postProfil(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_PROFIL}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostProfil = useCallback(
    async (payload: any) => {
      await PostProfil({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              setShowModalProfil(false);
              setResetForm(true);
              if (refetchCurrentUser) refetchCurrentUser();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostProfil]
  );

  const formikProfil = useFormik<FormProfilPayload>({
    initialValues: initialQueryResponse.detailProfil,
    enableReinitialize: false,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostProfil(payload);
    },
  });
  const value = useMemo(
    () => ({
      showModalProfil,
      setShowModalProfil,
      handlePostProfil,
      isLoadingPostProfil,
      detailProfil,
      setDetailProfil,
      submitting,
      setSubmitting,
      formikProfil,
      resetForm,
      setResetForm,
    }),
    [
      showModalProfil,
      setShowModalProfil,
      handlePostProfil,
      isLoadingPostProfil,
      detailProfil,
      setDetailProfil,
      submitting,
      setSubmitting,
      formikProfil,
      resetForm,
      setResetForm,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
