import { FC } from "react";

type Props = {
  data?: any;
};

const ColumnHeader: FC<Props> = (data) => {
  return (
    <thead>
      <tr className="fs-6">
        <th
          className="align-middle text-center cursor-pointer"
          style={{ width: "100px" }}
        >
          Unit
        </th>
        <th
          className="align-middle text-center cursor-pointer"
          style={{ width: "30px" }}
        >
          N
        </th>
        <th
          className="align-middle text-center cursor-pointer"
          style={{ width: "30px" }}
        >
          TTKD
        </th>
        <th
          className="align-middle text-center cursor-pointer"
          style={{ width: "30px" }}
        >
          TKD
        </th>
        <th
          className="align-middle text-center cursor-pointer"
          style={{ width: "30px" }}
        >
          TH
        </th>
        <th
          className="align-middle text-center cursor-pointer"
          style={{ width: "30px" }}
        >
          Total
        </th>
      </tr>
    </thead>
  );
};

export default ColumnHeader;
