import { Dispatch, SetStateAction } from "react";

export type QueryResponseContextProps<T> = {
  isShowModalTentukanKelompok: boolean;
  setIsShowModalTentukanKelompok: Dispatch<SetStateAction<boolean>>;
  isShowModalBuatAbsensi: boolean;
  setIsShowModalBuatAbsensi: Dispatch<SetStateAction<boolean>>;
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isShowModalTentukanKelompok: false,
  setIsShowModalTentukanKelompok: () => {},
  isShowModalBuatAbsensi: false,
  setIsShowModalBuatAbsensi: () => {},
  activeTab: 1,
  setActiveTab: () => {},
};
