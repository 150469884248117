import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListDaftarUnitKerja = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_DAFTAR_UNIT_KERJA_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postDaftarUnitKerja = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_DAFTAR_UNIT_KERJA_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailDaftarUnitKerja = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_DAFTAR_UNIT_KERJA_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteDaftarUnitKerja = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_DAFTAR_UNIT_KERJA_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListDaftarUnitKerja,
  postDaftarUnitKerja,
  getDetailDaftarUnitKerja,
  deleteDaftarUnitKerja,
};
