import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
};
export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
};
