import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormPendidikanPelatihanPayload,
  initialQueryResponse,
} from "../../../models/pendidikan-pelatihan/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/pendidikan-pelatihan";
import {
  deletePendidikanPelatihan,
  downloadDocPendidikanPelatihan,
  getDetailPendidikanPelatihan,
  getListPendidikanPelatihan,
  postPendidikanPelatihan,
} from "../../../api/pendidikan-pelatihan/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["jenis_diklat", "nama_diklat", "penyelenggara", "tahun", "peran", "tingkat", "berapa_jam"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalPendidikanPelatihan, setShowModalPendidikanPelatihan] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );
  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailPendidikanPelatihan.nik_gtk = nik_gtk;

  const [detailPendidikanPelatihan, setDetailPendidikanPelatihan] =
    useState<FormPendidikanPelatihanPayload>(
      initialQueryResponse.detailPendidikanPelatihan
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikPendidikanPelatihan.setValues(
        initialQueryResponse.detailPendidikanPelatihan
      );
      setDetailPendidikanPelatihan(
        initialQueryResponse.detailPendidikanPelatihan
      );
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_PENDIDIKAN_PELATIHAN}-${query}`,
    () => getListPendidikanPelatihan(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikPendidikanPelatihanValidationSchema = Yup.object().shape({
    jenis_diklat: Yup.string().required("Jenis Diklat Tidak Boleh Kosong"),
    nama_diklat: Yup.string().required("Nama Diklat Tidak Boleh Kosong"),
    penyelenggara: Yup.string().required(
      "Penyelenggara Diklat Tidak Boleh Kosong"
    ),
    tahun: Yup.string().required("Tahun Diklat Tidak Boleh Kosong"),
    peran: Yup.string().required("Peran Sebagai Tidak Boleh Kosong"),
    tingkat: Yup.string().required("Tingkat Diklat Tidak Boleh Kosong"),
    berapa_jam: Yup.string().required("Lama Diklat Tidak Boleh Kosong"),
    doc_sertifikat: Yup.mixed().required("Dokumen Tidak Boleh Kosong"),
  });

  const formikPendidikanPelatihan = useFormik<FormPendidikanPelatihanPayload>({
    initialValues: initialQueryResponse.detailPendidikanPelatihan,
    enableReinitialize: false,
    validationSchema: formikPendidikanPelatihanValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostPendidikanPelatihan(payload);
    },
  });

  const {
    mutateAsync: PostPendidikanPelatihan,
    isLoading: isLoadingPostPendidikanPelatihan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_PENDIDIKAN_PELATIHAN}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postPendidikanPelatihan(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_PENDIDIKAN_PELATIHAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostPendidikanPelatihan = useCallback(
    async (payload: any) => {
      await PostPendidikanPelatihan({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalPendidikanPelatihan(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostPendidikanPelatihan]
  );

  const {
    mutateAsync: GetDetailPendidikanPelatihan,
    isLoading: isLoadingGetDetailPendidikanPelatihan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_PENDIDIKAN_PELATIHAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailPendidikanPelatihan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_PENDIDIKAN_PELATIHAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailPendidikanPelatihan = useCallback(
    async (id: string) => {
      await GetDetailPendidikanPelatihan({ id })
        .then((res) => {
          setDetailPendidikanPelatihan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailPendidikanPelatihan]
  );

  const {
    mutateAsync: DownloadDocPendidikanPelatihan,
    isLoading: isLoadingDownloadDocPendidikanPelatihan,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_PENDIDIKAN_PELATIHAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocPendidikanPelatihan(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_PENDIDIKAN_PELATIHAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocPendidikanPelatihan = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocPendidikanPelatihan({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocPendidikanPelatihan]
  );

  const {
    mutateAsync: DeletePendidikanPelatihan,
    isLoading: isLoadingDeletePendidikanPelatihan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_PENDIDIKAN_PELATIHAN}`,
    mutationFn: ({ id }: { id: string }) => deletePendidikanPelatihan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_PENDIDIKAN_PELATIHAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeletePendidikanPelatihan = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeletePendidikanPelatihan({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  handleGetDetailGTK(res.data.nik_gtk);
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeletePendidikanPelatihan]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalPendidikanPelatihan,
      setShowModalPendidikanPelatihan,
      formikPendidikanPelatihan,
      formikPendidikanPelatihanValidationSchema,
      submitting,
      setSubmitting,
      handlePostPendidikanPelatihan,
      isLoadingPostPendidikanPelatihan,
      detailPendidikanPelatihan,
      setDetailPendidikanPelatihan,
      handleGetDetailPendidikanPelatihan,
      isLoadingGetDetailPendidikanPelatihan,
      resetForm,
      setResetForm,
      handleDownloadDocPendidikanPelatihan,
      isLoadingDownloadDocPendidikanPelatihan,
      handleDeletePendidikanPelatihan,
      isLoadingDeletePendidikanPelatihan,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalPendidikanPelatihan,
      setShowModalPendidikanPelatihan,
      formikPendidikanPelatihan,
      formikPendidikanPelatihanValidationSchema,
      submitting,
      setSubmitting,
      handlePostPendidikanPelatihan,
      isLoadingPostPendidikanPelatihan,
      detailPendidikanPelatihan,
      setDetailPendidikanPelatihan,
      handleGetDetailPendidikanPelatihan,
      isLoadingGetDetailPendidikanPelatihan,
      resetForm,
      setResetForm,
      handleDownloadDocPendidikanPelatihan,
      isLoadingDownloadDocPendidikanPelatihan,
      handleDeletePendidikanPelatihan,
      isLoadingDeletePendidikanPelatihan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
