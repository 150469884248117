import { Card } from "react-bootstrap";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import DaftarPersensiGTK from "./absensi-harian/daftar-persensi-gtk";
import { QueryRequestProvider } from "../../../provider/query-request/absensi-gtk";
import LaporanBulananGTK from "./laporan-absensi-harian/laporan-gtk-perbulan";
import RekapGTK from "./laporan-absensi-bulanan/rekapitulasi-presensi-gtk";
import ModalImportPresensi from "../modal/modal-import-presensi";
import ModalBuatAbsensi from "../../absensi-bpi/modal/buat-absensi";
import { QueryRequestProvider as QueryRequestProviderDaftarAbsensi } from "../../../provider/query-request/absensi-gtk/absensi-hari-ini";

import { QueryRequestProvider as QueryRequestProviderLaporanHarian } from "../../../provider/query-request/absensi-gtk/laporan-harian";
import { QueryResponseProvider as QueryResponseProviderLaporanHarian } from "../../../provider/query-response/absensi-gtk/laporan-harian";

import { QueryRequestProvider as QueryRequestProviderLaporanBulanan } from "../../../provider/query-request/absensi-gtk/laporan-bulanan";
import { QueryResponseProvider as QueryResponseProviderLaporanBulanan } from "../../../provider/query-response/absensi-gtk/laporan-bulanan";

import { QueryRequestProvider as QueryRequestProviderRekapAbsensiGTK } from "../../../provider/query-request/absensi-gtk/rekap-absensi";
import { QueryResponseProvider as QueryResponseProviderRekapAbsensiGTK } from "../../../provider/query-response/absensi-gtk/rekap-absensi";

import ToolbarChecklistFilter from "../modal/export-excel";
import RekapAbsensiGTK from "./rekap-absensi";

const DaftarPersensiGTKList = () => {
  const { ActiveTab, setActiveTab } = useQueryResponse();
  return (
    <>
      <Card.Body className="pt-3">
        <ToolbarChecklistFilter />
        <div className="m-2 col pb-5">
          <ModalImportPresensi />
        </div>

        <button
          type="button"
          className={` ${
            ActiveTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Presensi Harian GTK
        </button>

        <button
          type="button"
          className={` ${
            ActiveTab === 2
              ? "bg-dark text-white border border-dark"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Presensi GTK Tidak Hadir
        </button>

        <button
          type="button"
          className={` ${
            ActiveTab === 3
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(3);
          }}
        >
          Rekapitulasi Presensi GTK
        </button>

        {ActiveTab === 1 && <DaftarPersensiGTK />}
        {ActiveTab === 2 && <LaporanBulananGTK />}
        {ActiveTab === 3 && <RekapAbsensiGTK />}
      </Card.Body>
    </>
  );
};

export const DaftarPersensiGTKListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryRequestProviderDaftarAbsensi>
        <QueryRequestProviderLaporanHarian>
          <QueryResponseProviderLaporanHarian>
            <QueryRequestProviderLaporanBulanan>
              <QueryResponseProviderLaporanBulanan>
                <QueryRequestProviderRekapAbsensiGTK>
                  <QueryResponseProviderRekapAbsensiGTK>
                    <DaftarPersensiGTKList />
                  </QueryResponseProviderRekapAbsensiGTK>
                </QueryRequestProviderRekapAbsensiGTK>
              </QueryResponseProviderLaporanBulanan>
            </QueryRequestProviderLaporanBulanan>
          </QueryResponseProviderLaporanHarian>
        </QueryRequestProviderLaporanHarian>
      </QueryRequestProviderDaftarAbsensi>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
