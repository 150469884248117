import { Form, Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/hak-akses/kelola-role";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import { useEffect } from "react";

const ModalEntryRoles = () => {
  const {
    isShowModal,
    setIsShowModal,
    setResetForm,
    submitting,
    setSubmitting,
    formikRoles,
    isLoadingPostHakAkses,
    detailRoles,
    isLoadingGetDetailHakAkses,
  } = useQueryResponse();

  useEffect(() => {
    if (detailRoles && detailRoles.id) {
      formikRoles?.setValues(detailRoles);
    }
  }, [detailRoles]);

  return (
    <Modal
      backdrop="static"
      show={isShowModal}
      onBackdropClick={() => {
        setIsShowModal(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setIsShowModal(false);
        setResetForm(true);
      }}
    >
      {(isLoadingPostHakAkses || isLoadingGetDetailHakAkses) && (
        <FetchLoading />
      )}
      <Modal.Header closeButton>
        <Modal.Title>
          {detailRoles && detailRoles.id ? "Edit" : "Tambah"} Level Akses
        </Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikRoles?.submitForm();
          e.preventDefault();
        }}
      >
        <Modal.Body className="">
          <TextInputComponent
            label="Nama Level"
            placeholder="Masukkan Nama Level"
            isRequired
            formik={formikRoles?.getFieldProps("name")}
            value={formikRoles?.getFieldProps("name").value ?? ""}
          />
          {submitting && formikRoles?.errors.name && (
            <label className="mt-2 text-danger fs-6">
              {formikRoles?.errors.name}
            </label>
          )}
        </Modal.Body>
        <Modal.Footer className="bg-light d-flex flex-row justify-content-end">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="fa fa-floppy-disk me-2"></i>Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalEntryRoles;
