import { FC } from "react";
import { useQueryResponse } from "../../../../provider/query-response/dashboard";
import { useQueryRequest } from "../../../../provider/query-request/dashboard/detail-perubahan-status-kepegawaian";

type Props = {
  className: string;
  status_golongan_kepegawaian: string;
  menuju: string;
  data?: Array<any>;
  jumlah: number;
};

const ColumnBody: FC<Props> = ({
  className,
  status_golongan_kepegawaian,
  menuju,
  data,
  jumlah,
}) => {
  const { setIsShowDetailPerubahanStatusKepegawaian } = useQueryResponse();

  const { updateState } = useQueryRequest();

  const { setCurrentStatusGolongan } = useQueryResponse();

  return (
    <>
      {data?.map((val, idx) => {
        return (
          <td
            className={`text-center cursor-pointer ${
              val.total_gtk > 0 && className
            } `}
            onClick={() => {
              let filter = [
                {
                  name: "unit_kerja",
                  value: `${val.unit_kerja}`,
                  table: "gtk",
                },
                {
                  name: "status_golongan_kepegawaian",
                  value: `${status_golongan_kepegawaian}`,
                  table: "kepegawaian",
                },
                {
                  name: "is_current",
                  value: true,
                  table: "kepegawaian",
                },
              ];

              updateState({
                search: "",
                size: 10,
                page: 1,
                sort_by: "",
                sort_type: "asc",
                filter: JSON.stringify(filter),
              });
              setIsShowDetailPerubahanStatusKepegawaian(true);
              const data: any = {
                unit_kerja: val.unit_kerja,
                status_golongan_kepegawaian,
                menuju,
              };
              setCurrentStatusGolongan(data);
            }}
          >
            {val.total_gtk}
          </td>
        );
      })}
      <th className="text-center">{jumlah}</th>
    </>
  );
};

export default ColumnBody;
