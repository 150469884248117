import { FC, createContext, useContext, useEffect, useMemo, useState } from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { initialQueryState } from "../../../../models/global";
import { useQuery, useQueryClient } from "react-query";
import { useQueryRequest } from "../../../query-request/absensi-gtk/laporan-bulanan";
import { initialQueryResponse } from "../../../../models/absensi-gtk/laporan-bulanan/_queryResponse";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { getLaporanAbsensiBulanan } from "../../../../api/absensi-gtk/_request";
import { count } from "console";

import { useFormik } from "formik";
import * as Yup from "yup";




const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  
    const queryClient = useQueryClient();
    const { state, updateState } = useQueryRequest();

    state.col_header = `["nik_gtk", "gtk.nama_gtk", "gtk.unit_kerja", "gtk.email", "gtk.nomor_hp", "jam_masuk", "jam_keluar", "scan_masuk", "scan_keluar", "status_absen", "tanggal_absen", "keterangan"]`;
    
    const [query, setQuery] = useState<string>(
        stringifyRequestQuery(state, "laporan-absensi-bulanan")
      );
      const updatedQuery = useMemo(
        () => stringifyRequestQuery(state, "laporan-absensi-bulanan"),
        [state]
      );
    
      useEffect(() => {
        if (query !== updatedQuery) {
          setQuery(updatedQuery);
        }
      }, [query, updatedQuery]);
    
      /*useEffect(() => {
        if (resetForm) {
          formikAnak.setValues(initialQueryResponse.detailAnak);
          setDetailAnak(initialQueryResponse.detailAnak);
          setSubmitting(false);
          setResetForm(false);
        }
      }, [resetForm]);*/
    
      const {
        data: listData,
        isFetching: isLoadingFetchData,
        refetch: refetchListData,
      } = useQuery(`${QUERIES.GET_LAPORAN_BULANAN_ABSENSI_GTK}-${query}`, () => getLaporanAbsensiBulanan(query), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onError: (err) => {
          console.error(err);
        },
        enabled: query !== "?undefined",
      });

    const [isShowModalBuatAbsensiGTK, setShowModalImportAbsensiGTK] =
    useState<boolean>(false);

  const [ActiveTab, setActiveTab] = useState<number>(1);
  const value = useMemo(
    () => ({
      query,
      isLoadingFetchData,
      listData, 
      refetchListData,
      setShowModalImportAbsensiGTK,
      isShowModalBuatAbsensiGTK,
      ActiveTab,
      setActiveTab,
    }),
    [
      query,
      isLoadingFetchData,
      listData, 
      refetchListData,
      setShowModalImportAbsensiGTK,
      isShowModalBuatAbsensiGTK,
      ActiveTab,
      setActiveTab,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
