import clsx from "clsx";
import { HeaderProps } from "react-table";
import { FC, PropsWithChildren, useMemo } from "react";

type Props = {
  className?: string;
  title?: string;
  tableProps?: PropsWithChildren<HeaderProps<any>>;
  queryRequest?: () => any;
  isSorting?: boolean;
  rowSpan?: number;
  colSpan?: number;
  headerId?: string;
};

export const Header: FC<Props> = ({
  className,
  title,
  tableProps,
  queryRequest,
  isSorting = false,
  rowSpan,
  colSpan,
  headerId,
}) => {
  const id = tableProps?.column?.id ?? headerId;
  const sortState = queryRequest ? queryRequest() : undefined;

  const isSelectedForSorting = useMemo(() => {
    return sortState?.state.sort_by && sortState?.state.sort_by === id;
  }, [sortState?.state, id]);

  const order: "asc" | "desc" | undefined = useMemo(
    () => sortState?.state.sort_type,
    [sortState?.state]
  );

  const sortColumn = () => {
    if (isSorting) {
      if (id === "actions" || id === "selection") {
        return;
      }

      if (!isSelectedForSorting && sortState?.state) {
        sortState.updateState({ sort_by: id, sort_type: "asc" });
        return;
      }

      if (isSelectedForSorting && order !== undefined) {
        if (order === "asc") {
          sortState.updateState({ sort_by: id, sort_type: "desc" });
          return;
        }
        if (order === "desc") {
          sortState.updateState({ sort_by: id, sort_type: "asc" });
          return;
        }
      }
    }
  };

  return (
    <th
      rowSpan={rowSpan}
      colSpan={colSpan}
      {...tableProps?.column.getHeaderProps()}
      style={{
        cursor: isSorting ? "pointer" : "default",
        whiteSpace: "nowrap",
        fontSize: "11px",
      }}
      onClick={sortColumn}
      className={
        clsx(
          className,
          queryRequest && !(isSelectedForSorting && order !== undefined)
            ? "table-sort"
            : `table-sort-${order}`
        ) +
        `table-header ${
          sortState?.state.sort_type && sortState?.state.sort_by === id
            ? "bg-light"
            : ""
        }`
      }
    >
      <div
        className="d-flex flex-row justify-content-center align-items-center gap-3 "
        style={{
          height: "100%",
        }}
      >
        <label
          style={{ fontSize: 14, cursor: isSorting ? "pointer" : "default" }}
        >
          {title}
        </label>
        {queryRequest && isSorting && (
          <i
            className={`fas fa-sort-${
              sortState?.state.sort_type && sortState?.state.sort_by === id
                ? sortState?.state.sort_type
                : "asc"
            }`}
          />
        )}
      </div>
    </th>
  );
};
