import { Accordion, Card } from "react-bootstrap";
import { ShowLimitDT } from "../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../hope-ui/components/table/SearchInput";
import { useQueryRequest } from "../../../../provider/query-request/data-gtk";
import { useQueryResponse } from "../../../../provider/query-response/data-gtk";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import { useParams } from "react-router-dom";
const ToolbarChecklistFilter = () => {
  const { state, updateState } = useQueryRequest();
  const {
    setShowModal,
    allTableFilterGTKChecked,
    handleExportExcelDataGTK,
    isLoadingExportExcelDataGTK,
    handleExportPDFDataGTK,
    isLoadingExportPDFDataGTK,
  } = useQueryResponse();
  const { status } = useParams();
  return (
    <div>
      {(isLoadingExportExcelDataGTK || isLoadingExportPDFDataGTK) && (
        <FetchLoading />
      )}
      {status === "aktif" && (
        <Card.Header className="d-flex justify-content-end gap-3 py-3 border border-b-1 bg-light overflow-auto">
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() =>
              window.open(`${process.env.REACT_APP_BASE_URL}data-gtk/form`)
            }
          >
            <i className="far fa-plus me-1"></i> Tambah GTK
          </button>

          <button
            type="button"
            className="btn btn-sm btn-dark"
            onClick={() => setShowModal(true)}
          >
            <i className="fas fa-sliders me-1"></i> Set Filter
          </button>

          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (handleExportExcelDataGTK) handleExportExcelDataGTK();
            }}
          >
            <i className="far fa-file-excel me-1"></i> Export Excel
          </button>

          {/* <button
            type="button"
            className="btn btn-sm btn-warning"
            onClick={() => {
              if (handleExportPDFDataGTK) handleExportPDFDataGTK();
            }}
          >
            <i className="fas fa-file-pdf me-1"></i> Export PDF
          </button> */}
        </Card.Header>
      )}

      <div className="row pt-3 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default ToolbarChecklistFilter;
