import { Accordion, Col, Row } from "react-bootstrap";
import Select2MultiSelect from "../../../hope-ui/components/select-2-multiselect";
import { SearchInputDT } from "../../../hope-ui/components/table/SearchInput";
import { ShowLimitDT } from "../../../hope-ui/components/table/ShowLimit";
import { useQueryRequest } from "../../../provider/query-request/absensi-gtk";
import Select2 from "../../../hope-ui/components/select-2";
import { useQueryResponse } from "../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { semuaBulan, semuaTahun } from "../../../helpers/helpers";
import { useAuth } from "../../../provider/core/auth";
import { useEffect } from "react";

const Toolbar = () => {

    const { state, updateState } = useQueryRequest();
    const { currentUser } = useAuth();

    return (
        <>
    <div className="row pt-4 px-4 mb-0 pb-0" style={{marginTop:"35px",}}>
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
    </div>

        </>
    );


}

export default Toolbar;