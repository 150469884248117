import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../provider/query-response/profil";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../provider/query-response/detail-gtk";
import avatars1 from "../../../assets/images/avatars/01.png";
import FileInputComponent from "../../hope-ui/components/file-input";
import { useEffect } from "react";
import { useAuth } from "../../provider/core/auth";

const ModalProfil = () => {
  const { currentUser } = useAuth();
  const { showModalProfil, setShowModalProfil, formikProfil, setResetForm } =
    useQueryResponse();
  const { detailGTK } = useQueryResponseDetailGTK();

  useEffect(() => {
    if (detailGTK && showModalProfil)
      formikProfil?.setFieldValue("username", detailGTK?.nik_gtk);
  }, [detailGTK, showModalProfil]);

  return (
    <Modal
      backdrop="static"
      show={showModalProfil}
      onBackdropClick={() => {
        setShowModalProfil(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalProfil(false);
        setResetForm(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Foto Profil</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formikProfil?.submitForm();
        }}
      >
        <Modal.Body>
          <Row className="gy-4">
            <Col lg="12" className="d-flex justify-content-center">
              <img
                src={detailGTK?.poto?.toString() ?? avatars1}
                alt="profile-pic"
                className="img-fluid rounded-2"
                style={{
                  maxHeight: 300,
                }}
              />
            </Col>
            {currentUser?.data?.username === detailGTK?.nik_gtk && (
              <Col lg="12">
                <FileInputComponent
                  acceptLabel=""
                  label="Unggah Foto Profil"
                  acceptedType={[".png", ".jpg", ".jpeg", ".webp", ".svg"]}
                  required
                  onChange={(e) => {
                    if (e.target.files) {
                      formikProfil?.setFieldValue("poto", e.target.files[0]);
                    } else formikProfil?.setFieldValue("poto", null);
                  }}
                  fileNameLabel={
                    formikProfil?.getFieldProps("poto").value?.name
                  }
                  resetField={
                    formikProfil?.getFieldProps("poto").value ? false : true
                  }
                  maxFileSize={25}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
        {currentUser?.data?.username === detailGTK?.nik_gtk && (
          <Modal.Footer>
            <button type="submit" className="btn btn-primary btn-sm">
              Simpan
            </button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};
export default ModalProfil;
