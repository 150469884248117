import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns";
import { useQueryRequest } from "../../../../provider/query-request/absensi-gtk";
import {
  useQueryResponsePagination,
  useQueryResponse,
} from "../../../../provider/query-response/absensi-gtk/laporan-harian";
import "react-calendar/dist/Calendar.css";
import Toolbar from "./toolbar";
import ModelCatatanTerlambatGTK from "../model-catatan-gtk-terlambat";

const LaporanBulananGTK = () => {
  const { listData, isLoadingFetchData } = useQueryResponse();
  return (
    <>
      <h5 className="m-3">Daftar gtk tidak hadir</h5>

      <Toolbar />
      <ModelCatatanTerlambatGTK />
      <DataTable
        isLoading={isLoadingFetchData}
        queryRequest={useQueryRequest}
        pagination={useQueryResponsePagination}
        header={Columns}
        data={listData?.data}
      />
    </>
  );
};

export default LaporanBulananGTK;
