import { DataTable } from "../../../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns/columns";
import { useQueryRequest } from "../../../../../provider/query-request/memo";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../../provider/query-response/memo";
import { FC } from "react";
import Toolbar from "./toolbar";

type Props = {
  activeTab: number;
};

const ListMemo: FC<Props> = ({ activeTab }) => {
  const { listData, isLoadingFetchData, isShowDetailMemo } = useQueryResponse();
  const Data_table = listData?.data ?? [];
  return (
    <div
      style={{
        display: activeTab === 2 && !isShowDetailMemo ? "block" : "none",
      }}
    >
      <Toolbar />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={Data_table}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </div>
  );
};

export default ListMemo;
