import { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getJenisPTK = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_JENIS_PTK_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getStatusGolonganKepegawaian = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_STATUS_GOLONGAN_KEPEGAWAIAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getKompetensiKhusus = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_KOMPETENSI_KHUSUS_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getJenisKepegawaian = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_JENIS_KEPEGAWAIAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getUnitKerja = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_MASTER_UNIT_KERJA_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getStatusPerkawinan = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_STATUS_PERKAWINAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getLevelBPI = async (query?: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LEVEL_BPI_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getJenisPTK,
  getStatusGolonganKepegawaian,
  getKompetensiKhusus,
  getJenisKepegawaian,
  getUnitKerja,
  getStatusPerkawinan,
  getLevelBPI,
};
