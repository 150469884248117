import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";

export type FormMasterDendaGTKPayload = {
  id: string | null; 
  jenis_denda: string | null;
  maksimal: string | null;
  minimal: string | null;
  jumlah_nominal: string | null;
  keterangan: string | null;
  parameter: string | null;

}

export type QueryResponseContextProps<T> = {

  ShowModalDendaPersensiGTK: boolean;
  setShowModalDendaPersensiGTK: Dispatch<SetStateAction<boolean>>;
  ShowModalReadDendaPersensiGTK: boolean;
  setShowModalReadDendaPersensiGTK: Dispatch<SetStateAction<boolean>>;
  ShowModalUpdateDendaPersensiGTK: boolean;
  setShowModalUpdateDendaPersensiGTK: Dispatch<SetStateAction<boolean>>;
  ShowModalDeleteDendaPersensiGTK: boolean;
  setShowModalDeleteDendaPersensiGTK: Dispatch<SetStateAction<boolean>>;
  query?: string;
  listData?: Response<Array<T>>;
  refetchListData?: () => void;
  isLoadingFetchData: boolean;

  
};

export const initialQueryResponse: QueryResponseContextProps<any> = {

  ShowModalDendaPersensiGTK: false,
  setShowModalDendaPersensiGTK: () => {},
  ShowModalReadDendaPersensiGTK: false,
  setShowModalReadDendaPersensiGTK: () => {},
  ShowModalUpdateDendaPersensiGTK: false,
  setShowModalUpdateDendaPersensiGTK: () => {},
  ShowModalDeleteDendaPersensiGTK: false,
  setShowModalDeleteDendaPersensiGTK: () => {},
  isLoadingFetchData: false,
  
};
