import { FC } from "react";

type Props = {
  className?: string;
};
const KeteranganWarnaPresensiGTK: FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <label className="mb-1">Keterangan :</label>
      <div
        className={`d-flex flex-row justify-content-start flex-wrap align-items-center gap-2 `}
      >
        <label className="bg-success text-white fs-6 p-1 px-3 rounded-3">
          N = Normal
        </label>

        <label className="bg-yellow text-white fs-6 p-1 px-3 rounded-3">
          TTKD = Terlambat Tidak Kena Denda
        </label>
        <label className="bg-danger text-white fs-6 p-1 px-3 rounded-3">
          TKD = Terlambat Kena Denda
        </label>
        <label className="bg-gray text-white fs-6 p-1 px-3 rounded-3">
          TH = Tidak Hadir
        </label>
      </div>
    </div>
  );
};

export default KeteranganWarnaPresensiGTK;
