import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/riwayat-pendidikan";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import moment from "moment";
import dayjs from "dayjs";
import FileInputComponent from "../../../../hope-ui/components/file-input";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import NumberInputComponent from "../../../../hope-ui/components/number-input";

const ModalRiwayatPendidikan = () => {
  const {
    showModalRiwayatPendidikan,
    setShowModalRiwayatPendidikan,
    formikRiwayatPendidikan,
    submitting,
    setSubmitting,
    detailRiwayatPendidikan,
    setResetForm,
    handleDownloadDocRiwayatPendidikan,
    isLoadingGetDetailRiwayatPendidikan,
    isLoadingPostRiwayatPendidikan,
    isLoadingDownloadDocRiwayatPendidikan,
  } = useQueryResponse();

  useEffect(() => {
    if (detailRiwayatPendidikan.id) {
      formikRiwayatPendidikan?.setValues(detailRiwayatPendidikan);
    }
  }, [detailRiwayatPendidikan]);

  let doc_transkrip_nilai_name;
  let doc_ijazah_name;
  if (
    typeof formikRiwayatPendidikan?.getFieldProps("doc_transkrip_nilai")
      .value === "string"
  ) {
    const pecah = formikRiwayatPendidikan
      ?.getFieldProps("doc_transkrip_nilai")
      .value.split("/");
    doc_transkrip_nilai_name = pecah[pecah.length - 1];
  }

  if (
    typeof formikRiwayatPendidikan?.getFieldProps("doc_ijazah").value ===
    "string"
  ) {
    const pecah1 = formikRiwayatPendidikan
      ?.getFieldProps("doc_ijazah")
      .value.split("/");
    doc_ijazah_name = pecah1[pecah1.length - 1];
  }

  return (
    <Modal
      backdrop="static"
      show={showModalRiwayatPendidikan}
      onBackdropClick={() => {
        setShowModalRiwayatPendidikan(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalRiwayatPendidikan(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikRiwayatPendidikan?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailRiwayatPendidikan.id ? "Edit" : "Tambah"} Pendidikan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailRiwayatPendidikan ||
            isLoadingPostRiwayatPendidikan ||
            isLoadingDownloadDocRiwayatPendidikan) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Jurusan"
                placeholder="Masukkan Jurusan"
                isRequired
                formik={formikRiwayatPendidikan?.getFieldProps("jurusan")}
                value={
                  formikRiwayatPendidikan?.getFieldProps("jurusan").value ?? ""
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.jurusan && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.jurusan}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Jenjang Pendidikan"
                placeholder="Masukkan Jenjang Pendidikan"
                isRequired
                formik={formikRiwayatPendidikan?.getFieldProps(
                  "jenjang_pendidikan"
                )}
                value={
                  formikRiwayatPendidikan?.getFieldProps("jenjang_pendidikan")
                    .value ?? ""
                }
              />
              {submitting &&
                formikRiwayatPendidikan?.errors.jenjang_pendidikan && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRiwayatPendidikan?.errors.jenjang_pendidikan}
                  </div>
                )}
            </Col>
            <Col lg="6">
              <NumberInputComponent
                label="Tahun Masuk"
                placeholder="Masukkan Tahun Masuk"
                required
                formik={formikRiwayatPendidikan?.getFieldProps("tahun_masuk")}
                value={
                  formikRiwayatPendidikan?.getFieldProps("tahun_masuk").value ??
                  ""
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.tahun_masuk && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.tahun_masuk}
                </div>
              )}
            </Col>
            <Col lg="6">
              <NumberInputComponent
                label="Tahun Lulus"
                placeholder="Masukkan Tahun Lulus"
                required
                formik={formikRiwayatPendidikan?.getFieldProps("tahun_lulus")}
                value={
                  formikRiwayatPendidikan?.getFieldProps("tahun_lulus").value ??
                  ""
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.tahun_lulus && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.tahun_lulus}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Gelar Akademik"
                placeholder="Masukkan Gelar Akademik"
                isRequired
                formik={formikRiwayatPendidikan?.getFieldProps(
                  "gelar_akademik"
                )}
                value={
                  formikRiwayatPendidikan?.getFieldProps("gelar_akademik")
                    .value ?? ""
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.gelar_akademik && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.gelar_akademik}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Nama Perguruan Tinggi"
                placeholder="Masukkan Nama Perguruan Tinggi"
                isRequired
                formik={formikRiwayatPendidikan?.getFieldProps(
                  "nama_perguruan_tinggi"
                )}
                value={
                  formikRiwayatPendidikan?.getFieldProps(
                    "nama_perguruan_tinggi"
                  ).value ?? ""
                }
              />
              {submitting &&
                formikRiwayatPendidikan?.errors.nama_perguruan_tinggi && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRiwayatPendidikan?.errors.nama_perguruan_tinggi}
                  </div>
                )}
            </Col>
            <Col lg="6">
              <NumberInputComponent
                label="IPK"
                placeholder="Masukkan IPK"
                required
                formik={formikRiwayatPendidikan?.getFieldProps("ipk")}
                value={
                  formikRiwayatPendidikan?.getFieldProps("ipk").value ?? ""
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.ipk && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.ipk}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="No. Ijazah"
                placeholder="Masukkan No. Ijazah"
                isRequired
                formik={formikRiwayatPendidikan?.getFieldProps("nomor_ijazah")}
                value={
                  formikRiwayatPendidikan?.getFieldProps("nomor_ijazah")
                    .value ?? ""
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.nomor_ijazah && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.nomor_ijazah}
                </div>
              )}
            </Col>
            <Col lg="6">
              <FileInputComponent
                acceptLabel="Format File PDF"
                label="Upload Dokumen Transkrip Nilai"
                acceptedType={[".pdf"]}
                required
                onChange={(e) => {
                  if (e.target.files) {
                    formikRiwayatPendidikan?.setFieldValue(
                      "doc_transkrip_nilai",
                      e.target.files[0]
                    );
                  } else
                    formikRiwayatPendidikan?.setFieldValue(
                      "doc_transkrip_nilai",
                      null
                    );
                }}
                fileNameLabel={
                  formikRiwayatPendidikan?.getFieldProps("doc_transkrip_nilai")
                    .value?.name ?? doc_transkrip_nilai_name
                }
                resetField={
                  formikRiwayatPendidikan?.getFieldProps("doc_transkrip_nilai")
                    .value
                    ? false
                    : true
                }
                maxFileSize={25}
                linkPreview={
                  typeof formikRiwayatPendidikan?.getFieldProps(
                    "doc_transkrip_nilai"
                  ).value === "string"
                    ? formikRiwayatPendidikan?.getFieldProps(
                        "doc_transkrip_nilai"
                      ).value
                    : null
                }
                downloadPreview={() =>
                  handleDownloadDocRiwayatPendidikan(
                    formikRiwayatPendidikan?.getFieldProps(
                      "doc_transkrip_nilai"
                    ).value,
                    doc_transkrip_nilai_name
                  )
                }
              />
              {submitting &&
                formikRiwayatPendidikan?.errors.doc_transkrip_nilai && (
                  <div className="fs-6 text-danger mt-2">
                    {formikRiwayatPendidikan?.errors.doc_transkrip_nilai}
                  </div>
                )}
            </Col>

            <Col lg="6">
              <FileInputComponent
                acceptLabel="Format File PDF"
                label="Upload Dokumen Ijazah"
                acceptedType={[".pdf"]}
                required
                onChange={(e) => {
                  if (e.target.files) {
                    formikRiwayatPendidikan?.setFieldValue(
                      "doc_ijazah",
                      e.target.files[0]
                    );
                  } else
                    formikRiwayatPendidikan?.setFieldValue("doc_ijazah", null);
                }}
                fileNameLabel={
                  formikRiwayatPendidikan?.getFieldProps("doc_ijazah").value
                    ?.name ?? doc_ijazah_name
                }
                resetField={
                  formikRiwayatPendidikan?.getFieldProps("doc_ijazah").value
                    ? false
                    : true
                }
                maxFileSize={25}
                linkPreview={
                  typeof formikRiwayatPendidikan?.getFieldProps("doc_ijazah")
                    .value === "string"
                    ? formikRiwayatPendidikan?.getFieldProps("doc_ijazah").value
                    : null
                }
                downloadPreview={() =>
                  handleDownloadDocRiwayatPendidikan(
                    formikRiwayatPendidikan?.getFieldProps(
                      "doc_transkrip_nilai"
                    ).value,
                    doc_transkrip_nilai_name
                  )
                }
              />
              {submitting && formikRiwayatPendidikan?.errors.doc_ijazah && (
                <div className="fs-6 text-danger mt-2">
                  {formikRiwayatPendidikan?.errors.doc_ijazah}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalRiwayatPendidikan;
