import { Form, Modal } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/data-gtk";
import { useQueryResponse as useQueryResponseDetailGTK } from "../../../../provider/query-response/detail-gtk";
import { useEffect } from "react";
import FetchLoadingCompanyLogo from "../../../../hope-ui/components/fetch-loading-with-company-logo";
import GTKCard from "../../../../hope-ui/components/gtk-card";
import Select2 from "../../../../hope-ui/components/select-2";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const ModalNonAktifkanGTK = () => {
  const {
    showModalNonAktifkanGTK,
    setShowModalNonAktifkanGTK,
    handleGetAllSebabNonAktif,
    isLoadingGetAllSebabNonAktif,
    allSebabNonAktif,
    formikSebabNonAktif,
    submitting,
    setSubmitting,
    isLoadingPostSebabNonAktif,
  } = useQueryResponse();

  const { nikGTK, detailGTK, handleGetDetailGTK, isLoadingGetDetailGTK } =
    useQueryResponseDetailGTK();

  useEffect(() => {
    if (showModalNonAktifkanGTK && nikGTK) {
      handleGetDetailGTK(nikGTK);
      handleGetAllSebabNonAktif();
      formikSebabNonAktif?.setFieldValue("nik_gtk", nikGTK);
      formikSebabNonAktif?.setFieldValue("sebab_non_aktif", null);
      setSubmitting(false);
    }
  }, [
    showModalNonAktifkanGTK,
    nikGTK,
    handleGetAllSebabNonAktif,
    handleGetDetailGTK,
  ]);

  const options = allSebabNonAktif.map((val) => {
    return {
      value: val.sebab_non_aktif,
      label: val.sebab_non_aktif,
    };
  });

  return (
    <Modal
      backdrop="static"
      show={showModalNonAktifkanGTK}
      onBackdropClick={() => {
        setShowModalNonAktifkanGTK(false);
      }}
      size="lg"
      onHide={() => setShowModalNonAktifkanGTK(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Non Aktifkan GTK</Modal.Title>
      </Modal.Header>
      <Form
        onSubmit={(e) => {
          setSubmitting(true);
          formikSebabNonAktif?.submitForm();
          e.preventDefault();
        }}
      >
        <Modal.Body>
          {isLoadingPostSebabNonAktif && <FetchLoading />}
          {isLoadingGetDetailGTK ? (
            <FetchLoadingCompanyLogo />
          ) : (
            <>
              <GTKCard
                data={detailGTK}
                //poto={detailGTK?.akun?.poto?.toString()}
              />
              <Select2
                placeholder="Pilih Sebab Non Aktif"
                label="Sebab Non Aktif"
                isRequired
                isLoading={isLoadingGetAllSebabNonAktif}
                options={options}
                value={
                  formikSebabNonAktif?.getFieldProps("sebab_non_aktif").value
                }
                onChange={(e: any) => {
                  const value = e?.value;
                  formikSebabNonAktif?.setFieldValue("sebab_non_aktif", value);
                }}
              />
              {submitting && formikSebabNonAktif?.errors.sebab_non_aktif && (
                <div className="text-danger mt-2">
                  {formikSebabNonAktif.errors.sebab_non_aktif}
                </div>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="fa fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalNonAktifkanGTK;
