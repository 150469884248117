import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { initialQueryState } from "../../../../models/global";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useQueryRequest } from "../../../query-request/absensi-gtk";
import {
  FormAbsensiGtkPayload,
  FormCatatanGtkPayload,
  initialQueryResponse,
} from "../../../../models/absensi-gtk/daftar-absensi-gtk/_queryResponse";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import {
  exportExcelAbsensiGTKInput,
  getAbsensiGTK,
  getDetailAbsensiGtk,
  postImportPresensiGTK,
  putCatatanGtk,
  postCatatanGtk,
  getDetailCatatanAbsensiGtk,
} from "../../../../api/absensi-gtk/_request";

import { count } from "console";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { Dayjs } from "dayjs";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state, updateState } = useQueryRequest();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [DetailAbsensiGtk, setDetailAbsensiGtk] =
    useState<FormAbsensiGtkPayload>(initialQueryResponse.DetailAbsensiGtk);
  const [DetailCatatanAbsensiGtk, setDetailCatatanAbsensiGtk] =
    useState<FormCatatanGtkPayload>(
      initialQueryResponse.DetailCatatanAbsensiGtk
    );

  const [isShowModalBuatAbsensiGTK, setShowModalImportAbsensiGTK] =
    useState<boolean>(false);

  const [CatatanGtk, setCatatanGtk] = useState<boolean>(false);
  const [tanggalAbsen, setTanggalAbsen] = useState<
    Date | Dayjs | string | null
  >(new Date());

  const [nikGTK, setNIKGTK] = useState<string>("");

  state.col_header = `["nik_gtk", "gtk.nama_gtk", "gtk.unit_kerja", "gtk.email", "gtk.nomor_hp", "jam_masuk", "jam_keluar", "scan_masuk", "scan_keluar", "status_absen", "tanggal_absen", "keterangan"]`;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_ABSENSI_GTK}-${query}`,
    () => getAbsensiGTK(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const [gagalImportPresensiGTK, setGagalImportPresensiGTK] = useState<any>();

  const {
    mutateAsync: PostImportPresensiGTK,
    isLoading: isLoadingPostImportPresensiGTK,
  } = useMutation({
    mutationKey: `${QUERIES.POST_IMPORT_PRESENSI_GTK}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postImportPresensiGTK(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_IMPORT_PRESENSI_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostImportPresensiGTK = useCallback(
    async (payload: any) => {
      await PostImportPresensiGTK({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed) {
              if (refetchListData && res.success) {
                setShowModalImportAbsensiGTK(false);
                setResetForm(true);
                refetchListData();
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
              const data_gagal_import = res.data_gagal_import;
              setGagalImportPresensiGTK(data_gagal_import);
            }
          });
        });
    },
    [PostImportPresensiGTK]
  );

  const { mutateAsync: GetDetailAbsensiGTK, isLoading: isLoadingGetDetailGTK } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_ABSENSI_GTK}`,
      mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
        getDetailAbsensiGtk(nik_gtk),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.GET_DETAIL_ABSENSI_GTK}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleCatatanGtk = useCallback(
    async (nik_gtk: string) => {
      await GetDetailAbsensiGTK({ nik_gtk })
        .then((res) => {
          if (res.success) {
            setDetailAbsensiGtk(res.data);
          } else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              html: res.message,
              allowOutsideClick: false,
            });
            return;
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAbsensiGTK]
  );

  const {
    mutateAsync: GetCatatanDetailAbsensiGTK,
    isLoading: isLoadingGetDetailCatatanGTK,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_CATATAN_ABSENSI_GTK}`,
    mutationFn: ({
      nik_gtk,
      tanggal_absen,
      id_absensi_gtk,
    }: {
      nik_gtk: string;
      tanggal_absen: string;
      id_absensi_gtk?: string;
    }) => getDetailCatatanAbsensiGtk(nik_gtk, tanggal_absen, id_absensi_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_CATATAN_ABSENSI_GTK}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleCatatanAbsensiGtk = useCallback(
    async (nik_gtk: string, tanggal_absen: string, id_absensi_gtk?: string) => {
      await GetCatatanDetailAbsensiGTK({
        nik_gtk,
        tanggal_absen,
        id_absensi_gtk,
      })
        .then((res) => {
          if (res.success) {
            setDetailCatatanAbsensiGtk(res.data);
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetCatatanDetailAbsensiGTK]
  );

  const { mutateAsync: PutCatatanGtk, isLoading: isLoadingPutCatatanGtk } =
    useMutation({
      mutationKey: `${QUERIES.PUT_CATATAN_ABSENSI_GTK}`,
      mutationFn: ({ payload, id }: { payload: FormData; id: string }) =>
        putCatatanGtk(payload, id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.PUT_CATATAN_ABSENSI_GTK}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePutCatatanGtk = useCallback(
    async (payload: any, id: string) => {
      await PutCatatanGtk({ payload, id })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success && refetchListData) {
              refetchListData();
              setResetForm(true);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [resetForm]
  );

  const { mutateAsync: PostCatatanGtk, isLoading: isLoadingPostCatatanGtk } =
    useMutation({
      mutationKey: `${QUERIES.POST_CATATAN_ABSENSI_GTK}`,
      mutationFn: ({ payload }: { payload: FormData }) =>
        postCatatanGtk(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([
            `${QUERIES.POST_CATATAN_ABSENSI_GTK}`,
          ]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostCatatanGtk = useCallback(
    async (payload: any) => {
      await PostCatatanGtk({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed) {
              if (refetchListData && res.success) {
                setCatatanGtk(false);
                setResetForm(true);
                refetchListData();
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [postCatatanGtk]
  );

  const {
    mutateAsync: ExportExcelAbsensiGTKInput,
    isLoading: isLoadingExportExcelAbsensiGTKInput,
  } = useMutation({
    mutationKey: `${QUERIES.EXPORT_EXCEL_DATA_GTK}`,
    mutationFn: () => exportExcelAbsensiGTKInput(),
    onSuccess: async (res: any, variables) => {
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.EXPORT_EXCEL_DATA_GTK}`,
        ]);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleExportExcelAbsensiGTKInput = useCallback(async () => {
    await ExportExcelAbsensiGTKInput().catch((error) => {
      SweetAlert({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: error.data ? error.data.message : error,
        allowOutsideClick: false,
      }).then((res: any) => {
        if (res.isConfirmed) {
        }
      });
    });
  }, [ExportExcelAbsensiGTKInput]);

  const [ActiveTab, setActiveTab] = useState<number>(1);
  const value = useMemo(
    () => ({
      handlePostImportPresensiGTK,
      isLoadingPostImportPresensiGTK,
      handlePostCatatanGtk,
      setGagalImportPresensiGTK,
      gagalImportPresensiGTK,
      DetailAbsensiGtk,
      setDetailAbsensiGtk,
      handleCatatanGtk,
      DetailCatatanAbsensiGtk,
      setDetailCatatanAbsensiGtk,
      handleCatatanAbsensiGtk,
      isLoadingGetDetailCatatanGTK,
      query,
      isLoadingFetchData,
      listData,
      refetchListData,
      setShowModalImportAbsensiGTK,
      isShowModalBuatAbsensiGTK,
      isLoadingPutCatatanGtk,
      isLoadingPostCatatanGtk,
      ActiveTab,
      setActiveTab,
      CatatanGtk,
      setCatatanGtk,
      nikGTK,
      setNIKGTK,
      handleExportExcelAbsensiGTKInput,
      isLoadingExportExcelAbsensiGTKInput,
      isLoadingGetDetailGTK,
      handlePutCatatanGtk,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      tanggalAbsen,
      setTanggalAbsen,
    }),
    [
      handlePutCatatanGtk,
      handlePostImportPresensiGTK,
      handlePostCatatanGtk,
      isLoadingPostImportPresensiGTK,
      setGagalImportPresensiGTK,
      gagalImportPresensiGTK,
      DetailAbsensiGtk,
      setDetailAbsensiGtk,
      handleCatatanGtk,
      DetailCatatanAbsensiGtk,
      setDetailCatatanAbsensiGtk,
      handleCatatanAbsensiGtk,
      isLoadingGetDetailCatatanGTK,
      isLoadingPutCatatanGtk,
      isLoadingPostCatatanGtk,
      setShowModalImportAbsensiGTK,
      isShowModalBuatAbsensiGTK,
      ActiveTab,
      setActiveTab,
      CatatanGtk,
      setCatatanGtk,
      query,
      isLoadingFetchData,
      listData,
      refetchListData,
      nikGTK,
      setNIKGTK,
      handleExportExcelAbsensiGTKInput,
      isLoadingExportExcelAbsensiGTKInput,
      isLoadingGetDetailGTK,
      submitting,
      setSubmitting,
      resetForm,
      setResetForm,
      tanggalAbsen,
      setTanggalAbsen,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
