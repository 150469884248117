import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/anak";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import moment from "moment";
import dayjs from "dayjs";
import FileInputComponent from "../../../../hope-ui/components/file-input";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import Select2 from "../../../../hope-ui/components/select-2";
import InputRadio from "../../../../hope-ui/components/input-radio";
import { semuaTahun } from "../../../../helpers/helpers";

const ModalAnak = () => {
  const optionsTahun = new semuaTahun();
  const {
    showModalAnak,
    setShowModalAnak,
    formikAnak,
    submitting,
    setSubmitting,
    detailAnak,
    setResetForm,
    handleGetAllJenjangPendidikan,
    isLoadingGetDetailAnak,
    isLoadingPostAnak,
    isLoadingGetAllJenjangPendidikan,
    allJenjangPendidikan,
  } = useQueryResponse();

  useEffect(() => {
    if (showModalAnak) {
      handleGetAllJenjangPendidikan();
    }
  }, [showModalAnak]);

  useEffect(() => {
    if (detailAnak.id) {
      formikAnak?.setValues(detailAnak);
    }
  }, [detailAnak]);

  const optionsJenjangPendidikan = allJenjangPendidikan.map((val) => {
    return {
      value: val.name,
      label: val.name,
    };
  });

  return (
    <Modal
      backdrop="static"
      show={showModalAnak}
      onBackdropClick={() => {
        setShowModalAnak(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalAnak(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikAnak?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{detailAnak.id ? "Edit" : "Tambah"} Anak</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailAnak || isLoadingPostAnak) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Nama Anak"
                placeholder="Masukkan Nama Anak"
                isRequired
                formik={formikAnak?.getFieldProps("nama_anak")}
                value={formikAnak?.getFieldProps("nama_anak").value ?? ""}
              />
              {submitting && formikAnak?.errors.nama_anak && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.nama_anak}
                </div>
              )}
            </Col>
            <Col lg="6">
              <InputRadio
                label="Jenis Kelamin"
                data={[
                  {
                    id: "jk_Laki-laki_anak",
                    label: "Laki-laki",
                    value: "Laki-laki",
                  },
                  {
                    id: "jk_Perempuan_anak",
                    label: "Perempuan",
                    value: "Perempuan",
                  },
                ]}
                required
                value={formikAnak?.getFieldProps("jenis_kelamin_anak").value}
                onChange={(e) => {
                  const value = e.target.value;
                  formikAnak?.setFieldValue("jenis_kelamin_anak", value);
                }}
              />
              {submitting && formikAnak?.errors.jenis_kelamin_anak && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.jenis_kelamin_anak}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Tempat Lahir"
                placeholder="Masukkan Tempat Lahir"
                isRequired
                formik={formikAnak?.getFieldProps("tempat_lahir")}
                value={formikAnak?.getFieldProps("tempat_lahir").value ?? ""}
              />
              {submitting && formikAnak?.errors.tempat_lahir && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.tempat_lahir}
                </div>
              )}
            </Col>
            <Col lg="6">
              <Datepicker
                label="Tanggal Lahir"
                required
                value={moment(
                  formikAnak?.getFieldProps("tanggal_lahir").value
                ).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  const value = dayjs(e).format("YYYY-MM-DD");
                  formikAnak?.setFieldValue("tanggal_lahir", value);
                }}
              />
              {submitting && formikAnak?.errors.tanggal_lahir && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.tanggal_lahir}
                </div>
              )}
            </Col>

            <Col lg="6">
              <Select2
                placeholder="Pilih Status Anak"
                label="Status Anak"
                isRequired
                options={[
                  {
                    value: "Anak Kandung",
                    label: "Anak Kandung",
                  },
                  {
                    value: "Anak Non Kandung",
                    label: "Anak Non Kandung",
                  },
                ]}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnak?.setFieldValue("status_anak", value);
                }}
                value={formikAnak?.getFieldProps("status_anak").value}
              />
              {submitting && formikAnak?.errors.status_anak && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.status_anak}
                </div>
              )}
            </Col>

            <Col lg="6">
              <Select2
                placeholder="Pilih Jenjang Pendidikan"
                label="Jenjang Pendidikan"
                isRequired
                options={optionsJenjangPendidikan}
                isLoading={isLoadingGetAllJenjangPendidikan}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikAnak?.setFieldValue("jenis_pendidikan", value);
                }}
                value={formikAnak?.getFieldProps("jenis_pendidikan").value}
              />
              {submitting && formikAnak?.errors.jenis_pendidikan && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.jenis_pendidikan}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="NISN"
                placeholder="Masukkan NISN"
                formik={formikAnak?.getFieldProps("nisn")}
                value={formikAnak?.getFieldProps("nisn").value ?? ""}
              />
              {submitting && formikAnak?.errors.nisn && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.nisn}
                </div>
              )}
            </Col>
            <Col lg="6">
              <Select2
                label="Tahun Masuk"
                placeholder="Pilih Tahun Masuk"
                isClearable
                options={optionsTahun.data}
                onChange={(e) => {
                  const value: any = e ? e["value"] : null;
                  formikAnak?.setFieldValue("tahun_masuk", value);
                }}
                value={parseInt(formikAnak?.getFieldProps("tahun_masuk").value)}
              />
              {submitting && formikAnak?.errors.tahun_masuk && (
                <div className="fs-6 text-danger mt-2">
                  {formikAnak?.errors.tahun_masuk}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAnak;
