import { Column } from "react-table";
import { Header } from "../../../../hope-ui/components/table/Header";
import { StatusSertifikasiBahasaInggrisCell } from "./StatusSertifikasiBahasaInggrisCell";
import { useQueryRequest } from "../../../../provider/query-request/data-gtk";
import NamaGTKCell from "../../../../hope-ui/components/table/NamaGTKCell";
import KirimMemo from "./KirimMemo";
import AksiCell from "./AksiCell";
import { useQueryResponsePagination } from "../../../../provider/query-response/data-gtk";
import { useParams } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";

let _status;

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nik_gtk}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Lengkap"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <NamaGTKCell
        id={props.data[props.row.index].nik_gtk}
        label={props.data[props.row.index].nama_gtk}
      />
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Unit Kerja"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].unit_kerja}
      </label>
    ),
  },
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Hak Akses"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "role_name",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].role_name}
      </label>
    ),
  },
  {
    Header: (props) => {
      const { status } = useParams();
      _status = status;
      return (
        <>
          {status === "aktif" ? (
            <Header
              tableProps={props}
              title={"Progress Pengisian"}
              className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
              queryRequest={useQueryRequest}
              isSorting={false}
            />
          ) : (
            <Header
              tableProps={props}
              title={"Sebab Non Aktif"}
              className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
              queryRequest={useQueryRequest}
              isSorting={true}
            />
          )}
        </>
      );
    },
    id: _status === "aktif" ? "progress_bar" : "sebab_non_aktif",
    Cell: ({ ...props }) => {
      const { status } = useParams();
      return (
        <>
          {status === "aktif" ? (
            <ProgressBar
              variant="success"
              now={props.data[props.row.index].progress_bar}
              style={{
                width: "100%",
              }}
              label={`${props.data[props.row.index].progress_bar}%`}
            ></ProgressBar>
          ) : (
            <label className="fw-normal text-dark pe-3 text-center w-100">
              {props.data[props.row.index].sebab_non_aktif}
            </label>
          )}
        </>
      );
    },
  },

  {
    Header: (props) => {
      const { status } = useParams();
      return (
        <>
          {status === "aktif" && (
            <Header
              tableProps={props}
              title="Aksi"
              className="align-top max-w-100px  text-start px-4 pb-0 fs-6"
            />
          )}
        </>
      );
    },
    id: "action_kirim_memo",
    Cell: ({ ...props }) => {
      const { status } = useParams();
      return (
        <>
          {status === "aktif" && (
            <AksiCell data={props.data[props.row.index]} />
          )}
        </>
      );
    },
  },
];
