import { FC } from "react";
import { useQueryResponse } from "../../../../../../provider/query-response/absensi-lt3q/nilai-tahsin";
import SwitchInputComponent from "../../../../../../hope-ui/components/switch-input/SwitchInputComponent";

type Props = {
  nik_gtk: string;
  data?: any;
};

export const IsUjianCell: FC<Props> = ({ nik_gtk, data }) => {
  let index: any;
  const { formikNilaiTahsinLT3Q } = useQueryResponse();
  const value = formikNilaiTahsinLT3Q?.getFieldProps("detail").value;

  const find = value?.find((element) => element.nik_gtk === nik_gtk);
  if (find) {
    index = value.indexOf(find);
  }

  const is_ujian = formikNilaiTahsinLT3Q?.getFieldProps(
    `detail[${index}].is_ujian`
  ).value;

  const label = formikNilaiTahsinLT3Q?.getFieldProps(
    `detail[${index}].is_ujian`
  ).value
    ? "Ya"
    : "Tidak";

  return (
    <div className="px-4">
      <SwitchInputComponent
        id={`is_ujian_${index}`}
        checked={is_ujian}
        onChange={(e) => {
          formikNilaiTahsinLT3Q?.setFieldValue(
            `detail[${index}].is_ujian`,
            !is_ujian
          );

          if (is_ujian === true) {
            formikNilaiTahsinLT3Q?.setFieldValue(
              `detail[${index}].nilai_tilawah`,
              ""
            );

            formikNilaiTahsinLT3Q?.setFieldValue(
              `detail[${index}].nilai_teori`,
              ""
            );

            formikNilaiTahsinLT3Q?.setFieldValue(
              `detail[${index}].keterangan`,
              ""
            );
          }
        }}
        label={label}
      />
    </div>
  );
};
