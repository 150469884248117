import { Column } from "react-table";

import { Header } from "../../../../../../../hope-ui/components/table/Header";
import NamaGTKCell from "../../../../../../../hope-ui/components/table/NamaGTKCell";
import { useQueryRequest } from "../../../../../../../provider/query-request/absensi-lt3q/kelompok-lt3q/kelola-kelompok";
import { AksiCell } from "./AksiCell";
import { useQueryResponsePagination } from "../../../../../../../provider/query-response/absensi-lt3q/kelompok-lt3q/kelola-kelompok";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Nama Kelompok"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_kelompok_lt3q",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-center w-100">
        {props.data[props.row.index].nama_kelompok_lt3q}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => (
      <AksiCell
        id={props.data[props.row.index].id}
        data={props.data[props.row.index]}
      />
    ),
  },
];
