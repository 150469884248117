import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/mutasi";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import moment from "moment";
import dayjs from "dayjs";
import FileInputComponent from "../../../../hope-ui/components/file-input";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";

const ModalMutasi = () => {
  const {
    showModalMutasi,
    setShowModalMutasi,
    formikMutasi,
    submitting,
    setSubmitting,
    detailMutasi,
    setResetForm,
    handleDownloadDocMutasi,
    isLoadingGetDetailMutasi,
    isLoadingPostMutasi,
    isLoadingDownloadDocMutasi,
  } = useQueryResponse();

  useEffect(() => {
    if (detailMutasi.id) {
      formikMutasi?.setValues(detailMutasi);
    }
  }, [detailMutasi]);

  let doc_mutasi_name;
  if (typeof formikMutasi?.getFieldProps("doc_mutasi").value === "string") {
    const pecah = formikMutasi?.getFieldProps("doc_mutasi").value.split("/");
    doc_mutasi_name = pecah[pecah.length - 1];
  }

  return (
    <Modal
      backdrop="static"
      show={showModalMutasi}
      onBackdropClick={() => {
        setShowModalMutasi(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalMutasi(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikMutasi?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailMutasi.id ? "Edit" : "Tambah"} Mutasi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailMutasi ||
            isLoadingPostMutasi ||
            isLoadingDownloadDocMutasi) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="No. Surat Mutasi"
                placeholder="Masukkan No. Surat Mutasi"
                isRequired
                formik={formikMutasi?.getFieldProps("nomor_surat_mutasi")}
                value={
                  formikMutasi?.getFieldProps("nomor_surat_mutasi").value ?? ""
                }
              />
              {submitting && formikMutasi?.errors.nomor_surat_mutasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.nomor_surat_mutasi}
                </div>
              )}
            </Col>
            <Col lg="6">
              <Datepicker
                label="Tanggal Mutasi"
                required
                value={moment(
                  formikMutasi?.getFieldProps("tanggal_mutasi").value
                ).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  const value = dayjs(e).format("YYYY-MM-DD");
                  formikMutasi?.setFieldValue("tanggal_mutasi", value);
                }}
              />
              {submitting && formikMutasi?.errors.tanggal_mutasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.tanggal_mutasi}
                </div>
              )}
            </Col>
            <Col lg="6">
              <Datepicker
                label="Tanggal TMT Mutasi"
                required
                value={moment(
                  formikMutasi?.getFieldProps("tmt_mutasi").value
                ).format("YYYY-MM-DD")}
                onChange={(e: any) => {
                  const value = dayjs(e).format("YYYY-MM-DD");
                  formikMutasi?.setFieldValue("tmt_mutasi", value);
                }}
              />
              {submitting && formikMutasi?.errors.tmt_mutasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.tmt_mutasi}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Unit Kerja Sebelumnya"
                placeholder="Masukkan Unit Kerja Sebelumnya"
                isRequired
                formik={formikMutasi?.getFieldProps("unit_kerja_sebelumnya")}
                value={
                  formikMutasi?.getFieldProps("unit_kerja_sebelumnya").value ??
                  ""
                }
              />
              {submitting && formikMutasi?.errors.unit_kerja_sebelumnya && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.unit_kerja_sebelumnya}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Unit Kerja Tujuan"
                placeholder="Masukkan Unit Kerja Tujuan"
                isRequired
                formik={formikMutasi?.getFieldProps("unit_kerja_tujuan")}
                value={
                  formikMutasi?.getFieldProps("unit_kerja_tujuan").value ?? ""
                }
              />
              {submitting && formikMutasi?.errors.unit_kerja_tujuan && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.unit_kerja_tujuan}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextareaComponent
                label="Alasan Mutasi"
                placeholder="Masukkan Alasan Mutasi"
                required
                rows={3}
                formik={formikMutasi?.getFieldProps("alasan_mutasi")}
                value={formikMutasi?.getFieldProps("alasan_mutasi").value ?? ""}
              />
              {submitting && formikMutasi?.errors.alasan_mutasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.alasan_mutasi}
                </div>
              )}
            </Col>
            <Col lg="12">
              <FileInputComponent
                acceptLabel="Format File PDF"
                label="Upload Dokumen"
                acceptedType={[".pdf"]}
                required
                onChange={(e) => {
                  if (e.target.files) {
                    formikMutasi?.setFieldValue(
                      "doc_mutasi",
                      e.target.files[0]
                    );
                  } else formikMutasi?.setFieldValue("doc_mutasi", null);
                }}
                fileNameLabel={
                  formikMutasi?.getFieldProps("doc_mutasi").value?.name ??
                  doc_mutasi_name
                }
                resetField={
                  formikMutasi?.getFieldProps("doc_mutasi").value ? false : true
                }
                maxFileSize={25}
                linkPreview={
                  typeof formikMutasi?.getFieldProps("doc_mutasi").value ===
                  "string"
                    ? formikMutasi?.getFieldProps("doc_mutasi").value
                    : null
                }
                downloadPreview={() =>
                  handleDownloadDocMutasi(
                    formikMutasi?.getFieldProps("doc_mutasi").value,
                    doc_mutasi_name
                  )
                }
              />
              {submitting && formikMutasi?.errors.doc_mutasi && (
                <div className="fs-6 text-danger mt-2">
                  {formikMutasi?.errors.doc_mutasi}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalMutasi;
