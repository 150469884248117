import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListPrihalAlasan = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_DATA_PRIHAL_ALASAN_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};


const getReadPrihalAlasan = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_READ_PRIHAL_ALASAN_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

/*
const getReadPrihalAlasan = async (id: string, prihal: string, alasan: string): Promise<any> => {
  return client
    .get(
      `${ApiConfig.GET_READ_PRIHAL_ALASAN_URL}?id=${id}&prihal=${prihal}&alasan=${alasan}`
    )
    .then((response: AxiosResponse<any>) => response.data);
};
*/

const postStore = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_STORE_PRIHAL_ALASAN_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailPrihalAlasan = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_PRIHAL_ALASAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};


const postDeletePrihalAlasan = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.POST_DELETE_PRIHAL_ALASAN_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListPrihalAlasan,
  getReadPrihalAlasan,
  postStore,
  getDetailPrihalAlasan,
  postDeletePrihalAlasan
};
