import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC, FocusEventHandler } from "react";
import { Form, InputGroup } from "react-bootstrap";

type Props = {
  id?: string;
  label?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  formik?: FieldInputProps<any>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  value?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  ref?: any;
  isSuffix?: boolean;
  isPrefix?: boolean;
  suffixText?: string;
  suffixIcon?: any;
  prefixText?: string;
  prefixIcon?: any;
};

const TextInputGroupComponent: FC<Props> = ({
  id = "",
  label = null,
  type = "text",
  placeholder = "",
  className = "",
  disabled = false,
  onChange,
  formik,
  isRequired = false,
  value = "",
  onBlur,
  ref,
  isSuffix,
  isPrefix,
  suffixText,
  prefixText,
  suffixIcon,
  prefixIcon,
}) => {
  return (
    <>
      {label && (
        <Form.Label className="">
          {label} {isRequired && <span className="text-danger"> *</span>}
        </Form.Label>
      )}
      <InputGroup className=" mb-3">
        {isPrefix && (
          <span className="input-group-text">
            {prefixText ? prefixText : prefixIcon ? prefixIcon : ""}
          </span>
        )}
        <Form.Control
          type={type}
          className={className}
          aria-describedby="basic-addon3"
          placeholder={placeholder}
          onChange={onChange}
          {...formik}
          disabled={disabled}
        />
        {isSuffix && (
          <span className="input-group-text">
            {suffixText ? suffixText : suffixIcon ? suffixIcon : ""}
          </span>
        )}
      </InputGroup>
    </>
  );
};

export default TextInputGroupComponent;
