import { FC } from "react";
import { useQueryResponse } from "../../../../../../provider/query-response/absensi-lt3q/nilai-tahsin";
import NumberInputComponent from "../../../../../../hope-ui/components/number-input";

type Props = {
  nik_gtk: string;
  data?: any;
};

export const NilaiTilawahCell: FC<Props> = ({ nik_gtk, data }) => {
  let index: any;
  const { formikNilaiTahsinLT3Q } = useQueryResponse();
  const value = formikNilaiTahsinLT3Q?.getFieldProps("detail").value;

  const find = value?.find((element) => element.nik_gtk === nik_gtk);
  if (find) {
    index = value.indexOf(find);
  }

  const is_ujian = formikNilaiTahsinLT3Q?.getFieldProps(
    `detail[${index}].is_ujian`
  ).value;

  return (
    <div className="px-1">
      <NumberInputComponent
        placeholder="Masukkan Nilai Tilawah"
        style={{ minWidth: "200px" }}
        value={
          formikNilaiTahsinLT3Q?.getFieldProps(`detail[${index}].nilai_tilawah`)
            .value ?? ""
        }
        formik={formikNilaiTahsinLT3Q?.getFieldProps(
          `detail[${index}].nilai_tilawah`
        )}
        readOnly={!is_ujian}
      />
    </div>
  );
};
