import { Form } from "react-bootstrap";
import { ChangeEventHandler, FC } from "react";
import { FieldInputProps } from "formik";

type Props = {
  id?: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  formik?: FieldInputProps<any>;
  className?: string;
  isRequired?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  labelClassName?: string;
  checked?: boolean;
};
const CheckInputComponent: FC<Props> = ({
  id = "",
  label = null,
  type = "checkbox",
  className = "",
  isRequired = false,
  formik,
  onChange,
  labelClassName,
  checked,
}) => {
  return (
    <Form.Check
      className={
        className && className.length === 0
          ? "form-check mb-3"
          : "form-check mb-3" + className
      }
    >
      <Form.Check.Input
        id={id}
        {...formik}
        onChange={onChange}
        checked={checked}
      />
      <Form.Check.Label
        htmlFor={id}
        className={labelClassName}
        style={{
          maxWidth: "100%",
        }}
      >
        {label}
      </Form.Check.Label>
    </Form.Check>
  );
};

export default CheckInputComponent;
