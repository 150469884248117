import { Column } from "react-table";
import { Header } from "../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../provider/query-request/prihal-alasan";
import { useQueryResponsePagination } from "../../../provider/query-response/prihal-alasan";
import AksiCell from "./aksi";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="PERIHAL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "prihal",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-start w-100">
        {props.data[props.row.index].prihal}
      </label>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="ALASAN"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "alasan",
    Cell: ({ ...props }) => (
      <label className="fw-normal text-dark pe-3 text-start w-100">
        {props.data[props.row.index].alasan}
      </label>
    ),
  },


  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => <AksiCell data={props.data[props.row.index]} />,
  },
];
