import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormPrihalAlasanPayload = {
  id: number | string | null;
  prihal: string | null;
  alasan: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalPrihalAlasan: boolean;
  setShowModalPrihalAlasan: Dispatch<SetStateAction<boolean>>;
  formikPrihalAlasan?: FormikProps<FormPrihalAlasanPayload>;
  formikPrihalAlasanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostPrihalAlasan: (data: FormData) => void;
  isLoadingPostPrihalAlasan: boolean;
  handleGetDetailPrihalAlasan: (id: string) => void;
  isLoadingGetDetailPrihalAlasan: boolean;
  detailPrihalAlasan: FormPrihalAlasanPayload;
  setDetailPrihalAlasan: Dispatch<SetStateAction<FormPrihalAlasanPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeletePrihalAlasan: (id: string) => void;
  isLoadingDeletePrihalAlasan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalPrihalAlasan: false,
  setShowModalPrihalAlasan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostPrihalAlasan: () => {},
  isLoadingPostPrihalAlasan: false,
  handleGetDetailPrihalAlasan: () => {},
  isLoadingGetDetailPrihalAlasan: false,
  detailPrihalAlasan: {
    id: null,
    prihal: null,
    alasan: null,
  },
  setDetailPrihalAlasan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDeletePrihalAlasan: () => {},
  isLoadingDeletePrihalAlasan: false,
};
