import { Column } from "react-table";
import { Header } from "../../../../hope-ui/components/table/Header";
import { useQueryRequest } from "../../../../provider/query-request/absensi-gtk";
import { useQueryResponsePagination } from "../../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import NamaGTKCell from "../../../../hope-ui/components/table/NamaGTKCell";
import moment from "moment";
// import AksiCell from "./AksiCell";
import { formatRupiah } from "../../../../helpers/helpers";
import AksiCatatanGTK from "../aksi-catatan";
import AksiCatatanTerlambatGTK from "../aksi-catatan-terlambat";

export const Columns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="No."
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
      />
    ),
    id: "no",
    Cell: ({ ...props }) => {
      const { offset } = useQueryResponsePagination();
      let page: any = offset;
      return (
        <label className="fw-normal text-dark pe-3 text-center w-100">
          {props.row.index + 1 + page}
        </label>
      );
    },
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NIK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nik_gtk",
    Cell: ({ ...props }) => (
      <div className="text-start">
        {(() => {
          if ("TKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-danger"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].nik_gtk}
              </label>
            );
          } else if ("TTKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-warning"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].nik_gtk}
              </label>
            );
          } else if ("On Time" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-success"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].nik_gtk}
              </label>
            );
          } else {
            return (
              <label
                className="ps-3 fs-6 text-drak"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].nik_gtk}
              </label>
            );
          }
        })()}
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="NAMA GTK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "nama_gtk",
    Cell: ({ ...props }) => (
      <div className="text-start">
        {(() => {
          if ("TKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="text-danger"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.nama_gtk}
              </label>
            );
          } else if ("TTKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="text-warning"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.nama_gtk}
              </label>
            );
          } else if ("On Time" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="text-success"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.nama_gtk}
              </label>
            );
          } else {
            return (
              <label
                className="text-drak"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.nama_gtk}
              </label>
            );
          }
        })()}
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="UNIT KERJA"
        className="ps-3 fs-6 text-start"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "unit_kerja",
    Cell: ({ ...props }) => (
      <div className="text-start">
        {(() => {
          if ("TKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-danger"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.unit_kerja}
              </label>
            );
          } else if ("TTKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-warning"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.unit_kerja}
              </label>
            );
          } else if ("On Time" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-success"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.unit_kerja}
              </label>
            );
          } else {
            return (
              <label
                className="ps-3 fs-6 text-drak"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].gtk.unit_kerja}
              </label>
            );
          }
        })()}
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="TANGGAL"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "tanggal_absen",
    Cell: ({ ...props }) => (
      <div className="text-start">
        {(() => {
          if ("TKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-danger"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].tanggal_absen}
              </label>
            );
          } else if ("TTKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-warning"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].tanggal_absen}
              </label>
            );
          } else if ("On Time" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-success"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].tanggal_absen}
              </label>
            );
          } else {
            return (
              <label
                className="ps-3 fs-6 text-drak"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].tanggal_absen}
              </label>
            );
          }
        })()}
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="SCAN MASUK"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "scan_masuk",
    Cell: ({ ...props }) => (
      <div className="text-start">
        {(() => {
          if ("TKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-danger"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_masuk}
              </label>
            );
          } else if ("TTKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-warning"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_masuk}
              </label>
            );
          } else if ("On Time" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-success"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_masuk}
              </label>
            );
          } else {
            return (
              <label
                className="ps-3 fs-6 text-drak"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_masuk}
              </label>
            );
          }
        })()}
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="SCAN KELUAR"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={true}
      />
    ),
    id: "scan_keluar",
    Cell: ({ ...props }) => (
      <div className="text-start">
        {(() => {
          if ("TKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-danger"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_keluar !== "Invalid date" &&
                  props.data[props.row.index].scan_keluar}
              </label>
            );
          } else if ("TTKD" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-warning"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_keluar !== "Invalid date" &&
                  props.data[props.row.index].scan_keluar}
              </label>
            );
          } else if ("On Time" === props.data[props.row.index].status_absen) {
            return (
              <label
                className="ps-3 fs-6 text-success"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_keluar !== "Invalid date" &&
                  props.data[props.row.index].scan_keluar}
              </label>
            );
          } else {
            return (
              <label
                className="ps-3 fs-6 text-drak"
                style={{ textAlign: "left", paddingLeft: "1rem" }}
              >
                {props.data[props.row.index].scan_keluar !== "Invalid date" &&
                  props.data[props.row.index].scan_keluar}
              </label>
            );
          }
        })()}
      </div>
    ),
  },

  {
    Header: (props) => (
      <Header
        tableProps={props}
        title="Aksi"
        className="ps-3 fs-6 text-center"
        queryRequest={useQueryRequest}
        isSorting={false}
      />
    ),
    id: "action",
    Cell: ({ ...props }) => (
      <AksiCatatanTerlambatGTK
        data={props.data[props.row.index]}
        nik_gtk={props.data[props.row.index].nik_gtk}
        id_absensi_gtk={props.data[props.row.index].id}
        tanggal_absen={props.data[props.row.index].tanggal_absen}
      />
    ),
  },
];
