import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormKaryaBukuPayload = {
  id: number | string | null;
  id_buku: string | null;
  nik_gtk: string | null;
  isbn: string | null;
  judul_buku: string | null;
  penerbit: string | null;
  tahun: number | string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalKaryaBuku: boolean;
  setShowModalKaryaBuku: Dispatch<SetStateAction<boolean>>;
  formikKaryaBuku?: FormikProps<FormKaryaBukuPayload>;
  formikKaryaBukuValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostKaryaBuku: (data: FormData) => void;
  isLoadingPostKaryaBuku: boolean;
  handleGetDetailKaryaBuku: (id: string) => void;
  isLoadingGetDetailKaryaBuku: boolean;
  detailKaryaBuku: FormKaryaBukuPayload;
  setDetailKaryaBuku: Dispatch<SetStateAction<FormKaryaBukuPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocKaryaBuku: (url: string, fileName: string) => void;
  isLoadingDownloadDocKaryaBuku: boolean;
  handleDeleteKaryaBuku: (id: string) => void;
  isLoadingDeleteKaryaBuku: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalKaryaBuku: false,
  setShowModalKaryaBuku: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostKaryaBuku: () => {},
  isLoadingPostKaryaBuku: false,
  handleGetDetailKaryaBuku: () => {},
  isLoadingGetDetailKaryaBuku: false,
  detailKaryaBuku: {
    id: null,
    id_buku: null,
    nik_gtk: null,
    isbn: "",
    judul_buku: "",
    penerbit: "",
    tahun: "",
  },
  setDetailKaryaBuku: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocKaryaBuku: () => {},
  isLoadingDownloadDocKaryaBuku: false,
  handleDeleteKaryaBuku: () => {},
  isLoadingDeleteKaryaBuku: false,
};
