import { FC } from "react";
import companyLogo from "../../../../assets/images/icons/icon-simbi.svg";

type Props = {
  width?: number;
  height?: number;
};

const CompanyLogo: FC<Props> = ({ width = 49, height = 47 }) => {
  return (
    <img src={companyLogo} alt="company_logo" width={width} height={height} />
  );
};

export default CompanyLogo;
