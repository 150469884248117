import { FC, Fragment, useEffect, useState } from "react";
import { useLayout } from "../../../layout/core/LayoutProvider";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { FormEntryGTKWrapper } from "../../../modules/data-gtk/entry";
import { ImportExcelWrapper } from "../../../modules/data-gtk/import-excel";

type Props = {
  useTab?: boolean;
};

const FormEntryGTK: FC<Props> = ({ useTab = true }) => {
  const { nik_gtk } = useParams();

  const [activeTab, setActiveTab] = useState(1);

  const { setSetting } = useLayout();
  useEffect(() => {
    if (useTab)
      if (activeTab === 1) {
        document.title = `Sistem Informasi Bina Ilmi | Form ${
          nik_gtk ? "Edit" : "Tambah"
        } GTK`;
        setSetting((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.page_breadcrumbs = `Form ${nik_gtk ? "Edit" : "Tambah"} GTK`;
          return jasper;
        });
      } else if (activeTab === 2) {
        document.title = `Sistem Informasi Bina Ilmi | Form Import Data GTK`;
        setSetting((prevState) => {
          let jasper = Object.assign({}, prevState);
          jasper.page_breadcrumbs = `Form Import Data GTK`;
          return jasper;
        });
      }
  }, [nik_gtk, activeTab]);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card className={!useTab ? "mb-0" : ""}>
            {useTab && (
              <Card.Header className="p-3">
                <button
                  type="button"
                  className={` ${
                    activeTab === 1
                      ? "bg-primary text-white border border-primary"
                      : "bg-white text-dark border border-white"
                  } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
                  onClick={() => setActiveTab(1)}
                >
                  Data
                </button>

                <button
                  type="button"
                  className={` ${
                    activeTab === 2
                      ? "bg-primary text-white border border-primary"
                      : "bg-white text-dark border border-white"
                  } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill `}
                  onClick={() => setActiveTab(2)}
                >
                  Import Excel
                </button>
              </Card.Header>
            )}

            <FormEntryGTKWrapper activeTab={activeTab} useTab={useTab} />
            <ImportExcelWrapper activeTab={activeTab} />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default FormEntryGTK;
