import { Card } from "react-bootstrap";
import { Fragment } from "react";
import { useQueryRequest as useQueryRequestDataGTK } from "../../../../provider/query-request/data-gtk";
import { useQueryResponse } from "../../../../provider/query-response/absensi-bpi";

const Toolbar = () => {
  const updateStateDataGTK = useQueryRequestDataGTK().updateState;
  const { setIsShowModalTentukanKelompok, setIsShowModalBuatAbsensi } =
    useQueryResponse();
  return (
    <Fragment>
      <Card.Header className="border border-b-1 py-3 bg-light">
        <div className="d-flex flex-row justify-content-end gap-3">
          <button
            type="button"
            className="btn btn-sm btn-info "
            onClick={() => {
              setIsShowModalTentukanKelompok(true);
              updateStateDataGTK({
                size: 999999999999,
              });
            }}
          >
            <i className="fa fa-users me-2"></i> Tentukan Kelompok
          </button>

          <button
            type="button"
            className="btn btn-sm btn-dark"
            onClick={() => {
              setIsShowModalBuatAbsensi(true);
            }}
          >
            <i className="far fa-edit me-2"></i> Buat Notulensi
          </button>
        </div>
      </Card.Header>
    </Fragment>
  );
};

export default Toolbar;
