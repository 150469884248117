import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const postInpassing = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_INPASSING_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export { postInpassing };
