import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { initialQueryResponse } from "../../../models/auth/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import {
  getCurrentUser,
  postAuthenticate,
  logout,
} from "../../../api/auth/_request";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { useLocation, useNavigate } from "react-router-dom";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import Cookies from "js-cookie";

const QueryResponseContext = createContext(initialQueryResponse);

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();

  const {
    data: currentUser,
    isLoading: isLoadingFetchCurrentUser,
    refetch: refetchCurrentUser,
  } = useQuery(QUERIES.GET_CURRENT_USER, () => getCurrentUser(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: location.pathname !== "/login",
  });
  const {
    mutateAsync: PostAuthenticate,
    isLoading: isLoadingPostAuthenticate,
  } = useMutation({
    mutationKey: `${QUERIES.POST_AUTHENTICATE}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postAuthenticate(payload),
    onSuccess: async (res: any, variables) => {
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_AUTHENTICATE}`]);
        if (res.success) {
          const data = res.data;
          localStorage.setItem("access_token", data.access_token);
          const menus = res.data.menus;
          if (menus[0]) {
            window.location.href = menus[0].route_name;
          }
        } else {
          SweetAlert({
            icon: "warning",
            title: "Login Gagal",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleAuthenticate = useCallback(
    async (payload: any) => {
      await PostAuthenticate({ payload }).catch((error) => {
        SweetAlert({
          icon: "error",
          title: "Terjadi Kesalahan",
          text: error.data ? error.data.errors.message : error,
          allowOutsideClick: false,
        }).then((res: any) => {
          if (res.isConfirmed) {
          }
        });
      });
    },
    [PostAuthenticate]
  );

  const { mutateAsync: Logout, isLoading: isLoadingLogout } = useMutation({
    mutationKey: `${QUERIES.LOGOUT}`,
    mutationFn: () => logout(),
    onSuccess: async (res: any) => {
      try {
        await queryClient.invalidateQueries([`${QUERIES.LOGOUT}`]);
        if (res.success) {
          localStorage.removeItem("access_token");
          //window.location.href = process.env.REACT_APP_BASE_URL + "login";
          window.location.replace(process.env.REACT_APP_BASE_URL + "login");
        } else {
          SweetAlert({
            icon: "warning",
            title: "Login Gagal",
            text: res.message,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleLogout = useCallback(async () => {
    await Logout().catch((error) => {
      SweetAlert({
        icon: "error",
        title: "Terjadi Kesalahan",
        text: error.data ? error.data.errors.message : error,
        allowOutsideClick: false,
      }).then((res: any) => {
        if (res.isConfirmed) {
        }
      });
    });
  }, [Logout]);

  const value = useMemo(
    () => ({
      currentUser,
      isLoadingFetchCurrentUser,
      handleAuthenticate,
      isLoadingPostAuthenticate,
      handleLogout,
      isLoadingLogout,
      refetchCurrentUser,
    }),
    [
      currentUser,
      isLoadingFetchCurrentUser,
      handleAuthenticate,
      isLoadingPostAuthenticate,
      handleLogout,
      isLoadingLogout,
      refetchCurrentUser,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};
const useAuth = () => useContext(QueryResponseContext);
export { AuthProvider, useAuth };
