import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../../helpers/react18MigrationHelpers";
import { useQueryRequest } from "../../../../query-request/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../../helpers/helpers";
import { QUERIES } from "../../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../../models/global";
import { SweetAlert } from "../../../../../hope-ui/components/sweet-alert";

import {
  DetailAnggotaKelompokBPI,
  FormAnggotaKelompokBPIPayload,
  initialQueryResponse,
} from "../../../../../models/absensi-bpi/anggota-kelompok-bpi/_queryResponse";
import {
  getAnggotaKelompokBPI,
  postAnggotaKelompokBPI,
  getDetailAnggotaKelompokBPI,
  getAllKelompokBPI,
  deleteAnggotaKelompokBPI,
  getListAnggotaKelompokBPI,
} from "../../../../../api/absensi-bpi/_request";

import { useQueryResponse as useQueryResponseAbsensiBPI } from "../../../absensi-bpi";
import { useAuth } from "../../../../core/auth";
import { FormKelompokBPIPayload } from "../../../../../models/absensi-bpi/kelompok-bpi/_queryResponse";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { isShowModalTentukanKelompok } = useQueryResponseAbsensiBPI();
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["kelompok_bpi.nama_kelompok_bpi", "nik_gtk", "gtk.nama_gtk", "nik_tutor_bpi", "level_bpi"]`;
  const { currentUser } = useAuth();
  state.nik_tutor_bpi = currentUser?.data?.username;

  const [resetForm, setResetForm] = useState<boolean>(false);
  const [detailAnggotaKelompokBPI, setDetailAnggotaKelompokBPI] =
    useState<any>();
  const [allKelompokBPI, setAllKelompokBPI] = useState<
    Array<FormKelompokBPIPayload>
  >([]);

  const [anggotaKelompokBPI, setAnggotaKelompokBPI] = useState<
    Array<DetailAnggotaKelompokBPI>
  >([]);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "anggota-kelompok-bpi")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "anggota-kelompok-bpi"),
    [state]
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    mutateAsync: PostAnggotaKelompokBPI,
    isLoading: isLoadingPostAnggotaKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.POST_KELOMPOK_BPI}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postAnggotaKelompokBPI(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.POST_KELOMPOK_BPI}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostAnggotaKelompokBPI = useCallback(
    async (payload: any) => {
      await PostAnggotaKelompokBPI({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                refetchListData();
                setResetForm(true);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostAnggotaKelompokBPI]
  );

  const {
    mutateAsync: GetDetailAnggotaKelompokBPI,
    isLoading: isLoadingGetDetailAnggotaKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => getDetailAnggotaKelompokBPI(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailAnggotaKelompokBPI = useCallback(
    async (id: string) => {
      await GetDetailAnggotaKelompokBPI({ id })
        .then((res) => {
          if (res.success) setDetailAnggotaKelompokBPI(res.data);
          else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              text: res.message,
              allowOutsideClick: false,
            }).then((swal: any) => {
              if (swal.isConfirmed) {
                //setDetailAnggotaKelompokBPI(res.data);
              }
            });
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAnggotaKelompokBPI]
  );

  const {
    mutateAsync: DeleteAnggotaKelompokBPI,
    isLoading: isLoadingDeleteAnggotaKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => deleteAnggotaKelompokBPI(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteAnggotaKelompokBPI = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (res) => {
        if (res.isConfirmed) {
          await DeleteAnggotaKelompokBPI({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed && res.success) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteAnggotaKelompokBPI]
  );

  const {
    mutateAsync: GetAllKelompokBPI,
    isLoading: isLoadingGetAllKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ALL_KELOMPOK_BPI}`,
    mutationFn: ({ nik_gtk }: { nik_gtk: string }) =>
      getAllKelompokBPI(nik_gtk),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ALL_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAllKelompokBPI = useCallback(
    async (nik_gtk: string) => {
      await GetAllKelompokBPI({ nik_gtk })
        .then((res) => {
          setAllKelompokBPI(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAllKelompokBPI]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}-${query}`,
    () => getAnggotaKelompokBPI(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalTentukanKelompok,
    }
  );

  const {
    mutateAsync: GetAnggotaKelompokBPI,
    isLoading: isLoadingGetAnggotaKelompokBPI,
  } = useMutation({
    mutationKey: `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}`,
    mutationFn: ({ id }: { id: string }) => getListAnggotaKelompokBPI(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_ANGGOTA_KELOMPOK_BPI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetAnggotaKelompokBPI = useCallback(
    async (id: string) => {
      await GetAnggotaKelompokBPI({ id })
        .then((res) => {
          setAnggotaKelompokBPI(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetAnggotaKelompokBPI]
  );

  const resetDetailAnggotaKelompokBPI = () => {
    setDetailAnggotaKelompokBPI({
      id: null,
      nama_kelompok_bpi: null,
      nik_gtk: null,
      level_bpi: null,
    });
  };

  const value = useMemo(
    () => ({
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostAnggotaKelompokBPI,
      isLoadingPostAnggotaKelompokBPI,
      resetForm,
      setResetForm,
      handleGetDetailAnggotaKelompokBPI,
      isLoadingGetDetailAnggotaKelompokBPI,
      detailAnggotaKelompokBPI,
      setDetailAnggotaKelompokBPI,
      resetDetailAnggotaKelompokBPI,
      handleGetAllKelompokBPI,
      isLoadingGetAllKelompokBPI,
      allKelompokBPI,
      setAllKelompokBPI,
      handleDeleteAnggotaKelompokBPI,
      isLoadingDeleteAnggotaKelompokBPI,
      anggotaKelompokBPI,
      setAnggotaKelompokBPI,
      handleGetAnggotaKelompokBPI,
      isLoadingGetAnggotaKelompokBPI,
    }),
    [
      listData,
      refetchListData,
      isLoadingFetchData,
      query,
      handlePostAnggotaKelompokBPI,
      isLoadingPostAnggotaKelompokBPI,
      resetForm,
      setResetForm,
      handleGetDetailAnggotaKelompokBPI,
      isLoadingGetDetailAnggotaKelompokBPI,
      detailAnggotaKelompokBPI,
      setDetailAnggotaKelompokBPI,
      resetDetailAnggotaKelompokBPI,
      handleGetAllKelompokBPI,
      isLoadingGetAllKelompokBPI,
      allKelompokBPI,
      setAllKelompokBPI,
      handleDeleteAnggotaKelompokBPI,
      isLoadingDeleteAnggotaKelompokBPI,
      anggotaKelompokBPI,
      setAnggotaKelompokBPI,
      handleGetAnggotaKelompokBPI,
      isLoadingGetAnggotaKelompokBPI,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
