import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../../provider/query-response/presensi-saya/presensi-lt3q";
import {
  QueryRequestProvider,
  useQueryRequest,
} from "../../../provider/query-request/presensi-saya/presensi-lt3q";
import { Card } from "react-bootstrap";
import Toolbar from "./toolbar";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { Columns } from "./list/columns";

const PresensiSayaLT3Q = () => {
  const { isLoadingFetchData, listData } = useQueryResponse();
  return (
    <div>
      <Toolbar />
      <Card.Body>
        <DataTable
          isLoading={isLoadingFetchData}
          header={Columns}
          data={listData?.data ?? []}
          queryRequest={useQueryRequest}
          isPagination={false}
        />
      </Card.Body>
    </div>
  );
};

export const PresensiSayaLT3QWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <PresensiSayaLT3Q />
    </QueryResponseProvider>
  </QueryRequestProvider>
);
