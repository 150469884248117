import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/pendidikan-pelatihan";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import Datepicker from "../../../../hope-ui/components/datepicker";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import moment from "moment";
import dayjs from "dayjs";
import FileInputComponent from "../../../../hope-ui/components/file-input";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import NumberInputComponent from "../../../../hope-ui/components/number-input";
import Select2 from "../../../../hope-ui/components/select-2";

const ModalPendidikanPelatihan = () => {
  const {
    showModalPendidikanPelatihan,
    setShowModalPendidikanPelatihan,
    formikPendidikanPelatihan,
    submitting,
    setSubmitting,
    detailPendidikanPelatihan,
    setResetForm,
    handleDownloadDocPendidikanPelatihan,
    isLoadingGetDetailPendidikanPelatihan,
    isLoadingPostPendidikanPelatihan,
    isLoadingDownloadDocPendidikanPelatihan,
  } = useQueryResponse();

  useEffect(() => {
    if (detailPendidikanPelatihan.id) {
      formikPendidikanPelatihan?.setValues(detailPendidikanPelatihan);
    }
  }, [detailPendidikanPelatihan]);

  let doc_sertifikat_name;
  if (
    typeof formikPendidikanPelatihan?.getFieldProps("doc_sertifikat").value ===
    "string"
  ) {
    const pecah = formikPendidikanPelatihan
      ?.getFieldProps("doc_sertifikat")
      .value.split("/");
    doc_sertifikat_name = pecah[pecah.length - 1];
  }

  return (
    <Modal
      backdrop="static"
      show={showModalPendidikanPelatihan}
      onBackdropClick={() => {
        setShowModalPendidikanPelatihan(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalPendidikanPelatihan(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikPendidikanPelatihan?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailPendidikanPelatihan.id ? "Edit" : "Tambah"} Pendidikan
            Pelatihan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailPendidikanPelatihan ||
            isLoadingPostPendidikanPelatihan ||
            isLoadingDownloadDocPendidikanPelatihan) && <FetchLoading />}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Jenis Diklat"
                placeholder="Masukkan Jenis Diklat"
                isRequired
                formik={formikPendidikanPelatihan?.getFieldProps(
                  "jenis_diklat"
                )}
                value={
                  formikPendidikanPelatihan?.getFieldProps("jenis_diklat")
                    .value ?? ""
                }
              />
              {submitting && formikPendidikanPelatihan?.errors.jenis_diklat && (
                <div className="fs-6 text-danger mt-2">
                  {formikPendidikanPelatihan?.errors.jenis_diklat}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Nama Diklat"
                placeholder="Masukkan Nama Diklat"
                isRequired
                formik={formikPendidikanPelatihan?.getFieldProps("nama_diklat")}
                value={
                  formikPendidikanPelatihan?.getFieldProps("nama_diklat")
                    .value ?? ""
                }
              />
              {submitting && formikPendidikanPelatihan?.errors.nama_diklat && (
                <div className="fs-6 text-danger mt-2">
                  {formikPendidikanPelatihan?.errors.nama_diklat}
                </div>
              )}
            </Col>
            <Col lg="6">
              <TextInputComponent
                label="Penyelenggara Diklat"
                placeholder="Masukkan Penyelenggara Diklat"
                isRequired
                formik={formikPendidikanPelatihan?.getFieldProps(
                  "penyelenggara"
                )}
                value={
                  formikPendidikanPelatihan?.getFieldProps("penyelenggara")
                    .value ?? ""
                }
              />
              {submitting &&
                formikPendidikanPelatihan?.errors.penyelenggara && (
                  <div className="fs-6 text-danger mt-2">
                    {formikPendidikanPelatihan?.errors.penyelenggara}
                  </div>
                )}
            </Col>
            <Col lg="6">
              <NumberInputComponent
                label="Tahun Diklat"
                placeholder="Masukkan Tahun Diklat"
                required
                formik={formikPendidikanPelatihan?.getFieldProps("tahun")}
                value={
                  formikPendidikanPelatihan?.getFieldProps("tahun").value ?? ""
                }
              />
              {submitting && formikPendidikanPelatihan?.errors.tahun && (
                <div className="fs-6 text-danger mt-2">
                  {formikPendidikanPelatihan?.errors.tahun}
                </div>
              )}
            </Col>
            <Col lg="6">
              <Select2
                placeholder="Pilih Peran Sebagai"
                label="Peran Sebagai"
                isRequired
                options={[
                  {
                    value: "PANITIA",
                    label: "PANITIA",
                  },
                  {
                    value: "PESERTA",
                    label: "PESERTA",
                  },
                  {
                    value: "NARASUMBER",
                    label: "NARASUMBER",
                  },
                ]}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikPendidikanPelatihan?.setFieldValue("peran", value);
                }}
                value={formikPendidikanPelatihan?.getFieldProps("peran").value}
              />
              {submitting && formikPendidikanPelatihan?.errors.peran && (
                <div className="fs-6 text-danger mt-2">
                  {formikPendidikanPelatihan?.errors.peran}
                </div>
              )}
            </Col>

            <Col lg="6">
              <Select2
                placeholder="Pilih Tingkat Diklat"
                label="Tingkat Diklat"
                isRequired
                options={[
                  {
                    value: "Internasional",
                    label: "Internasional",
                  },
                  {
                    value: "Nasional",
                    label: "Nasional",
                  },
                  {
                    value: "Provinsi",
                    label: "Provinsi",
                  },
                  {
                    value: "Kabupaten/Kota",
                    label: "Kabupaten/Kota",
                  },
                  {
                    value: "Kecamatan",
                    label: "Kecamatan",
                  },
                ]}
                onChange={(e: any) => {
                  const value = e?.value;
                  formikPendidikanPelatihan?.setFieldValue("tingkat", value);
                }}
                value={
                  formikPendidikanPelatihan?.getFieldProps("tingkat").value
                }
              />
              {submitting && formikPendidikanPelatihan?.errors.tingkat && (
                <div className="fs-6 text-danger mt-2">
                  {formikPendidikanPelatihan?.errors.tingkat}
                </div>
              )}
            </Col>

            <Col lg="6">
              <NumberInputComponent
                label="Lama Diklat (Jam)"
                placeholder="Masukkan Lama Diklat (Jam)"
                required
                formik={formikPendidikanPelatihan?.getFieldProps("berapa_jam")}
                value={
                  formikPendidikanPelatihan?.getFieldProps("berapa_jam")
                    .value ?? ""
                }
              />
              {submitting && formikPendidikanPelatihan?.errors.berapa_jam && (
                <div className="fs-6 text-danger mt-2">
                  {formikPendidikanPelatihan?.errors.berapa_jam}
                </div>
              )}
            </Col>

            <Col lg="6">
              <FileInputComponent
                acceptLabel="Format File PDF"
                label="Upload Dokumen"
                acceptedType={[".pdf"]}
                required
                onChange={(e) => {
                  if (e.target.files) {
                    formikPendidikanPelatihan?.setFieldValue(
                      "doc_sertifikat",
                      e.target.files[0]
                    );
                  } else
                    formikPendidikanPelatihan?.setFieldValue(
                      "doc_sertifikat",
                      null
                    );
                }}
                fileNameLabel={
                  formikPendidikanPelatihan?.getFieldProps("doc_sertifikat")
                    .value?.name ?? doc_sertifikat_name
                }
                resetField={
                  formikPendidikanPelatihan?.getFieldProps("doc_sertifikat")
                    .value
                    ? false
                    : true
                }
                maxFileSize={25}
                linkPreview={
                  typeof formikPendidikanPelatihan?.getFieldProps(
                    "doc_sertifikat"
                  ).value === "string"
                    ? formikPendidikanPelatihan?.getFieldProps("doc_sertifikat")
                        .value
                    : null
                }
                downloadPreview={() =>
                  handleDownloadDocPendidikanPelatihan(
                    formikPendidikanPelatihan?.getFieldProps("doc_sertifikat")
                      .value,
                    doc_sertifikat_name
                  )
                }
              />
              {submitting &&
                formikPendidikanPelatihan?.errors.doc_sertifikat && (
                  <div className="fs-6 text-danger mt-2">
                    {formikPendidikanPelatihan?.errors.doc_sertifikat}
                  </div>
                )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalPendidikanPelatihan;
