import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormRiwayatPendidikanPayload,
  initialQueryResponse,
} from "../../../models/riwayat-pendidikan/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/riwayat-pendidikan";
import {
  deleteRiwayatPendidikan,
  downloadDocRiwayatPendidikan,
  getDetailRiwayatPendidikan,
  getListRiwayatPendidikan,
  postRiwayatPendidikan,
} from "../../../api/riwayat-pendidikan/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["jurusan", "jenjang_pendidikan", "nama_perguruan_tinggi", "tahun_masuk", "tahun_lulus", "gelar_akademik", "ipk", "nomor_ijazah"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalRiwayatPendidikan, setShowModalRiwayatPendidikan] =
    useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailRiwayatPendidikan.nik_gtk = nik_gtk;

  const [detailRiwayatPendidikan, setDetailRiwayatPendidikan] =
    useState<FormRiwayatPendidikanPayload>(
      initialQueryResponse.detailRiwayatPendidikan
    );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikRiwayatPendidikan.setValues(
        initialQueryResponse.detailRiwayatPendidikan
      );
      setDetailRiwayatPendidikan(initialQueryResponse.detailRiwayatPendidikan);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_RIWAYAT_PENDIDIKAN}-${query}`,
    () => getListRiwayatPendidikan(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikRiwayatPendidikanValidationSchema = Yup.object().shape({
    jurusan: Yup.string().required("Jurusan Tidak Boleh Kosong"),
    jenjang_pendidikan: Yup.string().required(
      "Jenjang Pendidikan Tidak Boleh Kosong"
    ),
    nama_perguruan_tinggi: Yup.string().required(
      "Nama Perguruan Tinggi Tidak Boleh Kosong"
    ),
    tahun_masuk: Yup.string().required("Tahun Masuk Tidak Boleh Kosong"),
    tahun_lulus: Yup.string().required("Tahun Lulus Tidak Boleh Kosong"),
    gelar_akademik: Yup.string().required("Gelar Akademik Tidak Boleh Kosong"),
    ipk: Yup.string().required("IPK Tidak Boleh Kosong"),
    nomor_ijazah: Yup.string().required("Nomor Ijazah Tidak Boleh Kosong"),
    doc_transkrip_nilai: Yup.mixed().required(
      "Dokumen Transkrip Nilai Tidak Boleh Kosong"
    ),
    doc_ijazah: Yup.mixed().required("Dokumen Ijazah Tidak Boleh Kosong"),
  });

  const formikRiwayatPendidikan = useFormik<FormRiwayatPendidikanPayload>({
    initialValues: initialQueryResponse.detailRiwayatPendidikan,
    enableReinitialize: false,
    validationSchema: formikRiwayatPendidikanValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostRiwayatPendidikan(payload);
    },
  });

  const {
    mutateAsync: PostRiwayatPendidikan,
    isLoading: isLoadingPostRiwayatPendidikan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_RIWAYAT_PENDIDIKAN}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postRiwayatPendidikan(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_RIWAYAT_PENDIDIKAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostRiwayatPendidikan = useCallback(
    async (payload: any) => {
      await PostRiwayatPendidikan({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalRiwayatPendidikan(false);
                setResetForm(true);
                refetchListData();
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostRiwayatPendidikan]
  );

  const {
    mutateAsync: GetDetailRiwayatPendidikan,
    isLoading: isLoadingGetDetailRiwayatPendidikan,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_RIWAYAT_PENDIDIKAN}`,
    mutationFn: ({ id }: { id: string }) => getDetailRiwayatPendidikan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_RIWAYAT_PENDIDIKAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailRiwayatPendidikan = useCallback(
    async (id: string) => {
      await GetDetailRiwayatPendidikan({ id })
        .then((res) => {
          setDetailRiwayatPendidikan(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailRiwayatPendidikan]
  );

  const {
    mutateAsync: DownloadDocRiwayatPendidikan,
    isLoading: isLoadingDownloadDocRiwayatPendidikan,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_RIWAYAT_PENDIDIKAN}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocRiwayatPendidikan(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_RIWAYAT_PENDIDIKAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocRiwayatPendidikan = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocRiwayatPendidikan({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocRiwayatPendidikan]
  );

  const {
    mutateAsync: DeleteRiwayatPendidikan,
    isLoading: isLoadingDeleteRiwayatPendidikan,
  } = useMutation({
    mutationKey: `${QUERIES.POST_RIWAYAT_PENDIDIKAN}`,
    mutationFn: ({ id }: { id: string }) => deleteRiwayatPendidikan(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_RIWAYAT_PENDIDIKAN}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDeleteRiwayatPendidikan = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteRiwayatPendidikan({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteRiwayatPendidikan]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalRiwayatPendidikan,
      setShowModalRiwayatPendidikan,
      formikRiwayatPendidikan,
      formikRiwayatPendidikanValidationSchema,
      submitting,
      setSubmitting,
      handlePostRiwayatPendidikan,
      isLoadingPostRiwayatPendidikan,
      detailRiwayatPendidikan,
      setDetailRiwayatPendidikan,
      handleGetDetailRiwayatPendidikan,
      isLoadingGetDetailRiwayatPendidikan,
      resetForm,
      setResetForm,
      handleDownloadDocRiwayatPendidikan,
      isLoadingDownloadDocRiwayatPendidikan,
      handleDeleteRiwayatPendidikan,
      isLoadingDeleteRiwayatPendidikan,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalRiwayatPendidikan,
      setShowModalRiwayatPendidikan,
      formikRiwayatPendidikan,
      formikRiwayatPendidikanValidationSchema,
      submitting,
      setSubmitting,
      handlePostRiwayatPendidikan,
      isLoadingPostRiwayatPendidikan,
      detailRiwayatPendidikan,
      setDetailRiwayatPendidikan,
      handleGetDetailRiwayatPendidikan,
      isLoadingGetDetailRiwayatPendidikan,
      resetForm,
      setResetForm,
      handleDownloadDocRiwayatPendidikan,
      isLoadingDownloadDocRiwayatPendidikan,
      handleDeleteRiwayatPendidikan,
      isLoadingDeleteRiwayatPendidikan,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
