import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-lt3q/laporan-bulanan";
import { initialQueryResponse } from "../../../../models/absensi-lt3q/laporan-bulanan/_queryResponse";
import {
  getAbsensiLT3Q,
  getLaporanAbsensiLT3Q,
} from "../../../../api/absensi-lt3q/_request";
import { useQueryResponse as useQueryResponseAbsesnsiLT3Q } from "../../absensi-lt3q";
import { useAuth } from "../../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["gtk.nik_gtk", "gtk.nama_gtk", "kelompok_lt3q.nama_kelompok_lt3q"]`;
  const { currentUser } = useAuth();
  state.nik_gtk_tutor = currentUser?.data?.username;

  const { activeTab } = useQueryResponseAbsesnsiLT3Q();

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "absensi-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "absensi-lt3q"),
    [state]
  );

  const [bulan, setBulan] = useState<number | string | null>(null);
  const [tahun, setTahun] = useState<number | null>(null);
  const [idKelompokLT3Q, setIdKelompokLT3Q] = useState<Array<any>>([]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LAPORAN_ABSENSI_LT3Q}-${query}`,
    () => getLaporanAbsensiLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: activeTab === 2,
    }
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      bulan,
      setBulan,
      tahun,
      setTahun,
      idKelompokLT3Q,
      setIdKelompokLT3Q,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      bulan,
      setBulan,
      tahun,
      setTahun,
      idKelompokLT3Q,
      setIdKelompokLT3Q,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
