import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FormSertifikasiKeterampilanKhususPayload,
  initialQueryResponse,
} from "../../../models/sertifikasi-keterampilan-khusus/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import {
  downloadDocSertifikasiKeterampilanKhusus,
  postSertifikasiKeterampilanKhusus,
} from "../../../api/sertifikasi-keterampilan-khusus/_request";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";
import { useAuth } from "../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const nik_gtk: any = currentUser?.data?.username;
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [jenisSertifikasi, setJenisSertifikasi] = useState<any>();
  const queryClient = useQueryClient();

  const formikSertifikasiKeterampilanKhususValidationSchema =
    Yup.object().shape({
      jenis_sertifikasi: Yup.string().required(
        "Jenis Sertifikasi Tidak Boleh Kosong"
      ),
      bidang_studi: Yup.string().required("Bidang Studi Tidak Boleh Kosong"),
      nomor_sertifikasi: Yup.string().required(
        "Nomor Sertifikasi Tidak Boleh Kosong"
      ),
      tahun_sertifikasi: Yup.string().required(
        "Tahun Sertifikasi Tidak Boleh Kosong"
      ),
      ngrg: Yup.string().required("NGRG Tidak Boleh Kosong"),
      nomor_peserta: Yup.string().required("Nomor Peserta Tidak Boleh Kosong"),
      jumlah_juz: Yup.string().required("Jumlah Juz Tidak Boleh Kosong"),
    });

  const formikSertifikasiKeterampilanKhusus =
    useFormik<FormSertifikasiKeterampilanKhususPayload>({
      initialValues: {
        id: null,
        id_sertifikasi: null,
        bidang_studi: "",
        jenis_sertifikasi: null,
        jumlah_juz: 0,
        ngrg: "",
        nik_gtk,
        nomor_peserta: "",
        nomor_sertifikasi: "",
        tahun_sertifikasi: "",
        doc_sertifikasi_keterampilan_khusus: null,
      },
      enableReinitialize: false,
      validationSchema: formikSertifikasiKeterampilanKhususValidationSchema,
      onSubmit: (values: any) => {
        const payload = new FormData();
        Object.keys(values).map((key, idx) => {
          payload.append(key, values[key]);
        });
        handlePostSertifikasiKeterampilanKhusus(payload);
      },
    });

  const {
    mutateAsync: PostSertifikasiKeterampilanKhusus,
    isLoading: isLoadingPostSertifikasiKeterampilanKhusus,
  } = useMutation({
    mutationKey: `${QUERIES.POST_SERTIFIKASI_KETERAMPILAN_KHUSUS}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postSertifikasiKeterampilanKhusus(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_SERTIFIKASI_KETERAMPILAN_KHUSUS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostSertifikasiKeterampilanKhusus = useCallback(
    async (payload: any) => {
      await PostSertifikasiKeterampilanKhusus({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailGTK(res.data.nik_gtk);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostSertifikasiKeterampilanKhusus]
  );

  const {
    mutateAsync: DownloadDocSertifikasiKeterampilanKhusus,
    isLoading: isLoadingDownloadDocSertifikasiKeterampilanKhusus,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_SERTIFIKASI_KETERAMPILAN_KHUSUS}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocSertifikasiKeterampilanKhusus(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_SERTIFIKASI_KETERAMPILAN_KHUSUS}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocSertifikasiKeterampilanKhusus = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocSertifikasiKeterampilanKhusus({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocSertifikasiKeterampilanKhusus]
  );

  const value = useMemo(
    () => ({
      formikSertifikasiKeterampilanKhusus,
      formikSertifikasiKeterampilanKhususValidationSchema,
      submitting,
      setSubmitting,
      handlePostSertifikasiKeterampilanKhusus,
      isLoadingPostSertifikasiKeterampilanKhusus,
      jenisSertifikasi,
      setJenisSertifikasi,
      handleDownloadDocSertifikasiKeterampilanKhusus,
      isLoadingDownloadDocSertifikasiKeterampilanKhusus,
    }),
    [
      formikSertifikasiKeterampilanKhusus,
      formikSertifikasiKeterampilanKhususValidationSchema,
      submitting,
      setSubmitting,
      handlePostSertifikasiKeterampilanKhusus,
      isLoadingPostSertifikasiKeterampilanKhusus,
      jenisSertifikasi,
      setJenisSertifikasi,
      handleDownloadDocSertifikasiKeterampilanKhusus,
      isLoadingDownloadDocSertifikasiKeterampilanKhusus,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
