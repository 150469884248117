import { Accordion, Card, Form } from "react-bootstrap";
import { useQueryRequest } from "../../../../../provider/query-request/status-aktivasi/tidak-aktif";
import { ShowLimitDT } from "../../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../../hope-ui/components/table/SearchInput";
import Select2 from "../../../../../hope-ui/components/select-2";
import { useQueryRequest as useQueryRequestFilterData } from "../../../../../provider/query-request/filter-data";
import { useQueryResponse as useQueryResponseFilterData } from "../../../../../provider/query-response/filter-data";
import { useEffect } from "react";
let text = "";

let arrFilter: any = [
  {
    name: "unit_kerja",
    value: null,
    table: "gtk",
  },
];

const Toolbar = () => {
  const { state, updateState } = useQueryRequest();
  const { listFilterData, isLoadingFetchListFilterData } =
    useQueryResponseFilterData();

  const updateStateFilterData = useQueryRequestFilterData().updateState;

  useEffect(() => {
    const group_by: any = [];
    const table_name: any = [];
    arrFilter.map((val) => {
      const find = table_name.find((element) => element === val.table);
      if (!find) table_name.push(val.table);
      group_by.push(val.name);
    });

    updateStateFilterData({
      table_name: JSON.stringify(table_name),
      group_by: JSON.stringify(group_by),
    });
  }, []);

  listFilterData?.data?.map((item) => {
    const table_name = item.table_name;
    const data = item.data_group_by;
    arrFilter.map((val, idx) => {
      const key = val.name;
      if (val.table === table_name && !val.options) {
        arrFilter[idx].options = [];
        data.map((child, _idx) => {
          child.map((c) => {
            if (c[key]) {
              arrFilter[idx].options.push({
                value: c[key],
                label: c[key],
              });
            }
          });
        });
      }
    });
  });

  return (
    <div>
      {/* <Card.Header className="d-flex justify-content-between border border-b-1 pb-4 bg-gray-100">
        <div className="header-title col-12">
          <Accordion className="bg-white">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filter Data</Accordion.Header>
              <Accordion.Body>
                <div className="row col-12 gy-4 ">
                  {arrFilter.map((val, idx) => {
                    let label = val.name.replace("_", " ");
                    const words = val.name.split("_");
                    for (let i = 0; i < words.length; i++) {
                      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                    }
                    label = words.join(" ");

                    return (
                      <div className="col-md-6" key={idx}>
                        <Form.Label>{label}</Form.Label>
                        <Select2
                          onChange={(e: any) => {
                            arrFilter[idx].value = e ? e.value : null;
                            const arr = arrFilter.filter(
                              (element) => element.value !== null
                            );
                            arr.map((_val, _idx) => {
                              //delete arr[_idx].options;
                            });
                            text = JSON.stringify(arr);
                            updateState({ filter: text });
                          }}
                          options={val.options}
                          isLoading={isLoadingFetchListFilterData}
                          placeholder={`-- PIlih ${label} --`}
                        />
                      </div>
                    );
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Card.Header> */}
      <div className="row pt-4 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </div>
  );
};
export default Toolbar;
