import { FieldInputProps } from "formik";
import { ChangeEventHandler, FC } from "react";
import { Form, FormCheck } from "react-bootstrap";

type Props = {
  id: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  formik?: FieldInputProps<any>;
  className?: string;
  isRequired?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  labelClassName?: string;
  checked?: boolean;
};

const SwitchInputComponent: FC<Props> = ({
  id = "",
  label = null,
  type = "checkbox",
  className = "",
  isRequired = false,
  formik,
  onChange,
  labelClassName,
  checked,
}) => {
  return (
    <Form.Check className="mb-3 form-switch">
      <FormCheck.Input
        className={
          className && className.length === 0
            ? "form-check-input"
            : "form-check-input" + className
        }
        type="checkbox"
        id={id}
        {...formik}
        onChange={onChange}
        checked={checked}
      />
      <FormCheck.Label className="form-check-label" htmlFor={id}>
        {label}
      </FormCheck.Label>
    </Form.Check>
  );
};

export default SwitchInputComponent;
