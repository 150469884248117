import { Card } from "react-bootstrap";
import Toolbar from "./toolbar";
import { useQueryResponse as useQueryResponseHakAkses } from "../../../../provider/query-response/hak-akses";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../../provider/query-response/hak-akses/kelola-role-menu";
import { useQueryRequest } from "../../../../provider/query-request/hak-akses/kelola-role-menu";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { Columns } from "./columns";
import ModalEntryKelolaRoleMenu from "../entry";

const KelolaRoleMenu = () => {
  const { activeTab } = useQueryResponseHakAkses();
  const { isLoadingFetchData, listData, appName, rolesId } = useQueryResponse();
  const Data_table = listData?.data;
  return (
    <Card.Body
      style={{
        display: activeTab === 2 ? "block" : " none",
      }}
    >
      <Toolbar />
      {!appName || !rolesId || appName.length === 0 || rolesId === 0 ? (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <label className="text-danger fs-6">
            Harap Untuk Memilih Aplikasi dan Hak Akses Terlebih Dahulu.
          </label>
        </div>
      ) : (
        <>
          <DataTable
            isLoading={isLoadingFetchData}
            header={Columns}
            data={Data_table}
            pagination={useQueryResponsePagination}
            queryRequest={useQueryRequest}
          />

          <ModalEntryKelolaRoleMenu />
        </>
      )}
    </Card.Body>
  );
};

export const KelolaRoleMenuWrapper = () => <KelolaRoleMenu />;
