import { Col, Form, Modal, Row } from "react-bootstrap";
import { useQueryResponse } from "../../../../provider/query-response/kesejahteraan";
import TextInputComponent from "../../../../hope-ui/components/text-input/TextInputComponent";
import { useEffect } from "react";
import FetchLoading from "../../../../hope-ui/components/fetch-loading";
import TextareaComponent from "../../../../hope-ui/components/textarea-input";
import NumberInputComponent from "../../../../hope-ui/components/number-input";

const ModalKesejahteraan = () => {
  const {
    showModalKesejahteraan,
    setShowModalKesejahteraan,
    formikKesejahteraan,
    submitting,
    setSubmitting,
    detailKesejahteraan,
    setResetForm,
    isLoadingGetDetailKesejahteraan,
    isLoadingPostKesejahteraan,
  } = useQueryResponse();

  useEffect(() => {
    if (detailKesejahteraan.id) {
      formikKesejahteraan?.setValues(detailKesejahteraan);
    }
  }, [detailKesejahteraan]);

  return (
    <Modal
      backdrop="static"
      show={showModalKesejahteraan}
      onBackdropClick={() => {
        setShowModalKesejahteraan(false);
        setResetForm(true);
      }}
      size="lg"
      onHide={() => {
        setShowModalKesejahteraan(false);
        setResetForm(true);
      }}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setSubmitting(true);
          formikKesejahteraan?.submitForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {detailKesejahteraan.id ? "Edit" : "Tambah"} Kesejahteraan
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(isLoadingGetDetailKesejahteraan || isLoadingPostKesejahteraan) && (
            <FetchLoading />
          )}
          <Row className="gy-4">
            <Col lg="6">
              <TextInputComponent
                label="Nama Kesejahteraan"
                placeholder="Masukkan Nama Kesejahteraan"
                isRequired
                formik={formikKesejahteraan?.getFieldProps(
                  "nama_kesejahteraan"
                )}
                value={
                  formikKesejahteraan?.getFieldProps("nama_kesejahteraan")
                    .value ?? ""
                }
              />
              {submitting && formikKesejahteraan?.errors.nama_kesejahteraan && (
                <div className="fs-6 text-danger mt-2">
                  {formikKesejahteraan?.errors.nama_kesejahteraan}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="Jenis Kesejahteraan"
                placeholder="Masukkan Jenis Kesejahteraan"
                isRequired
                formik={formikKesejahteraan?.getFieldProps(
                  "jenis_kesejahteraan"
                )}
                value={
                  formikKesejahteraan?.getFieldProps("jenis_kesejahteraan")
                    .value ?? ""
                }
              />
              {submitting &&
                formikKesejahteraan?.errors.jenis_kesejahteraan && (
                  <div className="fs-6 text-danger mt-2">
                    {formikKesejahteraan?.errors.jenis_kesejahteraan}
                  </div>
                )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="Penyelenggara"
                placeholder="Masukkan Penyelenggara"
                isRequired
                formik={formikKesejahteraan?.getFieldProps("penyelenggara")}
                value={
                  formikKesejahteraan?.getFieldProps("penyelenggara").value ??
                  ""
                }
              />
              {submitting && formikKesejahteraan?.errors.penyelenggara && (
                <div className="fs-6 text-danger mt-2">
                  {formikKesejahteraan?.errors.penyelenggara}
                </div>
              )}
            </Col>

            <Col lg="6">
              <NumberInputComponent
                label="Dari Tahun"
                placeholder="Masukkan Dari Tahun"
                required
                formik={formikKesejahteraan?.getFieldProps("ditahun")}
                value={
                  formikKesejahteraan?.getFieldProps("ditahun").value ?? ""
                }
              />
              {submitting && formikKesejahteraan?.errors.ditahun && (
                <div className="fs-6 text-danger mt-2">
                  {formikKesejahteraan?.errors.ditahun}
                </div>
              )}
            </Col>

            <Col lg="6">
              <NumberInputComponent
                label="Sampai Tahun"
                placeholder="Masukkan Sampai Tahun"
                required
                formik={formikKesejahteraan?.getFieldProps("sampai_tahun")}
                value={
                  formikKesejahteraan?.getFieldProps("sampai_tahun").value ?? ""
                }
              />
              {submitting && formikKesejahteraan?.errors.sampai_tahun && (
                <div className="fs-6 text-danger mt-2">
                  {formikKesejahteraan?.errors.sampai_tahun}
                </div>
              )}
            </Col>

            <Col lg="6">
              <TextInputComponent
                label="Status"
                placeholder="Masukkan Status"
                formik={formikKesejahteraan?.getFieldProps("status")}
                value={formikKesejahteraan?.getFieldProps("status").value ?? ""}
              />
              {submitting && formikKesejahteraan?.errors.status && (
                <div className="fs-6 text-danger mt-2">
                  {formikKesejahteraan?.errors.status}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border d-flex flex-row justify-content-end align-items-center py-3">
          <button type="submit" className="btn btn-sm btn-primary">
            <i className="far fa-floppy-disk me-2"></i>
            Simpan
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalKesejahteraan;
