import React, { FC, useEffect, useRef, useState } from "react";
import FileInputComponent from "../../../hope-ui/components/file-input";
import { Button, Card, Col, Form, Table } from "react-bootstrap";
import { useFormik } from "formik";
import { FormImportGTKPayload } from "../../../models/data-gtk/import/_queryResponse";
import * as Yup from "yup";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../../provider/query-response/data-gtk/import";
import FetchLoading from "../../../hope-ui/components/fetch-loading";

type Props = {
  activeTab?: number;
};

const ImportExcel: FC<Props> = ({ activeTab }) => {
  const ref = useRef<HTMLInputElement>();
  const {
    handlePostImportGTK,
    isLoadingPostImportGTK,
    gagalImportGTK,
    handleDownloadTemplateImportGTK,
    isLoadingDownloadTemplateImportGTK,
  } = useQueryResponse();

  const [submitting, setSubmitting] = useState(false);

  const formikImportGTKValidationSchema = Yup.object().shape({
    file: Yup.mixed().required("File Import Data GTK Tidak Boleh Kosong"),
  });
  const formikImportGTK = useFormik<FormImportGTKPayload>({
    initialValues: {
      file: null,
    },
    enableReinitialize: false,
    onSubmit: (values: any) => {
      const payload = new FormData();
      payload.append("file", values["file"]);
      if (handlePostImportGTK) handlePostImportGTK(payload);
      formikImportGTK.setFieldValue("file", null);
    },
    validationSchema: formikImportGTKValidationSchema,
  });

  return (
    <Form
      style={{
        display: activeTab === 2 ? "block" : "none",
      }}
      onSubmit={(e) => {
        setSubmitting(true);
        formikImportGTK.submitForm();
        e.preventDefault();
      }}
    >
      {(isLoadingPostImportGTK || isLoadingDownloadTemplateImportGTK) && (
        <FetchLoading />
      )}
      <Card.Body className="border">
        <div className="d-flex flex-row justify-content-start mb-3">
          <Button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => {
              if (handleDownloadTemplateImportGTK)
                handleDownloadTemplateImportGTK();
            }}
          >
            <i className="fa fa-download me-2"></i>
            Download Template
          </Button>
        </div>

        <Col md="8">
          <Form.Group>
            <FileInputComponent
              label="Import Data GTK"
              acceptedType={[".xlsx", ".xls"]}
              required
              onChange={(e) => {
                if (e.target.files) {
                  formikImportGTK.setFieldValue("file", e.target.files[0]);
                } else formikImportGTK.setFieldValue("file", null);
              }}
              fileNameLabel={formikImportGTK.getFieldProps("file").value?.name}
              ref={ref.current}
              resetField={
                formikImportGTK.getFieldProps("file").value ? false : true
              }
            />
          </Form.Group>
        </Col>
        {gagalImportGTK && gagalImportGTK.length > 0 && (
          <Col md="8">
            <div className="mt-3 alert alert-warning">
              <b className="mb-2">Pesan Sistem : </b>
              {gagalImportGTK.map((val) => {
                return <div className="fs-6 ">{val.message}</div>;
              })}
            </div>
          </Col>
        )}
      </Card.Body>
      <Card.Footer className="border d-flex flex-row justify-content-end align-items-center">
        <Button type="submit" className="btn btn-md btn-primary">
          <i className="fa fa-file-import me-2"></i>
          Import Data
        </Button>
      </Card.Footer>
    </Form>
  );
};

export const ImportExcelWrapper: FC<Props> = ({ activeTab }) => (
  <QueryResponseProvider>
    <ImportExcel activeTab={activeTab} />
  </QueryResponseProvider>
);
