import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListDendaPersensiGTK = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_DENDA_PERSENSI_GTK_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postSaveDendaPersensiGTK = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_SAVE_DENDA_PERSENSI_GTK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailDendaPersensiGTK = async (id: string): Promise<any> => {
  return client
    .put(`${ApiConfig.DETAIL_DENDA_PERSENSI_GTK_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const putEditDendaPersensiGTK = async (id: string): Promise<any> => {
  return client
    .put(`${ApiConfig.EDIT_DENDA_PERSENSI_GTK_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deleteDendaPersensiGTK = async (id: string): Promise<any> => {
    return client
      .delete(`${ApiConfig.DELETE_DENDA_PERSENSI_GTK_URL}/${id}`)
      .then((response: AxiosResponse<any>) => response.data);
};
  

export {
  getListDendaPersensiGTK,
  postSaveDendaPersensiGTK,
  getDetailDendaPersensiGTK,
  putEditDendaPersensiGTK,
  deleteDendaPersensiGTK,
};
