import { FC } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useQueryResponse } from "../../../../../provider/query-response/mutasi";

type Props = {
  data: any;
};
const AksiCell: FC<Props> = ({ data }) => {
  const {
    setShowModalMutasi,
    handleGetDetailMutasi,
    handleDownloadDocMutasi,
    handleDeleteMutasi,
  } = useQueryResponse();
  return (
    <DropdownButton
      as={ButtonGroup}
      key={"end"}
      drop={"end"}
      variant="primary"
      title={` Pilih Aksi `}
      size="sm"
    >
      <Dropdown.Item
        eventKey="1"
        onClick={(e) => {
          setShowModalMutasi(true);
          handleGetDetailMutasi(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Edit <i className="fa fa-pencil text-info ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={(e) => {
          handleDeleteMutasi(data.id);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Hapus <i className="fa fa-trash text-danger ms-1"></i>
        </div>
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        onClick={(e) => {
          const pecah = data.doc_mutasi.split("/");
          const fileName = pecah[pecah.length - 1];
          handleDownloadDocMutasi(data.doc_mutasi, fileName);
        }}
        className="cursor-pointer"
      >
        <div className="fs-6">
          Unduh Dokumen <i className="fa fa-download text-primary ms-1"></i>
        </div>
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default AksiCell;
