import { Card, Col, Row } from "react-bootstrap";
import Datepicker from "../../../../hope-ui/components/datepicker";
import { useQueryRequest } from "../../../../provider/query-request/presensi-saya/presensi-lt3q";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

const Toolbar = () => {
  const currentDate = new Date();
  const [tanggalAwal, setTanggalAwal] = useState<string>(
    dayjs(
      `${currentDate.getFullYear()}-${
        currentDate.getMonth() < 0
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1
      }-01`
    ).format("YYYY-MM-DD")
  );
  const [tanggalAkhir, setTanggalAkhir] = useState<string>();
  const { updateState } = useQueryRequest();

  return (
    <Card.Header>
      <Row>
        <Col lg={4}>
          <Datepicker
            label="Dari Tanggal"
            onChange={(e) => {
              const value = dayjs(e).format("YYYY-MM-DD");
              setTanggalAwal(value);
            }}
            value={dayjs(tanggalAwal).format("YYYY-MM-DD")}
            required
          />
        </Col>

        <Col lg={4}>
          <Datepicker
            label="Hingga Tanggal"
            onChange={(e) => {
              const value = dayjs(e).format("YYYY-MM-DD");
              setTanggalAkhir(value);
            }}
            value={dayjs(tanggalAkhir).format("YYYY-MM-DD")}
            minDate={dayjs(tanggalAwal)}
            required
          />
        </Col>

        <Col lg={3}>
          <div className="h-100 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-sm btn-dark"
              onClick={() => {
                updateState({
                  tanggal_awal: tanggalAwal,
                  tanggal_akhir: tanggalAkhir,
                });
              }}
            >
              Terapkan
              <i className="fa fa-sliders ms-2"></i>
            </button>
          </div>
        </Col>
      </Row>
    </Card.Header>
  );
};

export default Toolbar;
