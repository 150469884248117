import React from "react";
import ReactDOM from "react-dom/client";
//import "./index.css";
//import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { createRoot } from "react-dom/client";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppRoutes } from "./app/routing/AppRoutes";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/dark.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/scss/custom.scss";
import "@nosferatu500/react-sortable-tree/style.css";

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
} else alert("NO routes");
reportWebVitals();
