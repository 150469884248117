import axios, { AxiosResponse } from "axios";
import { client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListMenu = async (query: string): Promise<any> => {
  return client_sso
    .get(`${ApiConfig.GET_MENU_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postMenu = async (data: FormData): Promise<any> => {
  return client_sso
    .post(`${ApiConfig.GET_MENU_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

export { getListMenu, postMenu };
