import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WithChildren } from "../../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { stringifyRequestQuery } from "../../../../helpers/helpers";
import { QUERIES } from "../../../../helpers/crud-helpers/const";
import { initialQueryState } from "../../../../models/global";
import { SweetAlert } from "../../../../hope-ui/components/sweet-alert";
import { useQueryRequest } from "../../../query-request/absensi-lt3q/buat-absensi";
import {
  FormRiwayatLT3QPayload,
  initialQueryResponse,
} from "../../../../models/absensi-lt3q/buat-absensi/_queryResponse";
import {
  deleteAbsensiLT3Q,
  getDetailAbsensiLT3Q,
  getKehadiranLT3Q,
  getTotalInfaq,
  postBuatAbsensiLT3Q,
} from "../../../../api/absensi-lt3q/_request";
import { FormAnggotaKelompokLT3QPayload } from "../../../../models/absensi-lt3q/anggota-kelompok-lt3q/_queryResponse";
import { useQueryResponse as useQueryResponseAbsesnsiLT3Q } from "../../absensi-lt3q";
import { useQueryResponse as useQueryResponseDaftarAbsensi } from "../../absensi-lt3q/daftar-absensi";
import { useQueryResponse as useQueryResponseLaporanBulanan } from "../../absensi-lt3q/laporan-bulanan";
import { useAuth } from "../../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";

const QueryResponseContext = createContext(initialQueryResponse);

const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { state } = useQueryRequest();
  state.col_header = `["riwayat_lt3q.kelompok_lt3q.nama_kelompok_lt3q", "nik_gtk", "gtk.nama_gtk", "anggota_kelompok_lt3q.nik_tutor_lt3q"]`;
  const { currentUser } = useAuth();
  state.nik_tutor_lt3q = currentUser?.data?.username;

  const { isShowModalBuatAbsensi } = useQueryResponseAbsesnsiLT3Q();
  const refetchListDataAbsensi =
    useQueryResponseDaftarAbsensi().refetchListData;

  const refetchListDataLaporanBulanan =
    useQueryResponseLaporanBulanan().refetchListData;

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "kehadiran-lt3q")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "kehadiran-lt3q"),
    [state]
  );

  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [detailAbsensiLT3Q, setDetailAbsensiLT3Q] =
    useState<FormRiwayatLT3QPayload>(initialQueryResponse.detailAbsensiLT3Q);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  const [resetForm, setResetForm] = useState<boolean>(false);

  const [totalInfaq, setTotalInfaq] = useState<number>(
    initialQueryResponse.totalInfaq
  );

  const {
    mutateAsync: GetDetailAbsensiLT3Q,
    isLoading: isLoadingGetDetailAbsensiLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.GET_DETAIL_ABSENSI_LT3Q}`,
    mutationFn: ({ id }: { id: string }) => getDetailAbsensiLT3Q(id),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.GET_DETAIL_ABSENSI_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleGetDetailAbsensiLT3Q = useCallback(
    async (id: string) => {
      await GetDetailAbsensiLT3Q({ id })
        .then((res) => {
          if (res.success) {
            setDetailAbsensiLT3Q(res.data);
          } else {
            SweetAlert({
              icon: res.success ? "success" : "warning",
              title: "Info",
              html: res.message,
              allowOutsideClick: false,
            });
            return;
          }
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailAbsensiLT3Q]
  );

  const { mutateAsync: GetTotalInfaq, isLoading: isLoadingGetTotalInfaq } =
    useMutation({
      mutationKey: `${QUERIES.GET_TOTAL_INFAQ}`,
      mutationFn: ({ id }: { id: string }) => getTotalInfaq(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_TOTAL_INFAQ}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetTotalInfaq = useCallback(
    async (id: string) => {
      await GetTotalInfaq({ id })
        .then((res) => {
          const total_infaq = res.data;
          setTotalInfaq(total_infaq);
          formikBuatAbsensi?.setFieldValue("total_infaq", total_infaq);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetTotalInfaq]
  );

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_KEHADIRAN_LT3Q}-${query}`,
    () => getKehadiranLT3Q(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: isShowModalBuatAbsensi,
    }
  );
  useEffect(() => {
    if (listData) {
      setCurrentOffset(listData.offset);
    }
  }, [listData]);

  const formikBuatAbsensiValidationSchema = Yup.object().shape({
    riwayat_tanggal: Yup.string().required("Tanggal Tidak Boleh Kosong"),
    id_kelompok_lt3q: Yup.string().required("Kelompok LT3Q Tidak Boleh Kosong"),
    batas_tilawah_halaman: Yup.string().required(
      "Batas Tilawah Halaman Tidak Boleh Kosong"
    ),
    nik_gtk_tutor: Yup.string().required("NIK GTK Tutor Tidak Boleh Kosong"),
    tempat: Yup.string().required("Tempat Tidak Boleh Kosong"),
    materi: Yup.string().required("Materi Tidak Boleh Kosong"),
    kehadiran_lt3q: Yup.array().of(
      Yup.object().shape({
        nik_gtk: Yup.string().required("GTK Tidak Boleh Kosong"),
        status_absen: Yup.string().required(
          "Kehadiran LT3Q Tidak Boleh Kosong"
        ),
      })
    ),
  });

  const formikBuatAbsensi = useFormik<FormRiwayatLT3QPayload>({
    initialValues: {
      id: null,
      id_kelompok_lt3q: null,
      materi: null,
      riwayat_tanggal: null,
      batas_tilawah_halaman: null,
      nik_gtk_tutor: null,
      tempat: null,
      kehadiran_lt3q: [],
    },
    enableReinitialize: false,
    validationSchema: formikBuatAbsensiValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        if (key === "kehadiran_lt3q") {
          console.log(values[key]);
          payload.append(key, JSON.stringify(values[key]));
        } else payload.append(key, values[key]);
      });
      if (handlePostBuatAbsensiLT3Q) handlePostBuatAbsensiLT3Q(payload);
    },
  });

  useEffect(() => {
    if (resetForm) {
      formikBuatAbsensi.setValues(initialQueryResponse.detailAbsensiLT3Q);
      setDetailAbsensiLT3Q(initialQueryResponse.detailAbsensiLT3Q);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    mutateAsync: PostBuatAbsensiLT3Q,
    isLoading: isLoadingPostBuatAbsensiLT3Q,
  } = useMutation({
    mutationKey: `${QUERIES.POST_BUAT_ABSENSI_LT3Q}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postBuatAbsensiLT3Q(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_BUAT_ABSENSI_LT3Q}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostBuatAbsensiLT3Q = useCallback(
    async (payload: FormData) => {
      await PostBuatAbsensiLT3Q({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            html: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              setResetForm(true);
              if (refetchListDataAbsensi) refetchListDataAbsensi();
              if (refetchListDataLaporanBulanan)
                refetchListDataLaporanBulanan();
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostBuatAbsensiLT3Q]
  );
  const value = useMemo(
    () => ({
      listData,
      isLoadingFetchData,
      formikBuatAbsensi,
      formikBuatAbsensiValidationSchema,
      currentOffset,
      setCurrentOffset,
      submitting,
      setSubmitting,
      handlePostBuatAbsensiLT3Q,
      isLoadingPostBuatAbsensiLT3Q,
      resetForm,
      setResetForm,
      handleGetDetailAbsensiLT3Q,
      isLoadingGetDetailAbsensiLT3Q,
      detailAbsensiLT3Q,
      setDetailAbsensiLT3Q,
      handleGetTotalInfaq,
      isLoadingGetTotalInfaq,
      totalInfaq,
      setTotalInfaq,
    }),
    [
      listData,
      isLoadingFetchData,
      formikBuatAbsensi,
      formikBuatAbsensiValidationSchema,
      currentOffset,
      setCurrentOffset,
      submitting,
      setSubmitting,
      handlePostBuatAbsensiLT3Q,
      isLoadingPostBuatAbsensiLT3Q,
      resetForm,
      setResetForm,
      handleGetDetailAbsensiLT3Q,
      isLoadingGetDetailAbsensiLT3Q,
      detailAbsensiLT3Q,
      setDetailAbsensiLT3Q,
      handleGetTotalInfaq,
      isLoadingGetTotalInfaq,
      totalInfaq,
      setTotalInfaq,
    ]
  );

  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};

export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
