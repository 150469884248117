import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  FormRiwayatSertifikasiPayload,
  initialQueryResponse,
} from "../../../models/riwayat-sertifikasi/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import {
  downloadDocRiwayatSertifikasi,
  postRiwayatSertifikasi,
} from "../../../api/riwayat-sertifikasi/_request";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";
import { useAuth } from "../../core/auth";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { currentUser } = useAuth();
  const nik_gtk: any = currentUser?.data?.username;
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [jenisSertifikasi, setJenisSertifikasi] = useState<any>();
  const queryClient = useQueryClient();

  const formikRiwayatSertifikasiValidationSchema = Yup.object().shape({
    bidang_studi: Yup.string().required("Bidang Studi Tidak Boleh Kosong"),
    nomor_sertifikasi: Yup.string().required(
      "Nomor Sertifikasi Tidak Boleh Kosong"
    ),
    tahun_sertifikasi: Yup.string().required(
      "Tahun Sertifikasi Tidak Boleh Kosong"
    ),
    ngrg: Yup.string().required("NGRG Tidak Boleh Kosong"),
    nomor_peserta: Yup.string().required("Nomor Peserta Tidak Boleh Kosong"),
    jumlah_juz: Yup.string().required("Jumlah Juz Tidak Boleh Kosong"),
  });

  const formikRiwayatSertifikasi = useFormik<FormRiwayatSertifikasiPayload>({
    initialValues: {
      id: null,
      id_sertifikasi: null,
      bidang_studi: "",
      jenis_sertifikasi: null,
      jumlah_juz: 0,
      ngrg: "",
      nik_gtk,
      nomor_peserta: "",
      nomor_sertifikasi: "",
      tahun_sertifikasi: "",
      doc_sertifikasi_pendidik: null,
    },
    enableReinitialize: false,
    validationSchema: formikRiwayatSertifikasiValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostRiwayatSertifikasi(payload);
    },
  });

  const {
    mutateAsync: PostRiwayatSertifikasi,
    isLoading: isLoadingPostRiwayatSertifikasi,
  } = useMutation({
    mutationKey: `${QUERIES.POST_RIWAYAT_SERTIFIKASI}`,
    mutationFn: ({ payload }: { payload: FormData }) =>
      postRiwayatSertifikasi(payload),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.POST_RIWAYAT_SERTIFIKASI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePostRiwayatSertifikasi = useCallback(
    async (payload: any) => {
      await PostRiwayatSertifikasi({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              handleGetDetailGTK(res.data.nik_gtk);
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostRiwayatSertifikasi]
  );

  const {
    mutateAsync: DownloadDocRiwayatSertifikasi,
    isLoading: isLoadingDownloadDocRiwayatSertifikasi,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_RIWAYAT_SERTIFIKASI}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocRiwayatSertifikasi(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([
          `${QUERIES.DOWNLOAD_DOC_RIWAYAT_SERTIFIKASI}`,
        ]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocRiwayatSertifikasi = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocRiwayatSertifikasi({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocRiwayatSertifikasi]
  );

  const value = useMemo(
    () => ({
      formikRiwayatSertifikasi,
      formikRiwayatSertifikasiValidationSchema,
      submitting,
      setSubmitting,
      handlePostRiwayatSertifikasi,
      isLoadingPostRiwayatSertifikasi,
      jenisSertifikasi,
      setJenisSertifikasi,
      handleDownloadDocRiwayatSertifikasi,
      isLoadingDownloadDocRiwayatSertifikasi,
    }),
    [
      formikRiwayatSertifikasi,
      formikRiwayatSertifikasiValidationSchema,
      submitting,
      setSubmitting,
      handlePostRiwayatSertifikasi,
      isLoadingPostRiwayatSertifikasi,
      jenisSertifikasi,
      setJenisSertifikasi,
      handleDownloadDocRiwayatSertifikasi,
      isLoadingDownloadDocRiwayatSertifikasi,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
export { QueryResponseProvider, useQueryResponse };
