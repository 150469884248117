import { FC, Fragment } from "react";
import { Navbar, Container, Dropdown, Badge } from "react-bootstrap";
import { useLayout } from "../../../core/LayoutProvider";
import { Link } from "react-router-dom";
import CustomToggle from "../../dropdowns";

import avatars1 from "../../../../../assets/images/avatars/01.png";
import CompanyLogo from "../../../../hope-ui/components/company-logo";
import { useAuth } from "../../../../provider/core/auth";
import { useQueryResponse } from "../../../../provider/query-response/notifikasi";

const Header: FC = () => {
  const { setting } = useLayout();
  const navbarHide = setting.navbar_show.value; // array
  const headerNavbar = setting.header_navbar.value;
  const appName = setting.app_name.value;

  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };
  const { currentUser } = useAuth();
  const { handleLogout } = useAuth();
  const { setShowNotifikasi, totalPesan } = useQueryResponse();

  return (
    <Fragment>
      <Navbar
        variant="light"
        className={`nav iq-navbar ${headerNavbar} ${navbarHide.join(
          " "
        )} custom-navbar `}
      >
        <Container fluid className="navbar-inner">
          <Link to="/dashboard" className="navbar-brand">
            <CompanyLogo />
            <h4 className="logo-title">{appName}</h4>
          </Link>
          <div
            className="sidebar-toggle"
            data-toggle="sidebar"
            data-active="true"
            onClick={minisidebar}
          >
            <i className="icon">
              <svg width="20px" height="20px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                />
              </svg>
            </i>
          </div>

          <Dropdown
            as="li"
            className="nav-item d-flex flex-row justify-content-end w-100 align-items-center "
          >
            <div
              className={`pe-${
                totalPesan > 0 && "3"
              } cursor-pointer-only position-relative`}
              onClick={() => setShowNotifikasi(true)}
            >
              <i
                className="fas fa-envelope nav-item"
                style={{
                  fontSize: 24,
                }}
              ></i>
              {totalPesan > 0 && (
                <Badge
                  bg="danger"
                  style={{
                    position: "absolute",
                    bottom: 20,
                    right: -5,
                    fontSize: 11,
                  }}
                >
                  {totalPesan}
                </Badge>
              )}
            </div>
            <Dropdown.Toggle
              as={CustomToggle}
              variant=" nav-link py-0 d-flex align-items-center"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={
                  (currentUser &&
                    currentUser.data &&
                    currentUser.data["poto"]) ??
                  avatars1
                }
                style={{
                  objectFit: "cover",
                }}
                alt="User-Profile"
                className="theme-color-default-img img-fluid avatar avatar-50 avatar-rounded"
              />
              <div className="caption ms-3 d-none d-md-block ">
                <h6 className="mb-0 caption-title">
                  {currentUser?.data?.name}
                </h6>
                <p className="mb-0 caption-sub-title">
                  {currentUser?.data?.roles?.name}
                </p>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-end"
              aria-labelledby="navbarDropdown"
            >
              <Dropdown.Item
                onClick={() => {
                  if (handleLogout) handleLogout();
                }}
              >
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>
    </Fragment>
  );
};
export { Header };
