import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { useQueryResponse } from "../../../../../../provider/query-response/absensi-bpi/buat-absensi";
import { FC, useEffect } from "react";

type Props = {
  index: number;
  data: any;
};

export const AksiCell: FC<Props> = ({ index, data }) => {
  const { formikBuatAbsensi, submitting } = useQueryResponse();
  const value = formikBuatAbsensi?.getFieldProps("kehadiran_bpi").value;

  const find = value?.find((element) => element.nik_gtk === data.nik_gtk);

  if (find) index = value.indexOf(find);

  const status_absen = formikBuatAbsensi?.getFieldProps(
    `kehadiran_bpi[${index}].status_absen`
  ).value;
  let variant = "gray";
  if (status_absen === "Hadir") variant = "primary";
  else if (status_absen === "Sakit") variant = "warning";
  else if (status_absen === "Izin") variant = "info";
  else if (status_absen === "Alfa") variant = "danger";
  return (
    <div className="d-flex flex-column align-items-center justify-content-center w-100 py-5 gap-2">
      <Dropdown as={ButtonGroup}>
        <Button type="button" size="sm" variant={variant}>
          {formikBuatAbsensi?.getFieldProps(
            `kehadiran_bpi[${index}].status_absen`
          ).value ?? "Pilih Kehadiran"}
        </Button>
        <Dropdown.Toggle type="button" split variant={variant}>
          <span className="visually-hidden"></span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              formikBuatAbsensi?.setFieldValue(
                `kehadiran_bpi[${index}].status_absen`,
                "Hadir"
              );
            }}
          >
            Hadir
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              formikBuatAbsensi?.setFieldValue(
                `kehadiran_bpi[${index}].status_absen`,
                "Sakit"
              );
            }}
          >
            Sakit
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              formikBuatAbsensi?.setFieldValue(
                `kehadiran_bpi[${index}].status_absen`,
                "Izin"
              );
            }}
          >
            Izin
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            onClick={(e) => {
              formikBuatAbsensi?.setFieldValue(
                `kehadiran_bpi[${index}].status_absen`,
                "Alfa"
              );
            }}
          >
            Alfa
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {submitting &&
        formikBuatAbsensi?.errors[`kehadiran_bpi`] &&
        formikBuatAbsensi?.errors[`kehadiran_bpi`][index] &&
        formikBuatAbsensi?.errors[`kehadiran_bpi`][index]["status_absen"] && (
          <div className="text-danger mt-2">
            {formikBuatAbsensi?.errors[`kehadiran_bpi`][index]["status_absen"]}
          </div>
        )}
    </div>
  );
};
