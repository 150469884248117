import axios, { AxiosResponse } from "axios";
import { client, client_sso } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListAnak = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_ANAK_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postAnak = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_ANAK_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailAnak = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_ANAK_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const downloadDocAnak = async (url: string, fileName: string): Promise<any> => {
  return client
    .get(`${url}`, { responseType: "blob" })
    .then((response: AxiosResponse<any>) => {
      const href = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

const deleteAnak = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_ANAK_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const getAllJenjangPendidikan = async (): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_ALL_JENJANG_PENDIDIKAN_URL}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListAnak,
  postAnak,
  getDetailAnak,
  downloadDocAnak,
  deleteAnak,
  getAllJenjangPendidikan,
};
