import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";
import { Dayjs } from "dayjs";
import { FormKelompokLT3QPayload } from "../kelompok-lt3q/_queryResponse";

export type FormNilaiTahsinLT3QPayload = {
  id: string | number | null;
  nama_ujian: string | null;
  tanggal: Date | Dayjs | null;
  nik_tutor_lt3q: string | null;
  keterangan: string | null;
  detail: Array<FormNilaiTahsinLT3QDetailPayload>;
  id_kelompok_lt3q: number | null;
};

export type FormNilaiTahsinLT3QDetailPayload = {
  id: string | number | null;
  id_nilai_tahsin_lt3q: string | number | null;
  nik_gtk: string | null;
  nilai_tilawah: number | null;
  nilai_teori: number | null;
  keterangan: string | null;
  is_ujian: boolean;
  nama_gtk?: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handleGetAllKelompokLT3Q?: (nik_gtk: string, type?: string) => void;
  isLoadingGetAllKelompokLT3Q?: boolean;
  handleGetAnggotaKelompokLT3Q?: (id: string) => void;
  isLoadingGetAnggotaKelompokLT3Q?: boolean;
  allKelompokLT3Q: Array<FormKelompokLT3QPayload>;
  setAllKelompokLT3Q: Dispatch<SetStateAction<Array<FormKelompokLT3QPayload>>>;
  formikNilaiTahsinLT3Q?: FormikProps<FormNilaiTahsinLT3QPayload>;
  formikNilaiTahsinLT3QValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleGetAllNilaiTahsin?: (group?: string) => void;
  isLoadingGetAllNilaiTahsin?: boolean;
  allNilaiTahsin: Array<FormNilaiTahsinLT3QPayload>;
  setAllNilaiTahsin: Dispatch<
    SetStateAction<Array<FormNilaiTahsinLT3QPayload>>
  >;
  handlePostNilaiTahsin: (data: FormData) => void;
  isLoadingPostNilaiTahsin: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingGetAllKelompokLT3Q: false,
  handleGetAnggotaKelompokLT3Q: () => {},
  isLoadingGetAnggotaKelompokLT3Q: false,
  allKelompokLT3Q: [],
  setAllKelompokLT3Q: () => {},
  submitting: false,
  setSubmitting: () => {},
  resetForm: false,
  setResetForm: () => {},
  allNilaiTahsin: [],
  setAllNilaiTahsin: () => {},
  handlePostNilaiTahsin: () => {},
  isLoadingPostNilaiTahsin: false,
};
