import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";


export type FormImportPersensiGTKPayload = {
  filter_tanggal: Date | null;
  file: File[] | null;
};


export type FormAbsensiGtkPayload = {
  id: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
  tanggal_absen: Date | null;
};

export type FormCatatanGtkPayload = {
  id: string | null;
  nik_gtk: string | null;
  status_absen: string | null;
  keterangan: string | null;
};

export type FormPrihalAlasanPayload = {
  id: string | null;
  prihal: string | null;
  alasan: string | null;

};


export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  ActiveTab:number
  setActiveTab:Dispatch<SetStateAction<number>>
  handleExportExcelAbsensiGTKInput?: () => void;
  //isLoadingExportExcelAbsensiGTKInput: boolean

  handlePostCatatanGtk: (data: FormData) => void;
  DetailAbsensiGtk: FormAbsensiGtkPayload;
  setDetailAbsensiGtk: Dispatch<SetStateAction<FormAbsensiGtkPayload>>;
  handleCatatanGtk: (nik_gtk: string) => void;
  DetailCatatanAbsensiGtk: FormCatatanGtkPayload;
  setDetailCatatanAbsensiGtk: Dispatch<SetStateAction<FormCatatanGtkPayload>>;
  isLoadingPostCatatanGtk: boolean;
  CatatanGtk: boolean;
  setCatatanGtk: Dispatch<SetStateAction<boolean>>

  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;

  handleGetAllPrihalAlasan: () => void;
  isLoadingGetAllPrihalAlasan: boolean;
  allPrihalAlasan: Array<FormPrihalAlasanPayload>;
  setAllPrihalAlasan: Dispatch<SetStateAction<Array<FormPrihalAlasanPayload>>>;
  
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData:false,
  ActiveTab:1,
  setActiveTab:() => {},
  DetailAbsensiGtk: {
    id: null,
    nik_gtk: null,
    status_absen: null,
    keterangan: null,
    tanggal_absen:  null,
  },
  handleCatatanGtk: () => {},
  handlePostCatatanGtk: () => {},
  setDetailAbsensiGtk: () => {},
  CatatanGtk:false,
  setCatatanGtk:() => {},
  isLoadingPostCatatanGtk: false,
  submitting: false,
  setSubmitting: () => {},
  resetForm: false,
  setResetForm: () => {},
  DetailCatatanAbsensiGtk: {
    id: null,
    nik_gtk: null,
    status_absen: null,
    keterangan: null,
  },
  setDetailCatatanAbsensiGtk: () => {},

  handleGetAllPrihalAlasan: () => {},
  isLoadingGetAllPrihalAlasan: false,
  allPrihalAlasan:[],
  setAllPrihalAlasan: () => {},

};
