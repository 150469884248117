import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";

export type FormKesejahteraanPayload = {
  id: number | string | null;
  id_kesejahteraan: string | null;
  nik_gtk: string | null;
  nama_kesejahteraan: string | null;
  jenis_kesejahteraan: string | null;
  penyelenggara: string | null;
  ditahun: number | string | null;
  sampai_tahun: number | string | null;
  status: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalKesejahteraan: boolean;
  setShowModalKesejahteraan: Dispatch<SetStateAction<boolean>>;
  formikKesejahteraan?: FormikProps<FormKesejahteraanPayload>;
  formikKesejahteraanValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostKesejahteraan: (data: FormData) => void;
  isLoadingPostKesejahteraan: boolean;
  handleGetDetailKesejahteraan: (id: string) => void;
  isLoadingGetDetailKesejahteraan: boolean;
  detailKesejahteraan: FormKesejahteraanPayload;
  setDetailKesejahteraan: Dispatch<SetStateAction<FormKesejahteraanPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDownloadDocKesejahteraan: (url: string, fileName: string) => void;
  isLoadingDownloadDocKesejahteraan: boolean;
  handleDeleteKesejahteraan: (id: string) => void;
  isLoadingDeleteKesejahteraan: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalKesejahteraan: false,
  setShowModalKesejahteraan: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostKesejahteraan: () => {},
  isLoadingPostKesejahteraan: false,
  handleGetDetailKesejahteraan: () => {},
  isLoadingGetDetailKesejahteraan: false,
  detailKesejahteraan: {
    id: null,
    id_kesejahteraan: null,
    nik_gtk: null,
    jenis_kesejahteraan: "",
    nama_kesejahteraan: "",
    ditahun: "",
    penyelenggara: "",
    sampai_tahun: "",
    status: "",
  },
  setDetailKesejahteraan: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleDownloadDocKesejahteraan: () => {},
  isLoadingDownloadDocKesejahteraan: false,
  handleDeleteKesejahteraan: () => {},
  isLoadingDeleteKesejahteraan: false,
};
