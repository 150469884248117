import { FC, Fragment } from "react";
import { Table } from "react-bootstrap";

type Props = {
  data?: any;
};

const Table18: FC<Props> = (data) => {
  return (
    <Fragment>
      {data && !data?.data?.length && data?.data instanceof Object ? (
        <Table responsive bordered cellPadding={10}>
          {Object.keys(data?.data).map((key, idx) => {
            const value = data?.data[key];
            key = key.replace("gtk", "GTK");
            key = key.replace("nik", "NIK");
            const words = key.split("_");
            for (let i = 0; i < words.length; i++) {
              words[i] = words[i][0].toUpperCase() + words[i].substr(1);
            }
            let status = false;
            if (
              key.length > 2 &&
              key.includes("id") === true &&
              key.includes("bank") === true
            ) {
              status = true;
            } else if (
              key.length > 2 &&
              key.includes("id") === false &&
              key.includes("doc") === false
            ) {
              status = true;
            } else status = false;
            return (
              status && (
                <tr>
                  <th>{words.join(" ")}</th>
                  <td> : </td>
                  <td>
                    {value && typeof value !== "object" && value !== "null"
                      ? value.toString()
                      : "-"}
                  </td>
                </tr>
              )
            );
          })}
        </Table>
      ) : (
        !data.data && (
          <label className="text-danger text-center w-100">
            Tidak Ada Data Ditemukan
          </label>
        )
      )}

      {data?.data?.length && data?.data.length > 0 ? (
        <Table responsive className="text-center" bordered>
          <thead>
            <tr>
              <td>No.</td>
              {Object.keys(data.data[0]).map((key) => {
                let status = false;
                if (
                  key.length > 2 &&
                  key.includes("id") === true &&
                  key.includes("bank") === true
                ) {
                  status = true;
                } else if (
                  key.length > 2 &&
                  key.includes("id") === false &&
                  key.includes("doc") === false
                ) {
                  status = true;
                } else status = false;
                key = key.replace("gtk", "GTK");
                key = key.replace("nik", "NIK");
                const words = key.split("_");
                for (let i = 0; i < words.length; i++) {
                  words[i] = words[i][0].toUpperCase() + words[i].substr(1);
                }
                return status ? (
                  <td>{words.join(" ")}</td>
                ) : key.includes("doc") === true ? (
                  <td>{words.join(" ")}</td>
                ) : (
                  <></>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.data.map((value, idx) => {
              return (
                <tr>
                  <td>{idx + 1}</td>
                  {Object.keys(data.data[0]).map((key) => {
                    let status = false;
                    if (
                      key.length > 2 &&
                      key.includes("id") === true &&
                      key.includes("bank") === true
                    ) {
                      status = true;
                    } else if (
                      key.length > 2 &&
                      key.includes("id") === false &&
                      key.includes("doc") === false
                    ) {
                      status = true;
                    } else status = false;
                    return status ? (
                      <td>{value[key] ? value[key] : ""}</td>
                    ) : key.includes("doc") === true ? (
                      <td>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="fa fa-download me-2"></i>
                          Download
                        </button>
                      </td>
                    ) : (
                      <></>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        data?.data instanceof Array && (
          <label className="text-danger text-center w-100">
            Tidak Ada Data Ditemukan
          </label>
        )
      )}
    </Fragment>
  );
};

export default Table18;
