import { Fragment } from "react";
import { Card } from "react-bootstrap";
import Toolbar from "./toolbar";
import { QueryRequestProvider } from "../../../provider/query-request/absensi-bpi";
import {
  QueryResponseProvider,
  useQueryResponse,
} from "../../../provider/query-response/absensi-bpi";

import { QueryRequestProvider as QueryRequestProviderDaftarAbsensi } from "../../../provider/query-request/absensi-bpi/daftar-absensi";
import {
  QueryResponseProvider as QueryResponseProviderDaftarAbsensi,
  useQueryResponse as useQueryResponseDaftarAbsensi,
} from "../../../provider/query-response/absensi-bpi/daftar-absensi";

import { QueryRequestProvider as QueryRequestProviderLaporanAbsensi } from "../../../provider/query-request/absensi-bpi/laporan-bulanan";
import {
  QueryResponseProvider as QueryResponseProviderLaporanAbsensi,
  useQueryResponse as useQueryResponseLaporanAbsensi,
} from "../../../provider/query-response/absensi-bpi/laporan-bulanan";

import ModalKelompokBPI from "../modal/kelompok-bpi";

import { QueryResponseProvider as QueryResponseProviderKelolaKelompok } from "../../../provider/query-response/absensi-bpi/kelompok-bpi/kelola-kelompok";
import { QueryRequestProvider as QueryRequestProviderKelolaKelompok } from "../../../provider/query-request/absensi-bpi/kelompok-bpi/kelola-kelompok";

import { QueryResponseProvider as QueryResponseProviderKelolaAnggotaKelompok } from "../../../provider/query-response/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";
import { QueryRequestProvider as QueryRequestProviderKelolaAnggotaKelompok } from "../../../provider/query-request/absensi-bpi/kelompok-bpi/kelola-anggota-kelompok";

import { QueryResponseProvider as QueryResponseProviderDataGTK } from "../../../provider/query-response/data-gtk";
import { QueryRequestProvider as QueryRequestProviderDataGTK } from "../../../provider/query-request/data-gtk";

import { QueryResponseProvider as QueryResponseProviderLevelBPI } from "../../../provider/query-response/master-data/level-bpi";

import { QueryResponseProvider as QueryResponseProviderBuatAbsensi } from "../../../provider/query-response/absensi-bpi/buat-absensi";
import { QueryRequestProvider as QueryRequestProviderBuatAbsensi } from "../../../provider/query-request/absensi-bpi/buat-absensi";

import { QueryRequestProvider as QueryRequestProviderRekapAbsensiBPI } from "../../../provider/query-request/absensi-bpi/rekap-absensi";
import { QueryResponseProvider as QueryResponseProviderRekapAbsensiBPI } from "../../../provider/query-response/absensi-bpi/rekap-absensi";

import { QueryResponseProvider as QueryResponseProviderPindahTutor } from "../../../provider/query-response/absensi-bpi/kelompok-bpi/pindah-tutor";
import { QueryRequestProvider as QueryRequestProviderPindahTutor } from "../../../provider/query-request/absensi-bpi/kelompok-bpi/pindah-tutor";

import ModalBuatAbsensi from "../modal/buat-absensi";
import DaftarAbsensi from "./daftar-absensi";
import LaporanBulanan from "./laporan-bulanan";
import RekapAbsensiBPI from "./rekap-absensi";

const AbsensiBPIList = () => {
  const { activeTab, setActiveTab } = useQueryResponse();
  return (
    <Fragment>
      <Toolbar />
      <ModalKelompokBPI />
      <ModalBuatAbsensi />
      <Card.Body className="pt-3">
        <button
          type="button"
          className={` ${
            activeTab === 1
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Daftar Presensi BPI
        </button>

        <button
          type="button"
          className={` ${
            activeTab === 2
              ? "bg-primary text-white border border-primary"
              : "bg-white text-dark border border-white"
          } cursor-pointer btn-md me-2 py-2 px-4 rounded-pill border border-white`}
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Rekapitulasi Presensi BPI
        </button>

        {activeTab === 1 && <DaftarAbsensi />}
        {activeTab === 2 && <RekapAbsensiBPI />}
      </Card.Body>
    </Fragment>
  );
};

export const AbsensiBPIListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <QueryRequestProviderDaftarAbsensi>
        <QueryResponseProviderDaftarAbsensi>
          <QueryRequestProviderLaporanAbsensi>
            <QueryResponseProviderLaporanAbsensi>
              <QueryRequestProviderKelolaKelompok>
                <QueryResponseProviderKelolaKelompok>
                  <QueryRequestProviderKelolaAnggotaKelompok>
                    <QueryResponseProviderKelolaAnggotaKelompok>
                      <QueryRequestProviderPindahTutor>
                        <QueryResponseProviderPindahTutor>
                          <QueryRequestProviderDataGTK>
                            <QueryResponseProviderDataGTK>
                              <QueryResponseProviderLevelBPI>
                                <QueryRequestProviderBuatAbsensi>
                                  <QueryResponseProviderBuatAbsensi>
                                    <QueryRequestProviderRekapAbsensiBPI>
                                      <QueryResponseProviderRekapAbsensiBPI>
                                        <AbsensiBPIList />
                                      </QueryResponseProviderRekapAbsensiBPI>
                                    </QueryRequestProviderRekapAbsensiBPI>
                                  </QueryResponseProviderBuatAbsensi>
                                </QueryRequestProviderBuatAbsensi>
                              </QueryResponseProviderLevelBPI>
                            </QueryResponseProviderDataGTK>
                          </QueryRequestProviderDataGTK>
                        </QueryResponseProviderPindahTutor>
                      </QueryRequestProviderPindahTutor>
                    </QueryResponseProviderKelolaAnggotaKelompok>
                  </QueryRequestProviderKelolaAnggotaKelompok>
                </QueryResponseProviderKelolaKelompok>
              </QueryRequestProviderKelolaKelompok>
            </QueryResponseProviderLaporanAbsensi>
          </QueryRequestProviderLaporanAbsensi>
        </QueryResponseProviderDaftarAbsensi>
      </QueryRequestProviderDaftarAbsensi>
    </QueryResponseProvider>
  </QueryRequestProvider>
);
