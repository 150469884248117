import FetchLoading from "../../../hope-ui/components/fetch-loading";
import { DataTable } from "../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../provider/query-request/mutasi";
import {
  useQueryResponse,
  useQueryResponsePagination,
} from "../../../provider/query-response/mutasi";
import ModalMutasi from "./entry";
import { Columns } from "./list/columns";
import Toolbar from "./list/toolbar";

const Mutasi = () => {
  const {
    isLoadingFetchData,
    listData,
    isLoadingDownloadDocMutasi,
    isLoadingDeleteMutasi,
  } = useQueryResponse();
  return (
    <>
      {(isLoadingDownloadDocMutasi || isLoadingDeleteMutasi) && (
        <FetchLoading />
      )}
      <Toolbar />
      <ModalMutasi />
      <DataTable
        isLoading={isLoadingFetchData}
        header={Columns}
        data={listData?.data}
        pagination={useQueryResponsePagination}
        queryRequest={useQueryRequest}
      />
    </>
  );
};

export default Mutasi;
