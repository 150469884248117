import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormKelompokBPIPayload } from "../kelompok-bpi/_queryResponse";

export type FormAnggotaKelompokBPIPayload = {
  id: number | null;
  id_kelompok_bpi: string | null;
  nik_gtk: string | null;
  nik_tutor_bpi: string | null;
  level_bpi: string | null;
};

export type DetailAnggotaKelompokBPI = {
  id: number | null;
  id_kelompok_bpi: string | null;
  nik_gtk: string | null;
  nik_tutor_bpi: string | null;
  gtk: any;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  handlePostAnggotaKelompokBPI?: (data: FormData) => void;
  isLoadingPostAnggotaKelompokBPI: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteAnggotaKelompokBPI?: (id: string) => void;
  isLoadingDeleteAnggotaKelompokBPI?: boolean;
  handleGetDetailAnggotaKelompokBPI?: (id: string) => void;
  isLoadingGetDetailAnggotaKelompokBPI: boolean;
  detailAnggotaKelompokBPI?: FormAnggotaKelompokBPIPayload;
  setDetailAnggotaKelompokBPI: Dispatch<
    SetStateAction<FormAnggotaKelompokBPIPayload>
  >;
  resetDetailAnggotaKelompokBPI: () => void;
  handleGetAllKelompokBPI?: (nik_gtk: string) => void;
  isLoadingGetAllKelompokBPI: boolean;
  allKelompokBPI: Array<FormKelompokBPIPayload>;
  setAllKelompokBPI: Dispatch<SetStateAction<Array<FormKelompokBPIPayload>>>;
  anggotaKelompokBPI?: Array<DetailAnggotaKelompokBPI>;
  setAnggotaKelompokBPI: Dispatch<
    SetStateAction<Array<DetailAnggotaKelompokBPI>>
  >;
  handleGetAnggotaKelompokBPI: (id: string) => void;
  isLoadingGetAnggotaKelompokBPI: boolean;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostAnggotaKelompokBPI: false,
  resetForm: false,
  setResetForm: () => {},
  isLoadingGetDetailAnggotaKelompokBPI: false,
  isLoadingDeleteAnggotaKelompokBPI: false,
  setDetailAnggotaKelompokBPI: () => {},
  resetDetailAnggotaKelompokBPI: () => {},
  isLoadingGetAllKelompokBPI: false,
  setAllKelompokBPI: () => {},
  allKelompokBPI: [],
  handleGetAnggotaKelompokBPI: () => {},
  isLoadingGetAnggotaKelompokBPI: false,
  setAnggotaKelompokBPI: () => {},
};
