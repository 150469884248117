import { Fragment, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLayout } from "../../layout/core/LayoutProvider";
import { BackupDataListWrapper } from "../../modules/backup-data";

const BackupData = () => {
  const { setSetting } = useLayout();
  useEffect(() => {
    document.title = "Sistem Informasi Bina Ilmi | Backup Data";
    setSetting((prevState) => {
      let jasper = Object.assign({}, prevState);
      jasper.page_breadcrumbs = "Backup Data";
      return jasper;
    });
  }, []);

  return (
    <Fragment>
      <Row>
        <Col md="12" lg="12">
          <Card>
            <BackupDataListWrapper />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default BackupData;
