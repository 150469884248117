import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { DataTable } from "../../../../hope-ui/components/table/DataTable";
import { useQueryRequest } from "../../../../provider/query-request/absensi-gtk";
import { useQueryResponsePagination, useQueryResponse } from "../../../../provider/query-response/absensi-gtk/absensi-hari-ini";
import { Columns } from "./columns";
import Toolbar from "./toolbar";
import ModelCatatanTerlambatGTK from "../model-catatan-gtk-terlambat";


const DaftarPersensiGTK = () => {
  const {listData, isLoadingFetchData} = useQueryResponse();
  return (
   <Card.Body>

      <strong className="p-1 text-dark">Absensi Hari ini : </strong>
      
      <Toolbar />
      <ModelCatatanTerlambatGTK />
      <DataTable
        isLoading={isLoadingFetchData}
       queryRequest={useQueryRequest}
      pagination={useQueryResponsePagination}  
      header={Columns}
      data={listData?.data}
      />
   </Card.Body>
  );
};

export default DaftarPersensiGTK;
