import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormMutasiPayload,
  initialQueryResponse,
} from "../../../models/mutasi/_queryResponse";
import { WithChildren } from "../../../helpers/react18MigrationHelpers";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../../../helpers/crud-helpers/const";
import { SweetAlert } from "../../../hope-ui/components/sweet-alert";
import { stringifyRequestQuery } from "../../../helpers/helpers";
import { useQueryRequest } from "../../query-request/mutasi";
import {
  deleteMutasi,
  downloadDocMutasi,
  getDetailMutasi,
  getListMutasi,
  postMutasi,
} from "../../../api/mutasi/_request";
import { initialQueryState } from "../../../models/global";
import { useAuth } from "../../core/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryResponse as useQueryResponseDetailGTK } from "../detail-gtk";

const QueryResponseContext = createContext(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({ children }) => {
  const { handleGetDetailGTK } = useQueryResponseDetailGTK();
  const { state, updateState } = useQueryRequest();
  const queryClient = useQueryClient();
  state.col_header = `["nomor_surat_mutasi", "unit_kerja_tujuan", "tanggal_mutasi", "tmt_mutasi", "unit_kerja_sebelumnya", "alasan_mutasi"]`;

  const { currentUser } = useAuth();
  state.nik_gtk = currentUser?.data?.username;

  const [showModalMutasi, setShowModalMutasi] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [resetForm, setResetForm] = useState<boolean>(false);

  const [query, setQuery] = useState<string>(
    stringifyRequestQuery(state, "default-query-nik-gtk")
  );
  const updatedQuery = useMemo(
    () => stringifyRequestQuery(state, "default-query-nik-gtk"),
    [state]
  );

  const formikMutasiValidationSchema = Yup.object().shape({
    nomor_surat_mutasi: Yup.string().required(
      "No. Surat Mutasi Tidak Boleh Kosong"
    ),
    tanggal_mutasi: Yup.string().required("Tanggal Mutasi Tidak Boleh Kosong"),
    tmt_mutasi: Yup.string().required("Tanggal TMT Mutasi Tidak Boleh Kosong"),
    unit_kerja_sebelumnya: Yup.string().required(
      "Unit Kerja Sebelumnya Tidak Boleh Kosong"
    ),
    unit_kerja_tujuan: Yup.string().required(
      "Unit Kerja Tujuan Tidak Boleh Kosong"
    ),
    alasan_mutasi: Yup.string().required("Alasan Mutasi Tidak Boleh Kosong"),
    doc_mutasi: Yup.mixed().required("Dokumen Tidak Boleh Kosong"),
  });

  const nik_gtk: any = currentUser?.data?.username;
  initialQueryResponse.detailMutasi.nik_gtk = nik_gtk;

  const [detailMutasi, setDetailMutasi] = useState<FormMutasiPayload>(
    initialQueryResponse.detailMutasi
  );

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  useEffect(() => {
    if (resetForm) {
      formikMutasi.setValues(initialQueryResponse.detailMutasi);
      setDetailMutasi(initialQueryResponse.detailMutasi);
      setSubmitting(false);
      setResetForm(false);
    }
  }, [resetForm]);

  const {
    data: listData,
    isFetching: isLoadingFetchData,
    refetch: refetchListData,
  } = useQuery(
    `${QUERIES.GET_LIST_MUTASI}-${query}`,
    () => getListMutasi(query),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err);
      },
      enabled: query !== "?undefined",
    }
  );

  const formikMutasi = useFormik<FormMutasiPayload>({
    initialValues: {
      id: null,
      id_mutasi: null,
      nomor_surat_mutasi: null,
      tanggal_mutasi: null,
      tmt_mutasi: null,
      unit_kerja_sebelumnya: null,
      unit_kerja_tujuan: null,
      alasan_mutasi: null,
      nik_gtk,
      doc_mutasi: null,
    },
    enableReinitialize: false,
    validationSchema: formikMutasiValidationSchema,
    onSubmit: (values: any) => {
      const payload = new FormData();
      Object.keys(values).map((key, idx) => {
        payload.append(key, values[key]);
      });
      handlePostMutasi(payload);
    },
  });

  const { mutateAsync: PostMutasi, isLoading: isLoadingPostMutasi } =
    useMutation({
      mutationKey: `${QUERIES.POST_MUTASI}`,
      mutationFn: ({ payload }: { payload: FormData }) => postMutasi(payload),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_MUTASI}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handlePostMutasi = useCallback(
    async (payload: any) => {
      await PostMutasi({ payload })
        .then((res) => {
          SweetAlert({
            icon: res.success ? "success" : "warning",
            title: "Info",
            text: res.message,
            allowOutsideClick: false,
          }).then((swal: any) => {
            if (swal.isConfirmed && res.success) {
              if (refetchListData) {
                setShowModalMutasi(false);
                setResetForm(true);
                refetchListData();
                handleGetDetailGTK(res.data.nik_gtk);
              }
            }
          });
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [PostMutasi]
  );

  const { mutateAsync: GetDetailMutasi, isLoading: isLoadingGetDetailMutasi } =
    useMutation({
      mutationKey: `${QUERIES.GET_DETAIL_MUTASI}`,
      mutationFn: ({ id }: { id: string }) => getDetailMutasi(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.GET_DETAIL_MUTASI}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleGetDetailMutasi = useCallback(
    async (id: string) => {
      await GetDetailMutasi({ id })
        .then((res) => {
          setDetailMutasi(res.data);
        })
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [GetDetailMutasi]
  );

  const {
    mutateAsync: DownloadDocMutasi,
    isLoading: isLoadingDownloadDocMutasi,
  } = useMutation({
    mutationKey: `${QUERIES.DOWNLOAD_DOC_MUTASI}`,
    mutationFn: ({ url, fileName }: { url: string; fileName: string }) =>
      downloadDocMutasi(url, fileName),
    onSuccess: async (res: any, variables) => {
      const response = res;
      try {
        await queryClient.invalidateQueries([`${QUERIES.DOWNLOAD_DOC_MUTASI}`]);
        return response;
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleDownloadDocMutasi = useCallback(
    async (url: string, fileName: string) => {
      await DownloadDocMutasi({ url, fileName })
        .then((res) => {})
        .catch((error) => {
          SweetAlert({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: error.data ? error.data.message : error,
            allowOutsideClick: false,
          }).then((res: any) => {
            if (res.isConfirmed) {
            }
          });
        });
    },
    [DownloadDocMutasi]
  );

  const { mutateAsync: DeleteMutasi, isLoading: isLoadingDeleteMutasi } =
    useMutation({
      mutationKey: `${QUERIES.POST_MUTASI}`,
      mutationFn: ({ id }: { id: string }) => deleteMutasi(id),
      onSuccess: async (res: any, variables) => {
        const response = res;
        try {
          await queryClient.invalidateQueries([`${QUERIES.POST_MUTASI}`]);
          return response;
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleDeleteMutasi = useCallback(
    async (id: string) => {
      SweetAlert({
        icon: "warning",
        title: "Info",
        text: "Apakah Anda Yakin Ingin Menghapus Data?",
        allowOutsideClick: false,
        confirmButtonText: "Lanjutkan",
        cancelButtonText: "Batal",
        isShowCancelButton: true,
      }).then(async (swal: any) => {
        if (swal.isConfirmed) {
          await DeleteMutasi({ id })
            .then((res) => {
              SweetAlert({
                icon: res.success ? "success" : "warning",
                title: "Info",
                text: res.message,
                allowOutsideClick: false,
              }).then((swal: any) => {
                if (swal.isConfirmed) {
                  if (refetchListData) refetchListData();
                  handleGetDetailGTK(res.data.nik_gtk);
                }
              });
            })
            .catch((error) => {
              SweetAlert({
                icon: "error",
                title: "Terjadi Kesalahan",
                text: error.data ? error.data.message : error,
                allowOutsideClick: false,
              }).then((res: any) => {
                if (res.isConfirmed) {
                }
              });
            });
        }
      });
    },
    [DeleteMutasi]
  );

  const value = useMemo(
    () => ({
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalMutasi,
      setShowModalMutasi,
      formikMutasi,
      formikMutasiValidationSchema,
      submitting,
      setSubmitting,
      handlePostMutasi,
      isLoadingPostMutasi,
      detailMutasi,
      setDetailMutasi,
      handleGetDetailMutasi,
      isLoadingGetDetailMutasi,
      resetForm,
      setResetForm,
      handleDownloadDocMutasi,
      isLoadingDownloadDocMutasi,
      handleDeleteMutasi,
      isLoadingDeleteMutasi,
    }),
    [
      query,
      listData,
      isLoadingFetchData,
      refetchListData,
      showModalMutasi,
      setShowModalMutasi,
      formikMutasi,
      formikMutasiValidationSchema,
      submitting,
      setSubmitting,
      handlePostMutasi,
      isLoadingPostMutasi,
      detailMutasi,
      setDetailMutasi,
      handleGetDetailMutasi,
      isLoadingGetDetailMutasi,
      resetForm,
      setResetForm,
      handleDownloadDocMutasi,
      isLoadingDownloadDocMutasi,
      handleDeleteMutasi,
      isLoadingDeleteMutasi,
    ]
  );
  return (
    <QueryResponseContext.Provider value={value}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);
const useQueryResponsePagination = () => {
  const defaultPaginationState = {
    ...initialQueryState,
  };

  const { listData } = useQueryResponse();

  return listData ?? defaultPaginationState;
};
export { QueryResponseProvider, useQueryResponse, useQueryResponsePagination };
