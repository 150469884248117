import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";

export type QueryResponseContextProps<T> = {
  isShowModalBuatAbsensiGTK: boolean;
  setShowModalImportAbsensiGTK: Dispatch<SetStateAction<boolean>>
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  ActiveTab:number
  setActiveTab:Dispatch<SetStateAction<number>>
  handleExportExcelAbsensiGTKInput?: () => void;
  //isLoadingExportExcelAbsensiGTKInput: boolean
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isShowModalBuatAbsensiGTK:false,
  //isLoadingExportExcelAbsensiGTKInput : false,
  setShowModalImportAbsensiGTK:() => {},
  isLoadingFetchData:false,
  ActiveTab:1,
  setActiveTab:() => {},
};