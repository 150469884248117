import { ShowLimitDT } from "../../../../hope-ui/components/table/ShowLimit";
import { SearchInputDT } from "../../../../hope-ui/components/table/SearchInput";
import { useQueryRequest } from "../../../../provider/query-request/data-gtk";
import { Card } from "react-bootstrap";
const Toolbar = () => {
  const { state, updateState } = useQueryRequest();

  return (
    <div>
      <div className="row pt-3 px-4 mb-0 pb-0">
        <div className="col-md-6 col-12">
          <ShowLimitDT
            onChange={(e) => updateState({ size: parseInt(e.target.value) })}
            limit={state.size}
          />
        </div>
        <div className="col-md-6 col-12 ">
          <SearchInputDT
            state={(keyword: string) => {
              updateState({ search: keyword });
            }}
            value={state.search ? state.search : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
