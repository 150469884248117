import { Dispatch, SetStateAction } from "react";
import { Response } from "../../global";
import { FormikProps } from "formik";
import { DetailAnggotaKelompokLT3Q } from "../anggota-kelompok-lt3q/_queryResponse";
import { FormKelompokLT3QPayload } from "../kelompok-lt3q/_queryResponse";

export type FormPindahTutorLT3QPayload = {
  id: number | string | null;
  id_kelompok_lt3q: number | string | null;
  id_anggota_kelompok_lt3q: number | string | null;
  nik_tutor_lt3q_lama: string | null;
  nik_tutor_lt3q_tujuan: string | null;
  id_kelompok_lt3q_tujuan: number | string | null;
  keterangan: string | null;
};

export type TutorLT3Q = {
  nik_gtk: string | null;
  nama_gtk: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  formikPindahTutorLT3Q?: FormikProps<FormPindahTutorLT3QPayload>;
  formikPindahTutorLT3QValidationSchema?: any;
  handlePostPindahTutorLT3Q?: (payload: FormData) => void;
  isLoadingPostPindahTutorLT3Q: boolean;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handleGetTutorLT3Q?: () => void;
  isLoadingGetTutorLT3Q: boolean;
  allTutorLT3Q: Array<TutorLT3Q>;
  setAllTutorLT3Q: Dispatch<SetStateAction<Array<TutorLT3Q>>>;
  allKelompokTutorLT3QTujuan: Array<FormKelompokLT3QPayload>;
  setAllKelompokTutorLT3QTujuan: Dispatch<
    SetStateAction<Array<FormKelompokLT3QPayload>>
  >;
  handleGetAllKelompokLT3QTujuan?: (nik_gtk: string, type?: string) => void;
  isLoadingGetAllKelompokLT3QTujuan: boolean;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  isLoadingPostPindahTutorLT3Q: false,
  submitting: false,
  setSubmitting: () => {},
  handleGetTutorLT3Q: () => {},
  isLoadingGetTutorLT3Q: false,
  allTutorLT3Q: [],
  setAllTutorLT3Q: () => {},
  allKelompokTutorLT3QTujuan: [],
  setAllKelompokTutorLT3QTujuan: () => {},
  isLoadingGetAllKelompokLT3QTujuan: false,
  resetForm: false,
  setResetForm: () => {},
};
