import { AxiosResponse } from "axios";
import { client } from "../apiClient";
import { ApiConfig } from "../apiConfig";

const getListPelajaranDiampu = async (query: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_LIST_MASTER_MAPEL_URL}?${query}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const postPelajaranDiampu = async (data: FormData): Promise<any> => {
  return client
    .post(`${ApiConfig.POST_MASTER_MAPEL_URL}`, data)
    .then((response: AxiosResponse<any>) => response.data);
};

const getDetailPelajaranDiampu = async (id: string): Promise<any> => {
  return client
    .get(`${ApiConfig.GET_DETAIL_MASTER_MAPEL_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

const deletePelajaranDiampu = async (id: string): Promise<any> => {
  return client
    .delete(`${ApiConfig.DELETE_MASTER_MAPEL_URL}/${id}`)
    .then((response: AxiosResponse<any>) => response.data);
};

export {
  getListPelajaranDiampu,
  postPelajaranDiampu,
  getDetailPelajaranDiampu,
  deletePelajaranDiampu,
};
