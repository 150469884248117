import { Dispatch, SetStateAction } from "react";
import { Response } from "../global";
import { FormikProps } from "formik";
import { FormMasterMapelPayload } from "../master-data/master-mapel/_queryResponse";

export type FormMapelPayload = {
  id: number | string | null;
  id_mapel: string | null;
  nik_gtk: string | null;
  nama_mapel: string | null;
};

export type QueryResponseContextProps<T> = {
  query?: string;
  listData?: Response<Array<T>>;
  isLoadingFetchData: boolean;
  refetchListData?: () => void;
  showModalMapel: boolean;
  setShowModalMapel: Dispatch<SetStateAction<boolean>>;
  formikMapel?: FormikProps<FormMapelPayload>;
  formikMapelValidationSchema?: any;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handlePostMapel: (data: FormData) => void;
  isLoadingPostMapel: boolean;
  handleGetDetailMapel: (id: string) => void;
  isLoadingGetDetailMapel: boolean;
  detailMapel: FormMapelPayload;
  setDetailMapel: Dispatch<SetStateAction<FormMapelPayload>>;
  resetForm: boolean;
  setResetForm: Dispatch<SetStateAction<boolean>>;
  handleDeleteMapel: (id: string) => void;
  isLoadingDeleteMapel: boolean;
  handleGetAllMapel: () => void;
  isLoadingGetAllMapel: boolean;
  allMapel: Array<FormMasterMapelPayload>;
  setAllMapel: Dispatch<SetStateAction<Array<FormMasterMapelPayload>>>;
};

export const initialQueryResponse: QueryResponseContextProps<any> = {
  isLoadingFetchData: false,
  showModalMapel: false,
  setShowModalMapel: () => {},
  submitting: false,
  setSubmitting: () => {},
  handlePostMapel: () => {},
  isLoadingPostMapel: false,
  handleGetDetailMapel: () => {},
  isLoadingGetDetailMapel: false,
  detailMapel: {
    id: null,
    id_mapel: null,
    nik_gtk: null,
    nama_mapel: null,
  },
  setDetailMapel: () => {},
  resetForm: false,
  setResetForm: () => {},
  handleGetAllMapel: () => {},
  isLoadingGetAllMapel: false,
  handleDeleteMapel: () => {},
  isLoadingDeleteMapel: false,
  allMapel: [],
  setAllMapel: () => {},
};
